import { Typography } from '@mui/material';
import { ComponentProps, ReactNode } from 'react';
import { defaultTheme } from '../resources/theme/defaultTheme';

export const Text = ({
  children,
  sx,
  type,
  ...props
}: {
  children: ReactNode;
  type?: 'error' | 'warning' | 'info' | 'success';
} & ComponentProps<typeof Typography>) => (
  <Typography
    sx={{
      color: type
        ? defaultTheme.palette[type].main
        : defaultTheme.palette.common.black,
      ...sx,
    }}
    {...props}>
    {children}
  </Typography>
);

const logo = (
  <svg
    width="139"
    height="41"
    viewBox="0 0 139 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="20.5" cy="20.5" r="20.5" fill="#00345E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 29.0417C25.4183 29.0417 29 25.46 29 21.0417C29 16.6235 25.4183 13.0417 21 13.0417C16.5817 13.0417 13 16.6235 13 21.0417C13 22.8101 13.5737 24.4444 14.5452 25.7688H12.1856C11.4291 24.3612 11 22.7516 11 21.0417C11 15.5189 15.4772 11.0417 21 11.0417C26.5228 11.0417 31 15.5189 31 21.0417C31 26.5646 26.5228 31.0417 21 31.0417C19.4475 31.0417 17.9777 30.688 16.6667 30.0566V32.0001H15V28.6667V27.0001H16.6667L20 27.0001V28.6667H18.5723C19.338 28.9103 20.1536 29.0417 21 29.0417ZM23 16.0001H25V25.0001H23V16.0001ZM22 25.0001V18.0001H20V25.0001H22ZM19 20.0001H17V25.0001H19V20.0001Z"
      fill="white"
    />
    {/* <path
      d="M57 27.1331H59.088V21.4271H63.984V19.6811H59.088V15.6311H64.848V13.8671H57V27.1331Z"
      fill="#00345E"
    />
    <path
      d="M67.1953 27.1331H69.2833V13.8671H67.1953V27.1331Z"
      fill="#00345E"
    />
    <path
      d="M76.53 27.3671C79.428 27.3671 81.192 25.6391 81.192 23.5151C81.192 21.5711 80.058 20.5991 78.492 19.9331L76.674 19.1591C75.594 18.7271 74.532 18.2951 74.532 17.1431C74.532 16.0991 75.414 15.4511 76.764 15.4511C77.934 15.4511 78.852 15.9011 79.68 16.6391L80.778 15.3071C79.77 14.2811 78.312 13.6331 76.764 13.6331C74.244 13.6331 72.408 15.1991 72.408 17.2871C72.408 19.2131 73.83 20.2211 75.108 20.7611L76.944 21.5531C78.168 22.0931 79.068 22.4711 79.068 23.6771C79.068 24.7931 78.168 25.5491 76.566 25.5491C75.288 25.5491 73.992 24.9371 73.038 23.9831L71.814 25.4231C73.02 26.6471 74.712 27.3671 76.53 27.3671Z"
      fill="#00345E"
    />
    <path
      d="M85.9969 27.1331H88.0849V15.6311H91.9909V13.8671H82.1089V15.6311H85.9969V27.1331Z"
      fill="#00345E"
    />
    <path
      d="M98.3262 27.1331H100.414V22.1111H102.412C105.292 22.1111 107.398 20.7791 107.398 17.8811C107.398 14.8931 105.292 13.8671 102.34 13.8671H98.3262V27.1331ZM100.414 20.4371V15.5591H102.142C104.248 15.5591 105.346 16.1351 105.346 17.8811C105.346 19.6271 104.338 20.4371 102.214 20.4371H100.414Z"
      fill="#00345E"
    />
    <path
      d="M109.98 27.1331H117.684V25.3691H112.068V13.8671H109.98V27.1331Z"
      fill="#00345E"
    />
    <path
      d="M122.556 19.9331C122.988 18.4931 123.402 17.0351 123.78 15.5231H123.87C124.266 17.0171 124.662 18.4931 125.112 19.9331L125.634 21.7151H122.016L122.556 19.9331ZM118.272 27.1331H120.396L121.53 23.3531H126.138L127.254 27.1331H129.468L125.076 13.8671H122.664L118.272 27.1331Z"
      fill="#00345E"
    />
    <path
      d="M132.684 27.1331H134.772V15.6311H138.678V13.8671H128.796V15.6311H132.684V27.1331Z"
      fill="#00345E"
    /> */}
  </svg>
);

export default logo;

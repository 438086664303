import React from 'react';
import { Box } from '@material-ui/core';
import colors from '../resources/colors';

type Props = {
  content: string;
  active: boolean;
};

const Expertise = (props: Props) => {
  const { content, active } = props;
  return (
    <>
      {active === true ? (
        <Box
          width={50}
          height={50}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            borderRadius: '50%',
            fontSize: 15,
            backgroundColor: colors.twitterColor,
            color: colors.white,
            border: '1.5px solid gray',
            borderColor: colors.gray,
          }}>
          {' '}
          {content}
        </Box>
      ) : (
        <Box
          width={50}
          height={50}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            borderRadius: '50%',
            fontSize: 15,
            backgroundColor: colors.skygray,
            color: colors.gray,
            border: '1.5px solid gray',
            borderColor: colors.gray,
          }}>
          {' '}
          {content}
        </Box>
      )}
    </>
  );
};

export default Expertise;

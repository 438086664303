import firebase from 'firebase';
import { auth } from '../firebase';

/** 空白判定
 * @param text 入力文字列
 * @return {boolean} 空、もしくは空白があれば true、違えば false
 */
export const isTextInvalid = (text: string): boolean => {
  const isNull = !text;
  const isHaveWhiteSpace = !text.match(/\S/g); // \S：空白文字（半角スペース、\t、\n、\r、\f）以外すべて
  return isNull || isHaveWhiteSpace;
};

/** 入力文字判定（数字）
 * @param {string} text 入力された文字
 * @return {boolean} 数字ならば true、違えば false
 */
export const isTextIsNumber = (text: string): boolean =>
  text !== '' && +text >= 0;

/** 入力した現在のパスワードを用いたユーザー再認証
 * 参考リンク：
 * ・https://firebase.google.com/docs/auth/web/manage-users?hl=ja#re-authenticate_a_user
 * ・https://cloud.google.com/identity-platform/docs/work-with-mfa-users?hl=ja#re-authenticating_a_user
 * @param {string} password 現在のパスワード
 */
async function validateCurrentPassword(password: string): Promise<void> {
  // 再認証に使用するメールアドレスの取得
  const email = auth.currentUser?.email;
  if (!email) {
    throw new Error('ユーザーの取得に失敗しました');
  }

  // アカウント再認証（パスワードが正しいかの確認になる）
  await auth.currentUser
    ?.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(email, password)
    )
    .catch((error: firebase.FirebaseError) => {
      if (error.code === 'auth/multi-factor-auth-required') {
        // TODO: Handle multi-factor authentication.
        throw new Error('TODO: 多要素認証にてエラーが発生しました');
      } else {
        throw new Error('現在のパスワードが違います');
      }
    });
}

/** emailチェック用正規表現 */
// ローカル部分: アルファベット, 数字, _.+-の利用を許可
const emailValidator =
  /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

/** 入力されたメールアドレスが有効か判定する
 * エラーがあればコメントを出力する
 * @param email 入力メールアドレス
 */
export const isInvalidEmail = (email: string) => {
  if (!emailValidator.test(email)) {
    throw new Error('メールアドレスの形式が不正です。ご確認をお願いします。');
  }
  // TODO: 他のvalidation
};

/** パスワードチェック用正規表現 */
// 8桁以上入力のバリチェックの正規表現(利用可能文字を制限)
const passwordValidator = /^[a-zA-Z0-9-!\\/:-@[-`{-~!-~.?/-]{8,24}/;
// 必要に応じ変更
// 【参考】半角英数大文字・小文字、記号を含めた12桁以上入力のバリチェックの正規表現
// /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[-!\\/:-@[-`{-~])[!-~]{12,32}$/;

/** 入力されたパスワードが有効か判定する
 * エラーがあればコメントを出力する
 * @param {string} oldPassword 入力された現在のパスワード
 * @param {string} newPassword 入力された新しいパスワード
 * @param {string} newConfirmPassword 入力された新しいパスワード（確認用）
 */
export const isInvalidPassword = async (
  oldPassword: string,
  newPassword: string,
  newConfirmPassword: string
): Promise<void> => {
  // 入力した現在のパスワードを用いたユーザー再認証
  await validateCurrentPassword(oldPassword);

  // 新しいパスワードと確認用が一致するか判定
  if (newPassword !== newConfirmPassword) {
    throw new Error('新しいパスワードと新しいパスワード(確認)が一致しません');
  }

  // パスワードに使用している文字種チェック
  if (!passwordValidator.test(newPassword)) {
    throw new Error(
      'パスワードは半角英数大文字・小文字、記号を含め8桁以上で設定してください'
    );
  }

  // 変更前と後でパスワードが一致するか判定
  if (oldPassword === newPassword) {
    throw new Error('現在とは異なるパスワードを入力してください');
  }
  // TODO: 他のvalidation

  // TODO:13世代チェック
};

/** 入力されたパスワードが有効か判定する（サインアップ時）
 * エラーがあればコメントを出力する
 * @param {string} password 入力されたパスワード
 */
export const isInvalidPasswordAtSignUp = (password: string) => {
  // パスワードに使用している文字種チェック
  if (!passwordValidator.test(password)) {
    throw new Error(
      'パスワードは半角英数大文字・小文字、記号を含め8桁以上で設定してください'
    );
  }

  // TODO: 他のvalidation
};

/** 入力されたPINコードが有効か判定する
 * エラーがあればコメントを出力する
 * @param {string} pinCode 入力されたPINコード
 * @param {string} sentPinCode 送信されたPINコード
 */
export const isInvalidPinCode = (pinCode: string, sentPinCode: string) => {
  // 入力されたPINコードと送信されたPINコードが一致するか確認
  if (pinCode !== sentPinCode) {
    throw new Error(
      `会員登録コードが違います。\n再度コードを入力し直してください。`
    );
  }

  // TODO: 他のvalidation
};

import {
  ChatBubbleOutline,
  EditOutlined,
  GradingOutlined,
  History,
  ListAlt,
  LogoutOutlined,
  MailOutlined,
  NotificationsOutlined,
  SpatialAudioOutlined,
  SettingsOutlined,
} from '@mui/icons-material';
import { IconButton, Paper } from '@mui/material';
import { SxProps } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getImageUrlFromStorage } from '../../modules/functions/storageFunctions';
import { getIsFp } from '../../modules/functions/userFunctions';
import { AppDispatch } from '../../modules/store';
import { signOut } from '../../modules/store/slices/userSlice';
import { defaultTheme } from '../../resources/theme/defaultTheme';
import {
  LogoArea,
  Nav,
  NavList,
  NavListItem,
  NavListItemIcon,
  NavListItemText,
  UserArea,
  UserImage,
  UserName,
} from './components';
import logo from './logo';

const navList: Nav[] = [
  { icon: <ListAlt />, label: '新規顧客一覧', to: '/new-client' },
  {
    icon: <ChatBubbleOutline />,
    label: '相談中の顧客',
    to: '/message-history',
  },
  { icon: <History />, label: '面談履歴', to: '' },
];

const profileList: Nav[] = [
  { icon: <EditOutlined />, label: 'プロフィール', to: '' },
  { icon: <GradingOutlined />, label: 'ユーザー評価', to: '' },
  { icon: <MailOutlined />, label: '自動返信文', to: '/first-message' },
  {
    icon: <SpatialAudioOutlined />,
    label: 'メール通知設定',
    to: '/notification-setting',
  },
  {
    icon: <NotificationsOutlined />,
    label: '集客ON/OFF',
    to: '/customers-control',
  },
  { icon: <SettingsOutlined />, label: '設定', to: '' },
];

const SideNav = ({ sx }: { sx: SxProps }) => {
  const { nickName, uid } = useSelector((state) => state.user);
  const dispatch = useDispatch<AppDispatch>();
  const logout = async () => {
    await dispatch(signOut());
    window.location.href = '/fp-signIn';
  };
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    const fetch = async () =>
      setImageUrl(await getImageUrlFromStorage(`fp-images/${uid}.png)`));
    fetch();
  }, [uid]);
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${defaultTheme.palette.grey[400]}`,
        borderRadius: 0,
        ...sx,
      }}>
      <LogoArea
        onClick={() => {
          window.location.href = '/new-client';
        }}>
        {logo}
      </LogoArea>
      <NavList sx={{ flex: 1 }}>
        {navList.map((item) => (
          <NavListItem key={item.label} item={item}>
            <NavListItemIcon icon={item.icon} />
            <NavListItemText label={item.label} />
          </NavListItem>
        ))}
      </NavList>
      <NavList>
        {profileList.map((item) => (
          <NavListItem key={item.label} item={item}>
            <NavListItemIcon icon={item.icon} />
            <NavListItemText label={item.label} />
          </NavListItem>
        ))}
      </NavList>
      <UserArea>
        {getIsFp() && <UserImage imageUrl={imageUrl} />}
        <UserName title={nickName}>{nickName}</UserName>
        <IconButton onClick={logout}>
          <LogoutOutlined />
        </IconButton>
      </UserArea>
    </Paper>
  );
};

export default SideNav;

import firebase from 'firebase';
import liff from '@line/liff';

import { auth, db, functions } from '../../firebase';

import { FpOfFirestore } from './matchingFunctions';
import { getImageUrlFromStorage } from './storageFunctions';
import { diagnosisDataConverter } from '../entities/DiagnosisData';

import { DB_PATH } from '../../resources/constants';

const FP_IMAGE_PATH = 'fp-images';
const USER_IMAGE_PATH = 'user-images';

type UserNickName = {
  nickName: string;
};

export type LifePlanningInfo = {
  // 仕事に関する情報
  age: number;
  annualIncome: number;
  startWorkingAge: number;
  retirementAge: number;
  retirementPay: number;
  // 配偶者情報
  spouse?: {
    age: number;
    annualIncome: number;
    startWorkingAge: number;
    retirementAge: number;
    retirementPay: number;
  };
  // 老後に関する情報
  livingExpenses: number;
  housingCosts: number;
  otherExpenses: number;
  savings: number;

  // 出力内容
  retirementFund: number; // 老後に必要な資金
  reserveAmount: number; // 毎月の積立額
  reserveAmount3percent: number; // 毎月の積立額(3%で投資した場合)
};

export type ProfileInfo = {
  mail: string;
  imageUrl?: string; // TODO Deprecated, 変わりにgetImageUrlFromStorageを使う
  isDisplayed?: boolean; // 一覧画面への表示可否フラグ(true: 表示、false:非表示)
  receiveNotificationFlag?: boolean; // 通知メールの受け取り要否フラグ(true: 受け取り、false or undefined: 受け取らない)
  isFp: boolean;
};

/* Read */
/**
 * NickName I/O コンバーター
 */
const nickNameConverter: firebase.firestore.FirestoreDataConverter<UserNickName> =
  {
    toFirestore: (
      userNickName: UserNickName
    ): firebase.firestore.DocumentData => userNickName,
    fromFirestore: (
      snapshot: firebase.firestore.QueryDocumentSnapshot
    ): UserNickName => {
      const userNickNameData = snapshot.data() as UserNickName;
      return {
        nickName: userNickNameData.nickName,
      };
    },
  };

/**
 * (共通)UserIDの取得
 * 現在ログイン中のユーザーIDの取得
 */
export function getUserUid(): string {
  if (auth.currentUser) {
    return auth.currentUser.uid;
  }
  return 'undefined';
}
/**
 * (共通)メールアドレス取得
 * @return {string} email
 */
export function getEmail(): string {
  if (auth.currentUser) {
    return auth.currentUser.email ?? 'undefined';
  }
  return 'undefined';
}
/**
 * (共通)nick nameの取得
 * @return {string} nick name
 */
export async function getNickName(): Promise<string> {
  const isFp = getIsFp();
  const uid = getUserUid();
  const documentSnapshot = await db
    .collection(isFp ? DB_PATH.FPS : DB_PATH.USERS)
    .doc(uid)
    .withConverter(nickNameConverter)
    .get();
  const userNickName = documentSnapshot.data() as UserNickName;
  return userNickName.nickName;
}
/**
 * (共通)roleの取得
 * @return {boolean} role(FP or User)
 */
export function getIsFp(): boolean {
  if (auth.currentUser) {
    return auth.currentUser.displayName === 'FP';
  }
  return false;
}
/**
 * (共通)roleの取得(URLチェック)
 * URL の role から isFp の判定
 * @param {'fp' | 'user'} role
 * @return {boolean} role(FP or User)
 */
export function getIsFpFromUrl(role: 'fp' | 'user'): boolean {
  if (role === 'fp') {
    return true;
  }
  if (role === 'user') {
    return false;
  }
  throw new Error('入力された文字が正しくありません');
}

/**
 * (clientUser)診断結果の取得
 * userドキュメントから過去のライフプランニング診断の結果を取得する。
 */
export async function fetchLifePlanningInfo(
  userID?: string
): Promise<LifePlanningInfo | undefined> {
  // TODO: さすがにasで型付けはやめる。converterを正しく使う。
  const user = (await (
    await db
      .collection(DB_PATH.USERS)
      .doc(userID ?? getUserUid())
      .get()
  ).data()) as {
    lifePlanningInfo: LifePlanningInfo | undefined;
  };
  return user.lifePlanningInfo;
}
/**
 * プロフィール情報取得
 * @returns {Promise<ProfileInfo>}
 */
export async function getSelectPictureScreenInfo(): Promise<ProfileInfo> {
  await new Promise((resolve) => setTimeout(resolve, 1000));

  const imageUrl = getIsFp()
    ? await getImageUrlFromStorage(`${FP_IMAGE_PATH}/${getUserUid()}.png`)
    : await getImageUrlFromStorage(`${USER_IMAGE_PATH}/${getUserUid()}.png`);

  let isDisplayed: undefined | boolean;
  if (getIsFp()) {
    const documentSnapshot = await db
      .collection(DB_PATH.FPS)
      .doc(getUserUid())
      .get();
    const data = documentSnapshot.data() as FpOfFirestore;
    isDisplayed = data.isDisplayed;
  }

  return {
    mail: getEmail(),
    imageUrl,
    isDisplayed: isDisplayed ?? true,
    isFp: getIsFp(),
  };
}

/* Update */

/**
 * (共通)パスワードを更新する
 * @param {string} password
 */
export async function updatePassowrd(password: string) {
  if (!auth.currentUser) {
    throw new Error('ユーザーの取得に失敗しました');
  }
  auth.currentUser.updatePassword(password);
}

/**
 * ニックネームを追加する
 * @param {string} nickname
 * @returns {Promise<void>}
 */
export async function setNickname(nickname: string): Promise<void> {
  if (!auth.currentUser) {
    throw new Error('ユーザーの取得に失敗しました');
  }

  await db
    .collection(getIsFp() ? DB_PATH.FPS : DB_PATH.USERS)
    .doc(getUserUid())
    .set({
      nickName: nickname,
    });
}

/**
 * ニックネームを更新する
 * @param {string} nickname
 * @returns {Promise<void>}
 */
export async function updateNickname(nickname: string): Promise<void> {
  if (!auth.currentUser) {
    throw new Error('ユーザーの取得に失敗しました');
  }

  await db
    .collection(getIsFp() ? DB_PATH.FPS : DB_PATH.USERS)
    .doc(getUserUid())
    .update({
      nickName: nickname,
    });
}

/**
 * userドキュメントにライフプランニング診断の結果を保存する。
 * @param lifePlanningInfo - フォームの入力情報など
 */
export async function addLifePlanningInfo(
  lifePlanningInfo: LifePlanningInfo
): Promise<void> {
  await db.collection(DB_PATH.USERS).doc(getUserUid()).set(
    {
      lifePlanningInfo,
    },
    {
      merge: true,
    }
  );
}

/**
 * マッチング画面への表示・非表示を切り替える
 * @param {boolean} isDisplayed
 * @returns {Promise<void>}
 */
export async function updateIsDisplayed(isDisplayed: boolean): Promise<void> {
  try {
    await db.collection(DB_PATH.FPS).doc(getUserUid()).set(
      { isDisplayed },
      {
        merge: true,
      }
    );
  } catch (error) {
    throw error;
  }
  // slackに情報を通知
  const fpName = await getNickName();
  const isDisplayedStatus = isDisplayed ? '表示' : '非表示';

  try {
    functions.httpsCallable('noticeSlack')({
      body: `FP: ${fpName} さんがマッチング画面での表示状態を[${isDisplayedStatus}]に変更しました。`,
    });
  } catch (error) {
    throw error;
  }
}

/**
 * (FP)クライアントユーザ関連の通知メール受け取りの要否を切り替える
 * @param {boolean} receiveNotificationFlag
 * @returns {Promise<void>}
 */
export async function switchReceiveNotificationFlag(
  receiveNotificationFlag: boolean
): Promise<void> {
  try {
    await db.collection(DB_PATH.FPS).doc(getUserUid()).set(
      { receiveNotificationFlag },
      {
        merge: true,
      }
    );
  } catch (error) {
    throw error;
  }
  // slackに情報を通知
  const fpName = await getNickName();
  const receiveNoticeStatus = receiveNotificationFlag ? '通知する' : '通知なし';

  try {
    functions.httpsCallable('noticeSlack')({
      body: `FP: ${fpName} さんが初回診断のメール通知設定を[${receiveNoticeStatus}]に変更しました。`,
    });
  } catch (error) {
    throw error;
  }
}

/* 認証処理 */
/**
 * user.displayName の登録
 * role(FP or User)の登録として利用
 * @param {'FP' | 'User'} role
 */
export async function registerUserRoll(role: 'FP' | 'User') {
  if (auth.currentUser) {
    await auth.currentUser.updateProfile({
      displayName: role,
    });
  }
}
/**
 * randomUserNameCrateor
 * @return {string} randomUserName
 */
function randomUserNameCrateor(): string {
  const length = 8; // 生成したい文字列の長さ
  const source = 'abcdefghijklmnopqrstuvwxyz0123456789'; // 元になる文字

  return Array.from(crypto.getRandomValues(new Uint8Array(length)))
    .map((n) => source[n % source.length])
    .join('');
}

/**
 * (clientUser)signUp
 * @param email
 * @param password
 */
export async function signUp(
  email: string,
  password: string,
  nickName: string
): Promise<void> {
  try {
    // Authentication への登録
    await auth.createUserWithEmailAndPassword(email, password);

    // fs への登録
    // TODO: 登録内容を登録画面で入力させる
    try {
      await db
        .collection(DB_PATH.USERS)
        .doc(getUserUid())
        .set({
          nickName,
          job: '',
          lastLoginDate: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    } catch (error) {
      throw error;
    }

    // ロールの登録
    try {
      await registerUserRoll('User');
    } catch (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }

  // slackに情報を通知
  try {
    functions.httpsCallable('noticeSlack')({
      body: `(${process.env.REACT_APP_ENV})【新規ユーザ】ユーザ登録が完了しました! nickName: ${nickName} , email:${email} `,
    });
  } catch (error) {
    throw error;
  }
}

/**
 * (clientUser)signUp Anonymous
 * 匿名ユーザアカウントをAuthenticationに登録
 * @param email
 * @param password
 */
export async function signUpAnonymous(
  email: string,
  password: string,
  nickName: string
): Promise<void> {
  // 証明書の発行
  const credential = firebase.auth.EmailAuthProvider.credential(
    email,
    password
  );
  try {
    // Authentication への登録
    // 現在ログイン中のユーザ(匿名ユーザ)と証明書をリンク付け
    firebase.auth().currentUser?.linkWithCredential(credential);

    // fs への登録
    // TODO: 登録内容を登録画面で入力させる
    try {
      await db
        .collection(DB_PATH.USERS)
        .doc(getUserUid())
        .set({
          nickName,
          job: '',
          lastLoginDate: firebase.firestore.Timestamp.fromDate(new Date()),
        });
    } catch (error) {
      throw error;
    }

    // ロールの登録
    try {
      await registerUserRoll('User');
    } catch (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }

  // slackに情報を通知
  try {
    functions.httpsCallable('noticeSlack')({
      body: `(${process.env.REACT_APP_ENV})【新規ユーザ】ユーザ登録が完了しました! nickName: ${nickName} , email:${email} `,
    });
  } catch (error) {
    throw error;
  }
}

/**
 * (FP)signUp
 * @param email
 * @param password
 */
export async function signUpFp(
  email: string,
  password: string,
  nickName: string,
  belongs: string | undefined,
  age: number | undefined,
  sex: string | undefined,
  fpHistory: number | undefined,
  specialtyArray: string[] | undefined,
  deliverable: string | undefined,
  firstTimeFree: string | undefined,
  fee: string | undefined,
  financialProductsArray: string[] | undefined,
  introduction: string | undefined,
  others: string | undefined
): Promise<void> {
  try {
    // Authentication への登録
    await auth.createUserWithEmailAndPassword(email, password);

    // fs への登録
    // TODO: 登録内容を登録画面で入力させる
    try {
      await db
        .collection(DB_PATH.FPS)
        .doc(getUserUid())
        .set({
          nickName,
          belongs,
          age,
          sex,
          fpHistory: fpHistory ?? null,
          deliverable,
          firstTimeFree,
          fee,
          introduction,
          others,
          specialtyArray,
          financialProductsArray,
        }); // TODO: FPの登録処理
    } catch (error) {
      throw error;
    }

    // ロールの登録
    try {
      await registerUserRoll('FP');
    } catch (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
}

/**
 * (共通)会員登録コード送信
 * 会員登録時、記載されたメールアドレスにコードを送信する
 * @param {string} email
 * @returns {Promise<string>}
 */
export async function sendPinCode(email: string): Promise<string> {
  // ランダムな整数を出力する（0~9）
  const getRandomInt = (): number => Math.floor(Math.random() * 10);

  // PINコード生成
  const pinCode = `${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}${getRandomInt()}`;

  // メールでPINコード送信
  try {
    await functions.httpsCallable('sendMail')({
      receiverEmailAddress: email,
      title: '【MoneyDash】会員登録コードのお知らせ',
      body: `※こちらはMoneyDashの送信専用メールです。\n\n\n以下の会員登録コードを用いて会員登録を完了してください。\n\n「${pinCode}」\n\n`,
    });
    return pinCode;
  } catch (error) {
    throw error;
  }
}
/**
 * (共通)SignIn
 *  ID/Password認証
 * @param email
 * @param password
 */
export async function signIn(email: string, password: string): Promise<void> {
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (error) {
    throw error;
  }

  // DB上のログイン日時を更新
  try {
    await db
      .collection(DB_PATH.USERS)
      .doc(getUserUid())
      .set(
        {
          lastLoginDate: firebase.firestore.Timestamp.fromDate(new Date()), // 最終ログイン日更新
        },
        { merge: true }
      );
  } catch (error) {
    throw error;
  }
}
/**
 * (共通)signOut
 */
export async function signOut(): Promise<void> {
  try {
    liff.init({ liffId: process.env.REACT_APP_LIFF_ID ?? '' }).then(() => {
      if (liff.isLoggedIn()) {
        liff.logout();
      }
    });
    await auth.signOut();
  } catch (error) {
    throw error;
  }
}

/**
 * 認証方式チェック
 * どの認証機能を利用するか判定する
 * 列挙型
 */
const authEnum = {
  LINE: 'line',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
} as const;
type AuthEnum = typeof authEnum[keyof typeof authEnum];
/**
 * Firebase以外の認証を利用した場合のサインアップ後共通登録処理
 */
async function registWithOtherProviders(
  // nickName: string,
  email: string,
  authProvider: AuthEnum
): Promise<void> {
  try {
    await db
      .collection(DB_PATH.USERS)
      .doc(getUserUid())
      .set({
        // nickName,
        job: '',
      })
      .then(async () => {
        // slackに情報を通知
        // TODO: - メッセージの修正
        try {
          await functions
            .httpsCallable('noticeSlack')({
              body: `【新規ユーザ】ユーザ登録が完了しました! email:${email}, 認証アカウント:${authProvider} `,
            })
            .then(async () => {
              // ロールの登録
              try {
                await registerUserRoll('User').then(() => {
                  if (authProvider === 'line') {
                    // 各種登録処理が完了したらページを再読み込みさせる
                    window.location.reload();
                  }
                });
              } catch (error) {
                throw error;
              }
            });
        } catch (error) {
          throw error;
        }
      });
  } catch (error) {
    throw error;
  }
}
/**
 * LIFF を利用するための初期化処理
 */
export function initLiff(): void {
  liff
    .init({ liffId: process.env.REACT_APP_LIFF_ID ?? '' })
    .then(() => {
      if (liff.isLoggedIn()) {
        // ログインの確認後の処理など
      } else {
        liff.login({});
      }
    })
    .catch((error) => {
      throw error;
    });
}
/**
 * (clientUser)signInWithLine
 */
export async function signInWithLine(): Promise<void> {
  initLiff();
  liff.ready.then(async () => {
    if (liff.isLoggedIn()) {
      const context = liff.getContext();

      try {
        await functions
          .httpsCallable('customToekenCreator')({
            uid: context?.userId,
          })
          .then((createdResult) => {
            // LINE アカウントを利用した Firebase の認証実行
            firebase
              .auth()
              .signInWithCustomToken(createdResult.data)
              .then(async (result) => {
                const nickName = randomUserNameCrateor();
                const email = result.user?.email ?? ''; // TODO: メールアドレス登録

                // 新規アカウント登録の場合は、fs へ登録する
                if (result.user?.displayName !== 'User') {
                  await registWithOtherProviders(email, 'line');
                }
                // ログイン処理が完了したらページを再読み込みさせる
                window.location.reload();
              })
              .catch((error) => {
                throw error;
              });
          });
      } catch (error) {
        throw error;
      }
    }
  });
}
/**
 * (clientUser)signInWithFacebook
 */
export async function signInWithFacebook(): Promise<void> {
  try {
    // Facebook アカウントを利用した Firebase の認証実行
    const provider = new firebase.auth.FacebookAuthProvider();
    await firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        const nickName = randomUserNameCrateor();
        const email = result.user?.email ?? '';

        // 新規アカウント登録の場合は、fs へ登録する
        if (result.user?.displayName !== 'User') {
          await registWithOtherProviders(email, 'facebook');
        }
      });
  } catch (error) {
    throw error;
  }
}
/**
 * (clientUser)signInWithGoogle
 */
export async function signInWithGoogle(): Promise<void> {
  try {
    // Google アカウントを利用した Firebase の認証実行
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        const nickName = randomUserNameCrateor();
        const email = result.user?.email ?? '';

        // 新規アカウント登録の場合は、fireStore へ登録する
        // if (result.user?.displayName !== 'User') {
        //   await registWithOtherProviders(nickName, email, 'google');
        // }
        if (result.user?.displayName !== 'User') {
          await registWithOtherProviders(email, 'google');
        }
      });
  } catch (error) {
    throw error;
  }
}

type Question = {
  id: string;
  text: string;
  /** 次の質問が説明文かどうか(複数の質問を飛ばす場合、前の質問ではこれを'true'とする) */
  instruction: boolean;
  /** ロボットアイコンを表示するか */
  displayIcon: boolean;
  /** フリー入力欄を表示するか ※フリー入力はnumberに別途変換し利用 */
  hasFreeInputField: boolean;
  /** 入力欄に出すプレースホルダー */
  placeholder?: string;
  /** フリー入力欄の右端に表示する文字列 */
  unitText?: '万円' | '万円/月' | '万円/年' | '歳';
  /** 選択肢で表示する形式 */
  options: { option: string; hint?: string; value?: number }[];
  /** 「ひとつ前に戻る」クリック時に表示する質問 */
  previousQuestion: number;
  /** 次に表示する質問 */
  getNextQuestionIndex?: (answer: string) => number;
};

export const questions: Question[] = [
  // No.0
  {
    id: 'inst01',
    text: '16の質問で\nあなたの老後資金を\n具体化するでありマス！',
    instruction: true,
    displayIcon: true,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 0,
  },

  // No.1
  {
    id: 'inst02Info',
    text: '出力される結果はあくまで目安です。\n正確性を保証するものではありません。',
    instruction: true,
    displayIcon: false,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 0,
  },

  // No.2
  {
    id: 'inst02Info2',
    text: 'また、本診断は65歳以下の方を対象と\nしております。\nそれ以外の方の場合、正しく計算されませんのでご注意ください。',
    instruction: true,
    displayIcon: false,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 0,
  },

  // No.3
  {
    id: 'inst02',
    text: '『基本情報』：９問！\nまずあなたについての質問でありマス',
    instruction: true,
    displayIcon: true,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 0,
  },

  // No.4
  {
    id: 'age',
    text: '診断① 年齢：\nあなたの年齢はいくつでありマスか？',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: true,
    placeholder: '35',
    unitText: '歳',
    options: [],
    previousQuestion: 4,
  },

  // No.5
  {
    id: 'address',
    text: '診断② お住まい：\nお住まいはどこでありマスか？',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: false,
    options: [
      { option: '東京' },
      { option: '関東(東京除く)' },
      { option: '関西' },
      { option: '関東・関西以外' },
    ],
    previousQuestion: 4,
  },

  // No.6
  {
    id: 'sex',
    text: '診断③ 性別：\nあなたの性別はどちらでありマスか？',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: false,
    options: [{ option: '男性' }, { option: '女性' }],
    previousQuestion: 5,
  },

  // No.7
  {
    id: 'income',
    text: '診断④ 年収：\nあなたの年収はいくらでありマスか？',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: true,
    placeholder: '400',
    unitText: '万円',
    options: [],
    previousQuestion: 6,
  },

  // No.8
  {
    id: 'incomeMax',
    text: '診断⑤ 将来の最高年収：\nあなたの予想はいくらでありマスか？',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: true,
    placeholder: '600',
    unitText: '万円',
    options: [],
    previousQuestion: 7,
  },

  // No.9
  {
    id: 'isEmployee',
    text: '診断⑥ お仕事：\n会社員/公務員でありマスか？',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: false,
    options: [{ option: 'はい' }, { option: 'いいえ' }],
    previousQuestion: 8,
  },

  // No.10
  {
    id: 'retiredPayInfo',
    text: '診断⑦ 退職金：\n退職金はどれくらいでありマスか？',
    instruction: true,
    displayIcon: true,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 9,
  },
  // No.11
  {
    id: 'retiredPay',
    text: '一般的な会社員の退職金は大企業2000万円、中小企業1000万円です',
    instruction: false,
    displayIcon: false,
    hasFreeInputField: true,
    placeholder: 'その他入力',
    unitText: '万円',
    options: [
      { option: '2000万円', hint: '大企業', value: 2000 },
      { option: '1000万円', hint: '中小企業', value: 1000 },
      { option: '0万円', hint: '退職金なし', value: 0 },
    ],
    previousQuestion: 9,
  },

  // No.12
  {
    id: 'isEmployeePartner',
    text: '診断⑧ 配偶者のお仕事：\n配偶者は会社員/公務員でありマスか？',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: false,
    options: [{ option: 'はい' }, { option: 'いいえ' }],
    previousQuestion: 9,
    getNextQuestionIndex: (answer) => {
      if (answer === 'はい') return 13;
      return 14;
    },
  },

  // No.13
  {
    id: 'partnerIncome',
    text: '診断⑧ 配偶者の年収：\n配偶者の年収を入力するでありマス！',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: true,
    unitText: '万円/年',
    options: [
      { option: 'やや低め', hint: '200万円/年', value: 200 },
      { option: 'ふつう', hint: '400万円/年', value: 400 },
      { option: 'やや高め', hint: '600万円/年', value: 600 },
      { option: '高め', hint: '800万円/年', value: 800 },
    ],
    previousQuestion: 12,
  },

  // No.14
  {
    id: 'savingsAmount',
    text: '診断⑨ 老後用の貯金：\n貯金は今いくらでありマスか？',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: true,
    unitText: '万円',
    options: [],
    previousQuestion: 12,
  },

  // No.15
  {
    id: 'nextStep',
    text: '『老後の生活』：６問！\nつぎは老後についての質問でありマス\nわからない事は想定でOKでありマス',
    instruction: true,
    displayIcon: true,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 14,
  },

  // No.16
  {
    id: 'marriageStatus',
    text: '診断⑩ 老後の世帯：\n「単身」または「夫婦」の\nどちらを想定するでありマスか？',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: false,
    options: [{ option: '単身' }, { option: '夫婦' }],
    previousQuestion: 15,
    getNextQuestionIndex: (answer) => {
      if (answer === '単身') return 17;
      return 20;
    },
  },

  // No.16 診断⑩ 老後の世帯が「単身」の場合
  // No.17
  {
    id: 'livingStandard60to80',
    text: '診断⑪ 前期老後(65-80歳)：\n想定する生活水準を選ぶでありマス！\n(住居費用を除く)',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: false,
    options: [
      { option: '質素な暮らし', hint: '8万円/月', value: 8 },
      { option: '一般的な暮らし', hint: '14万円/月', value: 14 },
      { option: 'ゆとりのある暮らし', hint: '20万円/月', value: 20 },
      { option: '豪華な暮らし', hint: '30万円/月', value: 30 },
    ],
    previousQuestion: 16,
  },
  // No.18
  {
    id: 'livingStandard80to00Info',
    text: '診断⑫ 後期老後(80-100歳)：\n想定する生活水準を選ぶでありマス！',
    instruction: true,
    displayIcon: true,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 17,
  },
  // No.19
  {
    id: 'livingStandard80to00',
    text: '一般的に生活費は80歳以降で少なくなります',
    instruction: false,
    displayIcon: false,
    hasFreeInputField: false,
    options: [
      { option: '質素な暮らし', hint: '8万円/月', value: 8 },
      { option: '一般的な暮らし', hint: '14万円/月', value: 14 },
      { option: 'ゆとりのある暮らし', hint: '20万円/月', value: 20 },
      { option: '豪華な暮らし', hint: '30万円/月', value: 30 },
    ],
    previousQuestion: 17,
    getNextQuestionIndex: () => 24,
  },

  // No.16 診断⑩ 老後の世帯が「夫婦」の場合
  // No.20
  {
    id: 'livingStandard60to80Info',
    text: '診断⑪ 前期老後(65-80歳)：\n想定する生活水準を選ぶでありマス！\n(住居費用を除く)',
    instruction: true,
    displayIcon: true,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 16,
  },
  // No.21
  {
    id: 'livingStandard60to80',
    text: '「夫婦」での生活費となります。',
    instruction: false,
    displayIcon: false,
    hasFreeInputField: false,
    // 数値は「単身」での値を格納し、別途計算時に「夫婦」用の値に更新
    options: [
      { option: '質素な暮らし', hint: '14万円/月', value: 8 },
      { option: '一般的な暮らし', hint: '24万円/月', value: 14 },
      { option: 'ゆとりのある暮らし', hint: '34万円/月', value: 20 },
      { option: '豪華な暮らし', hint: '50万円/月', value: 30 },
    ],
    previousQuestion: 16,
  },
  // No.22
  {
    id: 'livingStandard80to00Info',
    text: '診断⑫ 後期老後(80-100歳)：\n想定する生活水準を選ぶでありマス！',
    instruction: true,
    displayIcon: true,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 20,
  },
  // No.23
  {
    id: 'livingStandard80to00',
    text: '一般的に生活費は80歳以降で少なくなります',
    instruction: false,
    displayIcon: false,
    hasFreeInputField: false,
    options: [
      { option: '質素な暮らし', hint: '14万円/月', value: 8 },
      { option: '一般的な暮らし', hint: '24万円/月', value: 14 },
      { option: 'ゆとりのある暮らし', hint: '34万円/月', value: 20 },
      { option: '豪華な暮らし', hint: '50万円/月', value: 30 },
    ],
    previousQuestion: 20,
  },

  // No.24
  {
    id: 'houseStatusFuture',
    text: '診断⑬ 老後の住居：\n「持ち家」または「賃貸」の\nどちらを想定するでありマスか？',
    instruction: false,
    displayIcon: true,
    hasFreeInputField: false,
    options: [{ option: '持ち家' }, { option: '賃貸' }],
    getNextQuestionIndex: (answer) => {
      if (answer === '持ち家') return 25;
      return 29;
    },
    previousQuestion: 16,
  },

  // No.24 診断⑬ 老後の住居が「持ち家」の場合
  // No.25
  {
    id: 'housePurchaseAgeInfo',
    text: '診断⑭ 住居購入時の年齢：\n購入時の年齢は何歳でありマスか？',
    instruction: true,
    displayIcon: true,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 24,
  },
  // No.26
  {
    id: 'housePurchaseAge',
    text: '今後購入予定の場合は、おおよその購入予定年齢を入力してください。35歳くらいで買われる方が多いようです。',
    instruction: false,
    displayIcon: false,
    hasFreeInputField: true,
    placeholder: '35',
    unitText: '歳',
    options: [],
    previousQuestion: 24,
  },
  // No.27
  {
    id: 'housePriceInfo',
    text: '診断⑮ 住居購入金額：\n住居購入金額はいくらでありマスか？',
    instruction: true,
    displayIcon: true,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 25,
  },
  // No.28
  {
    id: 'housePrice',
    text: '参考相場価格から選ぶか個別に金額を入力してください',
    instruction: false,
    displayIcon: false,
    hasFreeInputField: true,
    placeholder: 'その他入力',
    unitText: '万円',
    options: [
      { option: '4500万円', hint: 'マンション', value: 4500 },
      { option: ' 4400万円', hint: '注文住宅', value: 4400 },
      { option: '3500万円', hint: '建売住宅', value: 3500 },
      { option: '2500万円', hint: '中古住宅', value: 2500 },
    ],
    previousQuestion: 25,
    getNextQuestionIndex: () => 31,
  },

  // No.24 診断⑬ 老後の住居が「賃貸」の場合
  // No.29
  {
    id: 'houseRentInfo',
    text: '診断⑭ 毎月の家賃：\n家賃はいくらを想定するでありマスか？',
    instruction: true,
    displayIcon: true,
    hasFreeInputField: false,
    options: [],
    previousQuestion: 24,
  },
  // No.30
  {
    id: 'houseRent',
    text: '参考相場価格から選ぶか個別に金額を入力してください',
    instruction: false,
    displayIcon: false,
    hasFreeInputField: true,
    placeholder: 'その他入力',
    unitText: '万円/月',
    options: [
      { option: '7万円', hint: '1R', value: 7 },
      { option: '9万円', hint: '1LDK', value: 9 },
      { option: '12万円', hint: '2LDK', value: 12 },
      { option: '18万円', hint: '3LDK', value: 18 },
    ],
    previousQuestion: 24,
  },

  // No.31
  {
    id: 'last',
    text: '結果が出ました！',
    instruction: false,
    displayIcon: false,
    hasFreeInputField: false,
    options: [
      { option: '診断結果を解説して欲しい', value: 0 },
      { option: '将来のお金に不安を感じた', value: 1 },
      { option: 'よく分からなかった', value: 2 },
      { option: '将来のお金に不安は感じていない', value: 3 },
    ],
    previousQuestion: 0,
  },
];

import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../resources/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    height: '560px',
  },
  cardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '560px',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    whiteSpace: 'pre-line', // 改行を許可
  },
}));

export default useStyles;

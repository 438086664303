import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../resources/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.lightGray,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  header: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  leftDivider: {
    backgroundColor: colors.gray,
    width: '20%',
    height: '2px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
  },
  rightDivider: {
    backgroundColor: colors.gray,
    width: '20%',
    height: '2px',
  },
  carouselBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '20px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export const cardStyles = makeStyles((theme) => ({
  cardRoot: {
    width: '680px',
    padding: '20px 30px',
    // border: '1.5px solid black',
    // borderColor: colors.facebookColor,
    backgroundColor: colors.white,
    boxShadow: '10px',
  },
  strongAreaRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  strongAreaTitle: {
    height: '30px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  strongAreaContainer: {
    border: '1px',
    display: 'flex',
    padding: '10px',
    gap: '10px',
    backgroundColor: colors.lightGray,
    width: '300px',
    alignItems: 'center',
    justifyContent: 'center',
  },

  fpImage: {
    height: '120px',
    width: '120px',
  },
  fpBelongsIcon: {
    height: '50px',
    width: '200px',
  },
  nickname: {
    color: colors.nameColor,
    display: 'flex',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },

  cardItemBox: {
    fontWeight: 'bold',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardBtn: {
    height: '40px',
    boxShadow: '1',
    width: '300px',
    border: '1px solid black',
    borderColor: colors.facebookColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: 16,
  },
}));

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Card,
  Button,
  CircularProgress,
  Backdrop,
  Grid,
  // IconButton,
  // Divider,
  CardMedia,
} from '@material-ui/core';
// import
//   Facebook,
//  Twitter ,
// '@material-ui/icons';
// import { Search } from '@trejgun/material-ui-icons-google';
import { unwrapResult } from '@reduxjs/toolkit';
// import lineAppIcon from '../../../resources/image/lineAppIcon.png';

import useStyles from './FpSignInScreenStyle';
import { AppDispatch, RootState } from '../../../modules/store';
import {
  getNickName,
  signIn,
  // initLiff,
  // signInWithFacebook,
  // signInWithGoogle,
  // toggleIsLoadingWithAuthProvider,
} from '../../../modules/store/slices/userSlice';
import { isTextInvalid } from '../../../components/validation';
import Model from '../../../components/Modal';
import companyLogoS from '../../../resources/image/companyLogo.png';

const SignIn: React.FC = () => {
  const classes = useStyles();

  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 766px)' });

  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  // state
  const [textFieldList, setTextFieldList] = React.useState({
    email: '',
    password: '',
  });
  const [disabled, setDisabled] = React.useState(true);
  const { isLoading, isLoadingWithAuthProvider } = useSelector(
    (state: RootState) => state.user
  );
  const { email, password } = textFieldList;

  // 登録ボタン活性・非活性処理
  const isDisabled = () => isTextInvalid(email) || isTextInvalid(password);

  React.useEffect(() => {
    setDisabled(isDisabled());
  }, [email, password]);
  const [isOpen, setIsOpen] = React.useState(false);

  // functions
  const onClickSignInBtn = () => {
    dispatch(signIn({ email, password }))
      .then(unwrapResult)
      .then(() => {
        history.push({ pathname: '/new-client/' });
        window.location.reload(); // 初回遷移時はページNotFoundErrorが返却されるため、再読み込みさせる

        // NickNameの取得
        dispatch(getNickName());
      })
      .catch(() => {
        // ログイン失敗時の処理
        setIsOpen(true);
      });
  };

  const onClickSpSignInBtn = () => {
    dispatch(signIn({ email, password }))
      .then(unwrapResult)
      .then(() => {
        history.push({ pathname: '/matching/chatRooms' });

        // NickNameの取得
        dispatch(getNickName());
      })
      .catch(() => {
        // ログイン失敗時の処理
        setIsOpen(true);
      });
  };

  const onChangeTextField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setTextFieldList({ ...textFieldList, [name]: value });
  };

  const onClickCloseModalBtn = () => {
    setIsOpen(false);
  };

  // const onClickTwitterBtn = () => {
  //   // TODO: 準備中画面へ遷移
  //   history.push({ pathname: '/' });
  // };

  // const onClickGoogleBtn = () => {
  //   dispatch(toggleIsLoadingWithAuthProvider());
  //   dispatch(signInWithGoogle())
  //     .then(unwrapResult)
  //     .then(() => {
  //       history.push({ pathname: '/other-provider-signup' });

  //       // NickNameの取得
  //       dispatch(getNickName()).then(() => {
  //         dispatch(toggleIsLoadingWithAuthProvider());
  //       });
  //     })
  //     .catch(() => {
  //       // ログイン失敗時の処理
  //       setIsOpen(true);
  //       dispatch(toggleIsLoadingWithAuthProvider());
  //     });
  // };

  // const onClickFaceBookBtn = () => {
  //   dispatch(toggleIsLoadingWithAuthProvider());
  //   dispatch(signInWithFacebook())
  //     .then(unwrapResult)
  //     .then(() => {
  //       history.push({ pathname: '/other-provider-signup' });

  //       // NickNameの取得
  //       dispatch(getNickName()).then(() => {
  //         dispatch(toggleIsLoadingWithAuthProvider());
  //       });
  //     })
  //     .catch(() => {
  //       // ログイン失敗時の処理
  //       setIsOpen(true);
  //       dispatch(toggleIsLoadingWithAuthProvider());
  //     });
  // };

  // const onClickLineBtn = () => {
  //   dispatch(initLiff())
  //     .then(unwrapResult)
  //     .then(() => {
  //       // NickNameの取得
  //       dispatch(getNickName());
  //     })
  //     .catch(() => {
  //       // ログイン失敗時の処理
  //       setIsOpen(true);
  //     });
  // };

  return (
    <div>
      {/* ---------- web画面用表示領域 (min-width: 767px) ---------- */}
      {isDesktop && (
        <Grid className={classes.root}>
          <Grid className={classes.cardRoot}>
            <CardMedia
              className={classes.companyLogoS}
              component="img"
              image={companyLogoS}
              title="companyLogoS"
            />

            {/* form */}
            <Card className={classes.textCardRoot}>
              <TextField
                className={classes.emailTextField}
                label="メールアドレス"
                variant="outlined"
                value={email}
                name="email"
                onChange={onChangeTextField}
              />
              <TextField
                className={classes.passwordTextField}
                label="パスワード"
                type="password"
                variant="outlined"
                value={password}
                name="password"
                onChange={onChangeTextField}
              />
              <Button
                className={classes.signInButton}
                onClick={onClickSignInBtn}
                disabled={disabled}
                classes={{ disabled: classes.disableSignInButton }} // 非活性時のスタイル
              >
                ログイン
              </Button>
            </Card>

            {/* TODO: 準備中画面へ遷移 */}
            {/* <Typography align="center">
              <Link className={classes.forgotPasswordlink} to="/">
                パスワードを忘れた方はこちら
              </Link>
            </Typography> */}

            {/* icons */}
            {/* <Grid className={classes.middleTitleRoot}>
              <Divider className={classes.divider} />
              <Typography align="center" className={classes.middleTitle}>
                または
              </Typography>
              <Divider className={classes.divider} />
            </Grid>
            <Grid>
              <IconButton size="small" onClick={onClickFaceBookBtn}>
                <Facebook className={classes.facebookIcon} />
              </IconButton>
              <IconButton
                size="small"
                className={classes.googleIconButton}
                onClick={onClickGoogleBtn}>
                <Search className={classes.googleIconImage} />
              </IconButton>
              <IconButton
                size="small"
                className={classes.twitterIconButton}
                onClick={onClickTwitterBtn}>
                <Twitter className={classes.twitterIconImage} />
              </IconButton>
              <IconButton size="small" onClick={onClickLineBtn}>
                <CardMedia
                  component="img"
                  className={classes.lineIconImage}
                  image={lineAppIcon}
                  title="lineAppIcon"
                />
              </IconButton>
            </Grid> */}

            {/* インジケーター */}
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress />
            </Backdrop>

            {/* modal */}
            <Model
              isOpen={isOpen}
              title="ログイン失敗"
              body={`ログインに失敗しました。\nメールアドレス、パスワードをご確認のうえ、\n再度ログインを行ってください`}
              buttonName="閉じる"
              onClickCloseModalBtn={onClickCloseModalBtn}
            />
          </Grid>
        </Grid>
      )}
      {/* ---------- スマホ画面用表示領域 (max-width: 766px) ---------- */}
      {isMobile && (
        <Grid className={classes.root}>
          <Grid className={classes.spCardRoot}>
            <CardMedia
              className={classes.spCompanyLogoS}
              component="img"
              image={companyLogoS}
              title="companyLogoS"
            />

            {/* form */}
            <Card className={classes.textCardRoot}>
              <TextField
                className={classes.emailTextField}
                label="メールアドレス"
                variant="outlined"
                value={email}
                name="email"
                onChange={onChangeTextField}
              />
              <TextField
                className={classes.passwordTextField}
                label="パスワード"
                type="password"
                variant="outlined"
                value={password}
                name="password"
                onChange={onChangeTextField}
              />
              <Button
                className={classes.signInButton}
                onClick={onClickSpSignInBtn}
                disabled={disabled}
                classes={{ disabled: classes.disableSignInButton }} // 非活性時のスタイル
              >
                ログイン
              </Button>
            </Card>

            {/* TODO: 準備中画面へ遷移 */}
            {/* <Typography align="center">
              <Link className={classes.forgotPasswordlink} to="/">
                パスワードを忘れた方はこちら
              </Link>
            </Typography> */}

            {/* icons */}
            {/* <Grid className={classes.middleTitleRoot}>
              <Divider className={classes.divider} />
              <Typography align="center" className={classes.middleTitle}>
                または
              </Typography>
              <Divider className={classes.divider} />
            </Grid> */}
            {/* <Grid>
              <IconButton size="small" onClick={onClickFaceBookBtn}>
                <Facebook className={classes.facebookIcon} />
              </IconButton>
              <IconButton
                size="small"
                className={classes.googleIconButton}
                onClick={onClickGoogleBtn}>
                <Search className={classes.googleIconImage} />
              </IconButton>
              <IconButton
                size="small"
                className={classes.twitterIconButton}
                onClick={onClickTwitterBtn}>
                <Twitter className={classes.twitterIconImage} />
              </IconButton>
              <IconButton size="small" onClick={onClickLineBtn}>
                <CardMedia
                  component="img"
                  className={classes.lineIconImage}
                  image={lineAppIcon}
                  title="lineAppIcon"
                />
              </IconButton>
            </Grid> */}

            {/* インジケーター */}
            <Backdrop
              className={classes.backdrop}
              open={isLoading || isLoadingWithAuthProvider}>
              <CircularProgress />
            </Backdrop>

            {/* modal */}
            <Model
              isOpen={isOpen}
              title="ログイン失敗"
              body={`ログインに失敗しました。\nメールアドレス、パスワードをご確認のうえ、\n再度ログインを行ってください`}
              buttonName="閉じる"
              onClickCloseModalBtn={onClickCloseModalBtn}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default SignIn;

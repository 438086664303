import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../resources/colors';

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: colors.white,
    maxWidth: '1200px',
    margin: 'auto',
  },
  root: {
    alignItems: 'center',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },

  /* header */
  divider: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.facebookColor,
    height: '10px',
  },
  dividerlight: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.skygray,
    height: '2px',
  },
  backBtn: {
    color: colors.lightBlue,
    Cborderolor: colors.lightBlue,
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    zIndex: 1,
  },

  /* main */
  mainRoot: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    gap: '40px',
  },
  board: {
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    whiteSpace: 'pre-line', // 改行を許可
  },
  text: {
    textAlign: 'left',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontSize: '16px',
    whiteSpace: 'pre-line', // 改行を許可
  },
  btnRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  mtgLink: {
    verticalAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chatBtn: {
    marginTop: theme.spacing(2),
    backgroundColor: colors.lightBlue,
    color: colors.white,
    fontSize: '18px',
    width: '1200px',
  },
  nickname: {
    color: colors.nameColor,
    display: 'flex',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    fontSize: 28,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  itemTitleBox: {
    fontWeight: 'bold',
    fontSize: '17px',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  ratingStarBox: {
    fontWeight: 'bold',
    fontSize: '17px',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  fpImage: {
    marginTop: theme.spacing(2),
    height: '160px',
    width: '130px',
  },
  fpBelongsIcon: {
    height: '50px',
    width: '200px',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },

  /* Cardのみ */
  reviewCardContainer: {
    border: '2.5px solid gray',
    borderColor: colors.skygray,
    justifyContent: 'center',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  cardItemTitleBox: {
    marginBottom: '20px',
    fontWeight: 'bold',
    fontSize: '17px',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

export default useStyles;

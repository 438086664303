import firebase from 'firebase';
import { Person } from './Person';

/**
 * Firestore上のクライアントユーザーのデータ型。
 * export禁止。
 */
interface ClientUserDocument {
  age?: number;
  address?: string;
  income?: number;
  /** 「診断を受けてどう思ったか」
   * 0:診断結果を解説して欲しい
   * 1:将来のお金に不安を感じた
   * 2:よくわからなかった
   * 3:将来のお金に不安は感じていない 9:初期値 */
  anxietyLevel?: number;
  /** 診断済みか否か */
  isDiagnosed?: boolean;
  /**  性別。 */
  sex?: '男性' | '女性';
  job?: string; // 使っていない？
  /** 既にコンタクトを取ったFPの数 */
  matchedFPCount?: number;
  nickName: string;
  /** アカウント登録日 */
  registrationDate?: firebase.firestore.Timestamp;
  request?: string; // 使っていない？
}

/**
 * クライアントユーザーのデータ型。
 */
export interface ClientUser extends Person {
  age?: number;
  address?: string;
  income?: number;
  /** 「診断を受けてどう思ったか」 */
  anxietyLevel?: ClientUserDocument['anxietyLevel'];
  /** 診断済みか否か */
  isDiagnosed: boolean;
  job: string; // 使っていない？
  /** 既にコンタクトを取ったFPの数 */
  matchedFPCount: number;
  /** アカウント登録日 */
  registrationDate?: Date;
  request: string; // 使っていない？
  role: 'User';
}

/**
 * DBから取得した診断結果データが正しいか確認する。
 */
function isClientUserDocument(value: any): value is ClientUserDocument {
  if (typeof value !== 'object') {
    return false;
  }
  if (typeof value.age !== 'undefined' && typeof value.age !== 'number') {
    return false;
  }
  if (
    typeof value.address !== 'undefined' &&
    typeof value.address !== 'string'
  ) {
    return false;
  }

  if (typeof value.income !== 'undefined' && typeof value.income !== 'number') {
    return false;
  }
  if (
    value.anxietyLevel !== undefined &&
    value.anxietyLevel !== 0 &&
    value.anxietyLevel !== 1 &&
    value.anxietyLevel !== 2 &&
    value.anxietyLevel !== 3 &&
    value.anxietyLevel !== 9
  ) {
    return false;
  }
  if (
    typeof value.isDiagnosed !== 'undefined' &&
    typeof value.isDiagnosed !== 'boolean'
  ) {
    return false;
  }
  if (
    typeof value.sex !== 'undefined' &&
    value.sex !== '男性' &&
    value.sex !== '女性'
  ) {
    return false;
  }
  if (typeof value.job !== 'undefined' && typeof value.job !== 'string') {
    return false;
  }
  if (
    typeof value.matchedFPCount !== 'undefined' &&
    typeof value.matchedFPCount !== 'number'
  ) {
    return false;
  }
  if (typeof value.nickName !== 'string') {
    return false;
  }
  if (
    !(value.registrationDate instanceof firebase.firestore.Timestamp) &&
    value.registrationDate !== undefined
  ) {
    return false;
  }
  if (
    typeof value.request !== 'undefined' &&
    typeof value.request !== 'string'
  ) {
    return false;
  }

  return true;
}

/* Firestore Data Converter */
export const clientUserConverter: firebase.firestore.FirestoreDataConverter<ClientUser> =
  {
    toFirestore: (clientUser: ClientUser): firebase.firestore.DocumentData => ({
      anxietyLevel: clientUser.anxietyLevel,
      nickName: clientUser.nickName,
    }),
    fromFirestore: (
      snapshot: firebase.firestore.QueryDocumentSnapshot
    ): ClientUser => {
      const clientUser = snapshot.data();
      if (!isClientUserDocument(clientUser)) {
        // TODO: エラーログを出力する。
        return {
          id: 'dummy',
          isDiagnosed: false,
          job: '',
          matchedFPCount: 0,
          nickName: '取得エラー',
          request: '',
          role: 'User',
        };
      }
      return {
        id: snapshot.id,
        age: clientUser.age,
        address: clientUser.address,
        income: clientUser.income,
        anxietyLevel: clientUser.anxietyLevel,
        isDiagnosed: clientUser.isDiagnosed ?? false,
        sex: clientUser.sex,
        job: clientUser.job ?? '',
        matchedFPCount: clientUser.matchedFPCount ?? 0,
        nickName: clientUser.nickName,
        registrationDate:
          clientUser.registrationDate !== undefined
            ? clientUser.registrationDate.toDate()
            : undefined,
        request: clientUser.request ?? '',
        role: 'User',
      };
    },
  };

/**
 * 現在の年収などから老後にもらえる厚生年金の月額を計算する。
 * @param {number} age 現在の年齢
 * @param {number} currentIncome 現在の年収
 * @param {number} paymentYears 厚生年金の加入年数
 * @return {number} 1年あたりの年収
 */
function calcWelfarePension(
  age: number,
  currentIncome: number,
  paymentYears: number
): number {
  // 移動平均係数のリストの算出(Excelの計算式参照)
  const movingAverages: number[] = [...Array(40)]
    .map((_: undefined, index: number) => {
      switch (Math.floor(index / 5)) {
        case 0:
          return 267;
        case 1:
          return 370;
        case 2:
          return 410;
        case 3:
          return 448;
        case 4:
          return 476;
        case 5:
          return 502;
        case 6:
          return 529;
        default:
          return 520;
      }
    })
    .map((averageIncome, _, array) => averageIncome / array[0])
    .map((_, index, array) => {
      if (index < 2) {
        return array[0];
      }
      if (index > array.length - 3) {
        return array[array.length - 1];
      }
      return (
        (array[index - 2] +
          array[index - 1] +
          array[index] +
          array[index + 1] +
          array[index + 2]) /
        5
      );
    });

  // 平均年収係数の算出
  const averageIncomeWeight =
    movingAverages.reduce((previous, current) => previous + current) /
    movingAverages.length;

  let index: number;
  if (age < 20) {
    index = 0;
  } else if (age > 59) {
    index = 39;
  } else {
    index = age - 20;
  }

  return (
    ((((currentIncome * averageIncomeWeight) / movingAverages[index] / 12) *
      5.769) /
      1000) *
    paymentYears
  );
}

/**
 * 引数の情報から必要な老後資金を計算する。
 * @param {number} livingExpenses 老後生活費用
 * @param {number} housingCosts 老後住宅費用
 * @param {number} otherExpenses その他老後費用
 * @param {number} age 現在の年齢
 * @param {number} annualIncome 現在の収入
 * @param {number} startWorkingAge 仕事を始めた年齢
 * @param {number} retirementAge 退職年齢
 * @param {number} retirementPay 退職金
 * @param spouse 配偶者の情報(配偶者なしの場合不要)
 * @return {number} 1年あたりの年収
 */
// eslint-disable-next-line import/prefer-default-export
export function calcRetirementFund(
  livingExpenses: number,
  housingCosts: number,
  otherExpenses: number,
  age: number,
  annualIncome: number,
  startWorkingAge: number,
  retirementAge: number,
  retirementPay: number,
  spouse?: {
    age: number;
    annualIncome: number;
    startWorkingAge: number;
    retirementAge: number;
    retirementPay: number;
  }
): number {
  const totalCost = (livingExpenses + housingCosts) * 12 * 40 + otherExpenses;
  const spouseIncome =
    spouse !== undefined
      ? (6.6 +
          calcWelfarePension(
            spouse.age,
            spouse.annualIncome,
            spouse.retirementAge - spouse.startWorkingAge
          )) *
          12 *
          40 +
        spouse.retirementPay
      : 0;
  const totalIncome =
    (6.6 +
      calcWelfarePension(age, annualIncome, retirementAge - startWorkingAge)) *
      12 *
      40 +
    retirementPay +
    spouseIncome;

  return totalCost - totalIncome > 0 ? totalCost - totalIncome : 0;
}

import React, { useEffect } from 'react';
import {
  Grid,
  CardMedia,
  Typography,
  Divider,
  Button,
  Card,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { TextareaAutosize } from '@mui/base';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import matchingChatScreenStyles from './SpMatchingChatScreenStyles';
import { AppDispatch } from '../../../../modules/store';
import { getUserUid } from '../../../../modules/functions/userFunctions';
import { isTextInvalid } from '../../../../components/validation';
import {
  fetchDestinationUser,
  fetchMessages,
  Message,
  messageSelectors,
  messageSliceInit,
  selectDestinationUser,
  selectIsLoading,
  sendMessage,
} from '../../../../modules/store/slices/messageSlice';
import { updateIsRead } from '../../../../modules/store/slices/chatRoomSlice';

const SpMatchingChatScreen: React.FC = () => {
  const classes = matchingChatScreenStyles();
  const dispatch: AppDispatch = useDispatch();

  const { chatRoomID } = useParams<{ chatRoomID: string }>();

  // state
  const [chatText, setChatText] = React.useState('');
  const [disabled, setDisabled] = React.useState(true);

  const messages = useSelector(messageSelectors.selectAll);
  const destinationUser = useSelector(selectDestinationUser);
  const isLoading = useSelector(selectIsLoading);

  const userID = getUserUid();

  // カードコンポーネント内のボトムへ移動する処理
  const ref = React.createRef<HTMLDivElement>();
  const scrollToBottomOfList = React.useCallback(() => {
    ref!.current!.scrollIntoView({
      block: 'end',
    });
  }, [ref]);
  React.useEffect(() => {
    scrollToBottomOfList();
  }, [messages]);

  useEffect(() => {
    dispatch(messageSliceInit());
    dispatch(fetchDestinationUser(chatRoomID));
    dispatch(fetchMessages(chatRoomID)).then(() => {
      dispatch(updateIsRead(chatRoomID));
    });
  }, []);

  // functions
  /* 診断画面実装時に戻す。 */
  // const onClickPlanningResultButton = () => {
  //   dispatch(fetchLatestResult(userId));
  //   history.push({ pathname: '/life-planning/result' });
  // };

  /* MVP対象外のため除外 */
  // const onClickPaymentBtnBtn = () => {
  //   // TODO: 決済処理
  //   history.push({ pathname: '/' });
  // };

  const onClickSendBtn = () => {
    dispatch(
      sendMessage({
        chatRoomID,
        message: {
          id: '', // 新規メッセージのためid未設定
          senderID: userID,
          text: chatText,
          sentAt: new Date(),
        },
      })
    );

    // TODO: メッセージ送信を知らせるアラートなど

    // TODO: 入力欄の初期化など
    setChatText('');
  };

  /** チャット内容表示カード */
  const renderChatCard = (message: Message, isMine: boolean) => {
    const { sentAt, text } = message;
    return (
      <Grid className={classes.chatCardFpRoot}>
        {/* メッセージ */}
        <Card className={isMine ? classes.chatCardUser : classes.chatCardFp}>
          {/* 日付(YYYY/MM/DD hh:mm) */}
          <Typography className={classes.chatInfo} align="left">
            {sentAt.getFullYear()}/{sentAt.getMonth() + 1}/{sentAt.getDate()}{' '}
            {sentAt.getHours()}:{`00${sentAt.getMinutes()}`.slice(-2)}
          </Typography>
          <Typography className={classes.chatText} align="left">
            <div
              dangerouslySetInnerHTML={{
                __html: textToLink(text),
              }}
            />
          </Typography>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      {/* header */}
      <Grid className={classes.root}>
        <Grid className={classes.headerRoot}>
          <CardMedia
            component="img"
            image={destinationUser.imageUrl} // TODO: 画像未設定時のアイコンを指定。
            className={classes.image}
          />
          <Typography className={classes.username}>
            {destinationUser.nickName.length < 15
              ? destinationUser.nickName
              : destinationUser.nickName.substr(0, 15).concat('..')}
            {/* {destinationUser.nickName ?? 'ユーザー名の取得に失敗しました。'} */}
          </Typography>
        </Grid>
        <Divider className={classes.divider} />

        {/* chat */}
        <Card className={classes.chatRoot}>
          {messages.map((message, index) => (
            <Grid key={String(index)}>
              {renderChatCard(message, message.senderID === userID)}
            </Grid>
          ))}

          {/* カードコンポーネント内のボトムへ移動する処理 */}
          <div ref={ref} />
        </Card>

        {/* 入力 & 送信 */}
        <Grid className={classes.textFieldRoot}>
          <TextareaAutosize
            maxRows={5}
            placeholder="メッセージを入力"
            className={classes.inputTextField}
            value={chatText}
            onChange={(e): void => {
              setChatText(e.target.value);
              setDisabled(isTextInvalid(e.target.value));
            }}
          />
          <Button
            className={classes.sendBtn}
            onClick={onClickSendBtn}
            disabled={disabled}
            classes={{ disabled: classes.disableSendBtn }} // 非活性時のスタイル
          >
            送信
          </Button>
        </Grid>
      </Grid>

      {/* インジケーター */}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default SpMatchingChatScreen;

/**
 * テキスト内のハッシュタグやURLをリンクにする
 * @param comment
 * @returns
 */
export const textToLink = (comment: string) => {
  // 正規表現でURLを抽出
  const regexpUrl = /(https?:\/\/[\w/:%#$&?()~.=+-]+)/g;
  let linkedComment = comment.replace(regexpUrl, '<a href="$1">$1</a>');

  // 正規表現で#を抽出
  const regexpHash = /#+([a-zA-Z0-9亜-熙ぁ-んァ-ヶー-龥朗-鶴.\-_]+)/g;
  linkedComment = linkedComment.replace(
    regexpHash,
    '<a href="/search?q=$1&type=hash">#$1</a>'
  );

  return linkedComment;
};

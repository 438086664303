const colors = {
  /* white */
  white: '#FFFFFF',

  /* black */
  black: '#000000',
  backdropBlack: 'rgba(94, 94, 94, 0.7)',
  dark: '#1e1e1f',

  /* gray */
  gray: '#808080',
  lightGray: '#E5E5E5',
  paleGray: '#F9F9F9',

  /* pink */
  pink: '#FF3466',
  palePink: '#FFF5F8',

  /* red */
  wineRed: '#AB0000',
  sakuraRed: '#F3D9DF',

  /* blue */
  mainBlue: '#00345E',
  mintBlue: '#7fc7d9',
  paleBlue: '#F5F9FF',
  skyBlue: '#DFEBF5',
  paleWhite: '#F8F8F8',

  /* buttonColor */
  lightBlue: '#18A0FB',
  lightOrange: '#E08A4F',
  subLightBlue: '#DFEBF5',

  /* font */
  darkGray: '#4E4E4E',
  fontGray: '#4D4D4D',

  /* icons */
  twitterColor: '#1DA1F2',
  facebookColor: '#26527A',
  starColor: '#FF9900',
  yellow: '#FFC800',
  skygray: '#BFC5CA',
  nameColor: '#2F6F83',
};

export default colors;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { AppDispatch } from '../../../../modules/store';
import SpChatRoomCard from './SpChatRoomCard';
import {
  ChatRoom,
  chatRoomSelectors,
  fetchChatRooms,
  selectIsLoading,
} from '../../../../modules/store/slices/chatRoomSlice';
import colors from '../../../../resources/colors';

const styles = (theme: Theme) => ({
  title: {
    fontSize: '1.5rem',
    fontWeight: 700,
    textAlign: 'left' as 'left',
    marginTop: theme.spacing(2),
  },
  root: {
    backgroundColor: colors.white,
  },
  divider: {
    backgroundColor: colors.darkGray,
  },
  noChatRoomText: {
    fontSize: '1rem',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
});

// やり取り相手一覧画面
function SpChatListScreen({ classes }: WithStyles<typeof styles>) {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  // state
  const chatRooms = useSelector(chatRoomSelectors.selectAll);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    dispatch(fetchChatRooms());
  }, []);

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs />
        <Grid item xs={10}>
          <Typography className={classes.title}>チャット</Typography>
          <Divider className={classes.divider} />
          {chatRooms.length > 0 ? (
            <List dense>
              {chatRooms.map((chatRoom) => (
                <ListItem disableGutters>
                  <SpChatRoomCard
                    chatRoom={chatRoom}
                    onClick={(_chatRoom: ChatRoom) => {
                      history.push({
                        pathname: `/matching/chatRooms/${_chatRoom.id}`,
                      });
                    }}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography className={classes.noChatRoomText}>
              {isLoading ? '' : 'やり取り中の相手が存在しません。'}
            </Typography>
          )}
        </Grid>
        <Grid item xs />
      </Grid>

      {/* インジケーター */}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}

export default withStyles(styles)(SpChatListScreen);

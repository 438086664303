import { storage } from '../../firebase';

/**
 * Cloud Storage上にある画像のURLを取得する。
 * @param {string} storagePath Cloud Storage上の画像のPath
 * @return {string} 画像URL。
 */
export async function getImageUrlFromStorage(storagePath: string) {
  let imageUrl: string | undefined;
  try {
    imageUrl = (await storage.ref(storagePath).getDownloadURL()) as string;
  } catch {
    // TODO: 画像がないユーザーの処理
    imageUrl = (await storage
      .ref(`user-images/user.png`)
      .getDownloadURL()
      .catch((error) => console.error(error))) as string;
  }

  return imageUrl;
}

/**
 * Cloud Storage上にある動画のURLを取得する。
 * @param {string} storagePath Cloud Storage上の画像のPath
 * @return {string | undefined} 動画URL。取得失敗時はundefinedを返す。
 */
export async function getVideoUrlFromStorage(
  storagePath: string
): Promise<string | undefined> {
  let videoUrl: string | undefined;
  try {
    videoUrl = (await storage.ref(storagePath).getDownloadURL()) as string;
  } catch {
    videoUrl = undefined;
  }
  return videoUrl;
}

/**
 * Cloud Storage上にあるライフプランニング診断のExcelファイルのURLを取得する。
 * @return {string | undefined} ExcelファイルのダウンロードURL。取得失敗時はundefinedを返す。
 */
export async function fetchPlanningForm(): Promise<string | undefined> {
  let fileUrl: string | undefined;
  try {
    fileUrl = (await storage
      .ref(`planning-result/FISTPLAT_家庭の経営診断.xlsx`)
      .getDownloadURL()) as string;
  } catch {
    fileUrl = undefined;
  }

  return fileUrl;
}

/**
 * Cloud Storage上に診断結果のExcelファイルをアップロードする。
 * @param {string} userID uid
 * @param {File} file 診断結果のファイル
 */
export async function uploadPlanningResultFile(
  userID: string,
  file: File
): Promise<void> {
  await storage.ref(`planning-result/${userID}/診断結果.xlsx`).put(file);
}

export const iconMap = {
  emotionError: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12ZM12 13C9.66999 13 7.68998 14.46 6.88998 16.5L17.11 16.5C16.31 14.46 14.33 13 12 13ZM17.6708 7.66457C17.8561 8.03505 17.7059 8.48556 17.3354 8.6708L15.6771 9.49998L17.3354 10.3292C17.7059 10.5144 17.8561 10.9649 17.6708 11.3354C17.4856 11.7059 17.0351 11.856 16.6646 11.6708L13.6646 10.1708C13.4105 10.0438 13.25 9.78406 13.25 9.49998C13.25 9.2159 13.4105 8.9562 13.6646 8.82916L16.6646 7.32916C17.0351 7.14392 17.4856 7.29409 17.6708 7.66457ZM6.66459 8.6708C6.29411 8.48556 6.14394 8.03505 6.32918 7.66457C6.51442 7.29409 6.96493 7.14392 7.33541 7.32916L10.3354 8.82916C10.5895 8.9562 10.75 9.2159 10.75 9.49998C10.75 9.78406 10.5895 10.0438 10.3354 10.1708L7.33541 11.6708C6.96493 11.856 6.51442 11.7059 6.32918 11.3354C6.14394 10.9649 6.29411 10.5144 6.66459 10.3292L8.32295 9.49998L6.66459 8.6708Z"
        fill="#D36262"
      />
    </svg>
  ),
  emotionWarning: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12ZM17 9.5C17 10.33 16.33 11 15.5 11C14.67 11 14 10.33 14 9.5C14 8.67 14.67 8 15.5 8C16.33 8 17 8.67 17 9.5ZM10 9.5C10 10.33 9.33 11 8.5 11C7.67 11 7 10.33 7 9.5C7 8.67 7.67 8 8.5 8C9.33 8 10 8.67 10 9.5ZM15.1191 16.4732C15.3805 16.9598 15.9868 17.1423 16.4733 16.8809C16.9598 16.6196 17.1423 16.0133 16.881 15.5267C16.0342 13.9505 14.0827 13 12 13C9.91735 13 7.9659 13.9505 7.11912 15.5267C6.85775 16.0133 7.04028 16.6196 7.52681 16.8809C8.01334 17.1423 8.61962 16.9598 8.88098 16.4732C9.28664 15.7181 10.435 15 12 15C13.5651 15 14.7135 15.7181 15.1191 16.4732Z"
        fill="#EEA02B"
      />
    </svg>
  ),
  emotionNormal: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12ZM17 9.5C17 10.33 16.33 11 15.5 11C14.67 11 14 10.33 14 9.5C14 8.67 14.67 8 15.5 8C16.33 8 17 8.67 17 9.5ZM10 9.5C10 10.33 9.33 11 8.5 11C7.67 11 7 10.33 7 9.5C7 8.67 7.67 8 8.5 8C9.33 8 10 8.67 10 9.5ZM16 16V14H8V16H16Z"
        fill="#212121"
      />
    </svg>
  ),
  sex: {
    male: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 11.75C8.31 11.75 7.75 12.31 7.75 13C7.75 13.69 8.31 14.25 9 14.25C9.69 14.25 10.25 13.69 10.25 13C10.25 12.31 9.69 11.75 9 11.75ZM15 11.75C14.31 11.75 13.75 12.31 13.75 13C13.75 13.69 14.31 14.25 15 14.25C15.69 14.25 16.25 13.69 16.25 13C16.25 12.31 15.69 11.75 15 11.75ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 11.71 4.02 11.42 4.05 11.14C6.41 10.09 8.28 8.16 9.26 5.77C11.07 8.33 14.05 10 17.42 10C18.2 10 18.95 9.91 19.67 9.74C19.88 10.45 20 11.21 20 12C20 16.41 16.41 20 12 20Z"
          fill="currentColor"
        />
      </svg>
    ),
    female: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.75 13C7.75 12.31 8.31 11.75 9 11.75C9.69 11.75 10.25 12.31 10.25 13C10.25 13.69 9.69 14.25 9 14.25C8.31 14.25 7.75 13.69 7.75 13ZM13.75 13C13.75 12.31 14.31 11.75 15 11.75C15.69 11.75 16.25 12.31 16.25 13C16.25 13.69 15.69 14.25 15 14.25C14.31 14.25 13.75 13.69 13.75 13ZM2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22.1073 15.254 23.2854 19.0892 24 21.0998C24 21.0998 20 23 12 23C4 23 0 21.0998 0 21.0998C0.714611 19.0892 1.89265 15.254 2 12ZM12 20C7.59 20 4 16.41 4 12C4 11.71 4.02 11.42 4.05 11.14C6.41 10.09 8.28 8.16 9.26 5.77C11.07 8.33 14.05 10 17.42 10C18.2 10 18.95 9.91 19.67 9.74C19.88 10.45 20 11.21 20 12C20 16.41 16.41 20 12 20Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  saving: (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1045_13137)">
        <path
          d="M21.7063 10.5777C21.2412 10.6019 21.1394 10.607 20.4597 10.6421C19.5427 9.12599 18.0749 7.7549 15.9553 6.92873C14.8632 6.50174 13.5976 6.21974 12.146 6.13771C11.6516 6.10987 11.1374 6.10546 10.5998 6.12599C9.94943 6.15088 9.3364 6.23441 8.75778 6.36623C8.47507 6.42993 8.20189 6.5061 7.93746 6.59034C6.60518 5.63746 5.25021 5.48437 4.38888 5.51076C3.82859 5.52834 4.00948 5.55763 4.00948 5.55763C3.87837 5.57376 3.76193 5.64773 3.69087 5.75831C3.62131 5.86963 3.60373 6.00585 3.64474 6.1304L4.93381 9.72295C4.93381 9.92802 4.72873 10.2349 4.31932 9.39041C4.17429 9.09013 3.95238 9.26662 3.91062 9.3201C3.84762 9.39993 3.78757 9.48051 3.73043 9.56109C2.54173 11.231 2.2722 12.7003 1.11274 13.1602L1.01313 13.1983C0.285819 13.4781 -0.5059 13.7081 -0.366775 14.4031C-0.238618 15.044 0.132726 16.9908 0.287272 17.4566C0.578788 18.3289 0.882726 18.5743 1.4328 18.9288C1.73529 19.1236 2.04218 19.3074 2.35418 19.4876V19.4884C3.30926 20.0369 4.32073 20.5321 5.44573 21.0638C5.74531 21.5582 6.03678 22.243 6.24701 22.7638C6.39129 23.1226 6.49751 23.4054 6.53998 23.4918C6.68571 23.7818 6.90401 24.0001 7.55876 24.0001C8.2121 24.0001 8.57609 24.0001 9.01264 24.0001C9.44843 24.0001 9.59417 23.5643 9.59417 23.0553C9.59417 22.9769 9.59417 22.8773 9.59417 22.7638V22.4744H14.757V22.7638C14.757 22.8773 14.757 22.9769 14.757 23.0553C14.757 23.5643 14.8932 24.0001 15.302 24.0001C15.7114 24.0001 16.0527 24.0001 16.6664 24.0001C17.2809 24.0001 17.486 23.7818 17.6223 23.4918C17.6625 23.4053 17.7621 23.1226 17.8977 22.7638H17.8984C18.4258 21.7135 19.0718 20.5841 19.9214 19.5396C20.9827 18.296 21.6631 16.7008 21.6631 14.795C21.6631 14.1636 21.5744 13.4928 21.3906 12.8116C21.5034 12.6417 21.7502 12.293 22.1362 11.8668C22.687 11.2551 22.2146 10.5528 21.7063 10.5777ZM5.10888 14.4324C4.57202 14.4324 4.13623 13.9974 4.13623 13.4605C4.13623 12.9222 4.57202 12.4871 5.10888 12.4871C5.64649 12.4871 6.08154 12.9222 6.08154 13.4605C6.08154 13.9973 5.64649 14.4324 5.10888 14.4324Z"
          fill="currentColor"
        />
        <path
          d="M10.2972 4.62159C10.7147 4.69631 11.1131 4.41797 11.1871 4.0005C11.316 3.27394 11.694 2.66236 12.2191 2.22511C12.7443 1.78856 13.4144 1.53516 14.1227 1.53516C14.297 1.53516 14.4713 1.55053 14.6478 1.58203L14.65 1.58278C15.3758 1.71169 15.9874 2.08814 16.4246 2.61328C16.8604 3.13987 17.1138 3.80859 17.1138 4.51758C17.1138 4.69116 17.0992 4.86694 17.067 5.04492C16.993 5.46239 17.2721 5.86083 17.6895 5.93555C18.1063 6.00952 18.5054 5.73047 18.5794 5.313C18.627 5.04638 18.6497 4.78055 18.6497 4.51758C18.6497 3.4402 18.2637 2.42578 17.606 1.63331C16.9484 0.839344 16.0116 0.263672 14.9181 0.0703125L14.9203 0.0710625C14.6515 0.0226875 14.3849 0 14.1227 0C13.046 0 12.0317 0.385266 11.2384 1.04372C10.4452 1.70072 9.86955 2.63822 9.67619 3.73172C9.60147 4.14844 9.87977 4.54688 10.2972 4.62159Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1045_13137">
          <rect
            width="24"
            height="24"
            fill="currentColor"
            transform="translate(-1)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  investment: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5049 8.91943C12.3652 8.91943 9.00928 12.2761 9.00928 16.415C9.00928 20.5547 12.3652 23.9106 16.5049 23.9106C20.6438 23.9107 23.9998 20.5547 23.9998 16.4151C23.9998 12.2762 20.6438 8.91943 16.5049 8.91943ZM19.4836 15.8005H17.4314L17.2102 16.144V16.9658H19.4836V16.9688V17.9649V18.1319H17.2102V19.8926H15.7995V18.1319H13.5247V17.9649V16.9688V16.9658H15.7995V16.1455L15.5769 15.8005H13.5247V14.8037H14.9368L13.7371 12.9375H14.9339L16.5049 15.3816L18.0759 12.9375H19.2727L18.073 14.8037H19.4836V15.8005Z"
        fill="currentColor"
      />
      <path
        d="M12.1743 2.6814C12.1743 4.11329 9.44972 5.2727 6.08719 5.2727C2.72466 5.2727 0 4.11329 0 2.6814C0 1.24951 2.72461 0.0893555 6.08714 0.0893555C9.44967 0.0893555 12.1743 1.24951 12.1743 2.6814Z"
        fill="currentColor"
      />
      <path
        d="M0 7.06947C0 8.50061 2.72461 9.66077 6.08714 9.66077C9.44967 9.66077 12.1743 8.50061 12.1743 7.06947V6.42494C12.1743 6.22717 12.0747 6.04188 11.9099 5.93275C11.745 5.82433 11.5356 5.8046 11.354 5.88222C11.354 5.88222 9.44967 7.0592 6.08714 7.0592C2.72461 7.0592 0.821766 5.88217 0.821766 5.88217C0.638672 5.80455 0.429188 5.82433 0.265125 5.93271C0.0996094 6.04188 0 6.22717 0 6.42494V7.06947Z"
        fill="currentColor"
      />
      <path
        d="M8.05422 11.4521C7.55763 12.287 7.18779 13.1996 6.96293 14.1664C6.67507 14.1854 6.3843 14.1935 6.08547 14.1935C2.72585 14.1935 0.000488281 13.0326 0.000488281 11.5992V10.9584C0.000488281 10.7606 0.0978946 10.5746 0.26491 10.4654C0.42752 10.357 0.637707 10.3373 0.820098 10.4149C0.820098 10.4149 2.72585 11.5912 6.08547 11.5912C6.81204 11.5912 7.46829 11.537 8.05422 11.4521Z"
        fill="currentColor"
      />
      <path
        d="M6.97099 18.6981C6.68388 18.7179 6.3843 18.7252 6.08547 18.7252C2.72585 18.7252 0.000488281 17.5643 0.000488281 16.1353V15.4908C0.000488281 15.293 0.0978945 15.1063 0.26491 14.9972C0.42752 14.8888 0.637707 14.869 0.820098 14.9466C0.820098 14.9466 2.72585 16.1236 6.08547 16.1236C6.29936 16.1236 6.50514 16.12 6.70657 16.1119C6.69925 16.213 6.69925 16.3141 6.69925 16.4151C6.69925 17.1988 6.79225 17.9642 6.97099 18.6981Z"
        fill="currentColor"
      />
      <path
        d="M9.15724 22.9C8.25564 23.1285 7.20827 23.2574 6.08547 23.2574C2.72585 23.2574 0.000488281 22.0958 0.000488281 20.6668V20.0223C0.000488281 19.8245 0.0978946 19.6377 0.26491 19.5293C0.42752 19.4209 0.637707 19.4012 0.820098 19.478C0.820098 19.478 2.72585 20.655 6.08547 20.655C6.637 20.655 7.15333 20.6243 7.62358 20.5693C8.02347 21.4205 8.53985 22.2049 9.15724 22.9Z"
        fill="currentColor"
      />
    </svg>
  ),
};

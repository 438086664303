import { Button, Box } from '@material-ui/core';
import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import {
  getIsFp,
  getUserUid,
} from '../../../../modules/functions/userFunctions';
import { introductionHeaderBtnStyles } from '../IntroductionHeaderStyles';

type Props = {
  screenNameProp: 'lifePlanning' | 'learning' | 'matching' | 'diagnosis';
};

const StartButtonComponentFunc = (props: Props): ReactElement => {
  // state
  const { screenNameProp } = props;
  const btnscreenClasses = introductionHeaderBtnStyles();
  const history = useHistory();
  const isFp = getIsFp();

  // functions
  const onClickStartBtn = () => {
    history.push({ pathname: '/' });
  };

  const onClickDiagnosisStartBtn = () => {
    history.push({
      pathname: `/diagnosis`,
    });
  };

  const onClickRecommendStartBtn = () => {
    history.push({
      pathname: `/matching/recommend-fp/user/${getUserUid()}`,
    });
  };
  const onClickMatchingStartBtn = () => {
    history.push({
      pathname: `/matching/search/user/${getUserUid()}`,
    });
  };

  const onClickStartLearningBtn = () => {
    history.push({ pathname: '/learning/select' });
  };

  /* form画面実装時に戻す。 */
  // const onClicklifePlanningStartBtn = () => {
  // dispatch(init());
  // history.push({ pathname: '/life-planning/form' });
  // };

  // const onClicklifePlanningCheckBtn = () => {
  //   dispatch(fetchLatestResult());
  //   history.push({ pathname: '/life-planning/result' });
  // };

  // screen
  switch (screenNameProp) {
    case 'lifePlanning':
      return (
        <Box style={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
          <Button
            /* form画面実装時に戻す。 */
            // className={btnscreenClasses.lifePlanningStartBtn}
            className={btnscreenClasses.downloadBtn}
            onClick={onClickDiagnosisStartBtn}>
            老後資金診断を開始する
          </Button>
          {/* <Button
            className={btnscreenClasses.downloadBtn}
            href={planningFormUrl}>
            診断エクセルをダウンロード
          </Button> */}
          {/* <Button
              className={btnscreenClasses.lifePlanningCheckBtn}
              onClick={onClicklifePlanningCheckBtn}
              variant="outlined">
              過去の診断結果を見る
            </Button> */}
        </Box>
      );
    case 'matching':
      return (
        <Box style={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
          {isFp ? (
            // FPにはユーザ一覧をここで表示しないことを確認し、ボタン削除

            // <Button
            //   className={btnscreenClasses.matchingStartBtn}
            //   onClick={onClickMatchingStartBtn}>
            //   チャット可能ユーザ一覧を見る
            // </Button>
            ''
          ) : (
            <Box
              style={{
                display: 'flex',
                gap: '16px',
                justifyContent: 'center',
              }}>
              {/* <UploadLink /> */}

              <Button
                className={btnscreenClasses.matchingStartBtn}
                onClick={onClickRecommendStartBtn}>
                おすすめの専門家から選ぶ
              </Button>
              <Button
                className={btnscreenClasses.matchingStartBtn}
                onClick={onClickMatchingStartBtn}>
                お金の専門家一覧を見る
              </Button>
              {/* <MatchingWaitBtnModal /> */}
            </Box>
          )}
        </Box>
      );
    case 'learning':
      return (
        /* TODO: UI修正 */
        <Button
          className={btnscreenClasses.simpleBtn}
          onClick={onClickStartLearningBtn}>
          学習を始める
        </Button>
      );
    default:
      return (
        <Button
          className={btnscreenClasses.simpleBtn}
          onClick={onClickStartBtn}>
          始める
        </Button>
      );
  }
};

export default StartButtonComponentFunc;

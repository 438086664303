import { Typography, Card, CardActionArea, CardMedia } from '@material-ui/core';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';

import { ChatRoom } from '../../../../modules/store/slices/chatRoomSlice';
import { Fp } from '../../../../modules/functions/matchingFunctions';

interface Props extends WithStyles<typeof styles> {
  chatRoom: ChatRoom;
  onClick: (chatRoom: ChatRoom) => void;
}

/* Style */
const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
  },
  icon: {
    width: '5rem',
    height: '5rem',
    marginTop: '0.5rem',
    marginLeft: '0.5rem',
    marginBottom: '0.5rem',
  },
  infoText: {
    fontSize: '1rem',
    textAlign: 'left' as 'left',
  },
  dateText: {
    fontSize: '1rem',
    marginTop: '0.5rem',
    marginRight: '0.5rem',
  },
  notReadText: {
    marginRight: '0.2rem',
    marginBottom: '0.2rem',
    padding: '0.2rem',

    fontSize: '0.7rem',
    fontWeight: 'bold' as 'bold',
    color: theme.palette.error.main,
    border: 'solid' as 'solid',
    borderRadius: '0.5rem',
  },
});

function SpChatRoomCard(props: Props) {
  // state
  const { chatRoom, onClick, classes } = props;

  return (
    <Card onClick={() => onClick(chatRoom)} className={classes.root}>
      <CardActionArea>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <CardMedia
            component="img"
            image={chatRoom.destinationUser.imageUrl}
            className={classes.icon}
          />
          {/* 名前、所属 */}
          <div style={{ height: '3rem', marginLeft: '0.5rem' }}>
            <Typography className={classes.infoText}>
              {chatRoom.destinationUser.nickName}
            </Typography>
            {/* FPのみ所属を表示 */}
            {chatRoom.destinationUser.role === 'FP' ? (
              <Typography className={classes.infoText}>
                {(chatRoom.destinationUser as Fp).belongs}
              </Typography>
            ) : null}
          </div>
        </div>

        {/* 右側のFlexBox(時間と未読の表示エリア) */}
        <div
          style={{
            position: 'absolute',
            top: '0%',
            right: '0%',
            bottom: '0%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}>
          {/* 右上の時間表示 */}
          <Typography
            style={{ textAlign: 'right' }}
            className={classes.dateText}>
            {chatRoom.latestTime.getFullYear()}/
            {chatRoom.latestTime.getMonth() + 1}/{chatRoom.latestTime.getDate()}{' '}
            {chatRoom.latestTime.getHours()}:
            {`00${chatRoom.latestTime.getMinutes()}`.slice(-2)}
          </Typography>
          {/* 右下の未読表示 */}
          {!chatRoom.isRead ? (
            <Typography className={classes.notReadText}>未読あり</Typography>
          ) : null}
        </div>
      </CardActionArea>
    </Card>
  );
}

export default withStyles(styles)(SpChatRoomCard);

import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { defaultTheme } from '../resources/theme/defaultTheme';

/**
 * タグを表示
 * @example
 * ```typescript
 * <Tag>済</Tag>
 */
export const Tag = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      display: 'inline-flex',
      padding: '4px',
      backgroundColor: defaultTheme.palette.grey[300],
      borderRadius: '4px',
      fontSize: '12px',
      lineHeight: '100%',
    }}>
    {children}
  </Box>
);

import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../resources/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.lightGray,
  },
  cardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '480px',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  spCardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '350px',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  /* header */
  title: {
    fontSize: '40px',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  spTitle: {
    fontSize: '30px',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: 600,
    whiteSpace: 'pre-line', // 改行を許可
  },
  subTitleLink: {
    fontSize: '16px',
    fontWeight: 600,
    whiteSpace: 'pre-line', // 改行を許可
    paddingTop: theme.spacing(2),
  },
  spSubTitle: {
    color: colors.fontGray,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '16px',
    fontWeight: 600,
    whiteSpace: 'pre-line', // 改行を許可
  },
  spSubTitleLink: {
    fontSize: '14px',
    fontWeight: 600,
    whiteSpace: 'pre-line', // 改行を許可
    paddingTop: theme.spacing(2),
  },
  link: {
    fontSize: '16px',
    fontWeight: 600,
    color: colors.lightBlue,
    textDecoration: 'none',
  },
  spLink: {
    fontSize: '13px',
    fontWeight: 400,
    color: colors.lightBlue,
    textDecoration: 'none',
  },
  information: {
    marginBottom: theme.spacing(3),
  },
  spInformationText: {
    fontSize: '13px',
    fontWeight: 400,
    whiteSpace: 'pre-line', // 改行を許可
  },
  spGoogleBox: {
    height: '100%',
    width: '320px',
    backgroundColor: colors.paleGray,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxShadow: '1px 1px 1px #808080',
    flexDirection: 'column',
    display: 'flex',
    padding: theme.spacing(1),
  },
  spButtonText: {
    color: colors.black,
    fontSize: '16px',
    fontWeight: 200,
    whiteSpace: 'pre-line', // 改行を許可
  },
  googleIconImage: {
    width: 24,
    height: 24,
  },

  /* form 1 */
  textCardRoot: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    flexDirection: 'column',
    display: 'flex',
    padding: theme.spacing(1),
    // margin: theme.spacing(2),
    width: '350px',
  },
  companyLogoS: {
    height: 'auto',
    width: '400px',
    marginBottom: theme.spacing(2),
  },
  spCompanyLogoS: {
    height: 'auto',
    width: '250px',
    marginBottom: theme.spacing(2),
  },
  spRobotLogoS: {
    height: 'auto',
    width: '300px',
    marginBottom: theme.spacing(2),
  },

  emailTextField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    backgroundColor: colors.lightGray,
  },
  nameTextField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    backgroundColor: colors.lightGray,
  },
  sendPinCodeButton: {
    marginBottom: theme.spacing(4),
    backgroundColor: colors.lightBlue,
    color: colors.white,
    height: '56px',
  },

  /* form 2 */
  passwordTextField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    backgroundColor: colors.lightGray,
  },
  pinCodeTextField: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.lightGray,
  },
  signUpButton: {
    backgroundColor: colors.lightBlue,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: colors.white,
    height: '56px',
    fontSize: '16px',
    fontWeight: 600,
  },
  signInLinkButton: {
    backgroundColor: colors.subLightBlue,
    height: '32px',
  },
  signInButtonText: {
    color: colors.fontGray,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontSize: '16px',
    fontWeight: 400,
    whiteSpace: 'pre-line', // 改行を許可
  },
  button: {
    paddingTop: theme.spacing(2),
  },

  disableButton: {
    backgroundColor: colors.lightGray,
  },

  /* footer */
  alreadySignUpBtn: {
    marginTop: theme.spacing(2),
  },
  alreadySignUpLinkBtn: {
    color: colors.lightBlue,
    fontSize: '16px',
    fontWeight: 600,
    textDecoration: 'none',
  },
  divider: {
    width: '300px',
    backgroundColor: colors.lightGray,
  },

  /* インジケーター */
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default useStyles;

import { Stack, Grid, Typography, Divider } from '@mui/material';

import colors from '../../../../../resources/colors';

type Props = {
  livingCost: number;
  housingCost: number;
  careCost: number;
  medicalExpenses: number;
  funeralExpenses: number;
  otherExpenses: number;
  totalExpense: number;
};

const ResultCostBox = ({
  livingCost,
  housingCost,
  careCost,
  medicalExpenses,
  funeralExpenses,
  otherExpenses,
  totalExpense,
}: Props) => (
  <Stack
    spacing={0.1}
    padding={1}
    sx={{
      backgroundColor: colors.sakuraRed,
      borderRadius: 2,
    }}>
    <Typography
      fontSize={12}
      fontWeight={800}
      color={colors.facebookColor}
      align="left">
      支出の内訳
    </Typography>
    <DetailContents text="生活費" annotation={false} cost={livingCost} />
    <Divider />
    <DetailContents text="住居費" annotation={false} cost={housingCost} />
    <Divider />
    <DetailContents text="介護費" annotation cost={careCost} />
    <Divider />
    <DetailContents text="医療費" annotation cost={medicalExpenses} />
    <Divider />
    <DetailContents text="葬儀/死後" annotation cost={funeralExpenses} />
    <Divider />
    <DetailContents text="その他費用" annotation cost={otherExpenses} />
    <Divider />
    <DetailContents text="概算合計" annotation={false} cost={totalExpense} />
  </Stack>
);

type ChildProps = {
  text: string;
  cost: number;
  annotation: boolean;
};

const DetailContents = ({ text, annotation, cost }: ChildProps) => (
  <Grid container rowSpacing={1} alignItems="center">
    <Grid item xs={4}>
      <Typography
        fontSize={14}
        fontWeight={800}
        color={colors.facebookColor}
        textAlign="center">
        {text}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      {annotation && (
        <Typography
          fontSize={8}
          fontWeight={800}
          color={colors.facebookColor}
          textAlign="right">
          ※一般家庭平均
        </Typography>
      )}
    </Grid>
    <Grid item xs={2}>
      <Typography
        fontSize={20}
        fontWeight={800}
        color={colors.facebookColor}
        textAlign="right">
        {cost}
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography fontSize={14} fontWeight={800} color={colors.facebookColor}>
        万円
      </Typography>
    </Grid>
    <Grid item xs={1} />
  </Grid>
);

export default ResultCostBox;

import React from 'react';
import { Grid, Divider, Typography, Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FpReview } from '../../../../modules/functions/matchingFunctions';

// components
import Rating from '../../../../components/Rating';

// others
import { useStyles } from '../ReviewsScreenStyle';
import {
  getUserUid,
  getIsFpFromUrl,
} from '../../../../modules/functions/userFunctions';
import { AppDispatch, RootState } from '../../../../modules/store';
import {
  getFpInfo,
  getUserInfo,
} from '../../../../modules/store/slices/matchingSlice';

type Props = {
  fpReviewInfo: FpReview;
};

const ReviewsScreen: React.FC<Props> = (props: Props) => {
  // URL に記載された uid を取得する
  const { id } = useParams<{ id: string }>();

  // state
  const classes = useStyles();
  const isFp = getIsFpFromUrl('user');
  const dispatch: AppDispatch = useDispatch();
  const { fpReviewInfo } = props;
  const { fpInfo, userInfo } = useSelector(
    (state: RootState) => state.matching
  );
  const uid = getUserUid();
  const fpId = isFp ? uid : id;
  const userId = isFp ? id : uid;

  // const storagePathList = [
  //   isFp
  //     ? { type: 'image', url: userInfo.imageUrl }
  //     : { type: 'video', url: fpInfo.videoUrl },
  // ]; // TODO: ユーザーの紹介写真を保存し次第対応

  // fetch（画面再レンダリング時のみ実行される）
  React.useEffect(() => {
    // ユーザー or FP の情報を取得
    if (isFp) {
      if (userInfo.id === '') {
        dispatch(getUserInfo({ uid: userId }));
      }
    } else if (fpInfo.id === '') {
      dispatch(getFpInfo({ uid: fpId }));
    }
  }, []);

  return (
    <>
      {' '}
      {fpReviewInfo.userId && (
        <Grid className={classes.reviewCardContainer}>
          <Box
            marginTop="20px"
            fontWeight="bold"
            fontSize="17px"
            style={{
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}>
            {'  '}
            {fpReviewInfo.reviewerAge ? (
              <Typography>{fpReviewInfo.reviewerAge}代</Typography>
            ) : (
              <Typography>ー</Typography>
            )}
            {fpReviewInfo.reviewerSex ? (
              <Typography>{fpReviewInfo.reviewerSex}</Typography>
            ) : (
              <Typography>ー</Typography>
            )}
            {/* <Typography>:</Typography>
            {fpReviewInfo.createdDate ? (
              <Typography>{fpReviewInfo.createdDate}</Typography>
            ) : (
              <Typography>ー</Typography>
            )} */}
          </Box>
          <Divider className={classes.dividerlight} />
          <Box className={classes.itemTitleBox}>
            総合評価：
            <Box width="10px" />
            {fpReviewInfo.ratingMap?.totalRating ? (
              <Grid>
                <Rating
                  rating={fpReviewInfo.ratingMap.totalRating}
                  nodata={1}
                />
              </Grid>
            ) : (
              <Grid>
                <Rating rating={0} nodata={0} />
              </Grid>
            )}
            {fpReviewInfo.ratingMap?.totalRating ? (
              <Grid>{fpReviewInfo.ratingMap.totalRating.toFixed(1)}</Grid>
            ) : (
              <Grid>---</Grid>
            )}
          </Box>
          <Box className={classes.cardItemTitleBox}>
            {fpReviewInfo.message ? (
              <Typography>{fpReviewInfo.message}</Typography>
            ) : (
              <Typography>ー</Typography>
            )}
          </Box>
        </Grid>
      )}
    </>
  );
};

export default ReviewsScreen;

import { useMediaQuery } from 'react-responsive';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Card,
  Button,
  CircularProgress,
  Backdrop,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { unwrapResult } from '@reduxjs/toolkit';

import useStyles from './UpdatePasswordScreenStyles';
import { AppDispatch, RootState } from '../../../modules/store';
import {
  updatePassowrd,
  setErrMsg,
  toggleIsErrModalOpen,
  toggleIsSuccessModalOpen,
} from '../../../modules/store/slices/userSlice';
import { isTextInvalid } from '../../../components/validation';
import Model from '../../../components/Modal';

const Screen: React.FC = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 766px)' });

  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  // state
  const [textFieldList, setTextFieldList] = React.useState({
    oldPassword: '',
    newPassword: '',
    newConfirmPassword: '',
  });
  const [isVisibilityList, setIsVisibility] = React.useState({
    isOldPasswordVisibility: false,
    isNewPasswordVisibility: false,
    isNewConfirmPasswordVisibility: false,
  });
  const { oldPassword, newPassword, newConfirmPassword } = textFieldList;
  const {
    isOldPasswordVisibility,
    isNewPasswordVisibility,
    isNewConfirmPasswordVisibility,
  } = isVisibilityList;

  const [disabled, setDisabled] = React.useState(true);
  const { isLoading, errMsg, isErrModalOpen, isSuccessModalOpen } = useSelector(
    (state: RootState) => state.user
  );

  // 登録ボタン活性・非活性処理
  const isDisabled = () =>
    isTextInvalid(oldPassword) ||
    isTextInvalid(newPassword) ||
    isTextInvalid(newConfirmPassword);

  React.useEffect(() => {
    setDisabled(isDisabled());
  }, [oldPassword, newPassword, newConfirmPassword]);

  // functions
  const onClickPasswordResetInBtn = () => {
    dispatch(updatePassowrd({ oldPassword, newPassword, newConfirmPassword }))
      .then(unwrapResult)
      .then(() => {
        dispatch(toggleIsSuccessModalOpen());
      })
      .catch((error) => {
        // 失敗時の処理
        dispatch(setErrMsg(error.message));
        dispatch(toggleIsErrModalOpen());
      });
  };

  const onChangeTextField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setTextFieldList({ ...textFieldList, [name]: value });
  };

  const onClickVisibilityBtn = (
    name:
      | 'isOldPasswordVisibility'
      | 'isNewPasswordVisibility'
      | 'isNewConfirmPasswordVisibility'
  ): void => {
    const value = isVisibilityList[name];
    setIsVisibility({ ...isVisibilityList, [name]: !value });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onClickCloseErrModalBtn = () => {
    dispatch(toggleIsErrModalOpen());
  };

  const onClickCloseSuccessModalBtn = () => {
    history.push({ pathname: '/' });
    dispatch(toggleIsSuccessModalOpen());
  };

  return (
    <div>
      {/* ---------- web画面用表示領域 (min-width: 767px) ---------- */}
      {isDesktop && (
        <Grid className={classes.root}>
          <Grid className={classes.cardRoot}>
            <Typography align="center" className={classes.title}>
              パスワード変更
            </Typography>

            {/* form */}
            <Card className={classes.textCardRoot}>
              <Typography className={classes.passwordTextFieldTitle}>
                現在のパスワード
              </Typography>
              <TextField
                className={classes.passwordTextField}
                type={isOldPasswordVisibility ? 'text' : 'password'}
                variant="outlined"
                value={oldPassword}
                name="oldPassword"
                onChange={onChangeTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          onClickVisibilityBtn('isOldPasswordVisibility')
                        }
                        onMouseDown={handleMouseDownPassword}>
                        {isOldPasswordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography className={classes.passwordTextFieldTitle}>
                新しいパスワード
              </Typography>
              <TextField
                className={classes.passwordTextField}
                type={isNewPasswordVisibility ? 'text' : 'password'}
                variant="outlined"
                value={newPassword}
                name="newPassword"
                onChange={onChangeTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          onClickVisibilityBtn('isNewPasswordVisibility')
                        }
                        onMouseDown={handleMouseDownPassword}>
                        {isNewPasswordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography className={classes.passwordTextFieldTitle}>
                新しいパスワード（確認）
              </Typography>
              <TextField
                className={classes.passwordTextField}
                type={isNewConfirmPasswordVisibility ? 'text' : 'password'}
                variant="outlined"
                value={newConfirmPassword}
                name="newConfirmPassword"
                onChange={onChangeTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          onClickVisibilityBtn('isNewConfirmPasswordVisibility')
                        }
                        onMouseDown={handleMouseDownPassword}>
                        {isNewConfirmPasswordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                className={classes.passwordResetButton}
                onClick={onClickPasswordResetInBtn}
                disabled={disabled}
                classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
              >
                更新する
              </Button>
            </Card>

            {/* インジケーター */}
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress />
            </Backdrop>

            {/* modal：成功時と失敗時で別のモーダルを使う（可読性を上げるため） */}
            <Model
              isOpen={isErrModalOpen}
              title="パスワードの変更に失敗しました"
              body={errMsg}
              buttonName="もう一度入力する"
              onClickCloseModalBtn={onClickCloseErrModalBtn}
            />
            <Model
              isOpen={isSuccessModalOpen}
              title="パスワードを変更しました"
              body=""
              buttonName="ホームへ戻る"
              onClickCloseModalBtn={onClickCloseSuccessModalBtn}
            />
          </Grid>
        </Grid>
      )}
      {/* ---------- スマホ画面用表示領域 (max-width: 766px) ---------- */}
      {isMobile && (
        <Grid className={classes.root}>
          <Grid className={classes.spCardRoot}>
            <Typography align="center" className={classes.spTitle}>
              パスワード変更
            </Typography>

            {/* form */}
            <Card className={classes.textCardRoot}>
              <Typography className={classes.passwordTextFieldTitle}>
                現在のパスワード
              </Typography>
              <TextField
                className={classes.passwordTextField}
                type={isOldPasswordVisibility ? 'text' : 'password'}
                variant="outlined"
                value={oldPassword}
                name="oldPassword"
                onChange={onChangeTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          onClickVisibilityBtn('isOldPasswordVisibility')
                        }
                        onMouseDown={handleMouseDownPassword}>
                        {isOldPasswordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography className={classes.passwordTextFieldTitle}>
                新しいパスワード
              </Typography>
              <TextField
                className={classes.passwordTextField}
                type={isNewPasswordVisibility ? 'text' : 'password'}
                variant="outlined"
                value={newPassword}
                name="newPassword"
                onChange={onChangeTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          onClickVisibilityBtn('isNewPasswordVisibility')
                        }
                        onMouseDown={handleMouseDownPassword}>
                        {isNewPasswordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography className={classes.passwordTextFieldTitle}>
                新しいパスワード（確認）
              </Typography>
              <TextField
                className={classes.passwordTextField}
                type={isNewConfirmPasswordVisibility ? 'text' : 'password'}
                variant="outlined"
                value={newConfirmPassword}
                name="newConfirmPassword"
                onChange={onChangeTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          onClickVisibilityBtn('isNewConfirmPasswordVisibility')
                        }
                        onMouseDown={handleMouseDownPassword}>
                        {isNewConfirmPasswordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                className={classes.passwordResetButton}
                onClick={onClickPasswordResetInBtn}
                disabled={disabled}
                classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
              >
                更新する
              </Button>
            </Card>

            {/* インジケーター */}
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress />
            </Backdrop>

            {/* modal：成功時と失敗時で別のモーダルを使う（可読性を上げるため） */}
            <Model
              isOpen={isErrModalOpen}
              title="パスワードの変更に失敗しました"
              body={errMsg}
              buttonName="もう一度入力する"
              onClickCloseModalBtn={onClickCloseErrModalBtn}
            />
            <Model
              isOpen={isSuccessModalOpen}
              title="パスワードを変更しました"
              body=""
              buttonName="ホームへ戻る"
              onClickCloseModalBtn={onClickCloseSuccessModalBtn}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Screen;

import React from 'react';
import { Grid, Typography, CardMedia, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ClientUser } from '../../../../modules/entities/ClientUser';
import { Fp } from '../../../../modules/functions/matchingFunctions';
import {
  getIsFp,
  getUserUid,
} from '../../../../modules/functions/userFunctions';
import { AppDispatch } from '../../../../modules/store';
import { setFpInfo } from '../../../../modules/store/slices/matchingSlice';

import { fetchChatRoomID } from '../../../../modules/store/slices/messageSlice';

// component
import Rating from '../../../../components/Rating';
import Expertise from '../../../../components/Expertise';

// styles
import { cardStyles } from '../SearchFpScreenStyles';

// image
import monedoc from '../../../../resources/image/monedoc.png';

type Props = {
  info: Fp | ClientUser;
};

const SearchFpScreenCard: React.FC<Props> = (props: Props) => {
  // state
  const classes = cardStyles();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { info } = props;
  const isFp = getIsFp();
  const fpInfo = info as Fp;
  const { id } = useParams<{ id: string }>();
  const uid = getUserUid();
  const userId = isFp ? id : uid;

  // functions
  const onClickCard = () => {
    dispatch(setFpInfo({ fpInfo }));
    window.scrollTo(0, 0);
    history.push({
      pathname: `/matching/individual-fp/user/${info.id}`,
    });
  };

  const onClickChatBtn = async () => {
    dispatch(setFpInfo({ fpInfo }));
    const chatRoomID = await fetchChatRoomID(userId, info.id);
    history.push({
      pathname: `/matching/chatRooms/${chatRoomID}`,
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      {info.nickName && (
        <Box className={classes.cardRoot}>
          {fpInfo.belongs === '株式会社ＦＰパートナー' ? (
            <CardMedia
              component="img"
              image={monedoc}
              className={classes.fpBelongsIcon}
            />
          ) : (
            ''
          )}
          <Box height="10px" />
          <Box display="flex">
            <CardMedia
              component="img"
              image={fpInfo.imageUrl}
              className={classes.fpImage}
            />
            <Box width="10px" />
            <Box>
              <Box className={classes.nickname}>
                {fpInfo.nickName.length < 15
                  ? fpInfo.nickName
                  : fpInfo.nickName.substr(0, 15).concat('..')}
              </Box>
              <Box>
                <Box className={classes.cardItemBox}>
                  評価：
                  {fpInfo.ratingMap?.totalRating ? (
                    <Grid>
                      <Rating
                        rating={fpInfo.ratingMap.totalRating}
                        nodata={1}
                      />
                    </Grid>
                  ) : (
                    <Grid>
                      <Rating rating={0} nodata={0} />
                    </Grid>
                  )}
                  <Box />
                </Box>
              </Box>
              <Box className={classes.cardItemBox}>
                {' '}
                年齢：
                <Box />
                {fpInfo.age ? (
                  <Typography>{fpInfo.age}歳</Typography>
                ) : (
                  <Typography>ー</Typography>
                )}
                {/* <Typography>/</Typography>
                {fpInfo.sex ? (
                  <Typography>{fpInfo.sex}</Typography>
                ) : (
                  <Typography>ー</Typography>
                )} */}
              </Box>
            </Box>
            <Box width="15px" />
            <Box className={classes.strongAreaRoot}>
              <Box className={classes.strongAreaTitle}>得意相談領域</Box>
              <Box height="7px" />
              <Box className={classes.strongAreaContainer}>
                {fpInfo.strongAreaMap?.oldAge ? (
                  <Expertise
                    content="老後"
                    active={fpInfo.strongAreaMap.oldAge}
                  />
                ) : (
                  <Expertise content="老後" active={false} />
                )}
                {fpInfo.strongAreaMap?.housing ? (
                  <Expertise
                    content="住宅"
                    active={fpInfo.strongAreaMap.housing}
                  />
                ) : (
                  <Expertise content="住宅" active={false} />
                )}
                {fpInfo.strongAreaMap?.education ? (
                  <Expertise
                    content="教育"
                    active={fpInfo.strongAreaMap.education}
                  />
                ) : (
                  <Expertise content="教育" active={false} />
                )}
                {fpInfo.strongAreaMap?.assetManagement ? (
                  <Expertise
                    content="運用"
                    active={fpInfo.strongAreaMap.assetManagement}
                  />
                ) : (
                  <Expertise content="運用" active={false} />
                )}
                {fpInfo.strongAreaMap?.insurance ? (
                  <Expertise
                    content="保険"
                    active={fpInfo.strongAreaMap.insurance}
                  />
                ) : (
                  <Expertise content="保険" active={false} />
                )}
              </Box>
            </Box>
          </Box>
          <Box height="10px" />
          <Box display="flex" height="38px">
            <Box onClick={onClickCard} className={classes.cardBtn}>
              プロフィール/評価を見てみる
            </Box>
            <Box width="10px" />
            <Box onClick={onClickChatBtn} className={classes.cardBtn}>
              このFPに相談する
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SearchFpScreenCard;

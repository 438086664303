/**
 * lifePlanの希望に応じた老後の生活費を計算。
 * 寿命が65-80の場合及び80-100の場合で条件分岐。夫婦の場合1.8倍する。
 * @param lifeSpan 想定寿命(歳)
 * @param livingCostPerYear60to80 65歳から80歳までの月あたりの生活費
 * @param livingCostPerYear80to100 80歳から100歳までの月あたりの生活費
 * @param willBeMarried 老後生活は結婚しているか
 * @returns 老後の生活費
 */
export function calculateLivingCost(
  lifeSpan: number,
  livingCostPerYear60to80: number,
  livingCostPerYear80to100: number,
  willBeMarried: boolean
) {
  // 想定寿命が65歳未満の場合、老後の生活費はなし。
  if (lifeSpan < 65) return 0;

  // 単身の場合の生活費を計算。(想定寿命が80歳以上かどうかで場合分け。)
  const singleLivingCost =
    lifeSpan > 80
      ? Math.round(
          livingCostPerYear60to80 * 15 +
            livingCostPerYear80to100 * (lifeSpan - 80)
        ) * 12
      : Math.round(livingCostPerYear60to80 * (lifeSpan - 65)) * 12;

  // 結婚している場合は1.7倍。
  return willBeMarried ? 1.7 * singleLivingCost : singleLivingCost;
}

/**
 * 住宅費の計算
 * 賃貸の場合,寿命までの家賃を計算。なお、更新料を2年に1回とする。(24ヶ月におきに1ヶ月分追加)
 * 持ち家場合,勤続40年としてローンを計算。なお、修繕費固定資産税を年10万円とする。
 * @param houseStatus 賃貸 or 持ち家。
 * @param lifeSpan 想定寿命(歳)
 * @param purchaseHouseAge 持ち家購入時の年齢(賃貸の場合はundefined)
 * @param housePrice 持ち家の購入時価格(賃貸の場合はundefined)
 * @param monthlyRent 家賃(持ち家の場合はundefined)
 * @returns 老後の住宅費
 */
export function calculateHousingCost(
  houseStatus: string,
  lifeSpan: number,
  purchaseHouseAge?: number,
  housePrice?: number,
  monthlyRent?: number
): number {
  // 想定寿命が65歳未満の場合、老後の住宅費はなし。
  if (lifeSpan < 65) return 0;

  switch (houseStatus) {
    case '持ち家': {
      if (purchaseHouseAge === undefined) return 0;
      if (housePrice === undefined || housePrice < 0) return 0;
      const housingLoan =
        purchaseHouseAge < 26
          ? 0
          : Math.round(((housePrice * 1.07) / 40) * (purchaseHouseAge - 25));
      return 10 * (lifeSpan - 65) + housingLoan;
    }
    case '賃貸':
      if (monthlyRent === undefined || monthlyRent < 0) return 0;
      return (monthlyRent * 12 * (lifeSpan - 65) * 25) / 24;
    default:
      return 0;
  }
}

/**
 * 基礎年金の計算
 * 夫婦の場合2倍とする。
 * @param lifeSpan 想定寿命(歳)
 * @param willBeMarried 老後生活は結婚しているか
 * @returns 基礎年金
 */
export function calculateBasicAnnuity(
  lifeSpan: number,
  willBeMarried: boolean
): number {
  // 想定寿命が65歳未満の場合、老後の年金はなし。
  if (lifeSpan < 65) return 0;

  // 基礎年金の計算
  const basicAnnuity = 5.7 * 12 * (lifeSpan - 65);

  return willBeMarried ? basicAnnuity * 2 : basicAnnuity;
}

/**
 * 厚生年金の計算
 * 厚生年金試算計算ロジック : 最大年収の80%が平均年収と仮定し、500万円の厚生年金8.4万円/月を用いて比例計算。上限は18万円/月と設定。
 * @param isEmployee 会社員か否か
 * @param estimatedMaxIncome 最大想定年収
 * @param lifeSpan 想定寿命(歳)
 * @returns 厚生年金
 */
export function calculateEmployeeAnnuity(
  isEmployee: boolean,
  estimatedMaxIncome: number,
  lifeSpan: number
): number {
  // 想定寿命が65歳未満の場合、老後の年金はなし。
  if (lifeSpan < 65) return 0;

  // 厚生年金の計算
  const workAnnuity = isEmployee ? ((estimatedMaxIncome * 0.8) / 500) * 8.4 : 0;
  const maxWorkAnnuity =
    workAnnuity > 18
      ? 18 * 12 * (lifeSpan - 65)
      : workAnnuity * 12 * (lifeSpan - 65);

  return Math.round(maxWorkAnnuity);
}

/**
 * 年金の総額の計算
 * 厚生年金と基礎年金を合算する。夫婦の場合2倍とする。
 * 厚生年金試算計算ロジック : 最大年収の80%が平均年収と仮定し、500万円の厚生年金8.4万円/月を用いて比例計算。上限は18万円/月と設定。
 * @param isEmployee 会社員か否か
 * @param estimatedMaxIncome 最大想定年収
 * @param lifeSpan 想定寿命(歳)
 * @param willBeMarried 老後生活は結婚しているか
 * @returns 年金総額
 */
export function calculateAnnuity(
  isEmployee: boolean,
  estimatedMaxIncome: number,
  lifeSpan: number,
  willBeMarried: boolean
): number {
  // 想定寿命が65歳未満の場合、老後の年金はなし。
  if (lifeSpan < 65) return 0;

  // 基礎年金の計算
  const basicAnnuity = 5.7 * 12 * (lifeSpan - 65);

  // 厚生年金の計算
  const workAnnuity = isEmployee ? ((estimatedMaxIncome * 0.8) / 500) * 8.4 : 0;
  const maxWorkAnnuity =
    workAnnuity > 18
      ? 18 * 12 * (lifeSpan - 65)
      : workAnnuity * 12 * (lifeSpan - 65);

  return willBeMarried
    ? basicAnnuity * 2 + Math.round(maxWorkAnnuity)
    : basicAnnuity + Math.round(maxWorkAnnuity);
}

/**
 * 老後のために必要な貯金額を計算
 * 計算結果が0未満の場合、結果を0で上書きする。
 * 税金の計算 : 退職金一括手取り計算 勤続年数40年。控除後の50%が課税対象。税率20％。
 * 税金の計算式: https://mponline.sbi-moneyplaza.co.jp/money/retirement/20210511taishokukin-zeikinikura.html#1
 * @param lifeSpan
 * @param annuity
 * @param retiredPay
 * @param partnerEmployeeAnnuity
 * @param incomeAfterRetired
 * @param livingCost
 * @param housingCost
 * @param medicalCost
 * @param careCost
 * @param endingCost
 * @param otherCost
 * @param savingsAmount
 * @returns 老後のために必要な貯金額(0円未満の場合は0円。)
 */
export function calculateRequiredSavingsForOldAge(
  lifeSpan: number,
  annuity: number,
  retiredPay: number,
  partnerEmployeeAnnuity: number,
  incomeAfterRetired: number,
  livingCost: number,
  housingCost: number,
  medicalCost: number,
  careCost: number,
  endingCost: number,
  otherCost: number,
  savingsAmount: number
): number {
  // 費用総額
  const totalCost =
    livingCost + housingCost + medicalCost + endingCost + otherCost + careCost;

  // 税引後の退職金を計算
  const taxCharge =
    retiredPay - (800 + 70 * (40 - 20)) < 0
      ? 0
      : (0.2 * (retiredPay - (800 + 70 * (40 - 20)))) / 2;
  const actualRetiredPay = retiredPay - taxCharge;

  // 収入総額
  const totalIncome =
    annuity +
    incomeAfterRetired * 12 * (lifeSpan - 65) +
    partnerEmployeeAnnuity +
    actualRetiredPay +
    savingsAmount;

  return totalCost - totalIncome > 0 ? Math.round(totalCost - totalIncome) : 0;
}

/**
 * 老後のために必要な貯金額を計算(入力途中に表示する用)
 * 年金など、一から計算する仕様
 * 計算結果が0未満の場合、結果を0で上書きする。
 * 税金の計算 : 退職金一括手取り計算 勤続年数は35年。控除後の50%が課税対象。税率20％。
 * 税金の計算式: https://mponline.sbi-moneyplaza.co.jp/money/retirement/20210511taishokukin-zeikinikura.html#1
 * @param marriageStatus
 * @param isEmployee 会社員か否か
 * @param estimatedIncome 最大想定年収
 * @param lifeSpan 想定寿命(歳)
 * @param livingCostPerYear60to80
 * @param livingCostPerYear80to100
 * @param retiredPay
 * @param incomeAfterRetired
 * @param partnerIncome 配偶者の年収
 * @param houseStatus 賃貸 or 持ち家。
 * @param purchaseHouseAge 持ち家購入時の年齢(賃貸の場合はundefined)
 * @param housePrice 持ち家の購入時価格(賃貸の場合はundefined)
 * @param monthlyRent 家賃(持ち家の場合はundefined)
 * @param careExpense
 * @param medicalExpense
 * @param funeralExpenses
 * @param otherCost
 * @param savingsAmount
 * @returns 老後のために必要な貯金額(0円未満の場合は0円。)
 */
export function calculateRoughRequiredSavingsForOldAge(
  marriageStatus: string,
  isEmployee: boolean,
  estimatedIncome: number,
  lifeSpan: number,
  livingCostPerYear60to80: number,
  livingCostPerYear80to100: number,
  retiredPay: number,
  incomeAfterRetired: number,
  partnerIncome: number,
  houseStatus: string,
  careExpense: number,
  medicalExpense: number,
  funeralExpenses: number,
  otherCost: number,
  savingsAmount: number,

  purchaseHouseAge?: number,
  housePrice?: number,
  monthlyRent?: number
): number {
  // isMarried計算------------------------------------
  const isMarried = marriageStatus === '夫婦';

  // livingCost計算------------------------------------
  // 単身の場合の生活費を計算。(想定寿命が80歳以上かどうかで場合分け。)
  const singleLivingCost =
    lifeSpan > 80
      ? Math.round(
          livingCostPerYear60to80 * 20 +
            livingCostPerYear80to100 * (lifeSpan - 80)
        ) * 12
      : Math.round(livingCostPerYear60to80 * (lifeSpan - 65)) * 12;

  let preLivingCost = 0; // 初期化
  if (lifeSpan < 65) {
    // 想定寿命が65歳未満の場合、老後の生活費はなし。
    preLivingCost = 0;
  } else {
    // 結婚している場合は1.8倍。
    preLivingCost = isMarried ? 1.8 * singleLivingCost : singleLivingCost;
  }
  const livingCost = preLivingCost;

  // housingCost計算 ------------------------------------
  // 想定寿命が65歳未満の場合、老後の住宅費はなし。

  let preHouseCost = 0;

  if (houseStatus === '持ち家') {
    if (purchaseHouseAge === undefined) return 0;
    if (housePrice === undefined || housePrice < 0) return 0;
    const housingLoan =
      purchaseHouseAge < 26
        ? 0
        : Math.round(((housePrice * 1.07) / 35) * (purchaseHouseAge - 25));
    preHouseCost = 10 * (lifeSpan - 65) + housingLoan;
  }
  if (houseStatus === '賃貸') {
    if (monthlyRent === undefined || monthlyRent < 0) return 0;
    preHouseCost = (monthlyRent * 12 * (lifeSpan - 65) * 25) / 24;
  }
  if (lifeSpan < 65) {
    preHouseCost = 0;
  }
  const housingCost = preHouseCost;

  // annuity計算 ------------------------------------
  // 基礎年金の計算
  const basicAnnuity = 5.7 * 12 * (lifeSpan - 5);

  // 厚生年金の計算
  const workAnnuity = isEmployee ? ((estimatedIncome * 0.8) / 500) * 8.4 : 0;
  const maxWorkAnnuity =
    workAnnuity > 18
      ? 18 * 12 * (lifeSpan - 65)
      : workAnnuity * 12 * (lifeSpan - 65);

  let preAnnuity = 0; // 初期化
  if (lifeSpan < 65) {
    // 想定寿命が65歳未満の場合、老後の年金はなし。
    preAnnuity = 0;
  } else {
    // 結婚している場合は1.8倍。
    preAnnuity = isMarried
      ? basicAnnuity * 2 + Math.round(maxWorkAnnuity)
      : basicAnnuity + Math.round(maxWorkAnnuity);
  }
  const annuity = preAnnuity;

  // partnerEmployeeAnnuity計算 ------------------------------------
  // 配偶者の厚生年金の計算
  const partnerWorkAnnuity =
    partnerIncome === 0 ? 0 : ((partnerIncome * 0.8) / 500) * 8.4;
  const partnerMaxWorkAnnuity =
    partnerWorkAnnuity > 18
      ? 18 * 12 * (lifeSpan - 65)
      : partnerWorkAnnuity * 12 * (lifeSpan - 65);

  let prePartnerEmployeeAnnuity = 0; // 初期化
  if (lifeSpan < 65) {
    // 想定寿命が65歳未満の場合、老後の年金はなし。
    prePartnerEmployeeAnnuity = 0;
  } else {
    prePartnerEmployeeAnnuity = Math.round(partnerMaxWorkAnnuity);
  }
  const partnerEmployeeAnnuity = prePartnerEmployeeAnnuity;

  // careCost計算 ------------------------------------
  const careCost = isMarried ? careExpense * 2 : careExpense;

  // medicalCost計算 ------------------------------------
  const medicalCost = isMarried ? medicalExpense * 2 : medicalExpense;

  // endingCost計算 ------------------------------------
  const endingCost = isMarried ? funeralExpenses * 2 : funeralExpenses;

  // roughRequiredSavingsForOldAge計算 ------------------------------------
  // 費用総額
  const totalCost =
    livingCost + housingCost + medicalCost + endingCost + otherCost + careCost;

  // 税引後の退職金を計算
  const taxCharge =
    retiredPay - (800 + 70 * (35 - 20)) < 0
      ? 0
      : (0.2 * (retiredPay - (800 + 70 * (35 - 20)))) / 2;
  const actualRetiredPay = retiredPay - taxCharge;

  // 収入総額
  const totalIncome =
    actualRetiredPay +
    annuity +
    incomeAfterRetired * 12 * (lifeSpan - 65) +
    partnerEmployeeAnnuity +
    savingsAmount;

  const roughRequiredSavingsForOldAge =
    totalCost - totalIncome > 0 ? Math.round(totalCost - totalIncome) : 0;

  return roughRequiredSavingsForOldAge;
}

/**
 * 老後のために必要な貯金額を計算し、値を丸めて表示する
 * 計算結果が0未満の場合、結果を0で上書きする。
 * 税金の計算 : 退職金一括手取り計算 勤続年数40年。控除後の50%が課税対象。税率20％。
 * 税金の計算式: https://mponline.sbi-moneyplaza.co.jp/money/retirement/20210511taishokukin-zeikinikura.html#1
 * @param lifeSpan
 * @param annuity
 * @param partnerEmployeeAnnuity
 * @param retiredPay
 * @param incomeAfterRetired
 * @param livingCost
 * @param housingCost
 * @param medicalCost
 * @param careCost
 * @param endingCost
 * @param otherCost
 * @param savingsAmount
 * @returns 老後のために必要な貯金額(ユーザ表示用の丸めた値)
 */
export function calculateRoundingRequiredSavingsForOldAge(
  lifeSpan: number,
  annuity: number,
  partnerEmployeeAnnuity: number,
  retiredPay: number,
  incomeAfterRetired: number,
  livingCost: number,
  housingCost: number,
  medicalCost: number,
  careCost: number,
  endingCost: number,
  otherCost: number,
  savingsAmount: number
): number {
  // 費用総額
  const totalCost =
    livingCost + housingCost + medicalCost + endingCost + otherCost + careCost;

  // 税引後の退職金を計算
  const taxCharge =
    retiredPay - (800 + 70 * (40 - 20)) < 0
      ? 0
      : (0.2 * (retiredPay - (800 + 70 * (40 - 20)))) / 2;
  const actualRetiredPay = retiredPay - taxCharge;

  // 収入総額
  const totalIncome =
    actualRetiredPay +
    annuity +
    incomeAfterRetired * 12 * (lifeSpan - 65) +
    partnerEmployeeAnnuity +
    savingsAmount;

  const requiredSavingsForOldAge =
    totalCost - totalIncome > 0 ? Math.round(totalCost - totalIncome) : 0;

  let result = 0;

  if (requiredSavingsForOldAge < 1500) {
    result = 1000;
  } else if (
    requiredSavingsForOldAge > 1500 &&
    requiredSavingsForOldAge < 2500
  ) {
    result = 2000;
  } else if (
    requiredSavingsForOldAge > 2500 &&
    requiredSavingsForOldAge < 3500
  ) {
    result = 3000;
  } else if (
    requiredSavingsForOldAge > 3500 &&
    requiredSavingsForOldAge < 4500
  ) {
    result = 4000;
  } else {
    result = 4500;
  }
  return result;
}

/**
 * 現金で貯蓄する場合の1ヶ月あたりに貯金すべき金額を計算
 * @param requiredSavings 老後までに必要な貯蓄額
 * @param currentSavingsAmount 現在の貯蓄額
 * @param age 現在の年齢
 * @returns 現金で貯蓄する場合、1ヶ月あたりに貯金すべき金額(万円)
 */
export function calculateRequiredSavingsPerMonth(
  requiredSavings: number,
  currentSavingsAmount: number,
  age: number
): number {
  const requiredSavingsPerMonth =
    Math.round((requiredSavings * 10) / (65 - age) / 12) / 10;

  return requiredSavingsPerMonth < 0 ? 0 : requiredSavingsPerMonth;
}

/**
 * 積立運用(年利3%)の場合の毎月の積立額を計算
 * @param requiredSavings 老後までに必要な貯蓄額
 * @param currentSavingsAmount 現在の貯蓄額
 * @param age 現在の年齢
 * @returns 積立運用(年利3%)の場合、1ヶ月あたりに積立てるべき金額(万円)
 */
export function calculateRequiredInvestmentPerMonth(
  requiredSavings: number,
  currentSavingsAmount: number,
  age: number
): number {
  const requiredInvestmentPerMonth =
    Math.round(
      10 *
        ((requiredSavings -
          currentSavingsAmount * 1.03 ** (65 - age) +
          currentSavingsAmount) /
          (65 - age) /
          12 /
          2.72018462574 ** (0.0165 * (65 - age)))
    ) / 10;

  return requiredInvestmentPerMonth < 0 ? 0 : requiredInvestmentPerMonth;
}

/**
 * 老後の収入合計額を計算
 * 計算結果が0未満の場合、結果を0で上書きする。
 * @param isEmployee 会社員か否か
 * @param estimatedMaxIncome 最大想定年収
 * @param lifeSpan 想定寿命(歳)
 * @param willBeMarried 老後生活は結婚しているか
 * @param retiredPay 退職金
 * @param incomeAfterRetired 老後の収入
 * @param partnerIncome 配偶者の厚生年金
 * @param savingsAmount 貯金
 * @returns 老後に入る収入の合計額(0円未満の場合は0円。)
 */
export function calculateTotalRetirementIncome(
  isEmployee: boolean,
  estimatedMaxIncome: number,
  lifeSpan: number,
  willBeMarried: boolean,
  retiredPay: number,
  incomeAfterRetired: number,
  partnerIncome: number,
  savingsAmount: number
): number {
  // 想定寿命が65歳未満の場合、老後の年金はなし。
  if (lifeSpan < 65) return 0;

  // 基礎年金の計算
  const basicAnnuity = 5.7 * 12 * (lifeSpan - 65);

  // 厚生年金の計算
  const workAnnuity = isEmployee ? ((estimatedMaxIncome * 0.8) / 500) * 8.4 : 0;
  const maxWorkAnnuity =
    workAnnuity > 18
      ? 18 * 12 * (lifeSpan - 65)
      : workAnnuity * 12 * (lifeSpan - 65);

  const annuity = willBeMarried
    ? basicAnnuity * 2 + Math.round(maxWorkAnnuity)
    : basicAnnuity + Math.round(maxWorkAnnuity);

  // 配偶者の厚生年金の計算(老後に結婚していない場合は0に初期化)
  const partnerWorkAnnuity =
    partnerIncome === 0 ? 0 : ((partnerIncome * 0.8) / 500) * 8.4;
  const partnerMaxWorkAnnuity =
    partnerWorkAnnuity > 18
      ? 18 * 12 * (lifeSpan - 65)
      : partnerWorkAnnuity * 12 * (lifeSpan - 65);

  let prePartnerEmployeeAnnuity = 0; // 初期化
  if (lifeSpan < 65) {
    // 想定寿命が65歳未満の場合、老後の年金はなし。
    prePartnerEmployeeAnnuity = 0;
  } else {
    prePartnerEmployeeAnnuity = Math.round(partnerMaxWorkAnnuity);
  }
  const partnerEmployeeAnnuity = willBeMarried ? prePartnerEmployeeAnnuity : 0;

  return lifeSpan < 65
    ? savingsAmount
    : incomeAfterRetired * 12 * (lifeSpan - 65) +
        annuity +
        partnerEmployeeAnnuity +
        retiredPay +
        savingsAmount;
}

import { useState, createRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Stack,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Typography,
} from '@mui/material';
import { AppDispatch, RootState } from '../../../modules/store';
import colors from '../../../resources/colors';
import { DiagnosisData } from '../../../modules/entities/DiagnosisData';
import { questions } from './constants/questions';
import {
  registerDiagnosisData,
  updateDiagnosisData,
  updateDiagnosisAnxietyLevel,
} from '../../../modules/store/slices/diagnosisDataSlice';
import RoughResultBox from './components/RoughResultBox';
import DiagnosisMessageRow from './components/DiagnosisMessageBox';
import ResultMessageBox from './components/ResultMessageBox';
// 初期リリース時一時的に非表示
// import ResultDetailsBox from './components/ResultDetailsBox';
import ResultSavingMoneyBox from './components/ResultSavingMoneyBox';
import { updateAnxietyLevel } from '../../../modules/store/slices/clientUserSlice';
import {
  calculateBasicAnnuity,
  calculateEmployeeAnnuity,
  calculateAnnuity,
  calculateHousingCost,
  calculateLivingCost,
  calculateRequiredInvestmentPerMonth,
  calculateRequiredSavingsForOldAge,
  calculateRequiredSavingsPerMonth,
  calculateTotalRetirementIncome,
  calculateRoughRequiredSavingsForOldAge,
  calculateRoundingRequiredSavingsForOldAge,
} from '../../../utils/financeFunctions';

type ChatMessage = {
  message: string;
  isInstruction: boolean;
  isOwnMessage: boolean;
  isIconShown: boolean;
  isBackButton: boolean;
  isAnswerShown: boolean;
};

const SPDiagnosisScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const { isAnonymous } = useSelector((state: RootState) => state.user);
  const [messages, setMessages] = useState<ChatMessage[]>([
    {
      message: questions[0].text,
      isInstruction: questions[0].instruction,
      isOwnMessage: false,
      isIconShown: questions[0].displayIcon,
      isBackButton: false,
      isAnswerShown: false,
    },
  ]);

  const [questionIndex, setQuestionIndex] = useState([0]);
  const [isInputMode, setIsInputMode] = useState(true);
  // 診断機能の進行度合い
  const [phase, setPhase] = useState<'診断中' | '結果確認中' | '診断終了'>(
    '診断中'
  );
  const [inputText, setInputText] = useState('');
  // 回答データ
  const [answer, setAnswer] = useState<Partial<DiagnosisData>>({});
  // 途中概算
  const [roughAnswer, setRoughAnswer] = useState(3000);

  // 最終結果
  const [resultData, setResultData] = useState<DiagnosisData>();

  // カードコンポーネント内のボトムへ移動する処理
  const ref = createRef<HTMLDivElement>();
  const scrollToBottomOfList = useCallback(() => {
    ref!.current!.scrollIntoView({
      block: 'end',
    });
  }, [ref]);
  useEffect(() => {
    scrollToBottomOfList();
  }, [messages, resultData]);

  // 結果メッセージ表示時に回答データをDB登録
  useEffect(() => {
    if (phase === '結果確認中') {
      const result = calculateDiagnosisData(answer);
      setResultData(result);
      // diagnosisのコレクションに診断結果を追加
      dispatch(registerDiagnosisData(result));
      // diagnosisのフィールドを最新の診断結果で上書き
      dispatch(updateDiagnosisData(result));
    }
  }, [phase]);

  return (
    <Grid>
      {phase === '診断中' ? <RoughResultBox result={roughAnswer} /> : ''}
      <Stack
        direction="column"
        justifyContent="space-between"
        spacing={1}
        sx={{
          paddingTop: 18,
          height: '100vh',
          '&': {
            height: '100dvh',
          },
          position: 'absolute',
          top: 0,
        }}>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            paddingLeft: 3,
            paddingRight: 3,
            overflow: 'auto',
          }}>
          {messages.map((message) => (
            <>
              <DiagnosisMessageRow
                text={message.message}
                isIconShown={message.isIconShown}
                isOwnMessage={message.isOwnMessage}
                isBackButton={message.isBackButton}
                onClick={() => onClickBack()}
              />
              {/* 説明文の時は次の質問へスキップ */}
              {message.isInstruction ? setNext() : null}

              {/* 結果表示メッセージ */}
              {message.isAnswerShown && resultData !== undefined && (
                <>
                  <ResultMessageBox
                    // totalRequiredSavings={resultData.totalRequiredSavings}
                    // 丸めた値を表示
                    totalRequiredSavings={
                      resultData.roundingTotalRequiredSavings
                    }
                  />
                  {/* 支出内訳 */}
                  {/* <ResultDetailsBox
                  livingCost={resultData.livingCost}
                  housingCost={resultData.housingCost}
                  careCost={resultData.careCost}
                  medicalExpenses={resultData.medicalExpenses}
                  funeralExpenses={resultData.funeralExpenses}
                  otherExpenses={resultData.otherExpenses}
                  totalExpense={resultData.totalExpense}
                /> */}
                  <ResultSavingMoneyBox
                    totalRequiredSavings={
                      resultData.roundingTotalRequiredSavings
                    }
                    requiredSavingsPerMonth={calculateRequiredSavingsPerMonth(
                      resultData.roundingTotalRequiredSavings,
                      resultData.savingsAmount,
                      resultData.age
                    )}
                    requiredInvestmentPerMonth={calculateRequiredInvestmentPerMonth(
                      resultData.roundingTotalRequiredSavings,
                      resultData.savingsAmount,
                      resultData.age
                    )}
                  />
                  <DiagnosisMessageRow
                    text={
                      '老後に備えて上記金額を\n用意する必要があるでありマス！\n\n詳細については、\n専門家がチャットで無料解説するので\n気になる方は相談するでありマス！'
                    }
                    isIconShown
                    isOwnMessage={false}
                    isBackButton={false}
                    onClick={() => onClickBack()}
                  />
                </>
              )}
            </>
          ))}
          {/* カードコンポーネント内のボトムへ移動する処理 */}
          <div ref={ref} />
        </Stack>

        {/* 画面下部の回答選択/入力エリア */}
        {isInputMode && (
          <Stack
            spacing={1}
            padding={1}
            sx={{
              backgroundColor: colors.lightGray,
            }}>
            {/* 回答選択エリア */}
            {questions[questionIndex[questionIndex.length - 1]].options.length >
              0 && (
              <Grid container columnSpacing={2} rowSpacing={1}>
                {questions[questionIndex[questionIndex.length - 1]].options.map(
                  (questionOption, _, options) => (
                    <Grid
                      item
                      xs={
                        questionOption.hint === undefined &&
                        questions[questionIndex[questionIndex.length - 1]]
                          .id !== 'last'
                          ? 6
                          : 10
                      }
                      md={12 / options.length}>
                      <Button
                        variant="contained"
                        color="inherit"
                        sx={{
                          width: '100%',
                          backgroundColor: colors.paleBlue,
                          display: 'flex',
                          justifyContent: 'space-evenly',
                        }}
                        onClick={() => {
                          onClickAnswer(
                            questionOption.option,
                            questionOption.value
                          );
                          if (phase === '結果確認中') {
                            setPhase('診断終了');
                          }
                        }}>
                        <Typography fontSize={14}>
                          {questionOption.option}
                        </Typography>
                        {questionOption.hint !== undefined && (
                          <Typography fontSize={12}>
                            {questionOption.hint}
                          </Typography>
                        )}
                      </Button>
                    </Grid>
                  )
                )}
              </Grid>
            )}
            {/* 回答入力エリア */}
            {questions[questionIndex[questionIndex.length - 1]]
              .hasFreeInputField && (
              <Grid container direction="row" columnSpacing={1}>
                <Grid item xs />
                <Grid item xs={6} md={4}>
                  <TextField
                    id="outlined-basic"
                    label=""
                    type="number"
                    variant="outlined"
                    placeholder={
                      questions[questionIndex[questionIndex.length - 1]]
                        .placeholder
                    }
                    size="small"
                    value={inputText}
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {
                            questions[questionIndex[questionIndex.length - 1]]
                              .unitText
                          }
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: '100%' }}
                    onChange={(event) => {
                      setInputText(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3} md={1}>
                  <Button
                    variant="contained"
                    sx={{ width: '100%', height: '100%' }}
                    onClick={() => {
                      onClickAnswer(undefined, undefined);
                    }}>
                    送信
                  </Button>
                </Grid>
                <Grid item xs />
              </Grid>
            )}
          </Stack>
        )}
        {phase === '診断終了' && (
          <Stack
            spacing={1}
            padding={1}
            sx={{
              backgroundColor: colors.lightGray,
            }}>
            <Button
              variant="contained"
              color="inherit"
              sx={{
                width: '100%',
                backgroundColor: colors.paleBlue,
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
              onClick={() => {
                history.push({ pathname: `/matching/fp-list/` });
              }}>
              {isAnonymous ? (
                <Typography fontSize={14}>登録してFPの提案を待つ</Typography>
              ) : (
                <Typography fontSize={14}>相談できるFPを探す</Typography>
              )}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );

  // 指定した設問に対応する回答を登録
  function registerAnswer(id: string, reply: string | number | boolean) {
    switch (id) {
      case 'marriageStatus':
        if (typeof reply === 'string') {
          setAnswer({ ...answer, marriageStatus: reply });
        }
        break;
      case 'livingStandard60to80':
        if (typeof reply === 'number') {
          setAnswer({ ...answer, livingStandard60to80: reply });
        }
        break;
      case 'livingStandard80to00':
        if (typeof reply === 'number') {
          setAnswer({ ...answer, livingStandard80to100: reply });
        }
        break;
      case 'houseStatusFuture':
        if (typeof reply === 'string') {
          setAnswer({ ...answer, houseStatus: reply });
        }
        break;
      case 'houseStatusNow':
        break;
      case 'housePurchaseAge':
        if (typeof reply === 'number') {
          setAnswer({
            ...answer,
            ownedHouseInfo: { purchaseAge: reply, price: 0 },
          });
        }
        break;
      case 'housePrice':
        if (typeof reply === 'number') {
          setAnswer({
            ...answer,
            ownedHouseInfo: {
              purchaseAge: answer.ownedHouseInfo?.purchaseAge ?? 30,
              price: reply,
            },
          });
        }
        break;
      case 'housePurchaseAgeFuture':
        if (typeof reply === 'number') {
          setAnswer({
            ...answer,
            ownedHouseInfo: { purchaseAge: reply, price: 0 },
          });
        }
        break;
      case 'housePriceFuture':
        if (typeof reply === 'number') {
          setAnswer({
            ...answer,
            ownedHouseInfo: {
              purchaseAge: answer.ownedHouseInfo?.purchaseAge ?? 30,
              price: reply,
            },
          });
        }
        break;
      case 'houseRent':
        if (typeof reply === 'number') {
          setAnswer({ ...answer, houseRent: reply });
        }
        break;
      case 'age':
        if (typeof reply === 'number') {
          setAnswer({ ...answer, age: reply });
        }
        break;
      case 'address':
        if (typeof reply === 'string') {
          setAnswer({ ...answer, address: reply });
        }
        break;
      case 'sex':
        if (
          typeof reply === 'string' &&
          (reply === '男性' || reply === '女性')
        ) {
          setAnswer({ ...answer, sex: reply });
        }
        break;
      case 'lifeSpan':
        if (typeof reply === 'number') {
          setAnswer({ ...answer, lifeSpan: reply });
        }
        break;
      case 'income':
        if (typeof reply === 'number') {
          setAnswer({ ...answer, income: reply });
        }
        break;
      case 'incomeMax':
        if (typeof reply === 'number') {
          setAnswer({ ...answer, estimatedIncome: reply });
        }
        break;
      case 'isEmployee': {
        const isEmployee = reply === 'はい';
        setAnswer({ ...answer, isEmployee });
        break;
      }
      case 'issavingsMoney':
        if (reply === 'いいえ') {
          setAnswer({ ...answer, savingsAmount: 0 });
        }
        break;
      case 'savingsAmount':
        if (typeof reply === 'number') {
          setAnswer({ ...answer, savingsAmount: reply });
        }
        break;
      case 'retiredPay':
        if (typeof reply === 'number') {
          setAnswer({ ...answer, retiredPay: reply });
        }
        break;
      case 'incomeAfterRetired':
        if (typeof reply === 'number') {
          setAnswer({ ...answer, incomeAfterRetired: reply });
        }
        break;
      case 'partnerIncome':
        if (typeof reply === 'number') {
          setAnswer({ ...answer, partnerIncome: reply });
        }
        break;
      case 'last':
        if (reply === 0 || reply === 1 || reply === 2) {
          // userのフィールドに面談希望の最新データを保存
          dispatch(updateAnxietyLevel(reply));
          // diagnosisのフィールドに面談希望の最新データを保存
          dispatch(updateDiagnosisAnxietyLevel(reply));
        }
        break;
      default:
    }
  }

  // 指定した設問に対応する回答を削除
  function deleteAnswer(id: string) {
    switch (id) {
      case 'marriageStatus':
        setAnswer({ ...answer, marriageStatus: undefined });
        break;
      case 'livingStandard60to80':
        setAnswer({ ...answer, livingStandard60to80: undefined });
        break;
      case 'livingStandard80to00':
        setAnswer({ ...answer, livingStandard80to100: undefined });
        break;
      case 'houseStatusFuture':
        setAnswer({ ...answer, houseStatus: undefined });
        break;
      case 'houseStatusNow':
        break;
      case 'housePurchaseAge':
        setAnswer({
          ...answer,
          ownedHouseInfo: undefined,
        });
        break;
      case 'housePrice':
        setAnswer({
          ...answer,
          ownedHouseInfo: {
            purchaseAge: answer.ownedHouseInfo?.purchaseAge ?? 30,
            price: 0,
          },
        });
        break;
      case 'housePurchaseAgeFuture':
        setAnswer({
          ...answer,
          ownedHouseInfo: undefined,
        });
        break;
      case 'housePriceFuture':
        setAnswer({
          ...answer,
          ownedHouseInfo: {
            purchaseAge: answer.ownedHouseInfo?.purchaseAge ?? 35,
            price: 3000,
          },
        });
        break;
      case 'houseRent':
        setAnswer({ ...answer, houseRent: undefined });
        break;
      case 'age':
        setAnswer({ ...answer, age: undefined });
        break;
      case 'address':
        setAnswer({ ...answer, address: undefined });
        break;
      case 'sex':
        setAnswer({ ...answer, sex: undefined });
        break;
      case 'lifeSpan':
        setAnswer({ ...answer, lifeSpan: undefined });
        break;
      case 'income':
        setAnswer({ ...answer, income: undefined });
        break;
      case 'incomeMax':
        setAnswer({ ...answer, estimatedIncome: undefined });
        break;
      case 'isEmployee':
        setAnswer({ ...answer, isEmployee: undefined });
        break;
      case 'issavingsMoney':
        setAnswer({ ...answer, savingsAmount: undefined });
        break;
      case 'savingsAmount':
        setAnswer({ ...answer, savingsAmount: undefined });
        break;
      case 'retiredPay':
        setAnswer({ ...answer, retiredPay: undefined });
        break;
      case 'incomeAfterRetired':
        setAnswer({ ...answer, incomeAfterRetired: undefined });
        break;
      case 'partnerIncome':
        setAnswer({ ...answer, partnerIncome: undefined });
        break;
      default:
    }
  }

  // ひとつ前の質問に戻るボタンを押された時
  function onClickBack() {
    const newMessages = [...messages];
    const previousQuestionIndex =
      questions[questionIndex.length - 1].previousQuestion;

    // 過去の"ひとつ前の質問に戻る"ボタンを削除
    if (newMessages.length > 0) {
      newMessages.pop();
      newMessages.push({
        message: messages[messages.length - 1].message,
        isInstruction: messages[messages.length - 1].isInstruction,
        isOwnMessage: false,
        isIconShown: messages[messages.length - 1].isIconShown,
        isBackButton: false,
        isAnswerShown: false,
      });
    }
    // ひとつ前の質問に戻ると表示
    newMessages.push({
      message: 'ひとつ前の質問に戻る',
      isInstruction: false,
      isOwnMessage: true,
      isIconShown: false,
      isBackButton: false,
      isAnswerShown: false,
    });
    // 前の回答を削除
    deleteAnswer(questions[previousQuestionIndex].id);

    // 質問を表示
    newMessages.push({
      message: questions[previousQuestionIndex].text,
      isInstruction: questions[previousQuestionIndex].instruction,
      isOwnMessage: false,
      isIconShown: questions[previousQuestionIndex].displayIcon,
      isBackButton: Boolean(previousQuestionIndex > 0),
      isAnswerShown: false,
    });

    // 質問番号を更新
    setQuestionIndex(questionIndex.slice(0, previousQuestionIndex + 1));

    // 自由記述欄を初期化
    setInputText('');
    setMessages(newMessages);
  }

  // 選択肢が選ばれた時
  function onClickAnswer(
    selectedOption?: string,
    valueOfselectedOption?: number
  ) {
    const newMessages = [...messages];
    const newIndex = [...questionIndex];
    // 過去の"ひとつ前の質問に戻る"ボタンを削除
    if (
      newMessages.length > 0 &&
      questionIndex[questionIndex.length - 1] < questions.length - 1
    ) {
      newMessages.pop();
      newMessages.push({
        message: messages[messages.length - 1].message,
        isInstruction: messages[messages.length - 1].isInstruction,
        isOwnMessage: false,
        isIconShown: messages[messages.length - 1].isIconShown,
        isBackButton: false,
        isAnswerShown: false,
      });
    }

    // 概算を計算
    setRoughAnswer(calculateRoughDiagnosisData(answer));

    // ユーザの入力をチャットに表示
    newMessages.push({
      message:
        selectedOption !== undefined
          ? selectedOption
          : inputText +
            questions[questionIndex[questionIndex.length - 1]].unitText,
      isInstruction: false,
      isOwnMessage: true,
      isIconShown: false,
      isBackButton: false,
      isAnswerShown: false,
    });
    // 回答を登録
    if (selectedOption !== undefined) {
      registerAnswer(
        questions[questionIndex[questionIndex.length - 1]].id,
        valueOfselectedOption === undefined
          ? selectedOption
          : valueOfselectedOption
      );
    } else {
      registerAnswer(
        questions[questionIndex[questionIndex.length - 1]].id,
        +inputText // 受け取った値をnumberに変換
      );
    }

    // 次の質問が存在する場合
    if (questionIndex[questionIndex.length - 1] < questions.length - 1) {
      // 次の質問へ
      let nextQuestionIndex = questionIndex[questionIndex.length - 1] + 1;
      const { getNextQuestionIndex } =
        questions[questionIndex[questionIndex.length - 1]];
      if (getNextQuestionIndex !== undefined) {
        nextQuestionIndex = getNextQuestionIndex(
          selectedOption !== undefined ? selectedOption : inputText
        );
      }
      // 最後の質問の場合、結果表示へ進む。
      if (nextQuestionIndex === questions.length - 1) setPhase('結果確認中');
      // 次の質問を表示
      newMessages.push({
        message: questions[nextQuestionIndex].text,
        isInstruction: questions[nextQuestionIndex].instruction,
        isOwnMessage: false,
        isIconShown: questions[nextQuestionIndex].displayIcon,
        isBackButton: Boolean(nextQuestionIndex < questions.length - 1),
        isAnswerShown: Boolean(nextQuestionIndex === questions.length - 1),
      });

      // 質問番号を更新
      newIndex.push(nextQuestionIndex);
      setQuestionIndex(newIndex);
      // 自由記述欄を初期化
      setInputText('');
    } else {
      setIsInputMode(false);
      setPhase('診断終了');
      // 「診断結果を受けてどう思いましたか？」の回答を登録
    }
    setMessages(newMessages);
  }

  // 説明文を表示した時(ユーザ回答なしで次のメッセージを表示する時)
  function setNext() {
    const newMessages = [...messages];
    const newIndex = [...questionIndex];
    // 過去の"ひとつ前の質問に戻る"ボタンを削除
    if (
      newMessages.length > 0 &&
      questionIndex[questionIndex.length - 1] < questions.length - 1
    ) {
      newMessages.pop();
      newMessages.push({
        message: messages[messages.length - 1].message,
        isInstruction: false,
        isOwnMessage: false,
        isIconShown: messages[messages.length - 1].isIconShown,
        isBackButton: false,
        isAnswerShown: false,
      });
    }
    // 次の質問が存在する場合
    if (questionIndex[questionIndex.length - 1] < questions.length - 1) {
      // 次の質問へ
      let nextQuestionIndex = questionIndex[questionIndex.length - 1] + 1;
      const { getNextQuestionIndex } =
        questions[questionIndex[questionIndex.length - 1]];
      if (getNextQuestionIndex !== undefined) {
        nextQuestionIndex = getNextQuestionIndex(inputText);
      }
      // 次の質問を表示
      newMessages.push({
        message: questions[nextQuestionIndex].text,
        isInstruction: questions[nextQuestionIndex].instruction,
        isOwnMessage: false,
        isIconShown: questions[nextQuestionIndex].displayIcon,
        isBackButton: Boolean(nextQuestionIndex < questions.length - 1),
        isAnswerShown: Boolean(nextQuestionIndex === questions.length - 1),
      });

      // 質問番号を更新
      newIndex.push(nextQuestionIndex);
      setQuestionIndex(newIndex);
    }
    setMessages(newMessages);
  }
};

// 概算計算
const calculateRoughDiagnosisData = (answer: Partial<DiagnosisData>) => {
  const {
    marriageStatus = '夫婦',
    livingStandard60to80 = 14,
    livingStandard80to100 = 14,
    houseStatus = '持ち家',
    ownedHouseInfo = { purchaseAge: 35, price: 3000 },
    houseRent = 10,
    careCost = 300,
    medicalExpenses = 300,
    funeralExpenses = 200,
    otherExpenses = 0,
    age = 35,
    lifeSpan = 100,
    income = 600,
    estimatedIncome = 1000,
    isEmployee = true,
    savingsAmount = 0,
    retiredPay = 0,
    incomeAfterRetired = 0,
    partnerIncome = 0,
  } = answer;

  return calculateRoughRequiredSavingsForOldAge(
    marriageStatus,
    isEmployee,
    estimatedIncome,
    lifeSpan,
    livingStandard60to80,
    livingStandard80to100,
    retiredPay,
    incomeAfterRetired,
    partnerIncome,
    houseStatus,
    careCost,
    medicalExpenses,
    funeralExpenses,
    otherExpenses,
    savingsAmount,
    ownedHouseInfo.purchaseAge,
    ownedHouseInfo.price,
    houseRent
  );
};

// 診断結果計算
function calculateDiagnosisData(answer: Partial<DiagnosisData>): DiagnosisData {
  const {
    marriageStatus = '夫婦',
    livingStandard60to80 = 14,
    livingStandard80to100 = 14,
    houseStatus = '持ち家',
    ownedHouseInfo = { purchaseAge: 35, price: 3000 },
    houseRent = 10,
    careCost = 300,
    medicalExpenses = 300,
    funeralExpenses = 200,
    otherExpenses = 0,
    age = 35,
    address = 'その他',
    sex = '未設定',
    lifeSpan = 100,
    income = 600,
    estimatedIncome = 1000,
    isEmployee = true,
    savingsAmount = 0,
    retiredPay = 0,
    incomeAfterRetired = 0,
    partnerIncome = 0,
  } = answer;
  const isMarried = marriageStatus === '夫婦';

  const livingCost = calculateLivingCost(
    lifeSpan,
    livingStandard60to80,
    livingStandard80to100,
    isMarried
  );
  const housingCost = calculateHousingCost(
    houseStatus,
    lifeSpan,
    ownedHouseInfo.purchaseAge,
    ownedHouseInfo.price,
    houseRent
  );

  const basicAnnuity = calculateBasicAnnuity(lifeSpan, isMarried);
  const employeeAnnuity = calculateEmployeeAnnuity(
    isEmployee,
    estimatedIncome,
    lifeSpan
  );

  const annuity = calculateAnnuity(
    isEmployee,
    estimatedIncome,
    lifeSpan,
    isMarried
  );

  const partnerEmployeeAnnuity = calculateEmployeeAnnuity(
    true,
    partnerIncome,
    100
  );

  const calculatedCareCost = isMarried ? careCost * 2 : careCost;
  const calculatedMedicalExpenses = isMarried
    ? medicalExpenses * 2
    : medicalExpenses;
  const calculatedFuneralExpenses = isMarried
    ? funeralExpenses * 2
    : funeralExpenses;

  const totalRequiredSavings = calculateRequiredSavingsForOldAge(
    lifeSpan,
    annuity,
    partnerEmployeeAnnuity,
    retiredPay,
    incomeAfterRetired,
    livingCost,
    housingCost,
    calculatedMedicalExpenses,
    calculatedCareCost,
    calculatedFuneralExpenses,
    otherExpenses,
    savingsAmount
  );

  const roundingTotalRequiredSavings =
    calculateRoundingRequiredSavingsForOldAge(
      lifeSpan,
      annuity,
      partnerEmployeeAnnuity,
      retiredPay,
      incomeAfterRetired,
      livingCost,
      housingCost,
      calculatedMedicalExpenses,
      calculatedCareCost,
      calculatedFuneralExpenses,
      otherExpenses,
      savingsAmount
    );

  return {
    uid: '',
    marriageStatus,
    livingStandard60to80,
    livingStandard80to100,
    houseStatus,
    ownedHouseInfo: houseStatus === '持ち家' ? ownedHouseInfo : undefined,
    houseRent: houseStatus === '賃貸' ? houseRent : undefined,
    livingCost,
    housingCost,
    careCost: calculatedCareCost,
    medicalExpenses: calculatedMedicalExpenses,
    funeralExpenses: calculatedFuneralExpenses,
    otherExpenses,
    basicAnnuity,
    employeeAnnuity,
    annuity,
    age,
    address,
    sex,
    lifeSpan,
    income,
    estimatedIncome,
    isEmployee,
    savingsAmount,
    retiredPay,
    incomeAfterRetired,
    partnerIncome,
    partnerEmployeeAnnuity,
    totalExpense:
      livingCost +
      housingCost +
      calculatedMedicalExpenses +
      calculatedFuneralExpenses +
      otherExpenses +
      calculatedCareCost,
    // 老後までに貯蓄するべき金額
    totalRequiredSavings,
    // 毎月の必要な貯金額
    requiredSavingsPerMonth: calculateRequiredSavingsPerMonth(
      totalRequiredSavings,
      savingsAmount,
      age
    ),
    // 積立運用の場合の毎月の積立額
    requiredInvestmentPerMonth: calculateRequiredInvestmentPerMonth(
      totalRequiredSavings,
      savingsAmount,
      age
    ),
    // 老後までに貯蓄するべき金額(丸めた値)
    roundingTotalRequiredSavings,
    // 毎月の必要な貯金額(丸めた値)
    roundingRequiredSavingsPerMonth: calculateRequiredSavingsPerMonth(
      roundingTotalRequiredSavings,
      savingsAmount,
      age
    ),
    // 積立運用の場合の毎月の積立額(丸めた値)
    roundingRequiredInvestmentPerMonth: calculateRequiredInvestmentPerMonth(
      roundingTotalRequiredSavings,
      savingsAmount,
      age
    ),
    totalRetirementIncome: calculateTotalRetirementIncome(
      isEmployee,
      estimatedIncome,
      lifeSpan,
      isMarried,
      retiredPay,
      incomeAfterRetired,
      partnerIncome,
      savingsAmount
    ),
  };
}

export default SPDiagnosisScreen;

import { useMediaQuery } from 'react-responsive';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  TextField,
  Card,
  Button,
  CircularProgress,
  Backdrop,
  Typography,
  Grid,
} from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';

import useStyles from './OtherProviderSignUpScreenStyle';
import { AppDispatch, RootState } from '../../../modules/store';
import {
  getNickName,
  setUserTextField,
  setErrMsg,
  toggleIsErrModalOpen,
  toggleIsSuccessModalOpen,
  setNickname,
  updateNickname,
} from '../../../modules/store/slices/userSlice';
import { isTextInvalid } from '../../../components/validation';
import Model from '../../../components/Modal';

function SignUp() {
  const classes = useStyles();
  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 766px)' });

  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  // state
  const [disabledList, setDisabledList] = React.useState({
    disabledSignUpBtn: true,
  });
  const {
    isLoading,
    userInfo,
    isErrModalOpen,
    errMsg,
    isSuccessModalOpen,
    successMsg,
    successTitle,
  } = useSelector((state: RootState) => state.user);

  const { nickName } = userInfo;
  const { disabledSignUpBtn } = disabledList;

  // 登録ボタン活性・非活性処理
  const isDisabledSignUpBtn = () => isTextInvalid(nickName);

  React.useEffect(() => {
    setDisabledList({
      ...disabledList,
      disabledSignUpBtn: isDisabledSignUpBtn(),
    });
  }, [nickName]);

  // functions
  const onClickSignUpBtn = () => {
    dispatch(setNickname({ newNickname: nickName }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getNickName());
        dispatch(toggleIsSuccessModalOpen());
      })
      .catch((error) => {
        dispatch(setErrMsg(error.message));
        dispatch(toggleIsErrModalOpen());
      });
  };

  const onChangeSliceTextField = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.target;
    dispatch(setUserTextField({ name, value }));
  };

  const onClickCloseErrModalBtn = () => {
    dispatch(toggleIsErrModalOpen());
  };

  const onClickCloseSuccessModalBtn = () => {
    dispatch(toggleIsSuccessModalOpen());
    // サインアップ後
    // ホーム画面へ遷移
    history.push({ pathname: '/my-page' });

    // NickNameの取得
    dispatch(getNickName());
  };

  return (
    <div>
      {/* ---------- web画面用表示領域 (min-width: 767px) ---------- */}
      {isDesktop && (
        <Grid className={classes.root}>
          <Grid className={classes.cardRoot}>
            <Typography align="center" className={classes.title}>
              会員登録
            </Typography>

            {/* 説明 */}
            <Typography
              align="left" /* 改行後左寄せ */
              className={classes.subTitle}>
              会員登録により、
              {/* TODO: 準備中画面へ遷移 */}
              <Link className={classes.link} to="/terms-of-use">
                利用規約
              </Link>
              及び
              {/* TODO: 準備中画面へ遷移 */}
              <Link className={classes.link} to="/privacy-policy">
                プライバシーポリシー
              </Link>
              {`\nに同意したこととさせていただきます。`}
            </Typography>

            {/* TextSignUpInformationForm */}
            <Card className={classes.textCardRoot}>
              <TextField
                className={classes.nameTextField}
                label="ニックネーム（表示名）"
                variant="outlined"
                value={nickName}
                name="nickName"
                onChange={onChangeSliceTextField}
              />
              <Button
                className={classes.signUpButton}
                onClick={onClickSignUpBtn}
                disabled={disabledSignUpBtn}
                classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
              >
                会員登録
              </Button>
            </Card>

            {/* インジケーター */}
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress />
            </Backdrop>

            {/* modal */}
            <Model
              isOpen={isErrModalOpen}
              title=""
              body={errMsg}
              buttonName="もう一度入力する"
              onClickCloseModalBtn={onClickCloseErrModalBtn}
            />
            <Model
              isOpen={isSuccessModalOpen}
              title={successTitle}
              body={successMsg}
              buttonName="閉じる"
              onClickCloseModalBtn={onClickCloseSuccessModalBtn}
            />
          </Grid>
        </Grid>
      )}
      {/* ---------- スマホ画面用表示領域 (max-width: 766px) ---------- */}
      {isMobile && (
        <Grid className={classes.root}>
          <Grid className={classes.spCardRoot}>
            <Typography align="center" className={classes.spTitle}>
              会員登録
            </Typography>

            {/* 説明 */}
            <Typography
              align="left" /* 改行後左寄せ */
              className={classes.spSubTitle}>
              会員登録により、
              {/* TODO: 準備中画面へ遷移 */}
              <Link className={classes.link} to="/terms-of-use">
                利用規約
              </Link>
              及び
              {/* TODO: 準備中画面へ遷移 */}
              <Link className={classes.link} to="/privacy-policy">
                プライバシーポリシー
              </Link>
              {`\nに同意したこととさせていただきます。`}
            </Typography>

            {/* TextSignUpInformationForm */}
            <Card className={classes.textCardRoot}>
              <TextField
                className={classes.nameTextField}
                label="ニックネーム（表示名）"
                variant="outlined"
                value={nickName}
                name="nickName"
                onChange={onChangeSliceTextField}
              />
              <Button
                className={classes.signUpButton}
                onClick={onClickSignUpBtn}
                disabled={disabledSignUpBtn}
                classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
              >
                会員登録
              </Button>
            </Card>

            {/* インジケーター */}
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress />
            </Backdrop>

            {/* modal */}
            <Model
              isOpen={isErrModalOpen}
              title=""
              body={errMsg}
              buttonName="もう一度入力する"
              onClickCloseModalBtn={onClickCloseErrModalBtn}
            />
            <Model
              isOpen={isSuccessModalOpen}
              title={successTitle}
              body={successMsg}
              buttonName="閉じる"
              onClickCloseModalBtn={onClickCloseSuccessModalBtn}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default SignUp;

import React from 'react';
import { Grid, Typography, Modal, makeStyles, Button } from '@material-ui/core';

import colors from '../resources/colors';

/**
 * 【使い方】
 *
 * ① モーダルを import
 * ・import Model from '../{随時}/../components/Modal'
 *
 * ② モーダルオープンの状態管理用 state を記述
 * ・const [isOpen, setIsOpen] = React.useState(true)
 *
 * ③ modalを閉じるボタンを記載
 *  ・const onClickFalseBtn = () => {
 *     setIsOpen(false);
 *   };
 *  ・const onClickTrueBtn = () => {
 *     setIsOpen(false);
 *   };
 *
 * ④ 随時開くボタンを記述（例：動作失敗時の catch 内など）
 * ・setIsOpen(true)
 *
 * ⑤ モーダルを配置する（screen の return の最下部など）
 * ・<ConfirmModal
 *     isOpen={isOpen}
 *     title="確認"
 *     body={`処理を進めて問題ないですか？`}
 *     falseButtonName="キャンセル"
 *     trueButtonName="OK"
 *     onClickFalseBtn={onClickFalseBtn}
 *     onClickTrueBtn={onClickTrueBtn}
 *   />
 */

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.white,
    padding: theme.spacing(2, 4, 2, 4),
  },

  /* body */
  title: {
    fontSize: '24px',
    paddingBottom: theme.spacing(2),
  },
  body: {
    fontSize: '16px',
    marginBottom: theme.spacing(4),
    whiteSpace: 'pre-line', // 改行を許可
    wordBreak: 'break-all', // 単語サイズを無視して改行を許可
  },
  falseBtn: {
    marginTop: '10px',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: colors.gray,
    color: colors.white,
    height: '30px',
    minWidth: '100px',
    fontSize: '16px',
  },
  trueBtn: {
    marginTop: '10px',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: colors.lightBlue,
    color: colors.white,
    height: '30px',
    minWidth: '150px',
    fontSize: '16px',
  },
}));

type Props = {
  isOpen: boolean;
  title: string;
  body: string;
  falseButtonName: string;
  trueButtonName: string;
  onClickFalseBtn: () => void;
  onClickTrueBtn: () => void;
};

const ConfirmModalComponent = (props: Props) => {
  const {
    isOpen,
    title,
    body,
    falseButtonName,
    trueButtonName,
    onClickFalseBtn,
    onClickTrueBtn,
  } = props;
  const classes = useStyles();

  return (
    <Modal className={classes.root} open={isOpen}>
      <Grid className={classes.cardRoot}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.body}>{body}</Typography>
        <Grid>
          <Button onClick={onClickFalseBtn} className={classes.falseBtn}>
            {falseButtonName}
          </Button>
          <Button onClick={onClickTrueBtn} className={classes.trueBtn}>
            {trueButtonName}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmModalComponent;

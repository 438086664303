import { useMediaQuery } from 'react-responsive';
import { Grid } from '@material-ui/core';
import useStyles from './TermsOfUseScreenStyle';
// components
import TermsOfUseComponent from './components/TermsOfUseComponent';

function TermsOfUse() {
  const classes = useStyles();
  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 766px)' });

  return (
    <div>
      {/* ---------- web画面用表示領域 (min-width: 767px) ---------- */}
      {isDesktop && (
        <Grid className={classes.root}>
          <Grid className={classes.cardRoot}>
            <TermsOfUseComponent />
          </Grid>
        </Grid>
      )}
      {/* ---------- スマホ画面用表示領域 (max-width: 766px) ---------- */}
      {isMobile && (
        <Grid className={classes.root}>
          <Grid className={classes.spCardRoot}>
            <TermsOfUseComponent />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default TermsOfUse;

import React, { useEffect } from 'react';
import {
  Divider,
  Backdrop,
  CircularProgress,
  CardMedia,
  Button,
  Typography,
} from '@material-ui/core';
import { Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppDispatch, RootState } from '../../../modules/store';
import { SpMyPageScreenStyles } from './SpMyPageScreenStyle';
import { getUserUid } from '../../../modules/functions/userFunctions';
import {
  diagnosisDataSelectors,
  fetchDiagnosisData,
} from '../../../modules/store/slices/diagnosisDataSlice';

import RoughAmountBox from './components/RoughAmountBox';

import toChatImage from '../../../resources/image/myPage/toChat.png';
import toFpListImage from '../../../resources/image/myPage/toFpList.png';

const SpMyPageScreen: React.FC = () => {
  // URL に記載された uid を取得する

  // state
  const classes = SpMyPageScreenStyles();
  const history = useHistory();

  const clientId = getUserUid();

  const dispatch: AppDispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.matching);

  const diagnosisData = useSelector((state: RootState) =>
    diagnosisDataSelectors.selectById(state, clientId)
  );

  useEffect(() => {
    dispatch(fetchDiagnosisData(clientId));
  }, []);

  // functions
  const onClickUserInfoBtn = () => {
    history.push({ pathname: '/user-info' });
  };
  const onClickDiagnosisBtn = () => {
    history.push({ pathname: '/diagnosis' });
  };
  const onClickChatButton = () => {
    history.push({ pathname: '/matching/chatRooms' });
  };
  const onClickMatchingStartBtn = () => {
    history.push({
      pathname: `/matching/fp-list/`,
    });
  };

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.container}>
          {/* screen */}
          <Grid>
            {diagnosisData !== undefined ? (
              <Button
                className={classes.primaryCard}
                onClick={onClickUserInfoBtn}>
                <RoughAmountBox
                  result={diagnosisData.roundingTotalRequiredSavings}
                />
              </Button>
            ) : (
              <Button
                className={classes.primaryCard}
                onClick={onClickDiagnosisBtn}>
                <Grid
                  container
                  sx={{
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}>
                  <Grid>
                    <Typography className={classes.subText}>
                      老後資金診断を実施する
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            )}
            <Divider className={classes.divider} />
            <Button
              className={classes.secondaryCard}
              onClick={onClickChatButton}>
              <Grid
                container
                sx={{
                  textAlign: 'center',
                  justifyContent: 'center',
                }}>
                <CardMedia
                  className={classes.buttonImage}
                  component="img"
                  image={toChatImage}
                  title="toChat"
                />
                <Grid className={classes.text}>
                  <Typography className={classes.subText}>
                    老後診断結果解説+匿名チャット相談
                  </Typography>
                </Grid>
              </Grid>
            </Button>
            <Button
              className={classes.secondaryCard}
              onClick={onClickMatchingStartBtn}>
              <Grid
                container
                sx={{
                  textAlign: 'center',
                  justifyContent: 'center',
                }}>
                <CardMedia
                  className={classes.buttonImage}
                  component="img"
                  image={toFpListImage}
                  title="toFpListImage"
                />
                <Grid>
                  <Typography className={classes.subText}>
                    お金の専門家を探して相談する
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>

        {/* インジケーター */}
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress />
        </Backdrop>
      </Grid>
    </>
  );
};

export default SpMyPageScreen;

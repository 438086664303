import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Divider,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../../../modules/store';
import { matchingFpListScreenStyles } from './SpMatchingFpListScreenStyle';
import SpMatchingFpListScreenCard from './SpMatchingFpListScreenCard';
import { getFpList } from '../../../../modules/store/slices/matchingSlice';
import { ClientUser } from '../../../../modules/entities/ClientUser';
import { Fp } from '../../../../modules/functions/matchingFunctions';

const SpMatchingFpListScreen: React.FC = () => {
  // URL に記載された uid を取得する

  // state
  const classes = matchingFpListScreenStyles();
  const countsOfCards = 10;
  const dispatch: AppDispatch = useDispatch();
  const { isLoading, fpList } = useSelector(
    (state: RootState) => state.matching
  );
  const infoList = fpList;

  useEffect(() => {
    dispatch(getFpList());
  }, []);

  // functions
  /* カルーセル内に表示するカード数をリストに格納する */
  const getIndexList = (
    counts: number,
    list: Fp[] | ClientUser[]
  ): number[] => {
    const indexList: number[] = Array(Math.floor(list.length / counts)).fill(
      counts
    );
    const surplus = list.length % counts;
    if (surplus > 0) {
      indexList.push(list.length % counts);
    }
    return indexList;
  };

  const UserCarousel = () => (
    <Carousel
      autoPlay={false} // 自動遷移を許可しない
    >
      {getIndexList(countsOfCards, infoList).map((counts, carouselIndex) => (
        <Grid className={classes.cards} key={String(carouselIndex)}>
          {infoList
            .slice(
              carouselIndex * countsOfCards,
              carouselIndex * countsOfCards + counts
            )
            .map((info, index) => (
              <Grid key={String(index)}>
                <SpMatchingFpListScreenCard info={info} />
              </Grid>
            ))}
        </Grid>
      ))}
    </Carousel>
  );

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.container}>
          {/* screen */}
          <Typography className={classes.subTitle}>FPに相談する</Typography>
          <Divider className={classes.divider} />
          <Grid className={classes.infoText}>
            <Typography className={classes.text}>
              気になるFPさんをクリックして、{'\n'}{' '}
              チャットで相談を始めましょう。
            </Typography>
          </Grid>
          <UserCarousel />
        </Grid>

        {/* インジケーター */}
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress />
        </Backdrop>
      </Grid>
    </>
  );
};

export default SpMatchingFpListScreen;

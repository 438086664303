import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../resources/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.paleBlue,
  },
  cardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '480px',
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },

  /* header */
  title: {
    fontSize: '40px',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: 600,
    whiteSpace: 'pre-line', // 改行を許可
  },
  link: {
    color: colors.lightBlue,
    textDecoration: 'none',
  },

  /* form */
  textCardRoot: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    flexDirection: 'column',
    display: 'flex',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: '416px',
  },
  emailTextField: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.lightGray,
  },
  passwordTextField: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.lightGray,
  },
  nameTextField: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.lightGray,
  },
  otherTextField: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.lightGray,
  },
  signUpButton: {
    backgroundColor: colors.lightBlue,
    color: colors.white,
    height: '56px',
  },
  disableSignUpButton: {
    backgroundColor: colors.lightGray,
  },

  /* footer */
  alreadySignUpBtn: {
    marginTop: theme.spacing(2),
  },
  alreadySignUpLinkBtn: {
    color: colors.dark,
  },

  /* インジケーター */
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default useStyles;

import { FC, useState, ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useMediaQuery } from 'react-responsive';
import {
  TextField,
  Card,
  Button,
  CircularProgress,
  Backdrop,
  Typography,
  Grid,
} from '@material-ui/core';

import { AppDispatch, RootState } from '../../../modules/store';
import useStyles from './FirstChatMessageSettingScreenStyles';
import Modal from '../../../components/Modal';
import {
  getFpInfo,
  updateFpInfo,
} from '../../../modules/store/slices/matchingSlice';
import { DEFAULT_AUTO_REPLY_MESSAGE } from '../../../modules/functions/matchingFunctions';

const SUCCESS_MESSAGE = '自動返信メッセージを更新しました。';
const ERROR_MESSAGE = `通信に失敗しました。
もう一度お試しください。`;

const FirstChatMessageSettingScreen: FC = () => {
  const classes = useStyles();

  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 766px)' });

  const dispatch: AppDispatch = useDispatch();

  const { uid } = useSelector((state: RootState) => state.user);
  const { isLoading } = useSelector((state: RootState) => state.matching);

  // state
  const [text, setText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  // useEffect
  useEffect(() => {
    // リロード直後はuidが空文字の状態で呼び出されるためreturnする。
    if (uid === '') {
      return;
    }

    dispatch(getFpInfo({ uid }))
      .then(unwrapResult)
      .then((fpInfo) => {
        setText(fpInfo.autoReplyMessage);
      })
      .catch(() => {
        setIsError(true);
        setIsModalOpen(true);
      });
  }, [uid]);

  // Handlers
  const onChangeText = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setText(event.target.value);
  };

  const onClickUpdateButton = () => {
    dispatch(updateFpInfo({ fpID: uid, fpInfo: { autoReplyMessage: text } }))
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsModalOpen(true);
      });
  };

  const onClickCloseModalButton = () => {
    setIsModalOpen(false);
    setIsError(false);
  };

  return (
    <>
      {/* ---------- web画面用表示領域 (min-width: 767px) ---------- */}
      {isDesktop && (
        <Grid className={classes.root}>
          <Grid className={classes.cardRoot}>
            <Typography className={classes.title}>
              チャット自動返信文
            </Typography>

            <Card className={classes.textCardRoot}>
              <Typography className={classes.pictureTypography}>
                クライアントがチャットを開始した際に送る{'\n'}
                自動返信メッセージの文面について記載してください。{'\n\n'}
              </Typography>

              <TextField
                className={classes.textField}
                placeholder={DEFAULT_AUTO_REPLY_MESSAGE}
                variant="outlined"
                multiline // テキストフィールド内で複数行を許可する
                rows={5} // 表示する行数の指定
                value={text}
                name="request"
                onChange={onChangeText}
              />
            </Card>

            <Button
              className={classes.UpdateNickNameButton}
              onClick={onClickUpdateButton}
              disabled={text.trim() === ''}
              classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
            >
              更新
            </Button>

            {/* modal */}
            <Modal
              isOpen={isModalOpen}
              title=""
              body={!isError ? SUCCESS_MESSAGE : ERROR_MESSAGE}
              buttonName="閉じる"
              onClickCloseModalBtn={onClickCloseModalButton}
            />

            {/* インジケーター */}
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress />
            </Backdrop>
          </Grid>
        </Grid>
      )}
      {/* ---------- スマホ画面用表示領域 (max-width: 766px) ---------- */}
      {isMobile && (
        <Grid className={classes.root}>
          <Grid className={classes.spCardRoot}>
            <Typography className={classes.spTitle}>
              チャット{'\n'}自動返信文
            </Typography>

            <Card className={classes.spTextCardRoot}>
              <Typography className={classes.pictureTypography}>
                クライアントがチャットを開始した際に送る{'\n'}
                自動返信メッセージの文面について記載してください。{'\n\n'}
              </Typography>

              <TextField
                className={classes.textField}
                placeholder={DEFAULT_AUTO_REPLY_MESSAGE}
                variant="outlined"
                multiline // テキストフィールド内で複数行を許可する
                rows={5} // 表示する行数の指定
                value={text}
                name="request"
                onChange={onChangeText}
              />
            </Card>

            <Button
              className={classes.UpdateNickNameButton}
              onClick={onClickUpdateButton}
              disabled={text.trim() === ''}
              classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
            >
              更新
            </Button>

            {/* modal */}
            <Modal
              isOpen={isModalOpen}
              title=""
              body={!isError ? SUCCESS_MESSAGE : ERROR_MESSAGE}
              buttonName="閉じる"
              onClickCloseModalBtn={onClickCloseModalButton}
            />

            {/* インジケーター */}
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress />
            </Backdrop>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FirstChatMessageSettingScreen;

import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import firebase from 'firebase';

// ↓ Selectors利用のため。↓
// eslint-disable-next-line import/no-cycle
import { RootState } from '..';
import { db } from '../../../firebase';
import { DB_PATH } from '../../../resources/constants';
import { clientUserConverter } from '../../entities/ClientUser';
import { Person } from '../../entities/Person';
import { getFpInfo } from '../../functions/matchingFunctions';

/**
 * チャットルーム(やり取り相手)のEntity
 * @property {string} id
 * @property {boolean} isRead - 未読 or 既読
 * @property {Date | undefined} interviewDate - 最新の面談実施日。
 * @property {string} destinationUserID - チャット相手のID
 * @property {Person} destinationUser - チャット相手
 * @property {string} latestMessage - 最新のメッセージ
 * @property {Date} latestTime - 最後にメッセージが送られた時間
 */
export type ChatRoom = {
  id: string;
  isRead: boolean;
  interviewDate?: Date;
  destinationUserID: string;
  destinationUser: Person; // TODO: コンポーネントに結合処理を移譲
  latestMessage: string;
  latestTime: Date;
};

interface ChatRoomEntityState extends EntityState<ChatRoom> {
  isLoading: boolean;
  isReadCount: number; // 未読件数
  reservedInterviewCount: number; // 面談を実施した回数
}

/* Entity Adapter */
const chatRoomsAdapter = createEntityAdapter<ChatRoom>({
  sortComparer: (a, b) =>
    a.latestTime.getTime() < b.latestTime.getTime() ? 1 : -1,
});
const chatRoomInitialEntityState: ChatRoomEntityState =
  chatRoomsAdapter.getInitialState({
    isLoading: false,
    isReadCount: 0,
    reservedInterviewCount: 0,
  });

/* Firestore Data Converter */
const chatRoomConverter: firebase.firestore.FirestoreDataConverter<ChatRoom> = {
  toFirestore: (chatRoom: ChatRoom): firebase.firestore.DocumentData => ({
    isRead: chatRoom.isRead,
    destinationUserID: chatRoom.destinationUserID,
    latestTime: chatRoom.latestTime,
  }),
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): ChatRoom => {
    const chatRoom = snapshot.data();
    return {
      id: snapshot.id,
      isRead: chatRoom.isRead,
      destinationUserID: chatRoom.destinationUserID,
      destinationUser: {
        id: '',
        role: 'User',
        nickName: '取得中',
      },
      latestMessage: chatRoom.latestMessage ?? 'トークルームが作成されました。',
      latestTime: chatRoom.latestTime
        ? (chatRoom.latestTime as firebase.firestore.Timestamp).toDate()
        : new Date(),
    };
  },
};

/* Create(なし) */

/* Read */
/**
 * やり取り相手の一覧を取得。
 * @return {ChatRoom[]} やり取り相手の一覧
 */
export const fetchChatRooms = createAsyncThunk(
  'chatRooms/fetchChatRooms',
  async (_, thunkAPI) => {
    const { uid, role } = (thunkAPI.getState() as RootState).user;
    return Promise.all(
      (
        await db
          .collection(role === 'FP' ? DB_PATH.FPS : DB_PATH.USERS)
          .doc(uid)
          .collection(DB_PATH.CHAT_ROOMS)
          .withConverter(chatRoomConverter)
          .get()
      ).docs.map(async (doc) => {
        const chatRoom = doc.data();
        chatRoom.destinationUser =
          role === 'FP'
            ? (
                await db
                  .collection(DB_PATH.USERS)
                  .doc(chatRoom.destinationUserID)
                  .withConverter(clientUserConverter)
                  .get()
              ).data() ?? {
                id: '',
                role: 'User',
                nickName: '取得中',
              }
            : await getFpInfo(chatRoom.destinationUserID);

        const querySnapshot = await db
          .collection(DB_PATH.CHAT_ROOMS)
          .doc(chatRoom.id)
          .collection(DB_PATH.INTERVIEW_DATES)
          .orderBy('date', 'desc')
          .limit(1)
          .get();
        chatRoom.interviewDate = querySnapshot.empty
          ? undefined
          : (
              querySnapshot.docs[0].data().date as firebase.firestore.Timestamp
            ).toDate();
        return chatRoom;
      })
    );
  }
);

/* Update */
/**
 * 既読処理。選択したチャットルームを既読状態にする。
 */
export const updateIsRead = createAsyncThunk(
  'chatRooms/updateIsRead',
  async (chatRoomID: string, thunkAPI) => {
    const { uid, role } = (thunkAPI.getState() as RootState).user;
    await db
      .collection(role === 'FP' ? DB_PATH.FPS : DB_PATH.USERS)
      .doc(uid)
      .collection(DB_PATH.CHAT_ROOMS)
      .doc(chatRoomID)
      .withConverter(chatRoomConverter)
      .update({ isRead: true });

    return chatRoomID;
  }
);

/* Delete(なし) */

/* Others */
/**
 * ユーザードキュメント配下のchatRoomsサブコレクションを監視するリアルタイムリスナー
 * @param onSnapshot - chatRooms変更時のハンドラ
 * @return {() => void} unsubscribe用関数
 */
export const listenDocmentChange = (
  uid: string,
  role: Person['role'],
  onSnapshot: (doc: firebase.firestore.QuerySnapshot<ChatRoom>) => void
) =>
  db
    .collection(role === 'FP' ? DB_PATH.FPS : DB_PATH.USERS)
    .doc(uid)
    .collection(DB_PATH.CHAT_ROOMS)
    .withConverter(chatRoomConverter)
    .onSnapshot(onSnapshot);

/**
 * Create - interviewDatesサブコレクション
 * チャットルーム相手との面談日時を追加
 * @param {string} chatRoomID
 * @return {number} 面談回数
 */
export const addReservedInterviewDate = createAsyncThunk(
  'chatRooms/addReservedInterviewDate',
  async (chatRoomID: string) => {
    const interviewDate = new Date();
    await db
      .collection(DB_PATH.CHAT_ROOMS)
      .doc(chatRoomID)
      .collection(DB_PATH.INTERVIEW_DATES)
      .add({ date: interviewDate });
    return { chatRoomID, interviewDate };
  }
);

/**
 * Read - interviewDatesサブコレクション
 * チャットルーム相手との面談成立回数を取得
 * @param {string} chatRoomID
 * @return {number} 面談回数
 */
export const fetchReservedInterviewCount = createAsyncThunk(
  'chatRooms/fetchReservedInterviewCount',
  async (chatRoomID: string): Promise<number> =>
    (
      await db
        .collection(DB_PATH.CHAT_ROOMS)
        .doc(chatRoomID)
        .collection(DB_PATH.INTERVIEW_DATES)
        .get()
    ).docs.length
);

/* Slice */
export const slice = createSlice({
  name: 'chatRoom',
  initialState: chatRoomInitialEntityState,
  reducers: {
    setChatRoom: (
      state: EntityState<ChatRoom>,
      action: PayloadAction<ChatRoom>
    ) => {
      chatRoomsAdapter.setOne(state, action.payload);
    },
    updateChatRoom: (
      state: EntityState<ChatRoom>,
      action: PayloadAction<ChatRoom>
    ) => {
      chatRoomsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          isRead: action.payload.isRead,
          latestTime: action.payload.latestTime,
        },
      });
    },
    setIsReadCount: (
      state: typeof chatRoomInitialEntityState,
      action: PayloadAction<number>
    ) => {
      state.isReadCount = action.payload;
    },
    setLatestTime: (
      state: EntityState<ChatRoom>,
      action: PayloadAction<{ chatRoomID: string; latestTime: Date }>
    ) => {
      chatRoomsAdapter.updateOne(state, {
        id: action.payload.chatRoomID,
        changes: { latestTime: action.payload.latestTime },
      });
    },
  },
  extraReducers: (builder) => {
    // fetchChatRooms
    builder.addCase(fetchChatRooms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchChatRooms.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchChatRooms.fulfilled, (state, action) => {
      chatRoomsAdapter.setAll(state, action.payload);
      state.isLoading = false;
    });
    builder.addCase(updateIsRead.fulfilled, (state, action) => {
      chatRoomsAdapter.updateOne(state, {
        id: action.payload,
        changes: { isRead: true },
      });
    });
    // fetchReservedInterviewCount
    builder.addCase(fetchReservedInterviewCount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchReservedInterviewCount.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchReservedInterviewCount.fulfilled, (state, action) => {
      state.reservedInterviewCount = action.payload;
      state.isLoading = false;
    });
    // addReservedInterviewDate
    builder.addCase(addReservedInterviewDate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addReservedInterviewDate.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addReservedInterviewDate.fulfilled, (state, action) => {
      chatRoomsAdapter.updateOne(state, {
        id: action.payload.chatRoomID,
        changes: { interviewDate: action.payload.interviewDate },
      });
      state.reservedInterviewCount += 1; // 面談日時追加成功に従い面談回数を1加算。
      state.isLoading = false;
    });
  },
});

export default slice.reducer;
export const { setChatRoom, updateChatRoom, setIsReadCount, setLatestTime } =
  slice.actions;

export const chatRoomSelectors = chatRoomsAdapter.getSelectors<RootState>(
  (state) => state.chatRoom
);
export const selectIsLoading = (state: RootState) => state.chatRoom.isLoading;
export const selectReservedInterviewCount = (state: RootState) =>
  state.chatRoom.reservedInterviewCount;

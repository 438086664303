import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { Column } from 'react-table';

import { AppDispatch } from '../../../modules/store';
import { colors } from '../../../resources/theme/defaultTheme';
import {
  chatRoomSelectors,
  selectIsLoading,
  fetchChatRooms,
} from '../../../modules/store/slices/chatRoomSlice';

import { DataGrid } from '../../../components/DataGrid';
import { MakeInterviewDoneModal } from '../../common/modal/MakeInterviewDoneModal';
import { getColumnComponents, MessageHistoryData } from './components';

export const MessageHistoryScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const [messages, setMessages] = useState<MessageHistoryData[]>([]);
  const chatRooms = useSelector(chatRoomSelectors.selectAll);
  const isLoading = useSelector(selectIsLoading);

  const [confirmationModalState, setConfirmationModalState] = useState<{
    chatRoomID: string;
    name: string;
  }>();

  const openConfirmationModal = ({
    chatRoomID,
    name,
  }: Exclude<typeof confirmationModalState, undefined>) => {
    setConfirmationModalState({
      chatRoomID,
      name,
    });
  };

  const columnComponents = useMemo(
    () => getColumnComponents(openConfirmationModal),
    []
  );

  const columns: Column<MessageHistoryData>[] = [
    {
      Header: '未読',
      accessor: 'read',
      Cell: columnComponents.read,
      width: 90,
    },
    {
      Header: '面談状況',
      accessor: 'lastInterviewDate',
      Cell: columnComponents.lastInterviewDate,
      width: 150,
    },
    {
      Header: '名前',
      accessor: 'name',
      Cell: columnComponents.name,
      width: 'auto',
    },
    {
      Header: '最新メッセージ',
      accessor: 'lastComment',
      Cell: columnComponents.lastComment,
      width: 600,
    },
    {
      Header: '最新メッセージ日時',
      accessor: 'lastCommentDate',
      Cell: columnComponents.lastCommentDate,
      width: 200,
    },
  ];

  useEffect(() => {
    dispatch(fetchChatRooms());
    // 画面初期処理のため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setMessages(
        chatRooms.map((chatRoom) => ({
          chatRoomID: chatRoom.id,
          userId: chatRoom.destinationUserID,
          read: chatRoom.isRead,
          lastInterviewDate: chatRoom.interviewDate,
          sex: (chatRoom.destinationUser.sex ?? '男性') === '男性' ? 0 : 1,
          name: chatRoom.destinationUser.nickName,
          lastComment: chatRoom.latestMessage,
          lastCommentDate: chatRoom.latestTime,
        }))
      );
    };
    fetch();
  }, [chatRooms]);

  return (
    <>
      <Box sx={{ pr: 1, overflow: 'visible', minWidth: '1086px' }}>
        <DataGrid
          onRowClick={(i) => {
            window.location.href = `/matching/chatRooms/FP/${messages[i].chatRoomID}`;
          }}
          columns={columns}
          data={messages}
        />
      </Box>
      <MakeInterviewDoneModal
        onClose={() => setConfirmationModalState(undefined)}
        onOk={() => {
          // TODO 再更新のAPI呼びだす
          setConfirmationModalState(undefined);
        }}
        data={confirmationModalState}
      />
      <Backdrop
        sx={{ backgroundColor: colors.backdropColor, zIndex: 100 }}
        open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default MessageHistoryScreen;

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import Help from '@material-ui/icons/Help';
import colors from '../resources/colors';

type Props = {
  rating: number;
  nodata: number;
};

const Rating = ({ rating, nodata }: Props) => {
  const starRange = Array.from<number, number>({ length: rating }, (k) => k);
  const starBoderRange = Array.from<number, number>(
    { length: 5 - rating },
    (k) => k
  );
  const nodataRange = Array.from<number, number>({ length: 5 }, (k) => k);
  const isHalf = rating % 1 >= 0.5;
  // const isNotHalf = rating % 1 < 0.5;
  const isNotZero = rating % 1 > 0;
  const isHighValue = rating >= 4;
  const isNoData = nodata === 0;
  const color = isHighValue ? colors.starColor : colors.yellow;

  if (isNoData) {
    return (
      <>
        {nodataRange.map((i) => (
          <Help style={{ color: colors.skygray }} key={i} />
        ))}
      </>
    );
  }
  return (
    <>
      {starRange.map((i) => (
        <StarIcon style={{ color }} key={i} />
      ))}
      {isHalf && <StarHalfIcon style={{ color }} />}
      {!isHalf && isNotZero && <StarBorderIcon style={{ color }} />}
      {starBoderRange.map((i) => (
        <StarBorderIcon style={{ color }} key={i} />
      ))}
    </>
  );
};

export default Rating;

import clsx from 'clsx';
import { Typography, Grid, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../../modules/store';

// components
import StartButtonComponent from './components/StartButtonComponent';

// others
import {
  introductionHeaderStyles,
  introductionHeaderBtnStyles,
  introductionHeaderShortStyles,
  introductionHeaderVeryShortStyles,
} from './IntroductionHeaderStyles';

type Props = {
  screenName: 'lifePlanning' | 'learning' | 'matching' | 'diagnosis';
  mode: 'normal' | 'short' | 'veryShort';
};

const IntroductionHeader = (props: Props) => {
  // state
  const { screenName, mode } = props;
  const btnscreenClasses = introductionHeaderBtnStyles();
  const screenClasses = (() => {
    switch (mode) {
      case 'short':
        return introductionHeaderShortStyles();
      case 'veryShort':
        return introductionHeaderVeryShortStyles();
      default:
        return introductionHeaderStyles();
    }
  })();
  const { role } = useSelector((state: RootState) => state.user);
  const isFp = role === 'FP';

  // const
  const textList = {
    lifePlanning: {
      title: '将来必要なお金を把握する！\n老後資金・ライフプラン診断をする',
      description:
        'まずは将来どの程度お金が必要か把握して、\n今後どのようなお金の管理をしていけば良いかアドバイスを受けましょう！',
    },
    learning: {
      title: '知識を武装しよう！\n資産運用・節税に関して勉強する',
      description:
        '資産の運用方法や節税には難しい仕組みがたくさんあります。\n診断をした後にあなたに必要な学習コンテンツを厳選してお届けます！\nFPさんに相談する前の事前準備、相談後の復習にも役立ちます。',
    },
    matching: {
      title: '自分に合ったお金のアドバイザー(FP)を見つける',
      description:
        '自分の課題・悩みに合ったお金の専門家(FP)を探しましょう！\n\n　',
    },
    diagnosis: {
      title: '節税診断',
      description:
        '自分がちゃんと節税できているか、\nセルフチェックをしましょう。\nサラリーマンでも出来ることはあるんです。',
    },
  };

  // const spTextList = {
  //   lifePlanning: {
  //     title: '家庭の経営計画を作ろう！',
  //     description: '',
  //   },
  //   learning: {
  //     title: '知識を武装しよう！',
  //     description: '',
  //   },
  //   matching: {
  //     title: '専門家に相談しよう！',
  //     description: '',
  //   },
  //   diagnosis: {
  //     title: '節税診断',
  //     description: '',
  //   },
  // };

  const textListForFP = {
    lifePlanning: {
      title:
        '家庭の経営計画を作ろう！\n将来必要なお金を見える化・収支計画を診断する',
      description:
        'これから人生でやりたいことを達成するため、\nまずは今後の収支計画を診断しよう。きっと色々、見えてくるはず。\n　',
    },
    learning: {
      title: '知識を武装しよう！\n資産運用・節税に関して勉強する',
      description:
        '資産の運用方法や節税には難しい仕組みがたくさんあります。\n診断をした後にあなたに必要な学習コンテンツを厳選してお届けます！\nFPさんに相談する前の事前準備、相談後の復習にも役立ちます。',
    },
    matching: {
      title: '専門家に相談しよう！\nお金の相談相手(FP)を見つける',
      description:
        'ユーザは自分の課題/悩みに合ったお金の専門家(FP)を探しています。\n本機能はユーザがFPの一覧、詳細を確認し、気になるFPにアプローチをかけられる機能です。\n\nユーザがあなたにチャットを送ったとき、\n右上のチャットボックスからあなたはユーザーとメッセージがやりとりできるようになります。',
    },
    diagnosis: {
      title: '節税診断',
      description:
        '自分がちゃんと節税できているか、\nセルフチェックをしましょう。\nサラリーマンでも出来ることはあるんです。',
    },
  };

  // screen
  return (
    <section className={screenClasses.root}>
      <Container className={screenClasses.container}>
        {/* screen */}

        <Grid className={screenClasses.cardRoot}>
          {isFp ? (
            <Grid>
              <Typography className={screenClasses.title} align="left">
                {textListForFP[screenName].title}
              </Typography>
              <Typography className={screenClasses.description} align="left">
                {mode === 'normal'
                  ? textListForFP[screenName].description
                  : null}
              </Typography>
            </Grid>
          ) : (
            <Grid>
              <Typography className={screenClasses.title} align="left">
                {textList[screenName].title}
              </Typography>
              <Typography className={screenClasses.description} align="left">
                {mode === 'normal' ? textList[screenName].description : null}
              </Typography>
            </Grid>
          )}
          <Grid className={btnscreenClasses.buttonRoot}>
            {mode === 'normal' ? (
              <StartButtonComponent screenNameProp={screenName} />
            ) : null}
          </Grid>
        </Grid>

        {/* background */}
        <div className={screenClasses.backdrop} />
        <div
          className={clsx(
            screenClasses.backgroundPosition,
            screenClasses.background
          )}
        />
      </Container>
    </section>
  );
};

export default IntroductionHeader;

import { InputAdornment, TextField as MuiTextField } from '@mui/material';
import { ComponentProps } from 'react';
import { Text } from './Text';

export const TextField = ({
  unit,
  readOnly,
  alignRight = false,
  type,
  ...props
}: {
  unit?: string;
  readOnly?: boolean;
  alignRight?: boolean;
} & ComponentProps<typeof MuiTextField>) => (
  <MuiTextField
    InputProps={{
      ...props.InputProps,
      ...(unit && {
        endAdornment: (
          <InputAdornment position="end">
            <Text variant="caption">{unit}</Text>
          </InputAdornment>
        ),
      }),
      readOnly,
      inputProps: {
        ...props.inputProps,
        style: {
          ...(alignRight && {
            textAlign: 'right',
          }),
          ...props.inputProps?.style,
        },
      },
    }}
    type={readOnly ? 'text' : type}
    {...props}
  />
);

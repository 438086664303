import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  TextField,
  Card,
  Button,
  CircularProgress,
  Backdrop,
  Typography,
  Grid,
} from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';

import useStyles from './FpSignUpScreenStyles';
import { AppDispatch, RootState } from '../../../modules/store';
import {
  getNickName,
  signUpFp,
  setFpTextField,
  initFpInfo,
  setErrMsg,
  toggleIsErrModalOpen,
} from '../../../modules/store/slices/userSlice';
import { isTextInvalid, isTextIsNumber } from '../../../components/validation';
import Model from '../../../components/Modal';

function Screen() {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  // state
  const [textFieldList, setTextFieldList] = React.useState({
    email: '',
    password: '',
  });
  const [disabled, setDisabled] = React.useState(true);
  const { isLoading, fpInfo, errMsg, isErrModalOpen } = useSelector(
    (state: RootState) => state.user
  );
  const { email, password } = textFieldList;
  const {
    nickName,
    belongs,
    age,
    sex,
    fpHistory,
    deliverable,
    firstTimeFree,
    fee,
    introduction,
    others,
    specialty01,
    specialty02,
    specialty03,
    financialProducts01,
    financialProducts02,
    financialProducts03,
    financialProducts04,
    financialProducts05,
  } = fpInfo;

  // 入力項目の初期化（レンダリング時）
  React.useEffect(() => {
    dispatch(initFpInfo());
  }, []);

  // 登録ボタン活性・非活性処理
  const isDisabled = () =>
    isTextInvalid(email) || isTextInvalid(password) || isTextInvalid(nickName);

  React.useEffect(() => {
    setDisabled(isDisabled());
  }, [email, password, nickName]);

  // functions
  const onClickSignUpBtn = () => {
    dispatch(
      signUpFp({
        email,
        password,
        nickName,
        belongs,
        age,
        sex,
        fpHistory,
        specialtyArray: [specialty01, specialty02, specialty03],
        deliverable,
        firstTimeFree,
        fee,
        financialProductsArray: [
          financialProducts01,
          financialProducts02,
          financialProducts03,
          financialProducts04,
          financialProducts05,
        ],
        introduction,
        others,
      })
    )
      .then(unwrapResult)
      .then(() => {
        history.push({ pathname: '/signin' });

        // NickNameの取得
        dispatch(getNickName());
      })
      .catch((error) => {
        // 失敗時の処理
        dispatch(setErrMsg(error.message));
        dispatch(toggleIsErrModalOpen());
      });
  };

  const onChangeTextField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setTextFieldList({ ...textFieldList, [name]: value });
  };

  const onChangeSliceTextField = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.target;
    dispatch(setFpTextField({ name, value }));
  };

  const onChangeSliceNumberTextField = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.target;
    // 数字のみ入力させるロジック
    if (!Number.isNaN(+value)) {
      dispatch(
        setFpTextField({
          name,
          value: isTextIsNumber(value) ? +value : undefined,
        })
      );
    }
  };

  const onClickCloseErrModalBtn = () => {
    dispatch(toggleIsErrModalOpen());
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.cardRoot}>
        <Typography align="center" className={classes.title}>
          会員登録（FP）
        </Typography>

        {/* 説明 */}
        <Typography
          align="left" /* 改行後左寄せ */
          className={classes.subTitle}>
          会員登録により、
          {/* TODO: 準備中画面へ遷移 */}
          <Link className={classes.link} to="/">
            利用規約
          </Link>
          及び
          {/* TODO: 準備中画面へ遷移 */}
          <Link className={classes.link} to="/privacy-policy">
            プライバシーポリシー
          </Link>
          {`\nに同意したこととさせていただきます。`}
        </Typography>

        {/* form */}
        <Card className={classes.textCardRoot}>
          <TextField
            className={classes.nameTextField}
            label="名前（表示名） *"
            variant="outlined"
            value={nickName}
            name="nickName"
            onChange={onChangeSliceTextField}
          />
          <TextField
            className={classes.emailTextField}
            label="メールアドレス *"
            variant="outlined"
            value={email}
            name="email"
            onChange={onChangeTextField}
          />
          <TextField
            className={classes.passwordTextField}
            label="パスワード *"
            variant="outlined"
            type="password"
            placeholder="半角英数字8桁以上"
            value={password}
            name="password"
            onChange={onChangeTextField}
          />
          <TextField
            className={classes.otherTextField}
            label="所属 "
            variant="outlined"
            value={belongs}
            name="belongs"
            onChange={onChangeSliceTextField}
          />
          <TextField
            className={classes.otherTextField}
            label="年齢"
            variant="outlined"
            value={age ?? ''} // undefinedの時は空にする(数字のみ入力させるロジック)
            name="age"
            placeholder="半角数字にて入力"
            onChange={onChangeSliceNumberTextField}
          />
          <TextField
            className={classes.otherTextField}
            label="性別 "
            variant="outlined"
            value={sex}
            name="sex"
            placeholder="male または female"
            onChange={onChangeSliceTextField}
          />
          <TextField
            className={classes.otherTextField}
            label="FP歴"
            variant="outlined"
            value={fpHistory ?? ''} // undefinedの時は空にする(数字のみ入力させるロジック)
            name="fpHistory"
            placeholder="半角数字にて入力"
            onChange={onChangeSliceNumberTextField}
          />
          <TextField
            className={classes.otherTextField}
            label="得意分野1"
            variant="outlined"
            value={specialty01}
            name="specialty01"
            onChange={onChangeSliceTextField}
            placeholder="住宅・教育など"
          />
          <TextField
            className={classes.otherTextField}
            label="得意分野2"
            variant="outlined"
            value={specialty02}
            name="specialty02"
            onChange={onChangeSliceTextField}
            placeholder="specialty2"
          />
          <TextField
            className={classes.otherTextField}
            label="得意分野3"
            variant="outlined"
            value={specialty03}
            name="specialty03"
            onChange={onChangeSliceTextField}
            placeholder="specialty3"
          />
          <TextField
            className={classes.otherTextField}
            label="相談後にご提供するもの"
            variant="outlined"
            value={deliverable}
            name="deliverable"
            onChange={onChangeSliceTextField}
            placeholder="ライフプランニング資料など"
          />
          <TextField
            className={classes.otherTextField}
            label="初回無料有無 "
            variant="outlined"
            value={firstTimeFree}
            name="firstTimeFree"
            onChange={onChangeSliceTextField}
            placeholder="有 or 無"
          />
          <TextField
            className={classes.otherTextField}
            label="料金(円/h) "
            variant="outlined"
            value={fee}
            name="fee"
            onChange={onChangeSliceTextField}
            placeholder="〜〜円　など、自分で設定"
          />
          <TextField
            className={classes.otherTextField}
            label="取り扱い金融商品1"
            variant="outlined"
            value={financialProducts01}
            name="financialProducts01"
            onChange={onChangeSliceTextField}
            placeholder="生命保険など"
          />
          <TextField
            className={classes.otherTextField}
            label="取り扱い金融商品2"
            variant="outlined"
            value={financialProducts02}
            name="financialProducts02"
            onChange={onChangeSliceTextField}
            placeholder="financialProducts02"
          />
          <TextField
            className={classes.otherTextField}
            label="取り扱い金融商品3"
            variant="outlined"
            value={financialProducts03}
            name="financialProducts03"
            onChange={onChangeSliceTextField}
            placeholder="financialProducts03"
          />
          <TextField
            className={classes.otherTextField}
            label="取り扱い金融商品4"
            variant="outlined"
            value={financialProducts04}
            name="financialProducts04"
            onChange={onChangeSliceTextField}
            placeholder="financialProducts04"
          />
          <TextField
            className={classes.otherTextField}
            label="取り扱い金融商品5"
            variant="outlined"
            value={financialProducts05}
            name="financialProducts05"
            onChange={onChangeSliceTextField}
            placeholder="financialProducts05"
          />
          <TextField
            className={classes.otherTextField}
            label="自己紹介"
            variant="outlined"
            value={introduction}
            name="introduction"
            multiline // テキストフィールド内で複数行を許可する
            rows={3} // 表示する行数の指定
            onChange={onChangeSliceTextField}
          />
          <TextField
            className={classes.otherTextField}
            label="その他"
            variant="outlined"
            value={others}
            name="others"
            multiline // テキストフィールド内で複数行を許可する
            rows={3} // 表示する行数の指定
            onChange={onChangeSliceTextField}
          />

          <Button
            className={classes.signUpButton}
            onClick={onClickSignUpBtn}
            disabled={disabled}
            classes={{ disabled: classes.disableSignUpButton }} // 非活性時のスタイル
          >
            会員登録
          </Button>
        </Card>

        {/* footer */}
        <Typography align="center" className={classes.alreadySignUpBtn}>
          <Link className={classes.alreadySignUpLinkBtn} to="/signIn">
            会員登録済の方はこちら
          </Link>
        </Typography>

        {/* インジケーター */}
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress />
        </Backdrop>

        {/* modal */}
        <Model
          isOpen={isErrModalOpen}
          title="入力項目にエラーがあります"
          body={errMsg}
          buttonName="もう一度入力する"
          onClickCloseModalBtn={onClickCloseErrModalBtn}
        />
      </Grid>
    </Grid>
  );
}

export default Screen;

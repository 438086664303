import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../resources/colors';

export const SpMyPageScreenStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.paleWhite,
  },
  container: {
    flexDirection: 'row',
    display: 'flex',
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: theme.spacing(0, 1, 1, 1),
  },
  divider: {
    backgroundColor: colors.darkGray,
  },
  text: {
    textAlign: 'center',
    fontSize: '18px',
    whiteSpace: 'pre-line', // 改行を許可
  },
  subText: {
    textAlign: 'center',
    fontSize: '14px',
    whiteSpace: 'pre-line', // 改行を許可
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },

  primaryCard: {
    backgroundColor: colors.white,
    height: '100px',
    width: '280px',
    margin: theme.spacing(2, 2, 2, 2),
    boxShadow: '4px 4px 4px #808080',
    whiteSpace: 'pre-line', // 改行を許可
  },

  secondaryCard: {
    backgroundColor: colors.white,
    height: '140px',
    width: '280px',
    margin: theme.spacing(2, 2, 2, 2),
    boxShadow: '4px 4px 4px #808080',
    textAlign: 'center',
    align: 'center',
  },

  buttonImage: {
    height: 'auto',
    width: '150px',
  },
}));

import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { Grid } from '@material-ui/core';

// screens
// SP(SmartPhone)
// import SpFPAppFooter from './components/SP/SpAppFooter';

import SpCategoryHomeScreen from './containers/SP/home/home/SpCategoryHomeScreen';
import SpMatchingFpListScreen from './containers/SP/matching/matchingFpList/SpMatchingFpListScreen';
import SpIndividualFpScreen from './containers/SP/matching/individualFpScreen/SpIndividualFpScreen';
import SpChatListScreen from './containers/SP/matching/chatListScreen/SpChatListScreen';
import SpMatchingChatScreen from './containers/SP/matching/matchingChatScreen/SpMatchingChatScreen';
import SpMyPageScreen from './containers/SP/myPage/SpMyPageScreen';
import SpUserInfoScreen from './containers/SP/userMenu/userInfo/SpUserInfoScreen';

// Web
import FPAppBar from './components/AppBar/FPAppBar';
import AppFooter from './components/AppFooter';
import PrivateRoute from './components/privateRoute';
import AuthCheck from './components/AuthCheck';

import CategoryHomeScreen from './containers/home/home/categoryHomeScreen';
import SignInScreen from './containers/signIn/signInScreen/SignInScreen';
import SignUpScreen from './containers/signUp/signUpScreen/SignUpScreen';
import FpSignInScreen from './containers/signIn/signInScreen/FpSignInScreen';
import ProfileScreen from './containers/setting/ProfileScreen/ProfileScreen';
import DiagnosisResultContent from './containers/setting/DiagnosisResultScreen/DiagnosisResultScreen';

import FpSignUpScreen from './containers/signUp/fpSignUpScreen/FpSignUpScreen';

import OtherProviderSignUpScreen from './containers/signUp/otherProvidersSignUpScreen/OtherProviderSignUpScreen';
import SignInWithLineAuthScreen from './containers/signIn/signInWithLineAuthScreen/SignInWithLineAuthScreen';

import SearchFpScreen from './containers/matching/search/SearchFpScreen';
import RecommendFpScreen from './containers/matching/recommendFp/ReccommendFpScreen';
import IndividualFpScreen from './containers/matching/individualFp/IndividualFpScreen';
import MatchingChatScreen from './containers/matching/matchingChat/MatchingChatScreen';
import MatchingChatClientScreen from './containers/matching/matchingChatClient/MatchingChatClientScreen';
import MatchingHomeScreen from './containers/matching/home/MatchingHomeScreen';
import TermsOfUseScreen from './containers/common/termsOfUse/TermsOfUseScreen';
import PrivacyPolicyScreen from './containers/common/privacyPolicy/PrivacyPolicyScreen';
import ContactScreen from './containers/common/contact/ContactScreen';
import PreparingPageScreen from './containers/common/preparingPage/PreparingPageScreen';

import NotFoundErrorScreen from './containers/error/NotFoundErrorScreen';
import UpdatePasswordScreen from './containers/setting/UpdatePasswordScreen/UpdatePasswordScreen';
import ChatListScreen from './containers/matching/chatListScreen/ChatListScreen';

import DiagnosisContent from './containers/diagnosis/contentScreen/ContentScreen';
import SpDiagnosisScreen from './containers/SP/diagnosis/SpDiagnosisScreen';

import ReviewsScreen from './containers/matching/reviews/ReviewsScreen';

// MVP から外れたためコメントアウト
// import DiagnosisHomeScreen from './containers/diagnosis/home/DiagnosisHomeScreen';
// import LifePlanningHomeScreen from './containers/life_planning/home/LifePlanningHomeScreen';
// import LifePlanningFormScreen from './containers/life_planning/form/LifePlanningFormScreen';
// import LifePlanningConfirmScreen from './containers/life_planning/form/LifePlanningConfirmScreen';
// import LifePlanningResultScreen from './containers/life_planning/form/LifePlanningResultScreen';
// import LearningHomeScreen from './containers/learning/home/LearningHomeScreen';

// import SelectScreen from './containers/learning/selectScreen/SelectScreen';
// import ContentScreen from './containers/learning/contentScreen/ContentScreen';

// import FeeScreen from './containers/common/fee/FeeScreen';

function App() {
  // const ua = window.navigator.userAgent.toLowerCase();
  const ua = window.navigator.userAgent;

  if (
    ua.indexOf('iPhone') > 0 ||
    ua.indexOf('iPad') > 0 ||
    ua.indexOf('Mobile') > 0
  ) {
    // モバイル画面表示
    return (
      <div className="App">
        <Router>
          <AuthCheck>
            <Grid>
              <FPAppBar />
              {/* screens */}
              <Switch>
                {/* account */}
                <Route path="/signin" exact component={SignInScreen} />
                <Route path="/signup" exact component={SignUpScreen} />
                <Route path="/fp-signin" exact component={FpSignInScreen} />
                <Route path="/fp-signup" exact component={FpSignUpScreen} />
                <Route
                  path="/other-provider-signup"
                  exact
                  component={OtherProviderSignUpScreen}
                />
                <Route
                  path="/line-indicating-signin"
                  exact
                  component={SignInWithLineAuthScreen}
                />
                <Route path="/diagnosis" exact component={SpDiagnosisScreen} />
                {/* setting */}
                <PrivateRoute
                  path="/update-password"
                  exact
                  component={UpdatePasswordScreen}
                />
                <PrivateRoute path="/profile" exact component={ProfileScreen} />
                {/* root (home) */}
                <Route path="/" exact component={SpCategoryHomeScreen} />

                <PrivateRoute
                  path="/my-page"
                  exact
                  component={SpMyPageScreen}
                />

                {/* userMenu */}
                <PrivateRoute
                  path="/user-info"
                  exact
                  component={SpUserInfoScreen}
                />

                {/* common */}
                <Route
                  path="/terms-of-use"
                  exact
                  component={TermsOfUseScreen}
                />
                <Route
                  path="/privacy-policy"
                  exact
                  component={PrivacyPolicyScreen}
                />
                <Route path="/contact" exact component={ContactScreen} />
                {/* learning */}
                {/* matching */}
                {/* <PrivateRoute
                  path="/matching/home"
                  exact
                  component={spMatchingHomeScreen}
                /> */}
                <PrivateRoute
                  path="/matching/fp-list/"
                  exact
                  component={SpMatchingFpListScreen}
                />
                <PrivateRoute
                  path="/matching/individual-fp/:role/:id"
                  exact
                  component={SpIndividualFpScreen}
                />
                <PrivateRoute
                  path="/matching/chatRooms"
                  exact
                  component={SpChatListScreen}
                />
                <PrivateRoute
                  path="/matching/chatRooms/:chatRoomID"
                  exact
                  component={SpMatchingChatScreen}
                />
                {/* preparingPage */}
                <Route
                  path="/preparing-page"
                  exact
                  component={PreparingPageScreen}
                />
                {/* 404 page */}
                <Route component={NotFoundErrorScreen} />
              </Switch>
            </Grid>
          </AuthCheck>
        </Router>
      </div>
    );
  }
  // web画面表示
  return (
    <div className="App">
      <Router>
        <AuthCheck>
          <Grid>
            <FPAppBar />
            {/* screens */}
            <Switch>
              {/* account */}
              <Route path="/signin" exact component={SignInScreen} />
              <Route path="/signup" exact component={SignUpScreen} />
              <Route path="/fp-signin" exact component={FpSignInScreen} />
              <Route path="/fp-signup" exact component={FpSignUpScreen} />
              <PrivateRoute
                path="/other-provider-signup"
                exact
                component={OtherProviderSignUpScreen}
              />
              <Route
                path="/line-indicating-signin"
                exact
                component={SignInWithLineAuthScreen}
              />

              {/* setting */}
              <PrivateRoute
                path="/update-password"
                exact
                component={UpdatePasswordScreen}
              />

              <PrivateRoute path="/profile" exact component={ProfileScreen} />

              <PrivateRoute
                path="/diagnosis/result"
                exact
                component={DiagnosisResultContent}
              />

              {/* root (home) */}
              <Route path="/" exact component={CategoryHomeScreen} />

              {/* common */}
              <Route path="/terms-of-use" exact component={TermsOfUseScreen} />
              <Route
                path="/privacy-policy"
                exact
                component={PrivacyPolicyScreen}
              />
              <Route path="/contact" exact component={ContactScreen} />

              {/* 料金情報  2022/8_初期リリースの対象外とするためコメントアウト  */}
              {/* <Route path="/fee" exact component={FeeScreen} /> */}

              {/* diagnosis */}
              <PrivateRoute
                path="/diagnosis"
                exact
                component={DiagnosisContent}
              />

              {/* lifePlanning */}
              {/* <PrivateRoute
                path="/life-planning/home"
                exact
                component={LifePlanningHomeScreen}
              />

              <PrivateRoute
                path="/life-planning/form"
                exact
                component={LifePlanningFormScreen}
              />
              <PrivateRoute
                path="/life-planning/confirm"
                exact
                component={LifePlanningConfirmScreen}
              />
              <PrivateRoute
                path="/life-planning/result"
                exact
                component={LifePlanningResultScreen}
              /> */}

              {/* learning 2022/8_初期リリースの対象外とするためコメントアウト */}
              {/* 
              <PrivateRoute
                path="/learning/home"
                exact
                component={LearningHomeScreen}
              />
              <PrivateRoute
                path="/learning/select"
                exact
                component={SelectScreen}
              />
              <PrivateRoute
                path="/learning/content/:contentId"
                exact
                component={ContentScreen}
              /> */}

              {/* matching */}
              <PrivateRoute
                path="/matching/home"
                exact
                component={MatchingHomeScreen}
              />
              <PrivateRoute
                path="/matching/search/user/:id"
                exact
                component={SearchFpScreen}
              />
              <PrivateRoute
                path="/matching/recommend-fp/user/:id"
                exact
                component={RecommendFpScreen}
              />
              <PrivateRoute
                path="/matching/individual-fp/user/:id"
                exact
                component={IndividualFpScreen}
              />

              <PrivateRoute
                path="/matching/chatRooms"
                exact
                component={ChatListScreen}
              />
              <PrivateRoute
                path="/matching/chatRooms/:chatRoomID"
                exact
                component={MatchingChatScreen}
              />
              <PrivateRoute
                path="/matching/chatRooms/FP/:chatRoomID"
                exact
                component={MatchingChatClientScreen}
              />

              <PrivateRoute
                path="/matching/fp-reviews/:id"
                exact
                component={ReviewsScreen}
              />

              {/* preparingPage */}
              <Route
                path="/preparing-page"
                exact
                component={PreparingPageScreen}
              />

              {/* 404 page */}
              <Route component={NotFoundErrorScreen} />
            </Switch>
            <AppFooter />
          </Grid>
        </AuthCheck>
      </Router>
    </div>
  );
}

export default App;

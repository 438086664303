import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Backdrop, CircularProgress } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../../components/Icon';
import { ClientHeader } from '../../../newComponents/ClientHeader';
import { Column } from '../../../newComponents/layout/Column';
import { Row } from '../../../newComponents/layout/Row';
import { Select } from '../../../newComponents/Select';
import { Text } from '../../../newComponents/Text';
import { TextField } from '../../../newComponents/TextField';
import { colors } from '../../../resources/theme/defaultTheme';
import { MakeInterviewDoneModal } from '../../common/modal/MakeInterviewDoneModal';
import { AppDispatch, RootState } from '../../../modules/store';
import {
  diagnosisDataSelectors,
  fetchDiagnosisData,
  selectIsLoading,
} from '../../../modules/store/slices/diagnosisDataSlice';
import { EmotionText } from '../../../newComponents/EmotionText';
import {
  clietntUserSelectors,
  fetchClientUser,
} from '../../../modules/store/slices/clientUserSlice';

export const ClientDetailScreen = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const dispatch: AppDispatch = useDispatch();
  const diagnosisData = useSelector((state: RootState) =>
    diagnosisDataSelectors.selectById(state, clientId)
  );
  const isLoading = useSelector(selectIsLoading);
  const userData = useSelector((state: RootState) =>
    clietntUserSelectors.selectById(state, clientId)
  );
  useEffect(() => {
    dispatch(fetchDiagnosisData(clientId));
    if (userData === undefined) {
      dispatch(fetchClientUser(clientId));
    }
    // 初期データ取得のため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 老後に希望する暮らしのレベル
  // label は月に利用する金額(万円)
  const livingItems = [
    {
      // 8
      label: '質素な暮らし',
      value: 8,
    },
    {
      // 14
      label: '一般的な暮らし',
      value: 14,
    },
    {
      // 20
      label: 'ゆとりのある暮らし',
      value: 20,
    },
    {
      // 30
      label: '豪華な暮らし',
      value: 30,
    },
  ];

  const [confirmationModalState, setConfirmationModalState] = useState<{
    chatRoomID: string;
    name: string;
  }>();

  const openConfirmationModal = ({
    chatRoomID,
    name,
  }: Exclude<typeof confirmationModalState, undefined>) => {
    setConfirmationModalState({
      chatRoomID,
      name,
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <ClientHeader
        onMakeInterviewDoneClick={openConfirmationModal}
        user={userData}
      />
      <MakeInterviewDoneModal
        onClose={() => setConfirmationModalState(undefined)}
        onOk={() => {
          // TODO 再更新のAPI呼びだす
          setConfirmationModalState(undefined);
        }}
        data={confirmationModalState}
      />
      <Column
        sx={{
          alignItems: 'center',
          p: 3,
        }}>
        <Column
          sx={{
            width: '995px',
            gap: 3,
          }}>
          <Text variant="h1">老後の資金診断の結果</Text>
          {!isLoading && diagnosisData === undefined && (
            <>診断結果がありません。</>
          )}
          {diagnosisData !== undefined && (
            <>
              <Row
                sx={{
                  gap: 3,
                  '& > *': {
                    flex: 1,
                  },
                  alignItems: 'center',
                }}>
                <Column sx={{ gap: 1, flex: 3 }}>
                  {/* -------------- ユーザに表示している丸めた値 -------------- */}

                  <PrimaryCard>
                    <Text
                      variant="subtitle1"
                      sx={{
                        position: 'relative',
                        fontSize: '16px',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          bottom: '0',
                          width: '100%',
                          height: '1px',
                          borderRadius: '0.5px',
                          backgroundColor: colors.primary[400],
                        },
                      }}>
                      【ユーザへ表示している内容】
                    </Text>
                    <Row sx={{ alignItems: 'center', gap: 1 }}>
                      <Text variant="subtitle1" sx={{ flex: '1' }}>
                        ユーザの老後資金
                        <Text variant="caption" sx={{ flex: '1' }}>
                          (金額を丸めて表示してます)
                        </Text>
                      </Text>
                      <CurrencyRow>
                        <Text
                          variant="h1"
                          sx={{
                            position: 'relative',
                            fontSize: '32px',
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              bottom: '0',
                              width: '100%',
                              height: '2px',
                              borderRadius: '1px',
                              opacity: '0.8',
                              backgroundColor: colors.primary[400],
                            },
                          }}>
                          {diagnosisData.roundingTotalRequiredSavings !==
                          undefined
                            ? diagnosisData.roundingTotalRequiredSavings
                            : 'xxx'}
                        </Text>
                        <Text variant="caption">
                          {diagnosisData.roundingTotalRequiredSavings !==
                            undefined &&
                          diagnosisData.roundingTotalRequiredSavings > 4000
                            ? '万円以上'
                            : '万円程度'}
                        </Text>
                      </CurrencyRow>
                    </Row>
                    <Text variant="caption">
                      (老後資金を貯めるために必要な毎月の積立金額)
                    </Text>
                    <Row
                      sx={{
                        '& > *': {
                          flex: '1',
                        },
                      }}>
                      <Row sx={{ gap: 1 }}>
                        <IconCircle>
                          <Icon iconKey="saving" />
                        </IconCircle>
                        <CurrencyRow>
                          <Text sx={{ alignSelf: 'center' }} variant="body1">
                            貯金
                          </Text>
                          <Text variant="h1">
                            {diagnosisData.roundingRequiredSavingsPerMonth !==
                            undefined
                              ? diagnosisData.roundingRequiredSavingsPerMonth.toFixed(
                                  1
                                )
                              : '-'}
                          </Text>
                          <Text variant="caption">万円/月</Text>
                        </CurrencyRow>
                      </Row>
                      <Row sx={{ gap: 1 }}>
                        <IconCircle>
                          <Icon iconKey="investment" />
                        </IconCircle>
                        <CurrencyRow>
                          <Text sx={{ alignSelf: 'center' }} variant="body1">
                            運用
                            <Text variant="caption">(年利3%)</Text>
                          </Text>
                          <Text variant="h1">
                            {diagnosisData.roundingRequiredInvestmentPerMonth !==
                            undefined
                              ? diagnosisData.roundingRequiredInvestmentPerMonth.toFixed(
                                  1
                                )
                              : '-'}
                          </Text>
                          <Text variant="caption">万円/月</Text>
                        </CurrencyRow>
                      </Row>
                    </Row>
                  </PrimaryCard>

                  {/* -------------- 実際の値 -------------- */}
                  <Card>
                    <Text
                      variant="subtitle1"
                      sx={{
                        position: 'relative',
                        fontSize: '16px',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          bottom: '0',
                          width: '100%',
                          height: '1px',
                          borderRadius: '0.5px',
                          backgroundColor: colors.primary[400],
                        },
                      }}>
                      【実際の計算結果】
                    </Text>
                    <Row sx={{ alignItems: 'center', gap: 1 }}>
                      <Text variant="subtitle1" sx={{ flex: '1' }}>
                        ユーザの老後資金
                      </Text>
                      <CurrencyRow>
                        <Text
                          variant="h1"
                          sx={{
                            position: 'relative',
                            fontSize: '32px',
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              bottom: '0',
                              width: '100%',
                              height: '2px',
                              borderRadius: '1px',
                              opacity: '0.8',
                              backgroundColor: colors.primary[400],
                            },
                          }}>
                          {diagnosisData.totalRequiredSavings.toLocaleString()}
                        </Text>
                        <Text variant="caption">万円</Text>
                      </CurrencyRow>
                    </Row>

                    <Text variant="caption">
                      (老後資金を貯めるために必要な毎月の積立金額)
                    </Text>
                    <Row
                      sx={{
                        '& > *': {
                          flex: '1',
                        },
                      }}>
                      <Row sx={{ gap: 1 }}>
                        <IconCircle>
                          <Icon iconKey="saving" />
                        </IconCircle>
                        <CurrencyRow>
                          <Text sx={{ alignSelf: 'center' }} variant="body1">
                            貯金
                          </Text>
                          <Text variant="h1">
                            {diagnosisData.requiredSavingsPerMonth !== undefined
                              ? diagnosisData.requiredSavingsPerMonth.toFixed(1)
                              : 0}
                          </Text>
                          <Text variant="caption">万円/月</Text>
                        </CurrencyRow>
                      </Row>
                      <Row sx={{ gap: 1 }}>
                        <IconCircle>
                          <Icon iconKey="investment" />
                        </IconCircle>
                        <CurrencyRow>
                          <Text sx={{ alignSelf: 'center' }} variant="body1">
                            運用
                            <Text variant="caption">(年利3%)</Text>
                          </Text>
                          <Text variant="h1">
                            {diagnosisData.requiredInvestmentPerMonth !==
                            undefined
                              ? diagnosisData.requiredInvestmentPerMonth.toFixed(
                                  1
                                )
                              : 0}
                          </Text>
                          <Text variant="caption">万円/月</Text>
                        </CurrencyRow>
                      </Row>
                    </Row>
                  </Card>
                </Column>
                <Column sx={{ flex: 2 }}>
                  <LabelValueList>
                    <LabelValue
                      label="診断後の感想"
                      value={
                        <EmotionText anxietyLevel={userData?.anxietyLevel} />
                      }
                    />
                    <LabelValue
                      label="家族構成"
                      value={diagnosisData.marriageStatus}
                    />
                    <LabelValue
                      label="想定する老後の暮らし(費用)"
                      // todo 想定する老後の暮らしをテキストで表示する
                      value={`${diagnosisData.livingStandard60to80}万円/月`}
                    />
                    <LabelValue
                      label="想定する老後の住居"
                      value={diagnosisData.houseStatus}
                    />
                    <LabelValue
                      label="想定寿命"
                      value={`${diagnosisData.lifeSpan}歳`}
                    />
                  </LabelValueList>
                </Column>
              </Row>
              <Text variant="h2">
                内訳
                <Text variant="caption">【実際の計算結果】</Text>
              </Text>
              <Row
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr auto 1fr',
                  gap: 2,
                  alignItems: 'flex-start',
                }}>
                <Column sx={{ gap: 1 }}>
                  <Card>
                    <Row sx={{ alignItems: 'center' }}>
                      <Text sx={{ flex: 1 }}>老後の支出</Text>
                      <CurrencyRow>
                        <Text variant="h2">
                          {diagnosisData.totalExpense.toLocaleString()}
                        </Text>
                        <Text variant="caption">万円</Text>
                      </CurrencyRow>
                    </Row>
                  </Card>
                  <LabelValueList>
                    <LabelValueAccordion
                      initialCollapsed={false}
                      label="生活費"
                      value={
                        <CurrencyRow>
                          <Text variant="body1">
                            {diagnosisData.livingCost.toLocaleString()}
                          </Text>
                          <Text variant="caption">万円</Text>
                        </CurrencyRow>
                      }>
                      <Column sx={{ width: '100%', gap: 1 }}>
                        <Row sx={{ gap: 1 }}>
                          <TextField
                            readOnly
                            label="予想寿命"
                            type="number"
                            value={diagnosisData.lifeSpan}
                            unit="歳"
                          />
                          <Select
                            readOnly
                            label="老後の家族構成"
                            value={diagnosisData.marriageStatus}
                            items={[
                              {
                                label: '夫婦',
                                value: '夫婦',
                              },
                              {
                                label: '単身',
                                value: '単身',
                              },
                            ]}
                          />
                        </Row>
                        <Select
                          readOnly
                          label="65-80歳の暮らし"
                          value={diagnosisData.livingStandard60to80}
                          items={livingItems}
                        />
                        <Select
                          readOnly
                          label="80-100歳の暮らし"
                          value={diagnosisData.livingStandard80to100}
                          items={livingItems}
                        />
                      </Column>
                    </LabelValueAccordion>
                    <LabelValueAccordion
                      initialCollapsed={false}
                      label="住宅費（ローン残高含む）"
                      value={
                        <CurrencyRow>
                          <Text variant="body1">
                            {diagnosisData.housingCost}
                          </Text>
                          <Text variant="caption">万円</Text>
                        </CurrencyRow>
                      }>
                      <Column sx={{ width: '100%', gap: 1 }}>
                        <Row
                          sx={{
                            gap: 1,
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                          }}>
                          <Select
                            readOnly
                            label="居住形態"
                            value={diagnosisData.houseStatus}
                            items={[
                              {
                                label: '持ち家',
                                value: '持ち家',
                              },
                              {
                                label: '賃貸',
                                value: '賃貸',
                              },
                            ]}
                          />
                          <TextField
                            readOnly
                            label="(賃貸の場合)月額"
                            type="number"
                            value={diagnosisData.houseRent}
                            unit="万円/月"
                          />
                        </Row>
                        <Row
                          sx={{
                            gap: 1,
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                          }}>
                          <TextField
                            readOnly
                            label="(持ち家の場合)購入年齢"
                            type="number"
                            value={diagnosisData.ownedHouseInfo?.purchaseAge}
                            unit="歳"
                          />
                          <TextField
                            readOnly
                            label="(持ち家の場合)購入価格"
                            type="number"
                            value={diagnosisData.ownedHouseInfo?.price.toLocaleString()}
                            unit="万円"
                          />
                        </Row>
                      </Column>
                    </LabelValueAccordion>
                    <LabelValue
                      label="介護費"
                      sx={{ pr: 0 }}
                      value={
                        <TextField
                          alignRight
                          readOnly
                          value={diagnosisData.careCost.toLocaleString()}
                          type="number"
                          unit="万円"
                        />
                      }
                    />
                    <LabelValue
                      sx={{ pr: 0 }}
                      label="医療費"
                      value={
                        <TextField
                          alignRight
                          readOnly
                          value={diagnosisData.medicalExpenses.toLocaleString()}
                          type="number"
                          unit="万円"
                        />
                      }
                    />
                    <LabelValue
                      sx={{ pr: 0 }}
                      label="葬儀/死後精算費用"
                      value={
                        <TextField
                          alignRight
                          readOnly
                          value={diagnosisData.funeralExpenses.toLocaleString()}
                          type="number"
                          unit="万円"
                        />
                      }
                    />
                    <LabelValue
                      sx={{ pr: 0 }}
                      label="その他の費用"
                      value={
                        <TextField
                          alignRight
                          readOnly
                          value={diagnosisData.otherExpenses.toLocaleString()}
                          type="number"
                          unit="万円"
                        />
                      }
                    />
                  </LabelValueList>
                </Column>
                <Text
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '25px',
                    mt: '12px',
                  }}>
                  ー
                </Text>
                <Column>
                  <Card>
                    <Row sx={{ alignItems: 'center' }}>
                      <Text sx={{ flex: 1 }}>老後の収入</Text>
                      <CurrencyRow>
                        <Text variant="h2">
                          {diagnosisData.totalRetirementIncome !== undefined
                            ? diagnosisData.totalRetirementIncome.toLocaleString()
                            : 0}
                        </Text>
                        <Text variant="caption">万円</Text>
                      </CurrencyRow>
                    </Row>
                  </Card>
                  <LabelValueList>
                    <LabelValue
                      label="基礎年金"
                      sx={{ pr: 0 }}
                      value={
                        <TextField
                          alignRight
                          readOnly
                          value={
                            diagnosisData.basicAnnuity !== undefined
                              ? diagnosisData.basicAnnuity.toLocaleString()
                              : 0
                          }
                          type="number"
                          unit="万円"
                        />
                      }
                    />
                    <LabelValueAccordion
                      initialCollapsed={false}
                      label="厚生年金"
                      value={
                        <CurrencyRow>
                          <Text variant="body1">
                            {diagnosisData.employeeAnnuity !== undefined
                              ? diagnosisData.employeeAnnuity.toLocaleString()
                              : 0}
                          </Text>
                          <Text variant="caption">万円</Text>
                        </CurrencyRow>
                      }>
                      <Column sx={{ width: '100%', gap: 1 }}>
                        <Row sx={{ gap: 1 }}>
                          <Select
                            readOnly
                            label="雇用形態"
                            value={
                              diagnosisData.isEmployee ? '会社員' : '非会社員'
                            }
                            items={[
                              {
                                label: '会社員',
                                value: '会社員',
                              },
                              {
                                label: '非会社員',
                                value: '非会社員',
                              },
                            ]}
                          />
                          <TextField
                            readOnly
                            label="想定最大年収"
                            type="number"
                            value={diagnosisData.estimatedIncome.toLocaleString()}
                            unit="万円"
                          />
                        </Row>
                      </Column>
                    </LabelValueAccordion>
                    <LabelValue
                      label="配偶者厚生年金"
                      sx={{ pr: 0 }}
                      value={
                        <TextField
                          alignRight
                          readOnly
                          value={
                            diagnosisData.partnerEmployeeAnnuity !== undefined
                              ? diagnosisData.partnerEmployeeAnnuity.toLocaleString()
                              : 0
                          }
                          type="number"
                          unit="万円"
                        />
                      }
                    />
                    <LabelValue
                      label="退職金"
                      sx={{ pr: 0 }}
                      value={
                        <TextField
                          alignRight
                          readOnly
                          value={
                            diagnosisData.retiredPay !== undefined
                              ? diagnosisData.retiredPay.toLocaleString()
                              : 0
                          }
                          type="number"
                          unit="万円"
                        />
                      }
                    />
                    <LabelValue
                      label="現在の老後資金用の貯蓄残高"
                      sx={{ pr: 0 }}
                      value={
                        <TextField
                          alignRight
                          readOnly
                          value={diagnosisData.savingsAmount.toLocaleString()}
                          type="number"
                          unit="万円"
                        />
                      }
                    />
                  </LabelValueList>
                </Column>
              </Row>
            </>
          )}
        </Column>
      </Column>
      {/* インジケーター */}
      <Backdrop
        sx={{ backgroundColor: colors.backdropColor, zIndex: 100 }}
        open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

const IconCircle = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      width: '46px',
      height: '46px',
      borderRadius: '23px',
      backgroundColor: colors.primary[400],
      color: colors.common.white,
      display: 'grid',
      justifyContent: 'center',
      alignContent: 'center',
    }}>
    {children}
  </Box>
);

const CurrencyRow = ({ children }: { children: ReactNode }) => (
  <Row sx={{ alignItems: 'flex-end', gap: 1 }}>{children}</Row>
);

const LabelValue = ({
  label,
  value,
  sx,
}: {
  label: ReactNode;
  value: ReactNode;
  sx?: SxProps;
}) => (
  <Row
    sx={{ alignItems: 'center', paddingBlock: 1.5, paddingInline: 1, ...sx }}>
    <Text variant="body1" sx={{ flex: '1' }}>
      {label}
    </Text>
    <Text variant="body1">{value}</Text>
  </Row>
);

const LabelValueList = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) => (
  <Box
    sx={{
      '& > *': { borderBottom: `1px solid ${colors.grey[300]}` },
      ...sx,
    }}>
    {children}
  </Box>
);

const LabelValueAccordion = ({
  label,
  value,
  children,
  initialCollapsed = true,
}: {
  label: ReactNode;
  value: ReactNode;
  children: ReactNode;
  initialCollapsed?: boolean;
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  return (
    <Column>
      <Row
        sx={{
          gap: 1,
          paddingBlock: 1.5,
          paddingInline: 1,
          alignItems: 'center',
          cursor: 'pointer',
          position: 'relative',
          '&:hover': {
            backgroundColor: colors.grey[100],
            borderRadius: 1,
          },
        }}
        onClick={() => {
          setCollapsed(!collapsed);
        }}>
        <KeyboardArrowDown
          fontSize="small"
          sx={{
            transitionProperty: 'transform',
            transitionDuration: '200ms',
            ...(!collapsed && {
              transform: 'rotate(180deg)',
            }),
          }}
        />
        <Box sx={{ flex: '1' }}>{label}</Box>
        <Box>{value}</Box>
      </Row>
      {!collapsed && (
        <Row
          sx={{
            pt: 1,
            pb: 2,
          }}>
          {children}
        </Row>
      )}
    </Column>
  );
};

const PrimaryCard = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      backgroundColor: colors.primary[100],
      p: 2,
      borderRadius: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
    }}>
    {children}
  </Box>
);

const Card = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      backgroundColor: colors.grey[100],
      p: 2,
      borderRadius: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
    }}>
    {children}
  </Box>
);

import React from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';

/**
 * 【使い方】
 *
 * ① Video を import
 * ・import Video from '../{随時}/../components/videoPlayer'
 *
 * ② Video を配置する
 * ・<Video src={url} />
 */

/**
 * 【参考サイト：Video.js and ReactJS】
 * https://docs.videojs.com/tutorial-react.html
 */

type Props = {
  src: string | undefined;
  autoplay?: boolean;
};

/**
 * 動画保存先URLから動画を取得し表示する
 * @param {props} Props
 * @returns {React.ReactElement}
 */

export const VideoFunc = (props: Props): React.ReactElement => {
  const videoRef = React.useRef(null);
  const { src, autoplay } = props;

  // オプション設定
  const options = {
    autoplay,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: src ?? '',
        type: 'video/mp4',
      },
    ],
  };

  const VideoHtml = () => (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered">
        <track kind="captions" />
      </video>
    </div>
  );

  React.useEffect(() => {
    const videoElement = videoRef.current;
    let player: VideoJsPlayer;
    if (videoElement) {
      player = videojs(videoElement, options, () => {
        // TODO: player is ready に伴う動作など
      });
    }
    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [options]);

  return <VideoHtml />;
};

// 任意パラメータの初期値
VideoFunc.defaultProps = {
  autoplay: false,
};

export default VideoFunc;

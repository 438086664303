import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  addLifePlanningInfo,
  fetchLifePlanningInfo,
  LifePlanningInfo,
} from '../../functions/userFunctions';
import { calcRetirementFund } from '../../functions/logics/lifePlanningLogics';

export const ageOptions = [...Array(43)].map(
  (_: undefined, index: number) => index + 18
);
export const maritalStatuses = ['あり', 'なし'] as const;
export const livingExpensesOptions = [
  '一般的な暮らし',
  'ゆとりある暮らし',
  '任意',
] as const;
export const housingCostsOptions = [
  '0万円/月',
  '10万円/月',
  '20万円/月',
  '任意',
] as const;

type State = {
  // 仕事に関する情報
  age: number | undefined;
  maritalStatus: typeof maritalStatuses[number] | undefined;
  annualIncome: number | undefined;
  startWorkingAge: number;
  retirementAge: number | undefined;
  retirementPay: number | undefined;
  ageOfSpouse: number | undefined;
  annualIncomeOfSpouse: number | undefined;
  startWorkingAgeOfSpouse: number;
  retirementAgeOfSpouse: number | undefined;
  retirementPayOfSpouse: number | undefined;

  // 老後に関する情報
  livingExpensesOption: typeof livingExpensesOptions[number] | undefined;
  livingExpenses: number | undefined;
  housingCostsOption: typeof housingCostsOptions[number] | undefined;
  housingCosts: number | undefined;
  otherExpenses: number | undefined;
  savings: number | undefined;

  // 出力内容
  futureSavingsList: { name: number; value: number }[]; // 60歳以降の貯蓄額推移
  retirementFund: number | undefined; // 老後に必要な資金
  reserveAmount: number | undefined; // 毎月の積立額
  reserveAmount3percent: number | undefined; // 毎月の積立額(3%で投資した場合)

  // 制御系
  isNextButtonOnUserFormEnabled: boolean; // ユーザーに関するフォームの「次へ」ボタン
  isNextButtonOnOldAgeFormEnabled: boolean; // 老後に関するフォームの「次へ」ボタン
  isAgeValid: boolean; // 就職年齢 < 退職年齢を満たしているか。
  isAgeOfSpouseValid: boolean; // 就職年齢 < 退職年齢を満たしているか。(配偶者)
};

const initialState: State = {
  age: undefined,
  maritalStatus: undefined,
  annualIncome: undefined,
  startWorkingAge: 22,
  retirementAge: undefined,
  retirementPay: undefined,
  ageOfSpouse: undefined,
  annualIncomeOfSpouse: undefined,
  startWorkingAgeOfSpouse: 22,
  retirementAgeOfSpouse: undefined,
  retirementPayOfSpouse: undefined,
  livingExpensesOption: undefined,
  livingExpenses: undefined,
  housingCostsOption: undefined,
  housingCosts: undefined,
  otherExpenses: undefined,
  savings: undefined,
  futureSavingsList: Array(41)
    .fill(0)
    .map((_, index) => ({
      name: index + 60,
      value: 0,
    })),
  retirementFund: undefined,
  reserveAmount: undefined,
  reserveAmount3percent: undefined,
  isNextButtonOnUserFormEnabled: false,
  isNextButtonOnOldAgeFormEnabled: false,
  isAgeValid: true,
  isAgeOfSpouseValid: true,
};

/**
 * userドキュメントにライフプランニング診断の結果を保存する。
 */
export const fetchLatestResult = createAsyncThunk(
  'lifePlanning/fetchLatestResult',
  async (userID?: string): Promise<LifePlanningInfo | undefined> =>
    fetchLifePlanningInfo(userID)
);

/**
 * userドキュメントにライフプランニング診断の結果を保存する。
 */
export const sendResult = createAsyncThunk<
  void,
  undefined,
  {
    state: { lifePlanning: State };
  }
>('lifePlanning/sendResult', async (_, { getState }) => {
  const state = getState().lifePlanning;
  await addLifePlanningInfo({
    age: state.age!,
    annualIncome: state.annualIncome!,
    startWorkingAge: state.startWorkingAge!,
    retirementAge: state.retirementAge!,
    retirementPay: state.retirementPay!,
    spouse:
      state.maritalStatus === 'あり'
        ? {
            age: state.ageOfSpouse!,
            annualIncome: state.annualIncomeOfSpouse!,
            startWorkingAge: state.startWorkingAgeOfSpouse!,
            retirementAge: state.retirementAgeOfSpouse!,
            retirementPay: state.retirementPayOfSpouse!,
          }
        : undefined,
    livingExpenses: state.livingExpenses!,
    housingCosts: state.housingCosts!,
    otherExpenses: state.otherExpenses!,
    savings: state.savings!,
    retirementFund: state.retirementFund!,
    reserveAmount: state.reserveAmount!,
    reserveAmount3percent: state.reserveAmount3percent!,
  });
});

function isUserFormFilled(state: State): boolean {
  return (
    state.age !== undefined &&
    state.annualIncome !== undefined &&
    state.retirementAge !== undefined &&
    state.retirementPay !== undefined &&
    state.isAgeValid &&
    (state.maritalStatus === 'なし' ||
      (state.maritalStatus === 'あり' &&
        state.ageOfSpouse !== undefined &&
        state.annualIncomeOfSpouse !== undefined &&
        state.retirementAgeOfSpouse !== undefined &&
        state.retirementPayOfSpouse !== undefined &&
        state.isAgeOfSpouseValid))
  );
}

function isOldAgeFormFilled(state: State): boolean {
  return (
    (state.livingExpensesOption === '一般的な暮らし' ||
      state.livingExpensesOption === 'ゆとりある暮らし' ||
      (state.livingExpensesOption === '任意' &&
        state.livingExpenses !== undefined)) &&
    (state.housingCostsOption === '0万円/月' ||
      state.housingCostsOption === '10万円/月' ||
      state.housingCostsOption === '20万円/月' ||
      (state.housingCostsOption === '任意' &&
        state.housingCosts !== undefined)) &&
    state.otherExpenses !== undefined &&
    state.savings !== undefined
  );
}

export const slice = createSlice({
  name: 'lifePlanning',
  initialState,
  reducers: {
    init: (): State => initialState,
    setAge: (
      state: State,
      action: PayloadAction<{ age: number | null; isSpouse: boolean }>
    ): State => {
      const newState = {
        ...state,
        age: !action.payload.isSpouse
          ? action.payload.age ?? undefined
          : state.age,
        ageOfSpouse: action.payload.isSpouse
          ? action.payload.age ?? undefined
          : state.ageOfSpouse,
      };
      newState.isNextButtonOnUserFormEnabled = isUserFormFilled(newState);
      return newState;
    },
    setMaritalStatus: (
      state: State,
      action: PayloadAction<typeof maritalStatuses[number] | null>
    ): State => {
      const newState = {
        ...state,
        maritalStatus: action.payload ?? undefined,
      };
      newState.isNextButtonOnUserFormEnabled = isUserFormFilled(newState);
      return newState;
    },
    setAnnualIncome: (
      state: State,
      action: PayloadAction<{ annualIncome: number | null; isSpouse: boolean }>
    ): State => {
      const newState = {
        ...state,
        annualIncome: !action.payload.isSpouse
          ? action.payload.annualIncome ?? undefined
          : state.annualIncome,
        annualIncomeOfSpouse: action.payload.isSpouse
          ? action.payload.annualIncome ?? undefined
          : state.annualIncomeOfSpouse,
      };
      newState.isNextButtonOnUserFormEnabled = isUserFormFilled(newState);
      return newState;
    },
    setStartWorkingAge: (
      state: State,
      action: PayloadAction<{ startWorkingAge: number; isSpouse: boolean }>
    ): State => {
      const newState = {
        ...state,
        startWorkingAge: !action.payload.isSpouse
          ? action.payload.startWorkingAge
          : state.startWorkingAge,
        startWorkingAgeOfSpouse: action.payload.isSpouse
          ? action.payload.startWorkingAge
          : state.startWorkingAgeOfSpouse,
      };
      if (action.payload.isSpouse) {
        newState.isAgeOfSpouseValid =
          action.payload.startWorkingAge < (state.retirementAgeOfSpouse ?? 100);
      } else {
        newState.isAgeValid =
          action.payload.startWorkingAge < (state.retirementAge ?? 100);
      }
      newState.isNextButtonOnUserFormEnabled = isUserFormFilled(newState);
      return newState;
    },
    setRetirementAge: (
      state: State,
      action: PayloadAction<{ retirementAge: number | null; isSpouse: boolean }>
    ): State => {
      const newState = {
        ...state,
        retirementAge: !action.payload.isSpouse
          ? action.payload.retirementAge ?? undefined
          : state.retirementAge,
        retirementAgeOfSpouse: action.payload.isSpouse
          ? action.payload.retirementAge ?? undefined
          : state.retirementAgeOfSpouse,
      };
      if (action.payload.isSpouse) {
        newState.isAgeOfSpouseValid =
          (action.payload.retirementAge ?? 100) >
          (state.startWorkingAgeOfSpouse ?? 0);
      } else {
        newState.isAgeValid =
          (action.payload.retirementAge ?? 100) > (state.startWorkingAge ?? 0);
      }
      newState.isNextButtonOnUserFormEnabled = isUserFormFilled(newState);
      return newState;
    },
    setRetirementPay: (
      state: State,
      action: PayloadAction<{ retirementPay: number | null; isSpouse: boolean }>
    ): State => {
      const newState = {
        ...state,
        retirementPay: !action.payload.isSpouse
          ? action.payload.retirementPay ?? undefined
          : state.retirementPay,
        retirementPayOfSpouse: action.payload.isSpouse
          ? action.payload.retirementPay ?? undefined
          : state.retirementPayOfSpouse,
      };
      newState.isNextButtonOnUserFormEnabled = isUserFormFilled(newState);
      return newState;
    },
    setLivingExpensesOption: (
      state: State,
      action: PayloadAction<typeof livingExpensesOptions[number] | null>
    ): State => {
      let livingExpenses;
      switch (action.payload) {
        case '一般的な暮らし':
          livingExpenses = 28;
          break;
        case 'ゆとりある暮らし':
          livingExpenses = 35;
          break;
        default:
          livingExpenses = undefined;
          break;
      }
      if (state.maritalStatus === 'なし' && livingExpenses) {
        livingExpenses /= 2;
      }

      const newState = {
        ...state,
        livingExpensesOption: action.payload ?? undefined,
        livingExpenses,
      };
      newState.isNextButtonOnOldAgeFormEnabled = isOldAgeFormFilled(newState);
      return newState;
    },
    setLivingExpenses: (
      state: State,
      action: PayloadAction<number | null>
    ): State => {
      const newState = {
        ...state,
        livingExpenses: action.payload ?? undefined,
      };
      newState.isNextButtonOnOldAgeFormEnabled = isOldAgeFormFilled(newState);
      return newState;
    },
    setHousingCostsOption: (
      state: State,
      action: PayloadAction<typeof housingCostsOptions[number] | null>
    ): State => {
      let housingCosts;
      switch (action.payload) {
        case '0万円/月':
          housingCosts = 0;
          break;
        case '10万円/月':
          housingCosts = 10;
          break;
        case '20万円/月':
          housingCosts = 20;
          break;
        default:
          housingCosts = undefined;
          break;
      }

      const newState = {
        ...state,
        housingCostsOption: action.payload ?? undefined,
        housingCosts,
      };
      newState.isNextButtonOnOldAgeFormEnabled = isOldAgeFormFilled(newState);
      return newState;
    },
    setHousingCosts: (
      state: State,
      action: PayloadAction<number | null>
    ): State => {
      const newState = {
        ...state,
        housingCosts: action.payload ?? undefined,
      };
      newState.isNextButtonOnOldAgeFormEnabled = isOldAgeFormFilled(newState);
      return newState;
    },
    setOtherExpenses: (
      state: State,
      action: PayloadAction<number | null>
    ): State => {
      const newState = {
        ...state,
        otherExpenses: action.payload ?? undefined,
      };
      newState.isNextButtonOnOldAgeFormEnabled = isOldAgeFormFilled(newState);
      return newState;
    },
    setSavings: (state: State, action: PayloadAction<number | null>): State => {
      const newState = {
        ...state,
        savings: action.payload ?? undefined,
      };
      newState.isNextButtonOnOldAgeFormEnabled = isOldAgeFormFilled(newState);
      return newState;
    },
    calcResult: (state: State): State => {
      if (
        state.livingExpenses !== undefined &&
        state.housingCosts !== undefined &&
        state.otherExpenses !== undefined &&
        state.age !== undefined &&
        state.annualIncome !== undefined &&
        state.retirementAge !== undefined &&
        state.retirementPay !== undefined &&
        state.savings !== undefined
      ) {
        let spouse;
        if (
          state.maritalStatus === 'あり' &&
          state.ageOfSpouse !== undefined &&
          state.annualIncomeOfSpouse !== undefined &&
          state.retirementAgeOfSpouse !== undefined &&
          state.retirementPayOfSpouse !== undefined
        ) {
          spouse = {
            age: state.ageOfSpouse,
            annualIncome: state.annualIncomeOfSpouse,
            startWorkingAge: state.startWorkingAgeOfSpouse,
            retirementAge: state.retirementAgeOfSpouse,
            retirementPay: state.retirementPayOfSpouse,
          };
        }

        const retirementFund = calcRetirementFund(
          state.livingExpenses,
          state.housingCosts,
          state.otherExpenses,
          state.age,
          state.annualIncome,
          state.startWorkingAge,
          state.retirementAge,
          state.retirementPay,
          spouse
        );

        const reserveAmount =
          retirementFund - state.savings > 0
            ? (retirementFund - state.savings) / (60 - state.age) / 12
            : 0;
        return {
          ...state,
          futureSavingsList: state.futureSavingsList.map((item, index) => ({
            name: item.name,
            value:
              retirementFund -
              ((state.livingExpenses ?? 0) +
                (state.housingCosts ?? 0) +
                (state.otherExpenses ?? 0)) *
                index,
          })),
          retirementFund,
          reserveAmount,
          reserveAmount3percent:
            (reserveAmount * (60 - state.age)) /
            ((1.03 ** (60 - state.age + 1) - 1.03) / 0.03), // 複利の効果を計算し、利回り0%の場合との比率を計算。
        };
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    /* getFpList */
    builder.addCase(fetchLatestResult.fulfilled, (state, action): State => {
      const lifePlanningInfo = action.payload;
      if (lifePlanningInfo === undefined) {
        return state;
      }
      return {
        ...state,
        age: lifePlanningInfo.age,
        annualIncome: lifePlanningInfo.annualIncome,
        startWorkingAge: lifePlanningInfo.startWorkingAge,
        retirementAge: lifePlanningInfo.retirementAge,
        retirementPay: lifePlanningInfo.retirementPay,
        maritalStatus: lifePlanningInfo.spouse !== undefined ? 'あり' : 'なし',
        ageOfSpouse: lifePlanningInfo.spouse?.age,
        annualIncomeOfSpouse: lifePlanningInfo.spouse?.annualIncome,
        startWorkingAgeOfSpouse: lifePlanningInfo.spouse?.startWorkingAge ?? 22,
        retirementAgeOfSpouse: lifePlanningInfo.spouse?.retirementAge,
        retirementPayOfSpouse: lifePlanningInfo.spouse?.retirementPay,
        livingExpensesOption: '任意',
        livingExpenses: lifePlanningInfo.livingExpenses,
        housingCostsOption: '任意',
        housingCosts: lifePlanningInfo.housingCosts,
        otherExpenses: lifePlanningInfo.otherExpenses,
        savings: lifePlanningInfo.savings,
        futureSavingsList: state.futureSavingsList.map((item, index) => ({
          name: item.name,
          value:
            lifePlanningInfo.retirementFund -
            (lifePlanningInfo.livingExpenses +
              lifePlanningInfo.housingCosts +
              lifePlanningInfo.otherExpenses) *
              index,
        })),
        retirementFund: lifePlanningInfo.retirementFund,
        reserveAmount: lifePlanningInfo.reserveAmount,
        reserveAmount3percent: lifePlanningInfo.reserveAmount3percent,
      };
    });
  },
});

export default slice.reducer;
export const {
  init,
  setAge,
  setMaritalStatus,
  setAnnualIncome,
  setStartWorkingAge,
  setRetirementAge,
  setRetirementPay,
  setLivingExpensesOption,
  setLivingExpenses,
  setHousingCostsOption,
  setHousingCosts,
  setOtherExpenses,
  setSavings,
  calcResult,
} = slice.actions;

import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../../resources/colors';

const individualFpScreenStyles = makeStyles((theme) => ({
  rootContainer: {
    // marginBottom: theme.spacing(2),
    backgroundColor: colors.white,
  },
  root: {
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },

  /* header */
  backBtn: {
    backgroundColor: colors.white,
    color: colors.lightBlue,
    borderColor: colors.lightBlue,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: '112px',
    height: '28px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'flex-start',
    zIndex: 1,
  },

  /* main */
  mainRoot: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap', // コンポーネントの改行を許可する
  },
  storageImage: {
    maxHeight: 'auto',
    minWidth: '350px',
  },
  storageVideo: {
    maxHeight: 'auto',
    minWidth: '350px',
  },
  username: {
    textAlign: 'left',
    marginLeft: theme.spacing(1),
    fontSize: '20px',
    fontWeight: 700,
  },
  fpCard: {
    minWidth: '350px',
    paddingBottom: theme.spacing(2),
  },
  userCard: {
    minWidth: '350px',
    paddingBottom: theme.spacing(2),
  },
  board: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    whiteSpace: 'pre-line', // 改行を許可
  },
  text: {
    textAlign: 'left',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontSize: '14px',
    whiteSpace: 'pre-line', // 改行を許可
  },

  /* footer btn */
  btnRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  mtgLink: {
    verticalAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chatBtn: {
    backgroundColor: colors.lightBlue,
    color: colors.white,
    fontSize: '14px',
    width: '150px',
    height: '32px',
  },

  backdrop: {
    opacity: 0.5,
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default individualFpScreenStyles;

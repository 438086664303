import { Box } from '@mui/material';
import { ReactNode } from 'react';

import { Icon } from '../components/Icon';
import { ClientUser } from '../modules/entities/ClientUser';

const Row = ({ children }: { children: ReactNode }) => (
  <Box sx={{ display: 'inline-flex', gap: '4px', alignItems: 'center' }}>
    {children}
  </Box>
);

export const EmotionText = ({
  anxietyLevel,
}: {
  anxietyLevel: ClientUser['anxietyLevel'];
}) => {
  switch (anxietyLevel) {
    case 0:
      return (
        <Row>
          <Icon iconKey="emotionError" /> 結果説明希望
        </Row>
      );
    case 1:
      return (
        <Row>
          <Icon iconKey="emotionWarning" />
          不安あり
        </Row>
      );
    case 2:
      return (
        <Row>
          <Icon iconKey="emotionNormal" />
          分からなかった
        </Row>
      );
    case 3:
      return (
        <Row>
          <Icon iconKey="emotionNormal" />
          不安なし
        </Row>
      );
    default:
      return <>-</>;
  }
};

const constants = {
  thunkRequestStatus: {
    fulfilled: 'fulfilled',
    pending: 'pending',
    rejected: 'rejected',
  },
};

export const DB_PATH = {
  USERS: 'users',
  FPS: 'fps',
  CHAT_ROOMS: 'chatRooms',
  MESSAGES: 'messages',
  INTERVIEW_DATES: 'interviewDates',
  DIAGNOSES: 'diagnoses',
  DIAGNOSES_DATA: 'data',
};

export default constants;

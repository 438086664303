import { Box, Typography, Grid } from '@mui/material';

import colors from '../../../../../resources/colors';

type Props = {
  result: number;
};

/**
 * 診断機能(スマホ)の1行分のメッセージボックス(Robotアイコン含む)のコンポーネント
 */
function ResultBox(props: Props) {
  const { result } = props;

  return (
    <Box
      padding={1}
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: colors.paleGray,
        borderRadius: 2,
        textAlign: 'center',
      }}>
      <Grid container>
        <Grid xs={0.15} />
        <Grid xs={9}>
          <Typography
            fontSize={14}
            fontWeight="fontWeightMedium"
            color={colors.mainBlue}
            whiteSpace="pre-line">
            老後までに貯めるべきお金は
          </Typography>
        </Grid>
        <Grid xs={2.85} />
      </Grid>
      <Grid container>
        <Grid xs={3} />
        <Grid
          item
          xs={6}
          sx={{
            borderBottomColor: colors.mainBlue,
            borderBottom: 'solid',
            borderBottomWidth: 'thin',
            textAlign: 'center',
          }}>
          <Typography
            fontSize={24}
            fontWeight={600}
            color={colors.mainBlue}
            whiteSpace="pre-line">
            {result}万円
          </Typography>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </Box>
  );
}
export default ResultBox;

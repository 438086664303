import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Backdrop, Grid } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';

import useStyles from './SignInWithLineAuthScreenStyle';
import { AppDispatch } from '../../../modules/store';
import {
  getNickName,
  signInWithLine,
} from '../../../modules/store/slices/userSlice';

const SignInWithLineAuthScreen: React.FC = () => {
  const classes = useStyles();

  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 766px)' });

  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    dispatch(signInWithLine())
      .then(unwrapResult)
      .then(() => {
        history.push({ pathname: '/other-provider-signup' });
        // NickNameの取得
        dispatch(getNickName());
      })
      .catch(() => {
        // ログイン失敗時の処理
        history.push({ pathname: '/other-provider-signup' });
      });
  }, []);

  return (
    <div>
      {/* ---------- web画面用表示領域 (min-width: 767px) ---------- */}
      {isDesktop && (
        <Grid className={classes.root}>
          {/* インジケーター */}
          <Backdrop className={classes.backdrop} open>
            <CircularProgress />
          </Backdrop>
        </Grid>
      )}
      {/* ---------- スマホ画面用表示領域 (max-width: 766px) ---------- */}
      {isMobile && (
        <Grid className={classes.root}>
          {/* インジケーター */}
          <Backdrop className={classes.backdrop} open>
            <CircularProgress />
          </Backdrop>
        </Grid>
      )}
    </div>
  );
};

export default SignInWithLineAuthScreen;

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  Card,
  Button,
  CircularProgress,
  Backdrop,
  Typography,
  Grid,
} from '@material-ui/core';
import { Box } from '@mui/material';

import useStyles from './NotificationSetteingScreenStyles';
import { AppDispatch, RootState } from '../../../modules/store';
import {
  getFpInfo,
  updateFpInfo,
} from '../../../modules/store/slices/matchingSlice';

import Model from '../../../components/Modal';

const NotificationSetteingScreen: FC = () => {
  const { uid } = useSelector((state: RootState) => state.user);
  const dispatch: AppDispatch = useDispatch();
  const classes = useStyles();

  const [switchOnModalOpen, setSwitchOnModalOpen] = React.useState(false);
  const [switchOffModalOpen, setSwitchOffModalOpen] = React.useState(false);

  const [notificationFlag, setNotificationFlag] = useState(false);
  const [isError, setIsError] = useState(false);

  const { isLoading } = useSelector((state: RootState) => state.matching);

  // useEffect
  useEffect(() => {
    // リロード直後はuidが空文字の状態で呼び出されるためreturnする。
    if (uid === '') {
      return;
    }

    dispatch(getFpInfo({ uid }))
      .then(unwrapResult)
      .then((fpInfo) => {
        console.log('fpInfo:', fpInfo);

        setNotificationFlag(fpInfo.receiveNotificationFlag);
      })
      .catch(() => {
        setIsError(true);
      });
  }, [uid]);

  const onClickSwitchNotificationOnBtn = () => {
    setSwitchOnModalOpen(true);
  };

  const onClickSwitchNotificationOffBtn = () => {
    setSwitchOffModalOpen(true);
  };

  const onClickCloseSwitchOnModalBtn = () => {
    setNotificationFlag(true);

    dispatch(
      updateFpInfo({
        fpID: uid,
        fpInfo: { receiveNotificationFlag: true },
      })
    )
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setSwitchOnModalOpen(false);
      });
  };

  const onClickCloseSwitchOffModalBtn = () => {
    setNotificationFlag(false);

    dispatch(
      updateFpInfo({
        fpID: uid,
        fpInfo: { receiveNotificationFlag: false },
      })
    )
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setSwitchOffModalOpen(false);
      });
  };

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.cardRoot}>
          <Typography className={classes.title}>メール通知設定</Typography>

          <Card className={classes.textCardRoot}>
            <Typography className={classes.pictureTypography}>
              クライアントが初めて老後診断をした際、{'\n'}
              メールにて通知するかを設定します。
            </Typography>
          </Card>

          {notificationFlag ? (
            <Grid>
              <Card className={classes.textCardRoot}>
                <Typography className={classes.isDisplayedCheckText}>
                  メール通知設定 :
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className={classes.displayedText}>
                      ON
                    </Typography>
                  </Box>
                </Typography>
              </Card>
              <Card className={classes.textCardRoot}>
                <Button
                  className={classes.switchDisplayBtn}
                  onClick={onClickSwitchNotificationOffBtn}>
                  OFFにする
                </Button>
              </Card>
            </Grid>
          ) : (
            <Grid>
              <Card className={classes.textCardRoot}>
                <Typography className={classes.isDisplayedCheckText}>
                  メール通知設定 :
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className={classes.nonDisplayedText}>
                      OFF
                    </Typography>
                  </Box>
                </Typography>
              </Card>
              <Card className={classes.textCardRoot}>
                <Button
                  className={classes.switchDisplayBtn}
                  onClick={onClickSwitchNotificationOnBtn}>
                  ONにする
                </Button>
              </Card>
            </Grid>
          )}

          {/* modal */}
          <Model
            isOpen={switchOnModalOpen}
            title="通知をONにする"
            body={`ユーザーが初めて老後診断を完了した際\nメール通知が受け取れるようになります。`}
            buttonName="ONにする"
            onClickCloseModalBtn={onClickCloseSwitchOnModalBtn}
          />

          <Model
            isOpen={switchOffModalOpen}
            title="通知をOFFにする"
            body={`ユーザーが初めて老後診断を完了した際\nメール通知が受け取れなくなります。`}
            buttonName="OFFにする"
            onClickCloseModalBtn={onClickCloseSwitchOffModalBtn}
          />

          {/* インジケーター */}
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress />
          </Backdrop>
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationSetteingScreen;

import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../../resources/colors';

export const matchingFpListScreenStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.lightGray,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  container: {
    alignItems: 'flex-start',
  },
  header: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
  },
  subTitle: {
    flexDirection: 'row',
    display: 'flex',
    fontSize: '24px',
    fontWeight: 500,
    textAlign: 'left',
  },
  infoText: {
    marginTop: theme.spacing(1),
  },
  divider: {
    backgroundColor: colors.darkGray,
  },
  text: {
    textAlign: 'left',
    fontSize: '14px',
    whiteSpace: 'pre-line', // 改行を許可
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },

  /* carousel */
  cards: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}));

export const matchingFpListScreenCardStyles = makeStyles((theme) => ({
  root: {
    width: '360px',
    height: '120px',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    margin: theme.spacing(1, 1, 1, 1),
  },
  header: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  username: {
    fontSize: '16px',
    fontWeight: 800,
    color: colors.darkGray,
  },
  image: {
    height: '80px',
    width: '80px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  contents: {
    align: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  text: {
    textAlign: 'left',
    fontSize: '14px',
    color: colors.darkGray,
    whiteSpace: 'pre-line', // 改行を許可
  },
}));

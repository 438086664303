import React from 'react';
import {
  Grid,
  Typography,
  Card,
  CardMedia,
  ButtonBase,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { matchingFpListScreenCardStyles } from './SpMatchingFpListScreenStyle';
import { ClientUser } from '../../../../modules/entities/ClientUser';
import { Fp } from '../../../../modules/functions/matchingFunctions';
import { AppDispatch } from '../../../../modules/store';
import { setFpInfo } from '../../../../modules/store/slices/matchingSlice';

type Props = {
  info: Fp | ClientUser;
};

const SpMatchingFpListScreenCard: React.FC<Props> = (props: Props) => {
  // state
  const classes = matchingFpListScreenCardStyles();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { info } = props;
  const fpInfo = info as Fp;

  // functions
  const onClickCard = () => {
    dispatch(setFpInfo({ fpInfo }));
    window.scrollTo(0, 0);

    history.push({
      pathname: `/matching/individual-fp/user/${info.id}`,
    });
  };

  const FpInfo = () => (
    <Grid className={classes.contents}>
      <Typography className={classes.username}>
        {info.nickName.length < 15
          ? info.nickName
          : info.nickName.substr(0, 15).concat('..')}
      </Typography>
      {fpInfo.belongs ? (
        <Typography className={classes.text}>
          {`所属 : ${fpInfo.belongs}`}
        </Typography>
      ) : (
        <Typography className={classes.text}>所属 : 未設定</Typography>
      )}
      {fpInfo.specialtyArray[0] ? (
        <Typography className={classes.text}>
          得意領域 : {'\n'}
          {`${fpInfo.specialtyArray[0]}`}
        </Typography>
      ) : (
        <Typography className={classes.text}>得意領域 : 未設定</Typography>
      )}
    </Grid>
  );

  return (
    <Grid>
      {info.nickName ? (
        <ButtonBase onClick={onClickCard}>
          <Card className={classes.root}>
            <Grid className={classes.header}>
              <CardMedia
                component="img"
                image={info.imageUrl}
                className={classes.image}
              />
              {/* ユーザー or FP 一覧 */}
              <FpInfo />
            </Grid>
          </Card>
        </ButtonBase>
      ) : null}
    </Grid>
  );
};

export default SpMatchingFpListScreenCard;

import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../resources/colors';

const ProfileStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.lightGray,
  },
  cardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '800px',
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  spCardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '375px',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  /* header */
  title: {
    fontSize: '40px',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  spTitle: {
    fontSize: '30px',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },

  /* form */
  textCardRoot: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    flexDirection: 'column',
    display: 'flex',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: '480px',
  },
  spTextCardRoot: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    flexDirection: 'column',
    display: 'flex',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: '360px',
  },
  pictureTextField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
  },
  pictureTypography: {
    display: 'flex',
  },
  isDisplayedCheckText: {
    marginTop: theme.spacing(4),
    display: 'flex',
  },
  displayedText: {
    marginLeft: theme.spacing(0),
    color: 'red',
  },
  switchDisplayBtn: {
    backgroundColor: colors.lightBlue,
    marginLeft: theme.spacing(2),
    color: 'white',
    fontSize: 16,
    height: 22,
  },
  spSwitchDisplayBtn: {
    backgroundColor: colors.lightBlue,
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: 16,
    height: 22,
  },
  nonDisplayedText: {
    marginLeft: theme.spacing(0),
  },
  UpdateNickNameButton: {
    backgroundColor: colors.lightBlue,
    color: colors.white,
  },
  disableButton: {
    backgroundColor: colors.lightGray,
  },

  /* インジケーター */
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default ProfileStyles;

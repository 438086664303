import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../resources/colors';

export const RecommendFpScreenStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.lightGray,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  header: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  leftDivider: {
    backgroundColor: colors.gray,
    width: '20%',
    height: '2px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
  },
  rightDivider: {
    backgroundColor: colors.gray,
    width: '20%',
    height: '2px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },

  /* carousel */
  cards: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}));

export const RecommendFpScreenCardStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: colors.white,
    maxWidth: '900px',
    margin: 'auto',
  },
  root: {
    alignItems: 'center',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  divider: {
    backgroundColor: colors.facebookColor,
    height: '5px',
  },

  /* main */
  mainRoot: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    gap: '40px',
  },
  storageImage: {
    maxHeight: 'auto',
    minWidth: '300px',
  },
  board: {
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    whiteSpace: 'pre-line', // 改行を許可
  },
  content: {
    textAlign: 'left',
    fontSize: '18px',
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  /* footer btn */
  btnRoot: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  chatBtn: {
    marginTop: theme.spacing(2),
    backgroundColor: colors.lightBlue,
    color: colors.white,
    fontSize: '18px',
    width: '1200px',
  },
  fpImage: {
    marginTop: theme.spacing(2),
    height: '160px',
    width: '130px',
  },
  fpBelongsIcon: {
    height: '50px',
    width: '200px',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  nickname: {
    color: colors.nameColor,
    display: 'flex',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    fontSize: 28,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  itemTitleBox: {
    fontWeight: 'bold',
    fontSize: '17px',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  ratingStarBox: {
    fontWeight: 'bold',
    fontSize: '17px',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

import React from 'react';
import {
  Grid,
  Typography,
  Divider,
  CardMedia,
  Button,
  Box,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ClientUser } from '../../../../modules/entities/ClientUser';
import { Fp } from '../../../../modules/functions/matchingFunctions';
import { getUserUid } from '../../../../modules/functions/userFunctions';
import { AppDispatch } from '../../../../modules/store';
import { setFpInfo } from '../../../../modules/store/slices/matchingSlice';

import { fetchChatRoomID } from '../../../../modules/store/slices/messageSlice';

// component
import Rating from '../../../../components/Rating';

// styles
import { RecommendFpScreenCardStyles } from '../RecommendFpScreenStyle';

// image
import monedoc from '../../../../resources/image/monedoc.png';

type Props = {
  info: Fp | ClientUser;
};

const RecommendFpScreenCard: React.FC<Props> = (props: Props) => {
  // state
  const classes = RecommendFpScreenCardStyles();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { info } = props;
  const fpInfo = info as Fp;
  const userId = getUserUid();

  // functions
  const onClickCard = () => {
    dispatch(setFpInfo({ fpInfo }));
    window.scrollTo(0, 0);
    history.push({
      pathname: `/matching/individual-fp/user/${info.id}`,
    });
  };

  const onClickChatBtn = async () => {
    dispatch(setFpInfo({ fpInfo }));
    const chatRoomID = await fetchChatRoomID(userId, info.id);
    history.push({
      pathname: `/matching/chatRooms/${chatRoomID}`,
    });
    window.scrollTo(0, 0);
  };
  return (
    <>
      {/* 左余白 */}
      <Grid container spacing={3}>
        <Grid item xs={1} />
        {/* 情報表示部 */}
        <Grid item xs={10} className={classes.rootContainer}>
          <Grid className={classes.root}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: '2px',
              }}>
              <Button
                className={classes.btnRoot}
                variant="outlined"
                onClick={onClickCard}>
                このFPの詳細を見る
              </Button>
            </Box>
            <Divider className={classes.divider} />
            {/* main */}
            <Grid item xs>
              <Grid>
                <Box
                  display="grid"
                  style={{ gridTemplateColumns: '40% 60%' }}
                  className={classes.mainRoot}>
                  {/* Left Side */}
                  {
                    <Grid>
                      <Box
                        display="grid"
                        style={{ gridTemplateColumns: '40% 60%' }}>
                        {/* Left Side */}
                        <Grid>
                          <CardMedia
                            component="img"
                            image={fpInfo.imageUrl}
                            className={classes.fpImage}
                          />
                        </Grid>

                        {/* Right Side */}
                        <Grid>
                          <Box className={classes.nickname}>
                            {fpInfo.nickName.length < 15
                              ? fpInfo.nickName
                              : fpInfo.nickName.substr(0, 15).concat('..')}
                          </Box>
                          {fpInfo.belongs === '株式会社ＦＰパートナー' ? (
                            <CardMedia
                              component="img"
                              image={monedoc}
                              className={classes.fpBelongsIcon}
                            />
                          ) : (
                            ''
                          )}
                          <Box className={classes.itemTitleBox}>
                            {' '}
                            ＦＰ歴：
                            <Box width="10px" />
                            {fpInfo.fpHistory ? (
                              <Typography>{fpInfo.fpHistory}年</Typography>
                            ) : (
                              <Typography>ー</Typography>
                            )}
                          </Box>

                          <Box className={classes.itemTitleBox}>
                            {' '}
                            年齢：
                            <Box width="10px" />
                            {fpInfo.age ? (
                              <Typography>{fpInfo.age}歳</Typography>
                            ) : (
                              <Typography>ー</Typography>
                            )}
                            {/* <Typography>/</Typography>
                            {fpInfo.sex ? (
                              <Typography>{fpInfo.sex}</Typography>
                            ) : (
                              <Typography>ー</Typography>
                            )} */}
                          </Box>
                        </Grid>
                      </Box>
                      <Box height="5px" />
                      {/* Next box */}
                      <Box className={classes.itemTitleBox}>
                        {' '}
                        総合評価：
                        {fpInfo.ratingMap?.totalRating ? (
                          <Grid>
                            <Rating
                              rating={fpInfo.ratingMap.totalRating}
                              nodata={1}
                            />
                          </Grid>
                        ) : (
                          <Grid>
                            <Rating rating={0} nodata={0} />
                          </Grid>
                        )}
                        {fpInfo.ratingMap?.totalRating ? (
                          <Grid>
                            {fpInfo.ratingMap.totalRating.toFixed(1)}
                            {/* 小数点以下1桁まで表示 */}
                          </Grid>
                        ) : (
                          <Grid>---</Grid>
                        )}
                      </Box>
                    </Grid>
                  }
                  {/* Right Side */}
                  <Grid>
                    <Box className={classes.itemTitleBox}>項目別評価</Box>

                    <Box
                      display="grid"
                      style={{ width: 350, gridTemplateColumns: '50% 50%' }}>
                      {/* Left Side */}
                      <Grid>
                        <Box className={classes.itemTitleBox}>話しやすさ：</Box>
                        <Box className={classes.itemTitleBox}>納得感：</Box>
                        <Box className={classes.itemTitleBox}>
                          わかりやすさ：
                        </Box>
                        <Box className={classes.itemTitleBox}>
                          また相談したいか：
                        </Box>
                      </Grid>

                      {/* Right Side */}
                      <Grid>
                        {/* 話しやすさ */}
                        <Box className={classes.ratingStarBox}>
                          {fpInfo.ratingMap?.affable ? (
                            <Grid>
                              <Rating
                                rating={fpInfo.ratingMap.affable}
                                nodata={1}
                              />
                            </Grid>
                          ) : (
                            <Grid>
                              <Rating rating={0} nodata={0} />
                            </Grid>
                          )}
                          <Box width="10px" />
                          {fpInfo.ratingMap?.affable ? (
                            <Grid>
                              {fpInfo.ratingMap.affable.toFixed(1)}
                              {/* 小数点以下1桁まで表示 */}
                            </Grid>
                          ) : (
                            <Grid>---</Grid>
                          )}
                        </Box>
                        {/* 納得感 */}
                        <Box className={classes.ratingStarBox}>
                          {fpInfo.ratingMap?.satisfactory ? (
                            <Grid>
                              <Rating
                                rating={fpInfo.ratingMap.satisfactory}
                                nodata={1}
                              />
                            </Grid>
                          ) : (
                            <Grid>
                              <Rating rating={0} nodata={0} />
                            </Grid>
                          )}
                          <Box width="10px" />
                          {fpInfo.ratingMap?.satisfactory ? (
                            <Grid>
                              {fpInfo.ratingMap.satisfactory.toFixed(1)}
                              {/* 小数点以下1桁まで表示 */}
                            </Grid>
                          ) : (
                            <Grid>---</Grid>
                          )}
                        </Box>
                        {/* わかりやすさ */}
                        <Box className={classes.ratingStarBox}>
                          {fpInfo.ratingMap?.understandability ? (
                            <Grid>
                              <Rating
                                rating={fpInfo.ratingMap.understandability}
                                nodata={1}
                              />
                            </Grid>
                          ) : (
                            <Grid>
                              <Rating rating={0} nodata={0} />
                            </Grid>
                          )}
                          <Box width="10px" />
                          {fpInfo.ratingMap?.understandability ? (
                            <Grid>
                              {fpInfo.ratingMap.understandability.toFixed(1)}
                              {/* 小数点以下1桁まで表示 */}
                            </Grid>
                          ) : (
                            <Grid>---</Grid>
                          )}
                        </Box>
                        {/* また相談したいか */}
                        <Box className={classes.ratingStarBox}>
                          {fpInfo.ratingMap?.retainable ? (
                            <Grid>
                              <Rating
                                rating={fpInfo.ratingMap.retainable}
                                nodata={1}
                              />
                            </Grid>
                          ) : (
                            <Grid>
                              <Rating rating={0} nodata={0} />
                            </Grid>
                          )}
                          <Box width="10px" />
                          {fpInfo.ratingMap?.retainable ? (
                            <Grid>
                              {fpInfo.ratingMap.retainable.toFixed(1)}
                              {/* 小数点以下1桁まで表示 */}
                            </Grid>
                          ) : (
                            <Grid>---</Grid>
                          )}
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid className={classes.btnRoot}>
              <Button className={classes.chatBtn} onClick={onClickChatBtn}>
                このFPに相談する
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* 右余白 */}
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default RecommendFpScreenCard;

import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Divider,
  Backdrop,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../../modules/store';
import { useStyles } from './SearchFpScreenStyles';
import SearchFpScreenCard from './components/SearchFpScreenCard';
import IntroductionHeader from '../../home/introductionHeader/IntroductionHeader';
import { getFpList } from '../../../modules/store/slices/matchingSlice';
import { getIsFpFromUrl } from '../../../modules/functions/userFunctions';

const SearchFpScreen: React.FC = () => {
  // state
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const isFp = getIsFpFromUrl('user');
  const { isLoading, fpList, userList } = useSelector(
    (state: RootState) => state.matching
  );
  const infoList = isFp ? userList : fpList;

  useEffect(() => {
    dispatch(getFpList());
  }, []);

  // functions
  const UserCarousel = () => (
    <Box display="grid" className={classes.carouselBox}>
      {infoList.map((info) => (
        <SearchFpScreenCard info={info} />
      ))}
    </Box>
  );

  return (
    <>
      <IntroductionHeader screenName="matching" mode="veryShort" />

      <Grid className={classes.root}>
        {/* header */}
        <Grid className={classes.header}>
          <Divider className={classes.leftDivider} />
          <Typography className={classes.title}>
            {isFp
              ? 'クライアント：一覧'
              : '無料相談 ファイナンシャルプランナー(FP)：一覧'}
          </Typography>
          <Divider className={classes.rightDivider} />
        </Grid>
        <UserCarousel />
      </Grid>

      {/* インジケーター */}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default SearchFpScreen;

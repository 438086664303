import React from 'react';
import {
  Grid,
  CardMedia,
  Card,
  Typography,
  Button,
  Link,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// components
import FpInfo from './components/FpInfoComponent';
import UserInfo from './components/UserInfoComponent';
import ConfirmModal from '../../../../components/ConfirmModal';

// others
import individualFpScreenStyle from './SpIndividualFpScreenStyle';
import {
  getUserUid,
  getIsFpFromUrl,
} from '../../../../modules/functions/userFunctions';
import Video from '../../../../components/videoPlayer';
import { AppDispatch, RootState } from '../../../../modules/store';
import {
  getFpInfo,
  getUserInfo,
  matchingUsers,
} from '../../../../modules/store/slices/matchingSlice';

const SpIndividualFpScreen: React.FC = () => {
  // URL に記載された uid を取得する
  const { role, id } = useParams<{ role: 'fp' | 'user'; id: string }>();

  // state
  const classes = individualFpScreenStyle();
  const history = useHistory();
  const isFp = getIsFpFromUrl(role);
  const dispatch: AppDispatch = useDispatch();
  const { fpInfo, userInfo, isLoading } = useSelector(
    (state: RootState) => state.matching
  );
  const uid = getUserUid();
  const fpId = isFp ? uid : id;
  const userId = isFp ? id : uid;
  // チャット開始確認modal
  const [isChatOpen, setIsChatOpen] = React.useState(false);

  const storagePathList = [
    isFp
      ? { type: 'image', url: userInfo.imageUrl }
      : { type: 'video', url: fpInfo.videoUrl },
  ]; // TODO: ユーザーの紹介写真を保存し次第対応

  // fetch（画面再レンダリング時のみ実行される）
  React.useEffect(() => {
    // ユーザー or FP の情報を取得
    if (isFp) {
      if (userInfo.id === '') {
        dispatch(getUserInfo({ uid: userId }));
      }
    } else if (fpInfo.id === '') {
      dispatch(getFpInfo({ uid: fpId }));
    }
  }, []);

  // functions
  const onClickBackBtn = () => {
    history.push({
      pathname: `/matching/fp-list/`,
    });
  };

  const onClickFalseBtn = () => {
    setIsChatOpen(false);
    window.scrollTo(0, 0);
  };
  const onClickTrueBtn = async () => {
    setIsChatOpen(false);
    dispatch(matchingUsers(fpId))
      .then(unwrapResult)
      .then((chatRoomID) => {
        history.push({
          pathname: `/matching/chatRooms/${chatRoomID}`,
        });
      })
      .catch(() => {
        // TODO: エラー処理
      });
  };

  const onClickChatBtn = () => {
    setIsChatOpen(true);
  };

  // コンポーネント
  // TODO: 複数の画像表示方法実装後に外に出す
  const UserCarousel = () => (
    <Carousel
      autoPlay={false} // 自動遷移を許可しない
      navButtonsAlwaysInvisible // 動画の再生ボタンとカルセールNavボタンが重なるためハイド
      indicators={false} // 下部の遷移ボタンのハイド
    >
      {storagePathList.map((storagePath, storagePathIndex) => (
        <Grid key={String(storagePathIndex)}>
          {storagePath.type === 'image' ? (
            <CardMedia
              component="img"
              image={storagePath.url}
              className={classes.storageImage}
            />
          ) : (
            <Grid className={classes.storageVideo}>
              <Video src={storagePath.url} />
            </Grid>
          )}
        </Grid>
      ))}
    </Carousel>
  );

  return (
    <>
      <Grid>
        {/* 情報表示部 */}
        <Grid className={classes.rootContainer}>
          <Grid className={classes.root}>
            {/* header */}
            <Grid>
              <Button
                className={classes.backBtn}
                variant="outlined"
                onClick={onClickBackBtn}>
                {isFp ? '一般ユーザー一覧へ戻る' : 'FP一覧へ戻る'}
              </Button>
              <Button className={classes.chatBtn} onClick={onClickChatBtn}>
                チャットを始める
              </Button>
            </Grid>
            {/* main */}
            <Grid item xs>
              <Grid>
                <Typography className={classes.username}>
                  {isFp ? userInfo.nickName : fpInfo.nickName}
                </Typography>
                <Grid className={classes.mainRoot}>
                  {/* 画像/映像 */}
                  <UserCarousel />
                  {/* 個人情報 */}
                  {isFp ? (
                    <Card className={classes.userCard}>
                      <UserInfo userInfo={userInfo} />
                    </Card>
                  ) : (
                    <Card className={classes.fpCard}>
                      <FpInfo fpInfo={fpInfo} />
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* footer */}
            <Grid className={classes.btnRoot}>
              {fpInfo.mtgLink ? (
                <Grid className={classes.mtgLink}>
                  <Typography className={classes.text}>
                    <Link
                      href={fpInfo.mtgLink}
                      underline="hover"
                      target="_blank">
                      ミーティングを設定する
                    </Link>
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Modal */}
      <ConfirmModal
        isOpen={isChatOpen}
        title=""
        body={`「チャットを開始する」ボタンを押すと\n初回のみチャット開始の連絡が相手に飛びます。`}
        falseButtonName="戻る"
        trueButtonName="チャットを開始する"
        onClickFalseBtn={onClickFalseBtn}
        onClickTrueBtn={onClickTrueBtn}
      />

      {/* インジケーター */}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default SpIndividualFpScreen;

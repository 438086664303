import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../resources/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(12),
  },
  cardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '480px',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    whiteSpace: 'pre-line', // 改行を許可
  },
  spCardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '375px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    whiteSpace: 'pre-line', // 改行を許可
  },
}));
export default useStyles;

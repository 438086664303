import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

// ↓ Selectors利用のため。↓
// eslint-disable-next-line import/no-cycle
import { RootState } from '..';
import { db } from '../../../firebase';
import { DB_PATH } from '../../../resources/constants';
import {
  DiagnosisData,
  diagnosisDataConverter,
} from '../../entities/DiagnosisData';

interface DiagnosisDataEntityState extends EntityState<DiagnosisData> {
  isLoading: boolean;
}

/* Entity Adapter */
const diagnosesAdapter = createEntityAdapter<DiagnosisData>({
  selectId: (diagnosisData) => diagnosisData.uid,
});
const diagnosisDataInitialEntityState: DiagnosisDataEntityState =
  diagnosesAdapter.getInitialState({
    isLoading: false,
  });

/* Create */
/**
 * 新規診断結果をFirestoreに登録。
 * クライアントユーザーのみの利用を想定。
 * @param {DiagnosisData} diagnosisData - DiagnosisDataオブジェクトを組み立てて渡す。
 * @return {uid} ユーザID
 */
export const registerDiagnosisData = createAsyncThunk(
  'diagnosis/registerDiagnosisData',
  async (diagnosisData: DiagnosisData, thunkAPI) => {
    const { uid } = (thunkAPI.getState() as RootState).user;

    await db
      .collection(DB_PATH.DIAGNOSES)
      .doc(uid)
      .collection(DB_PATH.DIAGNOSES_DATA)
      .withConverter(diagnosisDataConverter)
      .add(diagnosisData);

    return uid;
  }
);

/* Update */
/**
 * 診断結果のフィールドに最新の診断結果で更新する。
 * @param {DiagnosisData} diagnosisData - DiagnosisDataオブジェクトを組み立てて渡す。
 * @return {uid} ユーザID
 */
export const updateDiagnosisData = createAsyncThunk(
  'diagnosis/updateDiagnosisData',
  async (diagnosisData: DiagnosisData, thunkAPI) => {
    const { uid } = (thunkAPI.getState() as RootState).user;

    await db
      .collection(DB_PATH.DIAGNOSES)
      .doc(uid)
      .withConverter(diagnosisDataConverter)
      .set(diagnosisData);
  }
);

/**
 * 「診断結果を受けてどう思ったか」の回答を最新の診断結果のフィールドに追加する。
 */
export const updateDiagnosisAnxietyLevel = createAsyncThunk(
  'diagnosis/updateDiagnosisAnxietyLevel',
  async (anxietyLevel: DiagnosisData['anxietyLevel'], thunkAPI) => {
    const { uid } = (thunkAPI.getState() as RootState).user;

    await db
      .collection(DB_PATH.DIAGNOSES)
      .doc(uid)
      .withConverter(diagnosisDataConverter)
      .update({ anxietyLevel });
  }
);

/* Read */
/**
 * Firestoreから最新の診断結果データを取得。
 * uidに指定したユーザーの診断結果を取得する。
 * uidにnullを指定すれば自身の診断結果を取得できる。(FPの場合はエラーとなる。)
 * @param {string | null} uid - 対象者のuid。
 * @return {DiagnosisData} 診断結果データ
 */
export const fetchDiagnosisData = createAsyncThunk(
  'diagnosis/fetchDiagnosisData',
  async (uid: string | null, thunkAPI) => {
    const targetUid = uid ?? (thunkAPI.getState() as RootState).user.uid;
    const querySnapshot = await db
      .collection(DB_PATH.DIAGNOSES)
      .doc(targetUid)
      .collection(DB_PATH.DIAGNOSES_DATA)
      .orderBy('diagnosisDate', 'desc')
      .limit(1)
      .withConverter(diagnosisDataConverter)
      .get();

    if (querySnapshot.empty) {
      throw new Error('document not found.(diagnoses)');
    }
    return querySnapshot.docs[0].data();
  }
);

/* Update(なし) */

/* Delete(なし) */

/* Slice */
export const slice = createSlice({
  name: 'diagnosis',
  initialState: diagnosisDataInitialEntityState,
  reducers: {
    init: () => diagnosisDataInitialEntityState,
  },
  extraReducers: (builder) => {
    // registerDiagnosisData
    builder.addCase(registerDiagnosisData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(registerDiagnosisData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(registerDiagnosisData.fulfilled, (state) => {
      state.isLoading = false;
    });
    // fetchDiagnosisData
    builder.addCase(fetchDiagnosisData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDiagnosisData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchDiagnosisData.fulfilled, (state, action) => {
      diagnosesAdapter.setOne(state, action.payload);
      state.isLoading = false;
    });
  },
});

export default slice.reducer;
export const { init } = slice.actions;

export const diagnosisDataSelectors = diagnosesAdapter.getSelectors<RootState>(
  (state) => state.diagnosis
);
export const selectIsLoading = (state: RootState) => state.diagnosis.isLoading;

import { Grid, Typography } from '@material-ui/core';

import { ClientUser } from '../../../../../modules/entities/ClientUser';
import individualFpScreenStyle from '../SpIndividualFpScreenStyle';

type Props = {
  userInfo: ClientUser;
};

const UserInfoComponent = (props: Props) => {
  // state
  const { userInfo } = props;
  const classes = individualFpScreenStyle();

  // screen
  return (
    <Grid>
      {userInfo.job ? (
        <Typography className={classes.text}>
          {'\n'}
          {`職業 : ${userInfo.job}`}
        </Typography>
      ) : (
        <Typography className={classes.text}>職業 : 未設定</Typography>
      )}
      {userInfo.age ? (
        <Typography className={classes.text}>
          {`年齢 : ${userInfo.age} 歳`}
        </Typography>
      ) : (
        <Typography className={classes.text}>年齢 : 未設定</Typography>
      )}
      {userInfo.income ? (
        <Typography className={classes.text}>
          {`年収 : ${userInfo.income} 万円`}
        </Typography>
      ) : (
        <Typography className={classes.text}>年収 : 未設定</Typography>
      )}
    </Grid>
  );
};

export default UserInfoComponent;

import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../resources/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.paleBlue,
  },
  cardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '480px',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  spCardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '375px',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  /* header */
  title: {
    fontSize: '40px',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  spTitle: {
    fontSize: '30px',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: 600,
    whiteSpace: 'pre-line', // 改行を許可
  },
  spSubTitle: {
    fontSize: '14px',
    fontWeight: 600,
    whiteSpace: 'pre-line', // 改行を許可
  },
  link: {
    color: colors.dark,
  },

  /* form */
  textCardRoot: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    flexDirection: 'column',
    display: 'flex',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: '375px',
  },
  companyLogoS: {
    height: 'auto',
    width: '400px',
  },
  spCompanyLogoS: {
    height: 'auto',
    width: '250px',
  },

  emailTextField: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.lightGray,
    borderColor: colors.dark,
  },
  passwordTextField: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.lightGray,
  },
  signUpButton: {
    color: 'black',
    height: '56px',
  },
  signInButton: {
    backgroundColor: colors.lightBlue,
    color: colors.white,
    height: '56px',
  },
  disableSignInButton: {
    backgroundColor: colors.lightGray,
  },
  forgotPasswordlink: {
    color: colors.dark,
  },

  /* インジケーター */
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },

  /* icons */
  middleTitleRoot: {
    width: '380px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  divider: {
    backgroundColor: colors.dark,
    width: '100px',
  },
  middleTitle: {
    fontSize: '16px',
  },
  facebookIcon: {
    width: 40,
    height: 40,
    color: colors.facebookColor,
  },
  twitterIconButton: {
    marginLeft: theme.spacing(1),
  },
  twitterIconImage: {
    width: 40,
    height: 40,
    color: colors.twitterColor,
  },
  lineIconImage: {
    width: 32,
    height: 32,
  },
  googleIconButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 32,
    height: 32,
  },
  googleIconImage: {
    width: 32,
    height: 32,
  },
}));

export default useStyles;

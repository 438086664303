import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

// ↓ Selectors利用のため。↓
// eslint-disable-next-line import/no-cycle
import { RootState } from '..';
import { db } from '../../../firebase';
import { DB_PATH } from '../../../resources/constants';
import { clientUserConverter, ClientUser } from '../../entities/ClientUser';

interface ClientUserEntityState extends EntityState<ClientUser> {
  isLoading: boolean;
}

/* Entity Adapter */
const clientUsersAdapter = createEntityAdapter<ClientUser>({
  sortComparer: (a, b) => {
    if (a.matchedFPCount !== b.matchedFPCount)
      return a.matchedFPCount > b.matchedFPCount ? 1 : -1;
    if (a.isDiagnosed !== b.isDiagnosed) return !a.isDiagnosed ? 1 : -1;
    if (a.registrationDate === undefined) return 1;
    if (b.registrationDate === undefined) return -1;
    return a.registrationDate.getTime() < b.registrationDate.getTime() ? 1 : -1;
  },
});
const clientUserInitialEntityState: ClientUserEntityState =
  clientUsersAdapter.getInitialState({
    isLoading: false,
  });

/* Create(なし) */

/* Read */
/**
 * 顧客一覧を取得。
 * 自身がコンタクト済みのクライアントは除外する。
 * @return {ClientUser[]} 顧客一覧
 */
export const fetchAllClientUser = createAsyncThunk(
  'clientUser/fetchAllClientUser',
  async (_) => {
    const userList: ClientUser[] = (
      await db
        .collection(DB_PATH.USERS)
        .withConverter(clientUserConverter)
        .get()
    ).docs.map((doc) => doc.data());

    return userList;
  }
);

/**
 * 新規顧客一覧を取得。
 * 自身がコンタクト済みのクライアントは除外する。
 * @return {ClientUser[]} 新規顧客一覧
 */
export const fetchNewClientUser = createAsyncThunk(
  'clientUser/fetchNewClientUser',
  async (_, thunkAPI) => {
    const userList: ClientUser[] = (
      await db
        .collection(DB_PATH.USERS)
        .withConverter(clientUserConverter)
        .get()
    ).docs.map((doc) => doc.data());
    // コンタクト済み(自分とのチャットルームが作成済み)であればリストから除外する。
    const { uid } = (thunkAPI.getState() as RootState).user;
    const contactedUserList = (
      await db.collection(DB_PATH.CHAT_ROOMS).where('fpID', '==', uid).get()
    ).docs.map((doc) => doc.data().userID as string);

    return userList.filter((user) => !contactedUserList.includes(user.id));
  }
);

/**
 * IDから特定の顧客データを取得。
 * @param {string} clientID - データを取得したい顧客のUID
 * @return {ClientUser}
 */
export const fetchClientUser = createAsyncThunk(
  'clientUser/fetchClientUser',
  async (clientID: string) => {
    const user = (
      await db
        .collection(DB_PATH.USERS)
        .doc(clientID)
        .withConverter(clientUserConverter)
        .get()
    ).data();
    if (user === undefined) throw new Error();
    return user;
  }
);

/* Update */
/**
 * 「診断結果を受けてどう思ったか」の回答をドキュメントに追加する。
 */
export const updateAnxietyLevel = createAsyncThunk(
  'clientUser/updateAnxietyLevel',
  async (anxietyLevel: ClientUser['anxietyLevel'], thunkAPI) => {
    const { uid } = (thunkAPI.getState() as RootState).user;

    await db
      .collection(DB_PATH.USERS)
      .doc(uid)
      .withConverter(clientUserConverter)
      .update({ anxietyLevel });
  }
);

/* Delete(なし) */

/* Slice */
export const slice = createSlice({
  name: 'clientUser',
  initialState: clientUserInitialEntityState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchAllClientUser
    builder.addCase(fetchAllClientUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllClientUser.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAllClientUser.fulfilled, (state, action) => {
      clientUsersAdapter.setAll(state, action.payload);
      state.isLoading = false;
    });
    // fetchNewClientUser
    builder.addCase(fetchNewClientUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNewClientUser.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchNewClientUser.fulfilled, (state, action) => {
      clientUsersAdapter.setAll(state, action.payload);
      state.isLoading = false;
    });
    // fetchClientUser
    builder.addCase(fetchClientUser.fulfilled, (state, action) => {
      clientUsersAdapter.addOne(state, action.payload);
    });
  },
});

export default slice.reducer;

export const clietntUserSelectors = clientUsersAdapter.getSelectors<RootState>(
  (state) => state.clientUser
);
export const selectIsLoading = (state: RootState) => state.clientUser.isLoading;

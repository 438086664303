import { useMediaQuery } from 'react-responsive';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Stack, Avatar, Box } from '@mui/material';
import {
  TextField,
  Card,
  Divider,
  Button,
  CircularProgress,
  Backdrop,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { Search } from '@trejgun/material-ui-icons-google';

import useStyles from './SignUpScreenStyle';
import { AppDispatch, RootState } from '../../../modules/store';
import {
  getNickName,
  signUpWithoutPin,
  signInWithGoogle,
  toggleIsLoadingWithAuthProvider,
  setUserTextField,
  initUserInfo,
  setErrMsg,
  toggleIsErrModalOpen,
  toggleIsSuccessModalOpen,
} from '../../../modules/store/slices/userSlice';
import { isTextInvalid } from '../../../components/validation';
import Model from '../../../components/Modal';
import companyLogo from '../../../resources/image/companyLogo.png';
import informationRobot from '../../../resources/image/signup/informationRobot.png';

function SignUp() {
  const classes = useStyles();
  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 766px)' });
  const history = useHistory();

  const dispatch: AppDispatch = useDispatch();
  //  const history = useHistory();

  // state
  const [textFieldList, setTextFieldList] = React.useState({
    email: '',
    password: '',
  });
  const [disabledList, setDisabledList] = React.useState({
    disabledSendPinCodeBtn: true,
    disabledSignUpBtn: true,
    disabledCheckPinFormTextField: true,
  });
  const [isPincodeModalOpen, setIsPincodeModalOpen] = React.useState(false);
  const {
    isLoading,
    userInfo,
    isErrModalOpen,
    errMsg,
    isSuccessModalOpen,
    successMsg,
    successTitle,
  } = useSelector((state: RootState) => state.user);

  const {
    nickName,
    // pinCode
  } = userInfo;
  const { email, password } = textFieldList;
  const {
    //    disabledSendPinCodeBtn,
    disabledSignUpBtn,
    //    disabledCheckPinFormTextField,
  } = disabledList;

  // 入力項目の初期化（レンダリング時）
  React.useEffect(() => {
    dispatch(initUserInfo());
  }, []);

  // 登録ボタン活性・非活性処理
  const isDisabledSendPinCodeBtn = () =>
    isTextInvalid(email) || isTextInvalid(nickName);
  // 備考：メール送信用PinCodeを用いた2要素認証にて利用
  // const isDisabledSignUpBtn = () =>
  // isTextInvalid(password) || isTextInvalid(pinCode);
  const isDisabledSignUpBtn = () =>
    isTextInvalid(nickName) || isTextInvalid(email) || isTextInvalid(password);

  React.useEffect(() => {
    setDisabledList({
      ...disabledList,
      disabledSendPinCodeBtn: isDisabledSendPinCodeBtn(),
    });
  }, [email, nickName]);

  // 備考：メール送信用PinCodeを用いた2要素認証にて利用
  // React.useEffect(() => {
  //   setDisabledList({
  //     ...disabledList,
  //     disabledSignUpBtn: isDisabledSignUpBtn(),
  //   });
  // }, [password, pinCode]);
  React.useEffect(() => {
    setDisabledList({
      ...disabledList,
      disabledSignUpBtn: isDisabledSignUpBtn(),
    });
  }, [nickName, email, password]);

  // 備考：メール送信用PinCodeを用いた2要素認証にて利用
  // functions
  // const onClickSendPinCodeBtn = () => {
  //   dispatch(sendPinCode({ email, password })).then(() => {
  //     // CheckPinCodeForm の活性化
  //     setDisabledList({
  //       ...disabledList,
  //       disabledCheckPinFormTextField: false,
  //     });
  //     setIsPincodeModalOpen(true);
  //   });
  // };

  const onClickSignUpBtn = () => {
    dispatch(signUpWithoutPin({ email, password, nickName }))
      .then(unwrapResult)
      .catch((error) => {
        // 失敗時の処理
        dispatch(setErrMsg(error.message));
        // dispatch(toggleIsErrModalOpen());
      });
  };

  const onClickSignInBtn = () => {
    history.push({ pathname: '/signin' });
  };

  const onChangeTextField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setTextFieldList({ ...textFieldList, [name]: value });
  };

  const onChangeSliceTextField = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.target;
    dispatch(setUserTextField({ name, value }));
  };

  // const onChangeSlicePinCodeTextField = (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ): void => {
  //   const { name, value } = e.target;
  //   // 数字のみ入力させるロジック
  //   if (!Number.isNaN(+value)) {
  //     // set 時は string
  //     dispatch(setUserTextField({ name, value }));
  //   }
  // };

  const onClickGoogleBtn = () => {
    dispatch(toggleIsLoadingWithAuthProvider());
    dispatch(signInWithGoogle())
      .then(unwrapResult)
      .then(() => {
        // NickNameがあればマイページへ、なければ変更画面へ遷移
        if (getNickName()) {
          history.push({ pathname: '/my-page' });
        } else {
          history.push({ pathname: '/other-provider-signup' });
        }

        // NickNameの取得
        dispatch(getNickName()).then(() => {
          dispatch(toggleIsLoadingWithAuthProvider());
        });
      })
      .catch(() => {
        // ログイン失敗時の処理
        dispatch(toggleIsLoadingWithAuthProvider());
      });
  };

  const onClickCloseErrModalBtn = () => {
    dispatch(toggleIsErrModalOpen());
  };

  const onClickCloseSuccessModalBtn = () => {
    dispatch(toggleIsSuccessModalOpen());
    // サインアップ後
    // FPリスト画面へ遷移(診断後登録ユーザ獲得時の暫定対応)
    history.push({ pathname: '/matching/fp-list/' });

    // ホーム画面へ遷移
    // window.location.replace('https://www.money-dash.com');
    // window.scrollTo(0, 0);

    // NickNameの取得
    dispatch(getNickName());
  };

  return (
    <div>
      {/* ---------- web画面用表示領域 (min-width: 767px) ---------- */}
      {isDesktop && (
        <Grid className={classes.root}>
          <Grid className={classes.cardRoot}>
            <CardMedia
              className={classes.companyLogoS}
              component="img"
              image={companyLogo}
              title="companyLogo"
            />

            {/* 説明 */}
            <Typography
              align="left" /* 改行後左寄せ */
              className={classes.subTitle}>
              会員登録により
              <Button
                target="_blank"
                className={classes.link}
                href="https://www.money-dash.com/terms-of-use">
                利用規約
              </Button>
              及び
              <Button
                target="_blank"
                className={classes.link}
                href="https://www.money-dash.com/privacypolicy">
                プライバシーポリシー
              </Button>
              {`\n`}に同意したこととさせていただきます。{`\n`}
            </Typography>

            <Typography align="center" className={classes.subTitleLink}>
              <Link className={classes.alreadySignUpLinkBtn} to="/signIn">
                ログインはこちら
              </Link>
            </Typography>

            {/* TextSignUpInformationForm */}
            <Card className={classes.textCardRoot}>
              <TextField
                className={classes.nameTextField}
                label="ニックネーム（表示名）"
                variant="outlined"
                value={nickName}
                name="nickName"
                onChange={onChangeSliceTextField}
              />
              <TextField
                className={classes.emailTextField}
                label="メールアドレス"
                variant="outlined"
                value={email}
                name="email"
                onChange={onChangeTextField}
              />
              <TextField
                className={classes.passwordTextField}
                label="パスワード"
                variant="outlined"
                type="password"
                placeholder="半角英数字8桁以上"
                value={password}
                name="password"
                onChange={onChangeTextField}
              />
              {/* <Button
                className={classes.sendPinCodeButton}
                onClick={onClickSendPinCodeBtn}
                disabled={disabledSendPinCodeBtn}
                classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
              >
                会員登録コードを送信
              </Button> */}

              {/* CheckPinCodeForm */}
              {/* <TextField
                className={classes.pinCodeTextField}
                disabled={disabledCheckPinFormTextField}
                label="会員登録コード"
                variant="outlined"
                placeholder="会員登録コードを入力してください"
                value={pinCode}
                name="pinCode"
                onChange={onChangeSlicePinCodeTextField}
              /> */}
              <Button
                className={classes.signUpButton}
                onClick={onClickSignUpBtn}
                disabled={disabledSignUpBtn}
                classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
              >
                会員登録
              </Button>
            </Card>

            {/* インジケーター */}
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress />
            </Backdrop>

            {/* modal */}
            <Model
              isOpen={isErrModalOpen}
              title=""
              body={errMsg}
              buttonName="もう一度入力する"
              onClickCloseModalBtn={onClickCloseErrModalBtn}
            />
            <Model
              isOpen={isPincodeModalOpen}
              title="会員登録コード送信"
              body={`\nご入力いただいたメールアドレスに会員登録コードを送りました。そちらを用いて会員登録をお願いします。`}
              buttonName="閉じる"
              onClickCloseModalBtn={() => {
                setIsPincodeModalOpen(false);
              }}
            />
            <Model
              isOpen={isSuccessModalOpen}
              title={successTitle}
              body={successMsg}
              buttonName="閉じる"
              onClickCloseModalBtn={onClickCloseSuccessModalBtn}
            />
          </Grid>
        </Grid>
      )}
      {/* ---------- スマホ画面用表示領域 (max-width: 766px) ---------- */}
      {isMobile && (
        <Grid className={classes.root}>
          <Grid className={classes.spCardRoot}>
            <CardMedia
              className={classes.spCompanyLogoS}
              component="img"
              image={companyLogo}
              title="companyLogo"
            />
            {/* ロボット説明 */}
            <CardMedia
              className={classes.spRobotLogoS}
              component="img"
              image={informationRobot}
              title="informationRobot"
            />

            {/* 説明 */}
            <Grid>
              <Typography
                align="left" /* 改行後左寄せ */
                className={classes.spSubTitle}>
                会員登録
              </Typography>
            </Grid>

            {/* google ログイン */}
            <Box
              padding={1}
              className={classes.spGoogleBox}
              onClick={onClickGoogleBtn}>
              <Grid container alignItems="center">
                <Grid item xs={2} />
                <Grid item xs={2}>
                  <Search className={classes.googleIconImage} />
                </Grid>
                <Grid item xs={6}>
                  <Typography align="center" className={classes.spButtonText}>
                    Googleで続ける
                  </Typography>
                </Grid>
                <Grid item xs={2} />
              </Grid>
            </Box>

            <Typography align="center" className={classes.spSubTitle}>
              または
            </Typography>

            {/* TextSignUpInformationForm */}
            <Card className={classes.textCardRoot}>
              <TextField
                className={classes.nameTextField}
                label="ニックネーム（表示名）"
                variant="outlined"
                value={nickName}
                name="nickName"
                onChange={onChangeSliceTextField}
              />
              <TextField
                className={classes.emailTextField}
                label="メールアドレス"
                variant="outlined"
                value={email}
                name="email"
                onChange={onChangeTextField}
              />
              <TextField
                className={classes.passwordTextField}
                label="パスワード"
                variant="outlined"
                type="password"
                placeholder="半角英数字8桁以上"
                value={password}
                name="password"
                onChange={onChangeTextField}
              />
              {/* <Button
                className={classes.sendPinCodeButton}
                onClick={onClickSendPinCodeBtn}
                disabled={disabledSendPinCodeBtn}
                classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
              >
                会員登録コードを送信
              </Button>
              /> */}

              {/* CheckPinCodeForm */}
              {/* 
              <TextField
                className={classes.pinCodeTextField}
                disabled={disabledCheckPinFormTextField}
                label="会員登録コード"
                variant="outlined"
                placeholder="会員登録コードを入力してください"
                value={pinCode}
                name="pinCode"
                onChange={onChangeSlicePinCodeTextField}
              /> */}
              <Button
                className={classes.signUpButton}
                onClick={onClickSignUpBtn}
                disabled={disabledSignUpBtn}
                classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
              >
                登録する
              </Button>
            </Card>

            {/* 説明 */}
            <Grid className={classes.information}>
              <Typography className={classes.spInformationText}>
                会員登録により
                <Button
                  target="_blank"
                  className={classes.spLink}
                  href="https://www.money-dash.com/terms-of-use">
                  利用規約
                </Button>
                及び
                <Button
                  target="_blank"
                  className={classes.spLink}
                  href="https://www.money-dash.com/privacypolicy">
                  個人情報保護方針
                </Button>
                {`\n`}に同意したこととさせていただきます。{`\n`}
              </Typography>
            </Grid>
            <Divider variant="middle" className={classes.divider} />
            {/* <Typography align="center" className={classes.subTitleLink}> */}
            {/* <Link className={classes.alreadySignUpLinkBtn} to="/signIn">
                ログインはこちら
              </Link> */}
            {/* </Typography> */}
            <Grid className={classes.button}>
              <Button
                className={classes.signInLinkButton}
                onClick={onClickSignInBtn}>
                <Typography align="center" className={classes.signInButtonText}>
                  ログインはこちら
                </Typography>
              </Button>
            </Grid>

            {/* インジケーター */}
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress />
            </Backdrop>
            {/* modal */}
            <Model
              isOpen={isErrModalOpen}
              title=""
              body={errMsg}
              buttonName="もう一度入力する"
              onClickCloseModalBtn={onClickCloseErrModalBtn}
            />
            <Model
              isOpen={isPincodeModalOpen}
              title="会員登録コード送信"
              body={`\nご入力いただいたメールアドレスに会員登録コードを送りました。そちらを用いて会員登録をお願いします。`}
              buttonName="閉じる"
              onClickCloseModalBtn={() => {
                setIsPincodeModalOpen(false);
              }}
            />
            <Model
              isOpen={isSuccessModalOpen}
              title={successTitle}
              body={successMsg}
              buttonName="閉じる"
              onClickCloseModalBtn={onClickCloseSuccessModalBtn}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default SignUp;

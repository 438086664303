import { useState, MouseEvent } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { MenuOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import colors from '../../resources/colors';

import { initNickName, signOut } from '../../modules/store/slices/userSlice';
import { AppDispatch, RootState } from '../../modules/store';

const styles = (theme: Theme) => ({
  menuButton: {
    color: colors.darkGray,
  },
  menuList: {
    marginTop: theme.spacing(4),
  },
  menuItem: {
    fontSize: theme.typography.fontSize,
  },
});

function UserMenu({ classes }: WithStyles<typeof styles>) {
  // state
  const dispatch: AppDispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const { nickName } = useSelector((state: RootState) => state.user);

  // functoins
  const onButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuItemClick = () => {
    setAnchorEl(null);
  };

  const onClickProfileBtn = () => {
    history.push({ pathname: '/profile' });
  };

  // const onClickDiagnosisResultBtn = () => {
  //   history.push({ pathname: '/diagnosis/result' });
  // };

  const onClickUpdatePasswordBtn = () => {
    history.push({ pathname: '/update-password' });
  };

  const onClickLogoutBtn = () => {
    dispatch(signOut())
      .then(unwrapResult)
      .then(() => {
        setAnchorEl(null);
        history.push({ pathname: '/signIn' });

        // NickNameの初期化
        dispatch(initNickName());
      })
      .catch(() => {
        // TODO: 失敗時の処理
      });
  };

  // const onClickMyPageBtn = () => {
  //   // TODO: 画面遷移実装（準備中画面へ遷移）
  //   setAnchorEl(null);
  // };

  return (
    <>
      <Button
        endIcon={<MenuOutlined />}
        onClick={onButtonClick}
        className={classes.menuButton}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onMenuItemClick}
        className={classes.menuList}>
        {/* <MenuItem onClick={onClickMyPageBtn} className={classes.menuItem}>
          マイページ
        </MenuItem> */}

        <MenuItem onClick={onClickProfileBtn} className={classes.menuItem}>
          プロフィール
        </MenuItem>

        {/* <MenuItem
          onClick={onClickDiagnosisResultBtn}
          className={classes.menuItem}>
          診断結果
        </MenuItem> */}

        <MenuItem
          onClick={onClickUpdatePasswordBtn}
          className={classes.menuItem}>
          パスワード変更
        </MenuItem>

        <MenuItem onClick={onClickLogoutBtn} className={classes.menuItem}>
          ログアウト
        </MenuItem>
      </Menu>
    </>
  );
}

export default withStyles(styles)(UserMenu);

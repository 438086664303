import React from 'react';
import { Grid, Typography, Modal, makeStyles, Button } from '@material-ui/core';

import colors from '../resources/colors';

/**
 * 【使い方】
 *
 * ① モーダルを import
 * ・import Model from '../{随時}/../components/Modal'
 *
 * ② モーダルオープンの状態管理用 state を記述
 * ・const [isOpen, setIsOpen] = React.useState(true)
 *
 * ③ onClickCloseModalBtn（閉じるボタン）を記述
 * ・const onClickCloseModalBtn = () => {
 *     setIsOpen(false);
 *   };
 *
 * ④ 随時開くボタンを記述（例：動作失敗時の catch 内など）
 * ・setIsOpen(true)
 *
 * ⑤ モーダルを配置する（screen の return の最下部など）
 * ・<Model
 *     isOpen={isOpen}
 *     title="ログイン失敗"
 *     body={`ログインに失敗しました。\n再度ログインを行ってください`}
 *     buttonName="再度ログインを行う"
 *     onClickCloseModalBtn={onClickCloseModalBtn}
 *   />
 */

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.white,
    padding: theme.spacing(2, 4, 2, 4),
  },
  multiBtnRoot: {
    display: 'flex',
    flexDirection: 'row',
  },

  /* body */
  title: {
    fontSize: '24px',
    paddingBottom: theme.spacing(2),
  },
  body: {
    fontSize: '16px',
    marginBottom: theme.spacing(4),
    whiteSpace: 'pre-line', // 改行を許可
    wordBreak: 'break-all', // 単語サイズを無視して改行を許可
  },
  closeBtn: {
    marginTop: '10px',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: colors.lightBlue,
    color: colors.white,
    height: '30px',
    minWidth: '150px',
    fontSize: '16px',
  },
  multiBtn: {
    marginTop: '10px',
    marginLeft: '20px',
    marginRight: '20px',
    color: colors.white,
    height: '30px',
    minWidth: '120px',
    fontSize: '16px',
  },
}));

type Props = {
  isOpen: boolean;
  title: string;
  body: string;
  buttonName: string;
  cancelBtnName?: String;
  onClickCloseModalBtn: () => void;
  onClickCancelBtn?: () => void;
};

const ModalComponent = (props: Props) => {
  const {
    isOpen,
    title,
    body,
    buttonName,
    cancelBtnName,
    onClickCloseModalBtn,
    onClickCancelBtn,
  } = props;
  const classes = useStyles();

  // TODO: 取り消しボタンの追加
  return (
    <Modal className={classes.root} open={isOpen}>
      <Grid className={classes.cardRoot}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.body}>{body}</Typography>
        {cancelBtnName ? (
          <Grid className={classes.multiBtnRoot}>
            <Button
              onClick={onClickCloseModalBtn}
              className={classes.multiBtn}
              style={{ backgroundColor: colors.lightBlue }}>
              {buttonName}
            </Button>
            <Button
              onClick={onClickCancelBtn}
              className={classes.multiBtn}
              style={{ backgroundColor: colors.gray }}>
              {cancelBtnName}
            </Button>
          </Grid>
        ) : (
          <Button onClick={onClickCloseModalBtn} className={classes.closeBtn}>
            {buttonName}
          </Button>
        )}
      </Grid>
    </Modal>
  );
};

ModalComponent.defaultProps = {
  cancelBtnName: undefined,
  onClickCancelBtn: undefined,
};

export default ModalComponent;

import { Stack, Grid, Typography, Box } from '@mui/material';

import colors from '../../../../resources/colors';

type Props = {
  /** 老後までに貯めるべきお金 */
  totalRequiredSavings: number;
};

/**
 * 1億円以上の値を返す
 * @param totalRequiredSavings 老後までに貯めるべきお金
 * @returns 1億円以上の値を返す
 */
export function calculateOverBillion(totalRequiredSavings: number): number {
  if (totalRequiredSavings < 10000) return 0;
  return Math.round(totalRequiredSavings / 10000);
}

/**
 * 1億円未満の値を返す
 * @param totalRequiredSavings 老後までに貯めるべきお金
 * @returns 1億円未満の値を返す
 */
export function calculateUnderBillion(totalRequiredSavings: number): number {
  return totalRequiredSavings % 10000;
}

/**
 * 表示している値の範囲を返す
 * @param totalRequiredSavings 老後までに貯めるべきお金
 * @returns 1億円未満の値を返す
 */
export function calculateResultRange(totalRequiredSavings: number): string {
  let range = '';

  if (totalRequiredSavings === 1000) {
    range = '(範囲 0 - 1500万円)';
  } else if (totalRequiredSavings === 2000) {
    range = '(範囲 1500 - 2500万円)';
  } else if (totalRequiredSavings === 3000) {
    range = '(範囲 2500 - 3500万円)';
  } else if (totalRequiredSavings === 4000) {
    range = '(範囲 3500 - 4500万円)';
  } else {
    range = '';
  }
  return range;
}

const ResultMessageBox = ({ totalRequiredSavings }: Props) => (
  <Stack
    spacing={1}
    padding={0.5}
    sx={{
      backgroundColor: colors.skyBlue,
      borderRadius: 2,
    }}>
    <Grid container>
      <Grid item xs={2} />
      <Grid item xs={8} paddingTop={1}>
        <Box
          padding={1}
          sx={{
            p: -1,
            border: '3px solid white',
            height: 32,
            width: '100%',
            borderColor: colors.white,
            textAlign: 'center',
            backgroundColor: colors.white,
          }}>
          <Typography
            fontSize={20}
            fontWeight={800}
            color={colors.facebookColor}>
            老後資金診断結果
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2} />
    </Grid>
    <Grid container>
      <Grid xs={0.5} />
      <Grid xs={9.5}>
        <Typography fontSize={14} fontWeight={500} color={colors.dark}>
          あなたが老後までに貯めるべきお金は
        </Typography>
      </Grid>
      <Grid xs={2} />
    </Grid>
    <Grid container>
      <Grid xs={1} />
      <Grid
        xs={10}
        sx={{
          borderBottom: 'solid',
          color: colors.lightBlue,
        }}>
        <Box
          sx={{
            backgroundColor: colors.skyBlue,
            textAlign: 'center',
          }}>
          {/* {totalRequiredSavings > 10000 ? (
        <Typography fontSize={30} fontWeight={600} color={colors.facebookColor}>
          {calculateOverBillion(totalRequiredSavings)}億
          {calculateUnderBillion(totalRequiredSavings)}万円
        </Typography>
      ) : (
        <Typography fontSize={30} fontWeight={600} color={colors.facebookColor}>
          {totalRequiredSavings}万円
        </Typography>
      )} */}
          {/* (仮対応)丸めた値を受け取り、出力 */}
          {totalRequiredSavings > 4500 ? (
            <Typography
              fontSize={30}
              fontWeight={600}
              color={colors.facebookColor}>
              {totalRequiredSavings}万円程度
            </Typography>
          ) : (
            <Typography
              fontSize={30}
              fontWeight={600}
              color={colors.facebookColor}>
              {totalRequiredSavings}万円以上
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid xs={1} />
    </Grid>
    <Grid container>
      <Grid xs={5} />
      <Grid xs={7}>
        <Typography
          fontSize={14}
          fontWeight="fontWeightMedium"
          color={colors.mainBlue}
          whiteSpace="pre-line">
          {calculateResultRange(totalRequiredSavings)}
        </Typography>
      </Grid>
    </Grid>
  </Stack>
);

export default ResultMessageBox;

import React from 'react';

import IntroductionHeader from '../../home/introductionHeader/IntroductionHeader';

const MatchingHomeScreen = () => (
  <div>
    <IntroductionHeader screenName="matching" mode="normal" />
    {/* <CategoryGridList /> */}
  </div>
);

export default MatchingHomeScreen;

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as OldThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import OldRoutes from './App';
import './index.css';
import { store } from './modules/store';
import reportWebVitals from './reportWebVitals';
import { defaultTheme } from './resources/theme/defaultTheme';
import oldTheme from './resources/theme/oldTheme';
import Routes, { newDesignPaths } from './routes';

const ThemeProviderChanger = ({
  children,
  isNewDesign,
}: {
  children: ReactNode;
  isNewDesign: boolean;
}) => {
  if (!isNewDesign) {
    return <OldThemeProvider theme={oldTheme}>{children}</OldThemeProvider>;
  }

  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
};

const App = () => {
  const isNewDesign = newDesignPaths.some((item) =>
    window.location.pathname.match(item)
  );
  return (
    <React.StrictMode>
      <ThemeProviderChanger isNewDesign={isNewDesign}>
        <CssBaseline />
        <Provider store={store}>
          {isNewDesign ? <Routes /> : <OldRoutes />}
        </Provider>
      </ThemeProviderChanger>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

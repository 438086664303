import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from '../firebase';

import { AppDispatch } from '../modules/store';
import {
  getNickName,
  setAuthUserInfo,
  // removeAuthUserInfo,
} from '../modules/store/slices/userSlice';

interface Props {
  children: ReactElement;
}

export function AuthCheckFunc(props: Props): ReactElement {
  // state
  const dispatch: AppDispatch = useDispatch();

  // ユーザー情報取得
  auth.onAuthStateChanged(async (user) => {
    // ログイン済ユーザーが取得できた場合の処理
    if (user) {
      // uid, FP or User, ログイン状態をStoreに保存
      dispatch(
        setAuthUserInfo({
          uid: user.uid,
          role: user.displayName === 'FP' ? 'FP' : 'User',
          isAnonymous: user.isAnonymous,
        })
      );

      // NickNameの取得 & インジケーター終了
      dispatch(getNickName());
    }
    // ログイン済ユーザーが取得できなかった場合の処理
    else {
      // uid, FP or Userを破棄。未ログイン状態に変更。
      // dispatch(removeAuthUserInfo()); // todo removeしないで問題ないか確認
      // 匿名ユーザとして扱う
      auth.signInAnonymously().catch((error) => {
        console.error(error);
      });
    }
  });
  return props.children;
}

export default AuthCheckFunc;

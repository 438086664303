import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Button,
  CircularProgress,
  Backdrop,
  Typography,
  Grid,
} from '@material-ui/core';
import { Box } from '@mui/material';

import useStyles from './CustomersControlScreenStyles';
import { AppDispatch, RootState } from '../../../modules/store';
import {
  updateIsDisplayed,
  toggleIsDisplayed,
} from '../../../modules/store/slices/userSlice';
import Model from '../../../components/Modal';

const CustomersControlScreen: React.FC = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const [isDisplayedModalOpen, setIsDisplayedModalOpen] = React.useState(false);

  const { isLoading, profileInfo } = useSelector(
    (state: RootState) => state.user
  );

  const onClickIsDisplayedBtn = () => {
    setIsDisplayedModalOpen(true);
  };

  const onClickCloseIsDisplayedModalBtn = () => {
    dispatch(
      updateIsDisplayed({ isDisplayed: !profileInfo.isDisplayed })
    ).finally(() => {
      dispatch(toggleIsDisplayed());
    });
    setIsDisplayedModalOpen(false);
  };

  return (
    <div>
      <Grid className={classes.root}>
        <Grid className={classes.cardRoot}>
          <Typography className={classes.title}>集客ON/OFF切り替え</Typography>

          <Card className={classes.textCardRoot}>
            <Typography className={classes.isDisplayedCheckText}>
              マッチング画面上の自身の情報の表示状況 :
              {profileInfo.isDisplayed ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.displayedText}>
                    表示
                  </Typography>
                  <Button
                    className={classes.switchDisplayBtn}
                    onClick={onClickIsDisplayedBtn}>
                    切り替える
                  </Button>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.nonDisplayedText}>
                    非表示
                  </Typography>
                  <Button
                    className={classes.switchDisplayBtn}
                    onClick={onClickIsDisplayedBtn}>
                    切り替える
                  </Button>
                </Box>
              )}
            </Typography>
          </Card>

          {/* modal */}
          <Model
            isOpen={isDisplayedModalOpen}
            title="表示切り替え"
            body={
              profileInfo.isDisplayed
                ? `マッチング画面において、\nあなたとやり取りを開始していないユーザーが\nあなたを見つけられなくなります。`
                : `マッチング画面において、\n新しいユーザーがあなたを見つけられるようになります。`
            }
            buttonName="表示を切り替える"
            onClickCloseModalBtn={onClickCloseIsDisplayedModalBtn}
          />

          {/* インジケーター */}
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress />
          </Backdrop>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomersControlScreen;

import { Typography, Grid } from '@material-ui/core';
import useStyles from './NotFoundErrorScreenStyle';

function NotFoundError() {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Grid className={classes.cardRoot}>
        <Typography variant="h5" align="center">
          読み込み中
        </Typography>
        <Typography variant="body1" align="center">
          完了しない場合、再読み込みを行ってください。
        </Typography>

        {/* <Typography variant="h3" align="center">
          404 
        </Typography>
        <Typography variant="h5" align="center">
          page not found
        </Typography>
        <Typography variant="body1" align="center">
          お探しのページは移動、または削除された可能性があります。
        </Typography> */}
      </Grid>
    </Grid>
  );
}

export default NotFoundError;

import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../resources/colors';

const individualFpScreenStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.lightGray,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  rootContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: colors.white,
    maxWidth: '1200px',
    margin: 'auto',
  },
  rootBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '2px',
  },

  /* header */
  divider: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.facebookColor,
    height: '10px',
  },
  backBtn: {
    color: colors.lightBlue,
    Cborderolor: colors.lightBlue,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-start',
    zIndex: 1,
  },

  /* main */
  mainRoot: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    gap: '40px',
  },

  fpVideo: {
    maxHeight: 'auto',
    minWidth: '200px',
  },

  userCard: {
    minWidth: '400px',
    paddingBottom: theme.spacing(2),
  },
  strongAreaRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  strongAreaContainer: {
    border: '1px',
    display: 'flex',
    padding: '10px',
    gap: '10px',
    backgroundColor: colors.paleBlue,
    width: '300px',
    alignItems: 'center',
    justifyContent: 'center',
  },

  itemTitleBox: {
    fontWeight: 'bold',
    fontSize: '17px',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  ratingStarBox: {
    fontWeight: 'bold',
    fontSize: '17px',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  text: {
    textAlign: 'left',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontSize: '16px',
    whiteSpace: 'pre-line', // 改行を許可
  },
  /* footer btn */
  btnRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  mtgLink: {
    verticalAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chatBtn: {
    marginTop: theme.spacing(2),
    backgroundColor: colors.lightBlue,
    color: colors.white,
    fontSize: '18px',
    width: '1200px',
  },
  fpImage: {
    height: '160px',
    width: '130px',
  },
  fpBelongsIcon: {
    height: '50px',
    width: '200px',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  nickname: {
    color: colors.nameColor,
    display: 'flex',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    fontSize: 28,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  Rating: {
    color: theme.palette.common.white,
  },

  backdrop: {
    opacity: 0.5,
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default individualFpScreenStyles;

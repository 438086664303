import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../resources/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.lightGray,
  },
  cardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '480px',
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  spCardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '375px',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  /* header */
  title: {
    fontSize: '40px',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
  spTitle: {
    fontSize: '30px',
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },

  /* form */
  textCardRoot: {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    flexDirection: 'column',
    display: 'flex',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: '375px',
  },
  passwordTextField: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.lightGray,
  },
  passwordTextFieldTitle: {
    display: 'flex',
  },
  passwordResetButton: {
    marginTop: theme.spacing(2),
    backgroundColor: colors.lightBlue,
    color: colors.white,
    height: '56px',
  },
  disableButton: {
    backgroundColor: colors.lightGray,
  },

  /* インジケーター */
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default useStyles;

import { Box, Typography, Grid } from '@mui/material';

import colors from '../../../../resources/colors';

type Props = {
  result: number;
};

/**
 * 診断機能(スマホ)の1行分のメッセージボックス(Robotアイコン含む)のコンポーネント
 */
function RoughResultBox(props: Props) {
  const { result } = props;

  let amount = 0;
  let text = '程度';
  let range = '';

  if (result < 1500) {
    amount = 1000;
    range = '(範囲 0 - 1500万円)';
  } else if (result > 1500 && result < 2500) {
    amount = 2000;
    range = '(範囲 1500 - 2500万円)';
  } else if (result > 2500 && result < 3500) {
    amount = 3000;
    range = '(範囲 2500 - 3500万円)';
  } else if (result > 3500 && result < 4500) {
    amount = 4000;
    range = '(範囲 3500 - 4500万円)';
  } else {
    amount = 4500;
    text = '以上';
  }

  return (
    <Box
      padding={1}
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: colors.paleGray,
        borderRadius: 2,
        textAlign: 'center',
      }}>
      <Grid container>
        <Grid xs={0.15} />
        <Grid xs={7}>
          <Typography
            fontSize={11}
            fontWeight="fontWeightMedium"
            color={colors.mainBlue}
            whiteSpace="pre-line">
            老後までに貯めるべきお金は？
          </Typography>
        </Grid>
        <Grid xs={4.85} />
      </Grid>
      <Grid container>
        <Grid xs={1} />
        <Grid
          item
          xs={2.5}
          sx={{
            borderColor: colors.mainBlue,
            border: 'solid',
            borderWidth: 'thin',
            borderRadius: 2,
            color: colors.mainBlue,
          }}>
          <Box
            padding={1}
            sx={{
              width: '100%',
              textAlign: 'center',
              alignItems: 'center',
              padding: '5px 0px',
            }}>
            <Typography
              fontSize={14}
              fontWeight={550}
              color={colors.mainBlue}
              whiteSpace="pre-line">
              計算中
            </Typography>
          </Box>
        </Grid>
        <Grid xs={0.5} />
        <Grid
          item
          xs={6.5}
          sx={{
            borderBottomColor: colors.mainBlue,
            borderBottom: 'solid',
            borderBottomWidth: 'thin',
            textAlign: 'center',
          }}>
          <Typography
            fontSize={20}
            fontWeight={600}
            color={colors.mainBlue}
            whiteSpace="pre-line">
            {amount}万円{text}
          </Typography>
        </Grid>
        <Grid item xs={1.5} textAlign="left" />
      </Grid>
      <Grid container>
        <Grid xs={5} />
        <Grid xs={7}>
          <Typography
            fontSize={11}
            fontWeight="fontWeightMedium"
            color={colors.mainBlue}
            whiteSpace="pre-line">
            {range}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
export default RoughResultBox;

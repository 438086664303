import React from 'react';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import categoryHomeScreenStyles from './categoryHomeScreenStyles';
import { RootState } from '../../../modules/store';

const CategoryHomeScreen = () => {
  // state
  const classes = categoryHomeScreenStyles();
  const history = useHistory();
  const { isLoggedIn } = useSelector((state: RootState) => state.user);

  // functions
  const onClickSignUpBtn = () => {
    history.push({ pathname: '/signup' });
  };

  const onClickDiagnosisBtn = () => {
    history.push({ pathname: '/diagnosis' });
  };

  return (
    <div>
      <section className={classes.root}>
        <Container className={classes.container}>
          {/* screen */}
          <Grid className={classes.cardRoot}>
            <Typography className={classes.subTitle}>
              人生100年時代を乗り切る
            </Typography>
            <Typography className={classes.mainTitle}>
              あなたに必要なお金の計画を作る
            </Typography>
            <Typography className={classes.description}>
              {`人生100年時代、
              これからは一人一人が自分の資産を守り、管理する必要があると言われています。
              「何かはじめないといけない」とぼんやりと思いながらも、
              何をすればいいかもわからず先延ばしにしてしまっていませんか？
              FIST PLAT は気軽に相談できるお金の専門家に出会えるプラットフォームです。
              
              将来必要なお金を診断して、お金の専門家にコーチングしてもらいましょう！
              `}
            </Typography>

            {isLoggedIn ? (
              <Grid>
                <Button
                  className={classes.diagnosisBtn}
                  onClick={onClickDiagnosisBtn}>
                  老後資金診断を開始する (無料) ▶︎
                </Button>
              </Grid>
            ) : (
              <Grid>
                <Button
                  className={classes.diagnosisBtn}
                  onClick={onClickSignUpBtn}>
                  老後資金診断を開始する (無料) ▶︎
                </Button>
                <Typography className={classes.signUpRoot}>
                  既に会員の方は
                  <Link className={classes.signUplink} to="/signin">
                    こちら
                  </Link>
                </Typography>
              </Grid>
            )}
          </Grid>

          {/* background */}
          <div className={classes.backdrop} />
          <div
            className={clsx(classes.backgroundPosition, classes.background)}
          />
        </Container>
      </section>
    </div>
  );
};
export default CategoryHomeScreen;

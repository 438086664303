import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';

import { AppDispatch } from '../../../modules/store';
import {
  clietntUserSelectors,
  fetchAllClientUser,
} from '../../../modules/store/slices/clientUserSlice';
import { DataGrid } from '../../../components/DataGrid';
import { ClientUser } from '../../../modules/entities/ClientUser';
import { columnComponents } from './components';

const NewClientScreen = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const clientUsers = useSelector(clietntUserSelectors.selectAll);

  // 顧客一覧の取得
  useEffect(() => {
    dispatch(fetchAllClientUser());
  }, []);

  const columns: Column<ClientUser>[] = [
    {
      Header: 'メッセージ送信済みのFP数',
      accessor: 'matchedFPCount',
      Cell: columnComponents.matchedFPCount,
    },
    {
      Header: '名前',
      accessor: 'nickName',
    },
    {
      Header: '診断結果への感想',
      accessor: 'anxietyLevel',
      Cell: columnComponents.anxietyLevel,
    },
    {
      Header: 'お住まい',
      accessor: 'address',
      Cell: columnComponents.address,
    },
    {
      Header: '年齢',
      accessor: 'age',
      Cell: columnComponents.age,
    },
    {
      Header: '年収',
      accessor: 'income',
      Cell: columnComponents.income,
    },
    {
      Header: '登録日',
      accessor: 'registrationDate',
      Cell: columnComponents.registrationDate,
    },
  ];

  return (
    <Box
      sx={{
        pr: 1,
        overflow: 'visible',
        minWidth: '1086px',
      }}>
      <DataGrid
        onRowClick={(i) => history.push(`/clients/${clientUsers[i].id}`)}
        columns={columns}
        data={clientUsers}
        getRowProps={(row) => ({
          className: row.original.matchedFPCount > 0 ? '--negative' : '',
        })}
      />
    </Box>
  );
};

export default NewClientScreen;

/* eslint-disable react/require-default-props */
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { ComponentProps, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

export type Nav = {
  icon: ReactJSXElement;
  label: string;
  to: string;
};

export const NavList = ({
  children,
  sx = {},
}: {
  children: ReactNode;
  sx?: SxProps;
}) => (
  <List
    sx={{
      py: 0,
      px: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 0.5,
      ...sx,
    }}
    component="nav">
    {children}
  </List>
);

export const NavListItem = ({
  children,
  item,
}: {
  children: ReactNode;
  item: Nav;
}) => (
  <ListItem
    key={item.label}
    button
    component={NavLink}
    to={item.to}
    activeClassName="Mui-selected"
    sx={{
      px: 2,
      py: 1,
      borderRadius: 1,
    }}
    disabled={!item.to.length}
    exact>
    {children}
  </ListItem>
);

export const NavListItemIcon = ({ icon }: { icon: ReactJSXElement }) => (
  <ListItemIcon sx={{ color: 'inherit', minWidth: 'auto', marginRight: 2 }}>
    {icon}
  </ListItemIcon>
);

export const NavListItemText = ({ label }: { label: string }) => (
  <ListItemText
    primary={label}
    sx={{ margin: 0 }}
    primaryTypographyProps={{
      fontSize: 15,
      fontWeight: 'medium',
    }}
  />
);

export const LogoArea = ({
  children,
  ...props
}: { children: ReactNode } & ComponentProps<typeof Box>) => (
  <Box
    sx={{
      display: 'flex',
      p: 2,
    }}
    {...props}>
    {children}
  </Box>
);

export const UserArea = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      p: 2,
      pl: 4,
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      gridTemplateRows: '1fr',
      gap: 2,
      alignItems: 'center',
    }}>
    {children}
  </Box>
);

export const UserImage = ({ imageUrl }: { imageUrl?: string }) => (
  <Box
    sx={{
      width: '24px',
      height: '24px',
      borderRadius: 1.5,
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: 'contain',
    }}
  />
);

export const UserName = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => (
  <Box
    title={title}
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }}>
    {children}
  </Box>
);

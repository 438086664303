import { Typography, Grid } from '@material-ui/core';
import useStyles from './ContactScreenStyle';

function Contact() {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Grid className={classes.cardRoot}>
        <Typography variant="h5" align="center">
          【お問い合わせ連絡先】
        </Typography>
        <Typography variant="h6" align="center">
          {'\n'}
          連絡先：fistplat.contact@gmail.com
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Contact;

import { Typography } from '@material-ui/core';
import { Grid } from '@mui/material';

import { SpMyPageScreenStyles } from '../SpMyPageScreenStyle';

type Props = {
  result: number;
};

function RoughAmountBox(props: Props) {
  const { result } = props;
  const classes = SpMyPageScreenStyles();

  let amount = 0;
  let text = '程度';
  let range = '';

  if (result < 1500) {
    amount = 1000;
    range = '(範囲 0 - 1500万円)';
  } else if (result > 1500 && result < 2500) {
    amount = 2000;
    range = '(範囲 1500 - 2500万円)';
  } else if (result > 2500 && result < 3500) {
    amount = 3000;
    range = '(範囲 2500 - 3500万円)';
  } else if (result > 3500 && result < 4500) {
    amount = 4000;
    range = '(範囲 3500 - 4500万円)';
  } else {
    amount = 4500;
    text = '以上';
  }

  return (
    <Grid
      container
      sx={{
        textAlign: 'center',
        justifyContent: 'center',
      }}>
      <Grid
        container
        sx={{
          alignItem: 'start',
        }}>
        <Grid
          item
          xs={5}
          sx={{
            GridRow: 'start',
          }}>
          <Typography className={classes.subText}>老後資金診断</Typography>
        </Grid>
        <Grid item xs={7} />
      </Grid>
      <Grid
        container
        item
        sx={{
          alignItems: 'center',
        }}>
        <Grid item xs={3} />
        <Grid
          item
          xs={6}
          sx={{
            borderBottom: 'solid',
            borderBottomWidth: 'thin',
            textAlign: 'center',
          }}>
          <Typography className={classes.text}>
            {amount}万円{text}
          </Typography>
        </Grid>
        <Grid xs={3} />
      </Grid>
      <Grid
        container
        sx={{
          verticalAlign: 'bottom',
        }}>
        <Grid xs={9} />
        <Grid item xs={3}>
          <Typography className={classes.subText}>詳細へ</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default RoughAmountBox;

import { format } from 'date-fns';

import { Cell } from '../../../components/DataGrid';
import { Tag } from '../../../components/Tag';
import { EmotionText } from '../../../newComponents/EmotionText';

import { ClientUser } from '../../../modules/entities/ClientUser';

export const columnComponents = {
  matchedFPCount: ({ value }: { value: ClientUser['matchedFPCount'] }) => {
    if (value === 0) {
      return <Tag>未対応</Tag>;
    }
    return `${value}人`;
  },
  anxietyLevel: ({ value }: { value: ClientUser['anxietyLevel'] }) => (
    <Cell.Row>
      <EmotionText anxietyLevel={value} />
    </Cell.Row>
  ),
  age: ({ value }: { value: ClientUser['age'] }) =>
    value !== undefined ? `${value}歳` : '-',
  address: ({ value }: { value: ClientUser['address'] }) =>
    value !== undefined ? `${value}` : '-',
  income: ({ value }: { value: ClientUser['income'] }) =>
    value !== undefined ? `${value}万円` : '-',
  registrationDate: ({ value }: { value: ClientUser['registrationDate'] }) =>
    value !== undefined ? format(value, 'yyyy/MM/dd') : '-',
  isDiagnosed: ({ value }: { value: ClientUser['isDiagnosed'] }) =>
    value ? 'あり' : '-',
};

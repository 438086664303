/* TODO: 削除予定 */
/* eslint-disable no-alert */
import { BreakfastDiningOutlined } from '@mui/icons-material';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  isInvalidEmail,
  isInvalidPassword,
  isInvalidPasswordAtSignUp,
  isInvalidPinCode,
} from '../../../components/validation';
import { Person } from '../../entities/Person';
import {
  signIn as signInFunc,
  signUpFp as signUpFpFunc,
  signUp as signUpFunc,
  signUpAnonymous as signUpAnonymousFunc,
  signOut as signOutFunc,
  getNickName as getNickNameFunc,
  updatePassowrd as updatePassowrdFunc,
  setNickname as setNicknameFunc,
  updateNickname as updateNicknameFunc,
  sendPinCode as sendPinCodeFunc,
  getSelectPictureScreenInfo as getSelectPictureScreenInfoFunc,
  updateIsDisplayed as updateIsDisplayedFunc,
  switchReceiveNotificationFlag as switchReceiveNotificationFlagFunc,
  initLiff as initLiffFunc,
  signInWithLine as signInWithLineFunc,
  signInWithFacebook as signInWithFacebookFunc,
  signInWithGoogle as signInWithGoogleFunc,
  ProfileInfo,
} from '../../functions/userFunctions';

type State = {
  isLoading: boolean;
  isLoggedIn: boolean;
  isLoadingWithAuthProvider: boolean;
  uid: string;
  nickName: string;
  role: Person['role'];
  errMsg: string;
  successMsg: string;
  successTitle: string;
  isErrModalOpen: boolean;
  isSuccessModalOpen: boolean;
  isAnonymous: boolean; // 匿名ユーザか否か

  /* User 登録時に使用する情報 */
  userInfo: {
    nickName: string;
    sentPinCode: string;
    pinCode: string;
  };

  /* FP 登録時に使用する情報 */
  fpInfo: {
    nickName: string;
    belongs: string;
    age: number | undefined;
    sex: string;
    fpHistory: number | undefined;
    deliverable: string;
    firstTimeFree: string;
    fee: string;
    introduction: string;
    others: string;

    /* 得意分野 */
    specialty01: string;
    specialty02: string;
    specialty03: string;

    /* 取り扱い金融商品配列 */
    financialProducts01: string;
    financialProducts02: string;
    financialProducts03: string;
    financialProducts04: string;
    financialProducts05: string;
  };

  /* SelectPictureScreen */
  profileInfo: ProfileInfo;
};

const initialState: State = {
  isLoading: false,
  isLoggedIn: false,
  isLoadingWithAuthProvider: false,
  uid: '',
  nickName: 'nickName',
  role: 'User',
  errMsg: '',
  successMsg: '',
  successTitle: '',
  isErrModalOpen: false,
  isSuccessModalOpen: false,
  isAnonymous: false,

  /* User 登録時に使用する情報 */
  userInfo: {
    nickName: '',
    sentPinCode: '',
    pinCode: '',
  },

  /* FP 登録時に使用する情報 */
  fpInfo: {
    nickName: '',
    belongs: '',
    age: undefined,
    sex: '',
    fpHistory: undefined,
    deliverable: '',
    firstTimeFree: '',
    fee: '',
    introduction: '',
    others: '',
    specialty01: '',
    specialty02: '',
    specialty03: '',
    financialProducts01: '',
    financialProducts02: '',
    financialProducts03: '',
    financialProducts04: '',
    financialProducts05: '',
  },
  /* SelectPictureScreen */
  profileInfo: {
    mail: '',
    imageUrl: '',
    isDisplayed: true,
    receiveNotificationFlag: false,
    isFp: false,
  },
};

/* Read */

/**
 * (共通)ニックネーム取得
 * ログイン済みユーザのニックネームを取得する
 */
export const getNickName = createAsyncThunk(
  'getNickName/user',
  async (): Promise<string> => {
    try {
      return await getNickNameFunc();
    } catch (error) {
      throw error;
    }
  }
);

/* Update */

/**
 * (共通)ログイン(サインイン)
 *  ID/Password認証
 */
export const signIn = createAsyncThunk(
  'signIn/user',
  async (args: { email: string; password: string }): Promise<void> => {
    const { email, password } = args;
    try {
      await signInFunc(email, password);
    } catch (error) {
      throw error;
    }
  }
);

/**
 * (共通)ログイン(サインイン)
 * Lineアカウント認証
 */
export const signInWithLine = createAsyncThunk(
  'signInWithLine/user',
  async (): Promise<void> => {
    try {
      await signInWithLineFunc();
    } catch (error) {
      throw error;
    }
  }
);

export const initLiff = createAsyncThunk(
  'initLiff/user',
  async (): Promise<void> => {
    try {
      await initLiffFunc();
    } catch (error) {
      throw error;
    }
  }
);

/**
 * (共通)ログイン(サインイン)
 *  Facebookアカウント認証
 */
export const signInWithFacebook = createAsyncThunk(
  'signInWithFacebook/user',
  async (): Promise<void> => {
    try {
      await signInWithFacebookFunc();
    } catch (error) {
      throw error;
    }
  }
);

/**
 * (共通)ログイン(サインイン)
 *  googleアカウント認証
 */
export const signInWithGoogle = createAsyncThunk(
  'signInWithGoogle/user',
  async (): Promise<void> => {
    try {
      await signInWithGoogleFunc();
    } catch (error) {
      throw error;
    }
  }
);

/**
 * (clientUsers)アカウント登録
 * PinCode認証要
 */
export const signUp = createAsyncThunk<
  void,
  {
    email: string;
    password: string;
    nickName: string;
    pinCode: string;
  },
  {
    state: { user: State };
  }
>('signUp/user', async (args, thankApi): Promise<void> => {
  const { email, password, nickName, pinCode } = args;
  const { sentPinCode } = thankApi.getState().user.userInfo;
  // Passwordのvalidation チェック
  try {
    isInvalidPasswordAtSignUp(args.password);
  } catch (error) {
    throw error;
  }
  // 会員登録コードの一致 チェック
  try {
    isInvalidPinCode(pinCode, sentPinCode);
  } catch (error) {
    throw error;
  }

  // 登録
  try {
    await signUpFunc(email, password, nickName);
  } catch (error) {
    /* TODO: 登録後の処理実装後削除予定 */
    throw error;
  }
});

/**
 * (clientUsers)アカウント登録
 * PinCode認証不要, 匿名アカウント登録
 */
export const signUpWithoutPin = createAsyncThunk<
  void,
  {
    email: string;
    password: string;
    nickName: string;
  },
  {
    state: { user: State };
  }
>('signUp/user', async (args): Promise<void> => {
  const { email, password, nickName } = args;

  // emailのvalidation チェック
  try {
    isInvalidEmail(args.email);
  } catch (error) {
    throw error;
  }

  // Passwordのvalidation チェック
  try {
    isInvalidPasswordAtSignUp(args.password);
  } catch (error) {
    throw error;
  }

  // 登録
  try {
    await signUpAnonymousFunc(email, password, nickName);
  } catch (error) {
    /* TODO: 登録後の処理実装後削除予定 */
    throw error;
  }
});

/**
 * (FP)アカウント登録
 */
export const signUpFp = createAsyncThunk(
  'signUpFp/user',
  async (args: {
    email: string;
    password: string;
    nickName: string;
    belongs: string | undefined;
    age: number | undefined;
    sex: string;
    fpHistory: number | undefined;
    specialtyArray: string[] | undefined;
    deliverable: string | undefined;
    firstTimeFree: string | undefined;
    fee: string | undefined;
    financialProductsArray: string[] | undefined;
    introduction: string | undefined;
    others: string | undefined;
  }): Promise<void> => {
    // validation チェック
    try {
      isInvalidPasswordAtSignUp(args.password);
    } catch (error) {
      throw error;
    }

    // 登録
    try {
      await signUpFpFunc(
        args.email,
        args.password,
        args.nickName,
        args.belongs,
        args.age,
        args.sex,
        args.fpHistory,
        args.specialtyArray,
        args.deliverable,
        args.firstTimeFree,
        args.fee,
        args.financialProductsArray,
        args.introduction,
        args.others
      );
    } catch (error) {
      /* TODO: 登録後の処理実装後削除予定 */
      window.alert(JSON.stringify('登録に失敗しました'));

      throw error;
    }
  }
);

/**
 * (共通)ログアウト(サインアウト)
 */
export const signOut = createAsyncThunk(
  'signOut/user',
  async (): Promise<void> => {
    try {
      await signOutFunc();
    } catch (error) {
      /* TODO: 登録後の処理実装後削除予定 */
      window.alert(JSON.stringify('ログアウトに失敗しました'));
      throw error;
    }
  }
);

/**
 * (共通)会員登録コード送信
 * 会員登録時、記載されたメールアドレスにコードを送信する
 * @param {string} email
 */
export const sendPinCode = createAsyncThunk(
  'sendPinCode/user',
  async (args: { email: string; password: string }): Promise<string> => {
    // Emailのvalidation チェック
    try {
      isInvalidEmail(args.email);
    } catch (error) {
      throw error;
    }

    // メールアドレスへ会員登録コードの送信
    try {
      return await sendPinCodeFunc(args.email);
    } catch (error) {
      throw error;
    }
  }
);

/**
 * パスワードを更新する
 * @param {string} password
 */
export const updatePassowrd = createAsyncThunk(
  'updatePassowrd/user',
  async (args: {
    oldPassword: string;
    newPassword: string;
    newConfirmPassword: string;
  }): Promise<void> => {
    const { oldPassword, newPassword, newConfirmPassword } = args;

    // validation チェック
    try {
      await isInvalidPassword(oldPassword, newPassword, newConfirmPassword);
    } catch (error) {
      throw error;
    }

    // 更新
    try {
      return await updatePassowrdFunc(newPassword);
    } catch {
      /* TODO: */
      const error = {
        message: 'パスワードの更新に失敗しました',
      };
      throw error;
    }
  }
);

/**
 * ニックネームを追加する
 * @param {string} nickname
 */
export const setNickname = createAsyncThunk(
  'addNickname/user',
  async (args: { newNickname: string }): Promise<void> => {
    const { newNickname } = args;

    // 更新
    try {
      return await setNicknameFunc(newNickname);
    } catch {
      /* TODO: */
      const error = {
        message: 'ニックネームの追加に失敗しました',
      };
      throw error;
    }
  }
);

/**
 * ニックネームを更新する
 * @param {string} nickname
 */
export const updateNickname = createAsyncThunk(
  'updateNickname/user',
  async (args: { newNickname: string }): Promise<void> => {
    const { newNickname } = args;

    // 更新
    try {
      return await updateNicknameFunc(newNickname);
    } catch {
      /* TODO: */
      const error = {
        message: 'ニックネームの更新に失敗しました',
      };
      throw error;
    }
  }
);

/**
 * プロフィールを更新する
 * @returns {Promise<ProfileInfo>}
 */
export const getSelectPictureScreenInfo = createAsyncThunk(
  'getSelectPictureScreenInfo/user',
  async (): Promise<State['profileInfo']> => {
    try {
      return await getSelectPictureScreenInfoFunc();
    } catch {
      throw new Error(`データの取得に失敗しました。`);
    }
  }
);

/**
 * マッチング画面への表示・非表示を切り替える
 * @param {boolean} isDisplayed
 * @returns {Promise<void>}
 */
export const updateIsDisplayed = createAsyncThunk(
  'updateIsDisplayed/user',
  async (args: { isDisplayed: boolean }): Promise<void> => {
    const { isDisplayed } = args;

    try {
      return await updateIsDisplayedFunc(isDisplayed);
    } catch {
      throw new Error(`データの更新に失敗しました。`);
    }
  }
);

/**
 * (FP)クライアントユーザ関連の通知メール受け取りの要否を切り替える
 * @param {boolean} receiveNotificationFlag
 * @returns {Promise<void>}
 */
export const switchReceiveNotificationFlag = createAsyncThunk(
  'switchReceiveNotificationFlag/user',
  async (args: { receiveNotificationFlag: boolean }): Promise<void> => {
    const { receiveNotificationFlag } = args;

    try {
      return await switchReceiveNotificationFlagFunc(receiveNotificationFlag);
    } catch {
      throw new Error(`通知受け取り要否の切り替えに失敗しました。`);
    }
  }
);

/* Delete(なし) */

/* Slice */
export const slice = createSlice({
  name: 'user',
  initialState,
  /* reducers */
  reducers: {
    /** 汎用系 */
    /* ニックネームの初期化 */
    initNickName: (state: State): State => ({
      ...state,
      nickName: 'nickName',
    }),
    /* auth.currentUserの情報を保存 */
    setAuthUserInfo: (
      state: State,
      action: PayloadAction<{
        uid: string;
        role: State['role'];
        isAnonymous: boolean;
      }>
    ): State => ({
      ...state,
      uid: action.payload.uid,
      role: action.payload.role,
      isAnonymous: action.payload.isAnonymous,
      isLoggedIn: true,
    }),
    /* auth.currentUserの情報を破棄 */
    removeAuthUserInfo: (state: State): State => ({
      ...state,
      uid: initialState.uid,
      role: initialState.role,
      isLoggedIn: false,
    }),

    /* setter系 */
    /* (登録時)clientUser情報保持
     * 登録時に利用。入力データを保持する。
     * @params - name : ラベル名前
     * @params - value: 入力値
     */
    setUserTextField: (
      state: State,
      action: PayloadAction<{ name: string; value: string }>
    ): State => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        [action.payload.name]: action.payload.value,
      },
    }),
    initUserInfo: (state: State): State => ({
      ...state,
      userInfo: {
        nickName: '',
        sentPinCode: '',
        pinCode: '',
      },
    }),
    /* (登録時)FP情報保持
     * 登録時に利用。入力データを保持する。
     * @params - name : ラベル名前
     * @params - value: 入力値
     */
    setFpTextField: (
      state: State,
      action: PayloadAction<{
        name: string;
        value: string | number | undefined;
      }>
    ): State => ({
      ...state,
      fpInfo: { ...state.fpInfo, [action.payload.name]: action.payload.value },
    }),
    initFpInfo: (state: State): State => ({
      ...state,
      fpInfo: {
        nickName: '',
        belongs: '',
        age: undefined,
        sex: '',
        fpHistory: undefined,
        deliverable: '',
        firstTimeFree: '',
        fee: '',
        introduction: '',
        others: '',
        specialty01: '',
        specialty02: '',
        specialty03: '',
        financialProducts01: '',
        financialProducts02: '',
        financialProducts03: '',
        financialProducts04: '',
        financialProducts05: '',
      },
    }),

    /* エラーメッセージを保持
     * @params なし
     */
    setErrMsg: (state, action): State => ({
      ...state,
      errMsg: action.payload,
    }),
    /* 状態切り替え */
    /* エラーモーダルの状態を切り替え */
    toggleIsErrModalOpen: (state): State => ({
      ...state,
      isErrModalOpen: !state.isErrModalOpen,
    }),
    /* サクセスモーダルの状態を切り替え */
    toggleIsSuccessModalOpen: (state): State => ({
      ...state,
      isSuccessModalOpen: !state.isSuccessModalOpen,
    }),
    /* AuthProviderのloadingの状態を切り替え */
    toggleIsLoadingWithAuthProvider: (state): State => ({
      ...state,
      isLoadingWithAuthProvider: !state.isLoadingWithAuthProvider,
    }),
    /* (FP)プロフィール画面への表示ステータスを切り替え */
    toggleIsDisplayed: (state): State => ({
      ...state,
      profileInfo: {
        ...state.profileInfo,
        isDisplayed: !state.profileInfo.isDisplayed,
      },
    }),
    /* (FP)通知設定のステータスを切り替え */
    toggleReceiveNotificationFlag: (state): State => ({
      ...state,
      profileInfo: {
        ...state.profileInfo,
        receiveNotificationFlag: !state.profileInfo.receiveNotificationFlag,
      },
    }),
  },

  /* extraReducers */
  extraReducers: (builder) => {
    /* signIn */
    builder.addCase(
      signIn.fulfilled,
      (state): State => ({
        ...state,
        isLoggedIn: true,
        isLoading: false,
      })
    );
    builder.addCase(
      signIn.pending,
      (state): State => ({
        ...state,
        isLoggedIn: false,
        isLoading: true,
      })
    );
    builder.addCase(
      signIn.rejected,
      (state): State => ({
        ...state,
        isLoggedIn: false,
        isLoading: false,
      })
    );

    /* initLiff */
    builder.addCase(
      initLiff.fulfilled,
      (state): State => ({
        ...state,
        isLoggedIn: true,
        isLoading: false,
      })
    );
    builder.addCase(
      initLiff.pending,
      (state): State => ({
        ...state,
        isLoggedIn: false,
        isLoading: true,
      })
    );
    builder.addCase(
      initLiff.rejected,
      (state): State => ({
        ...state,
        isLoggedIn: false,
        isLoading: false,
      })
    );

    /* signInWithLine */
    builder.addCase(
      signInWithLine.fulfilled,
      (state): State => ({
        ...state,
        isLoggedIn: true,
        isLoading: false,
      })
    );
    builder.addCase(
      signInWithLine.pending,
      (state): State => ({
        ...state,
        isLoggedIn: false,
        isLoading: true,
      })
    );
    builder.addCase(
      signInWithLine.rejected,
      (state): State => ({
        ...state,
        isLoggedIn: false,
        isLoading: false,
      })
    );

    /* signInWithFacebook */
    builder.addCase(
      signInWithFacebook.fulfilled,
      (state): State => ({
        ...state,
        isLoggedIn: true,
        isLoading: false,
      })
    );
    builder.addCase(
      signInWithFacebook.pending,
      (state): State => ({
        ...state,
        isLoggedIn: false,
        isLoading: true,
      })
    );
    builder.addCase(
      signInWithFacebook.rejected,
      (state): State => ({
        ...state,
        isLoggedIn: false,
        isLoading: false,
      })
    );

    /* signInWithGoogle */
    builder.addCase(
      signInWithGoogle.fulfilled,
      (state): State => ({
        ...state,
        isLoggedIn: true,
        isLoading: false,
      })
    );
    builder.addCase(
      signInWithGoogle.pending,
      (state): State => ({
        ...state,
        isLoggedIn: false,
        isLoading: true,
      })
    );
    builder.addCase(
      signInWithGoogle.rejected,
      (state): State => ({
        ...state,
        isLoggedIn: false,
        isLoading: false,
      })
    );

    /* signUp */
    builder.addCase(
      signUp.fulfilled,
      (state): State => ({
        ...state,
        isLoading: false,
        successTitle: '登録完了',
        successMsg: `アカウントの登録が完了しました。\n`,
        isSuccessModalOpen: true,
        isAnonymous: false,
        userInfo: {
          ...state.userInfo,
        },
      })
    );
    builder.addCase(
      signUp.pending,
      (state): State => ({
        ...state,
        isLoading: true,
        errMsg: '',
        isErrModalOpen: false,
        successMsg: '',
        successTitle: '',
        isSuccessModalOpen: false,
        userInfo: {
          ...state.userInfo,
        },
      })
    );
    builder.addCase(
      signUp.rejected,
      (state, action): State => ({
        ...state,
        isLoading: false,
        errMsg: action.error.message ?? 'システムエラーが発生しました。',
        isErrModalOpen: true,
      })
    );

    /* signUpFp */
    builder.addCase(
      signUpFp.fulfilled,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );
    builder.addCase(
      signUpFp.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      signUpFp.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );

    /* signOut */
    builder.addCase(
      signOut.fulfilled,
      (state): State => ({
        ...state,
        isLoggedIn: false,
        isLoading: false,
      })
    );
    builder.addCase(
      signOut.pending,
      (state): State => ({
        ...state,
        isLoggedIn: true,
        isLoading: true,
      })
    );
    builder.addCase(
      signOut.rejected,
      (state): State => ({
        ...state,
        isLoggedIn: true,
        isLoading: false,
      })
    );
    /* sendPinCode */
    builder.addCase(
      sendPinCode.fulfilled,
      (state, action): State => ({
        ...state,
        isLoading: false,
        userInfo: {
          ...state.userInfo,
          sentPinCode: action.payload,
        },
      })
    );
    builder.addCase(
      sendPinCode.pending,
      (state): State => ({
        ...state,
        isLoading: true,
        errMsg: '',
        isErrModalOpen: false,
      })
    );
    builder.addCase(
      sendPinCode.rejected,
      (state, action): State => ({
        ...state,
        isLoading: false,
        errMsg: action.error.message ?? 'システムエラーが発生しました。',
        isErrModalOpen: true,
      })
    );

    /* getNickName */
    builder.addCase(
      getNickName.fulfilled,
      (state, action): State => ({
        ...state,
        nickName: action.payload,
        isLoading: false,
      })
    );
    builder.addCase(
      getNickName.pending,
      (state): State => ({
        ...state,
        nickName: 'nickName',
        isLoading: true,
      })
    );
    builder.addCase(
      getNickName.rejected,
      (state): State => ({
        ...state,
        nickName: 'nickName',
        isLoading: false,
      })
    );

    /* getSelectPictureScreenInfo */
    builder.addCase(
      getSelectPictureScreenInfo.fulfilled,
      (state, action): State => ({
        ...state,
        isLoading: false,
        profileInfo: action.payload,
      })
    );
    builder.addCase(
      getSelectPictureScreenInfo.pending,
      (state): State => ({
        ...state,
        isLoading: true,
        profileInfo: {
          mail: '',
          imageUrl: '',
          isDisplayed: true,
          receiveNotificationFlag: true,
          isFp: false,
        },
      })
    );
    builder.addCase(
      getSelectPictureScreenInfo.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );

    /* updatePassowrd */
    builder.addCase(
      updatePassowrd.fulfilled,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );
    builder.addCase(
      updatePassowrd.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      updatePassowrd.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );

    /* setNickName */
    builder.addCase(
      setNickname.fulfilled,
      (state): State => ({
        ...state,
        isLoading: false,
        successTitle: 'ニックネーム追加',
        successMsg: `\nニックネームの追加が完了しました。`,
      })
    );
    builder.addCase(
      setNickname.pending,
      (state): State => ({
        ...state,
        isLoading: true,
        errMsg: '',
        isErrModalOpen: false,
        successMsg: '',
        successTitle: '',
        isSuccessModalOpen: false,
      })
    );
    builder.addCase(
      setNickname.rejected,
      (state, action): State => ({
        ...state,
        isLoading: false,
        errMsg: action.error.message ?? 'システムエラーが発生しました。',
      })
    );

    /* updateNickName */
    builder.addCase(
      updateNickname.fulfilled,
      (state): State => ({
        ...state,
        isLoading: false,
        successTitle: 'ニックネーム更新',
        successMsg: `\nニックネームの更新が完了しました。`,
      })
    );
    builder.addCase(
      updateNickname.pending,
      (state): State => ({
        ...state,
        isLoading: true,
        errMsg: '',
        isErrModalOpen: false,
        successMsg: '',
        successTitle: '',
        isSuccessModalOpen: false,
      })
    );
    builder.addCase(
      updateNickname.rejected,
      (state, action): State => ({
        ...state,
        isLoading: false,
        errMsg: action.error.message ?? 'システムエラーが発生しました。',
      })
    );

    /* updateIsDisplayed */
    builder.addCase(
      updateIsDisplayed.fulfilled,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );
    builder.addCase(
      updateIsDisplayed.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      updateIsDisplayed.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );

    /* switchReceiveNotificationFlag */
    builder.addCase(
      switchReceiveNotificationFlag.fulfilled,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );
    builder.addCase(
      switchReceiveNotificationFlag.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      switchReceiveNotificationFlag.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );
  },
});

export default slice.reducer;
export const {
  initNickName,
  setAuthUserInfo,
  removeAuthUserInfo,
  setErrMsg,
  toggleIsErrModalOpen,
  toggleIsSuccessModalOpen,
  toggleIsDisplayed,
  toggleReceiveNotificationFlag,
  toggleIsLoadingWithAuthProvider,

  /* User 登録時に使用する */
  initUserInfo,
  setUserTextField,

  /* FP 登録時に使用する */
  initFpInfo,
  setFpTextField,
} = slice.actions;

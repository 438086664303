import { Stack, Grid, Typography, Divider } from '@mui/material';

import colors from '../../../../../resources/colors';

type Props = {
  basicAnnuity: number;
  employeeAnnuity: number;
  partnerEmployeeAnnuity: number;
  retiredPay: number;
  savingsAmount: number;
  totalRetirementIncome: number;
};

const ResultIncomeBox = ({
  basicAnnuity,
  employeeAnnuity,
  partnerEmployeeAnnuity,
  retiredPay,
  savingsAmount,
  totalRetirementIncome,
}: Props) => (
  <Stack
    spacing={0.1}
    padding={1}
    sx={{
      backgroundColor: colors.skyBlue,
      borderRadius: 2,
    }}>
    <Typography
      fontSize={12}
      fontWeight={800}
      color={colors.facebookColor}
      align="left">
      収入の内訳
    </Typography>
    <DetailContents text="基礎年金" annotation={false} income={basicAnnuity} />
    <Divider />
    <DetailContents
      text="厚生年金"
      annotation={false}
      income={employeeAnnuity}
    />
    <Divider />
    <DetailContents
      text="配偶者厚生年金"
      annotation={false}
      income={partnerEmployeeAnnuity}
    />
    <Divider />
    <DetailContents text="退職金" annotation={false} income={retiredPay} />
    <Divider />
    <DetailContents
      text="現在の貯蓄"
      annotation={false}
      income={savingsAmount}
    />
    <Divider />
    <DetailContents
      text="概算合計"
      annotation={false}
      income={totalRetirementIncome}
    />
  </Stack>
);

type ChildProps = {
  text: string;
  income: number;
  annotation: boolean;
};

const DetailContents = ({ text, annotation, income }: ChildProps) => (
  <Grid container rowSpacing={1} alignItems="center">
    <Grid item xs={4}>
      <Typography
        fontSize={14}
        fontWeight={800}
        color={colors.facebookColor}
        textAlign="center">
        {text}
      </Typography>
    </Grid>
    <Grid item xs={4}>
      {annotation && (
        <Typography
          fontSize={8}
          fontWeight={800}
          color={colors.facebookColor}
          textAlign="right">
          ※一般家庭平均
        </Typography>
      )}
    </Grid>
    <Grid item xs={2}>
      <Typography
        fontSize={20}
        fontWeight={800}
        color={colors.facebookColor}
        textAlign="right">
        {income}
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography fontSize={14} fontWeight={800} color={colors.facebookColor}>
        万円
      </Typography>
    </Grid>
    <Grid item xs={1} />
  </Grid>
);

export default ResultIncomeBox;

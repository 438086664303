import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../resources/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  cardRoot: {
    marginTop: '60px',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'left',
    backgroundColor: colors.white,
    width: '800px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    whiteSpace: 'pre-line', // 改行を許可
  },
  spCardRoot: {
    marginTop: '60px',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'left',
    backgroundColor: colors.white,
    width: '375px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    whiteSpace: 'pre-line', // 改行を許可
  },
}));

export default useStyles;

import React, { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';

import {
  Grid,
  Typography,
  Divider,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../../modules/store';
import { RecommendFpScreenStyles } from './RecommendFpScreenStyle';
import IntroductionHeader from '../../home/introductionHeader/IntroductionHeader';
import { getFpList } from '../../../modules/store/slices/matchingSlice';
import { ClientUser } from '../../../modules/entities/ClientUser';
import { Fp } from '../../../modules/functions/matchingFunctions';
import RecommendFpScreenCard from './components/RecommendFpScreenCard';

const RecommendFpScreen: React.FC = () => {
  // state
  const classes = RecommendFpScreenStyles();
  const countsOfCards = 4;
  const dispatch: AppDispatch = useDispatch();
  // const isFp = getIsFpFromUrl(role);
  const { isLoading, fpList } = useSelector(
    (state: RootState) => state.matching
  );
  const DISPLAY_COUNT = 3;
  const infoList = fpList
    .map((fp, i) => ({ ...fp, score: i + 1 }))
    // .sort((a, b) => b.score - a.score)
    .sort(() => 0.5 - Math.random())
    .slice(0, DISPLAY_COUNT);

  useEffect(() => {
    dispatch(getFpList());
  }, []);

  // functions
  /* カルーセル内に表示するカード数をリストに格納する */
  const getIndexList = (
    counts: number,
    list: Fp[] | ClientUser[]
  ): number[] => {
    const indexList: number[] = Array(Math.floor(list.length / counts)).fill(
      counts
    );
    const surplus = list.length % counts;
    if (surplus > 0) {
      indexList.push(list.length % counts);
    }
    return indexList;
  };

  const UserCarousel = () => (
    <Carousel
      autoPlay={false} // 自動遷移を許可しない
    >
      {getIndexList(countsOfCards, infoList).map((counts, carouselIndex) => (
        <Grid className={classes.cards} key={String(carouselIndex)}>
          {infoList
            .slice(
              carouselIndex * countsOfCards,
              carouselIndex * countsOfCards + counts
            )
            .map((info, index) => (
              <Grid key={String(index)}>
                <RecommendFpScreenCard info={info} />
              </Grid>
            ))}
        </Grid>
      ))}
    </Carousel>
  );

  return (
    <>
      <IntroductionHeader screenName="matching" mode="veryShort" />

      <Grid className={classes.root}>
        {/* header */}
        <Grid className={classes.header}>
          <Divider className={classes.leftDivider} />
          <Typography className={classes.title}>
            おすすめのファイナンシャルプランナー(FP)
          </Typography>
          <Divider className={classes.rightDivider} />
        </Grid>
        <UserCarousel />
      </Grid>

      {/* インジケーター */}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default RecommendFpScreen;

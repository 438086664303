import { createMuiTheme } from '@material-ui/core/styles';
import { common, green, red } from '@material-ui/core/colors';

const oldTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#F5F9FF',
      main: '#00345E',
      dark: '#1e1e1f',
    },
    secondary: {
      light: '#fff5f8',
      main: '#ff3366',
      dark: '#e62958',
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      main: red[500],
      dark: red[700],
    },
    success: {
      main: green[500],
      dark: green[700],
    },
    divider: common.black,
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

export default oldTheme;

import React, { useEffect } from 'react';
import {
  Divider,
  Backdrop,
  CircularProgress,
  CardMedia,
  Button,
  Card,
  Box,
  Typography,
} from '@material-ui/core';
import { Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { AppDispatch, RootState } from '../../../../modules/store';
import { SpMyPageScreenStyles } from './SpUserInfoScreenStyle';
import { getUserUid } from '../../../../modules/functions/userFunctions';
import {
  diagnosisDataSelectors,
  fetchDiagnosisData,
} from '../../../../modules/store/slices/diagnosisDataSlice';
import ResultBox from './components/ResultBox';
import ResultSavingMoneyBox from './components/ResultSavingMoneyBox';
import ResultCostBox from './components/ResultCostBox';
import ResultIncomeBox from './components/ResultIncomeBox';

import toChatImage from '../../../../resources/image/myPage/toChat.png';
import toFpListImage from '../../../../resources/image/myPage/toFpList.png';

const SpMyPageScreen: React.FC = () => {
  // URL に記載された uid を取得する

  // state
  const classes = SpMyPageScreenStyles();
  const history = useHistory();

  const clientId = getUserUid();

  const dispatch: AppDispatch = useDispatch();
  const { isLoading } = useSelector((state: RootState) => state.matching);

  const diagnosisData = useSelector((state: RootState) =>
    diagnosisDataSelectors.selectById(state, clientId)
  );

  useEffect(() => {
    dispatch(fetchDiagnosisData(clientId));
  }, []);

  // functions
  // const onClickProfileBtn = () => {
  //   history.push({ pathname: '/profile' });
  // };
  // const onClickDiagnosisBtn = () => {
  //   history.push({ pathname: '/diagnosis' });
  // };
  // const onClickChatButton = () => {
  //   history.push({ pathname: '/matching/chatRooms' });
  // };
  // const onClickMatchingStartBtn = () => {
  //   history.push({
  //     pathname: `/matching/fp-list/`,
  //   });
  // };

  return (
    <>
      <Grid className={classes.root}>
        <Grid className={classes.container}>
          {/* screen */}
          <Grid>
            {diagnosisData !== undefined && (
              <Grid
                container
                sx={{
                  textAlign: 'center',
                  justifyContent: 'center',
                }}>
                <ResultBox result={diagnosisData.totalRequiredSavings} />

                <Link className={classes.link} to="/diagnosis">
                  診断をもう一度する
                </Link>

                <Grid className={classes.componentContainer}>
                  <ResultSavingMoneyBox
                    totalRequiredSavings={diagnosisData.totalRequiredSavings}
                    requiredSavingsPerMonth={
                      diagnosisData.requiredSavingsPerMonth !== undefined
                        ? diagnosisData.requiredSavingsPerMonth
                        : 0
                    }
                    requiredInvestmentPerMonth={
                      diagnosisData.requiredInvestmentPerMonth !== undefined
                        ? diagnosisData.requiredInvestmentPerMonth
                        : 0
                    }
                  />
                </Grid>
                <Grid className={classes.componentContainer}>
                  <ResultCostBox
                    livingCost={diagnosisData.livingCost}
                    housingCost={diagnosisData.housingCost}
                    careCost={diagnosisData.careCost}
                    medicalExpenses={diagnosisData.medicalExpenses}
                    funeralExpenses={diagnosisData.funeralExpenses}
                    otherExpenses={diagnosisData.otherExpenses}
                    totalExpense={diagnosisData.totalExpense}
                  />
                </Grid>
                <Grid className={classes.componentContainer}>
                  <ResultIncomeBox
                    basicAnnuity={
                      diagnosisData.basicAnnuity !== undefined
                        ? diagnosisData.basicAnnuity
                        : 0
                    }
                    employeeAnnuity={
                      diagnosisData.employeeAnnuity !== undefined
                        ? diagnosisData.employeeAnnuity
                        : 0
                    }
                    partnerEmployeeAnnuity={
                      diagnosisData.partnerEmployeeAnnuity !== undefined
                        ? diagnosisData.partnerEmployeeAnnuity
                        : 0
                    }
                    retiredPay={
                      diagnosisData.retiredPay !== undefined
                        ? diagnosisData.retiredPay
                        : 0
                    }
                    savingsAmount={diagnosisData.savingsAmount}
                    totalRetirementIncome={
                      diagnosisData.totalRetirementIncome !== undefined
                        ? diagnosisData.totalRetirementIncome
                        : 0
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* インジケーター */}
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress />
        </Backdrop>
      </Grid>
    </>
  );
};

export default SpMyPageScreen;

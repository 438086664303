/* eslint-disable import/no-cycle */ // Entity AdapterのSelectors利用のため。
import { configureStore } from '@reduxjs/toolkit';

// slice
import userSlice from './slices/userSlice';
import matchingSlice from './slices/matchingSlice';
import lifePlanningSlice from './slices/lifePlanningSlice';
import learningSlice from './slices/learningSlice';
import chatRoomSlice from './slices/chatRoomSlice';
import clientUserSlice from './slices/clientUserSlice';
import messageSlice from './slices/messageSlice';
import diagnosisDataSlice from './slices/diagnosisDataSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    lifePlanning: lifePlanningSlice,
    matching: matchingSlice,
    learning: learningSlice,
    chatRoom: chatRoomSlice,
    clientUser: clientUserSlice,
    message: messageSlice,
    diagnosis: diagnosisDataSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/functions';

/* 設定まわり */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

/* firebaseの機能をAppで使えるように呼び出しておく */
export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage();
export const provider = new firebase.auth.GoogleAuthProvider();
export const functions = firebaseApp.functions('asia-northeast1');

db.settings({
  ignoreUndefinedProperties: true,
});

// Emulatorの有効化
// if (process.env.NODE_ENV !== 'production') {
//   // Authentication用の設定
//   auth.useEmulator('http://localhost:9099/');
//   // Cloud Functions用の設定
//   functions.useEmulator('localhost', 5001);
//   // Firestore用の設定
//   db.settings({
//     host: 'localhost:8080',
//     ssl: false,
//     ignoreUndefinedProperties: true,
//   });
// }

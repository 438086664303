import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';

export const Column = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      ...sx,
    }}>
    {children}
  </Box>
);

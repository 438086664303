import { Grid, Typography } from '@material-ui/core';

import { Fp } from '../../../../../modules/functions/matchingFunctions';
import individualFpScreenStyle from '../SpIndividualFpScreenStyle';

type Props = {
  fpInfo: Fp;
};

const FpInfoComponent = (props: Props) => {
  // state
  const { fpInfo } = props;
  const classes = individualFpScreenStyle();

  // screen
  return (
    <Grid>
      {fpInfo.belongs ? (
        <Typography className={classes.text}>
          {'\n'}
          {`所属 : ${fpInfo.belongs}`}
        </Typography>
      ) : (
        <Typography className={classes.text}>所属 : 未設定</Typography>
      )}
      {fpInfo.fpHistory ? (
        <Typography className={classes.text}>
          {`FP歴 : ${fpInfo.fpHistory}年`}
        </Typography>
      ) : (
        ''
      )}
      {fpInfo.specialtyArray[0] ? (
        <Typography className={classes.text}>
          {`得意領域 : ${fpInfo.specialtyArray[0]}`}
        </Typography>
      ) : (
        <Typography className={classes.text}>得意領域 : 未設定</Typography>
      )}
      {fpInfo.introduction ? (
        <Typography className={classes.text}>
          自己紹介: {'\n'}
          {fpInfo.introduction}
          {'\n'}
        </Typography>
      ) : (
        ''
      )}
      {fpInfo.others ? (
        <Typography className={classes.text}>
          その他: {'\n'}
          {fpInfo.others}
        </Typography>
      ) : (
        ''
      )}
    </Grid>
  );
};

export default FpInfoComponent;

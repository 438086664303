import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';
import {
  Row,
  RowPropGetter,
  TableOptions,
  useSortBy,
  useTable,
} from 'react-table';
import { defaultTheme } from '../resources/theme/defaultTheme';

const hooks = [useSortBy];

/**
 * グリッドを表示する。[react-table](https://react-table.tanstack.com/)ライブラリを使用し、必要な箇所のみ拡張して実装している。
 * ここで記載されているprops以外にも、ライブラリのuseTable関数の第一引数で指定できるoptionをpropsとして設定できる。
 * @param {function=} props.onRowClick 行をクリックした時のcallback関数
 * @param {function=} props.getRowProps 各行のデータを元に動的に、行に対してpropsを設定する関数
 * @example
 * ```typescript
 * <DataGrid
 *   onRowClick={(i) => {
 *     window.location.href = `/matching/chatRooms/${messages[i].userId}`;
 *   }}
 *   columns={columns} // 各列のデータの定義を指定
 *   data={sortedMessages} // 実際に表示されるデータを指定
 * />
 */
export const DataGrid = <D extends {}>({
  onRowClick,
  getRowProps,
  ...options
}: TableOptions<D> & {
  onRowClick?: (dataArrayIndex: number) => void;
  getRowProps?: (row: Row<D>) => RowPropGetter<D> & { sx?: SxProps };
}) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable<D>(
    options,
    ...hooks
  );

  return (
    <Table {...getTableProps()}>
      <TableHead
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: defaultTheme.palette.common.white,
          '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '1px',
            bottom: 0,
            backgroundColor: defaultTheme.palette.grey[400],
          },
        }}>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) =>
              column.canSort ? (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps)}>
                  <TableSortLabel
                    active={column.isSorted}
                    direction={column.isSortedDesc ? 'desc' : 'asc'}>
                    {column.render('Header')}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableCell>
              )
            )}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);

          const rowProps = getRowProps
            ? row.getRowProps(getRowProps(row))
            : row.getRowProps();
          const { className, ...restRowProps } = rowProps;
          return (
            <TableRow
              {...restRowProps}
              {...(onRowClick && { onClick: () => onRowClick(row.index) })}
              className={`${className || ''} ${
                onRowClick ? '--row-clickable' : ''
              }`}
              hover>
              {row.cells.map((cell) => (
                <TableCell width={cell.column.width} {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

/**
 * 行のデータオブジェクトから特定のkeyを指定し、それを昇順、降順に並び変える[sort関数](https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#description)
 * @param {string} key sortしたいデータオブジェクトのkeyを指定
 * @param {string=} desc 昇順(asc)か降順(desc)を指定する。デフォルトは降順
 */
export const getSortFuncByKy =
  (key: string, desc: 'desc' | 'asc' = 'desc') =>
  (a: any, b: any) => {
    let num = 0;
    if (a[key] > b[key]) num = 1;
    if (a[key] < b[key]) num = -1;
    if (desc === 'asc') num *= -1;
    return num;
  };

export const Cell = {
  Row: ({ children }: { children: ReactNode }) => <Box>{children}</Box>,
  Ellipsis: ({ children, sx }: { children: string; sx?: SxProps }) => (
    <Box
      title={children}
      sx={{
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...sx,
      }}>
      {children}
    </Box>
  ),
};

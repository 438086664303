import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import firebase from 'firebase';

// ↓ Selectors利用のため。↓
// eslint-disable-next-line import/no-cycle
import { RootState } from '..';
import { db } from '../../../firebase';
import { DB_PATH } from '../../../resources/constants';
import { Person } from '../../entities/Person';
import { getPersonInfo } from '../../functions/matchingFunctions';

/**
 * メッセージのEntity
 * @property {string} id
 * @property {string} senderID - メッセージ送信者のID
 * @property {string} text - メッセージ内容
 * @property {Date} sentAt - 送信時間
 */
export type Message = {
  id: string;
  senderID: string;
  text: string;
  sentAt: Date;
};

interface MessageEntityState extends EntityState<Message> {
  destinationUser: Person;
  isLoading: boolean;
}

/* Entity Adapter */
const messagesAdapter = createEntityAdapter<Message>({
  sortComparer: (a, b) => (a.sentAt.getTime() > b.sentAt.getTime() ? 1 : -1),
});
const messageInitialEntityState: MessageEntityState =
  messagesAdapter.getInitialState({
    destinationUser: { id: '', role: 'User', nickName: '取得中' },
    isLoading: false,
  });

/* Firestore Data Converter */
const messageConverter: firebase.firestore.FirestoreDataConverter<Message> = {
  toFirestore: (message: Message): firebase.firestore.DocumentData => ({
    senderID: message.senderID,
    text: message.text,
    sentAt: message.sentAt,
  }),
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): Message => {
    const message = snapshot.data();
    return {
      id: snapshot.id,
      senderID: message.senderID,
      text: message.text,
      sentAt: (message.sentAt as firebase.firestore.Timestamp).toDate(),
    };
  },
};

/* Create */
/**
 * 新規メッセージをFirestoreに登録
 * @param {string} chatRoomID
 * @param {Message} message
 * @return {Message} 登録したMessage
 */
export const sendMessage = createAsyncThunk(
  'message/sendMessage',
  async (args: { chatRoomID: string; message: Message }) =>
    (
      await (
        await db
          .collection(DB_PATH.CHAT_ROOMS)
          .doc(args.chatRoomID)
          .collection(DB_PATH.MESSAGES)
          .withConverter(messageConverter)
          .add(args.message)
      ).get()
    ).data()! // undefinedの場合rejectedに進むように例外を起こす。
);

/* Read */
/**
 * チャットルーム上のメッセージを取得
 * @param {string} chatRoomID
 * @return {Message[]} メッセージ一覧
 */
// TODO: ページング処理
export const fetchMessages = createAsyncThunk(
  'message/fetchMessages',
  async (chatRoomID: string) =>
    (
      await db
        .collection(DB_PATH.CHAT_ROOMS)
        .doc(chatRoomID)
        .collection(DB_PATH.MESSAGES)
        .withConverter(messageConverter)
        .get()
    ).docs.map((doc) => doc.data())
);

/* Update(なし) */

/* Delete(なし) */

/* Others */
/**
 * チャットルームIDからやり取り相手の情報を取得。
 * @param {string} chatRoomID
 * @return {Person} やり取り相手の情報
 */
export const fetchDestinationUser = createAsyncThunk(
  'message/fetchDestinationUser',
  async (chatRoomID: string, thunkAPI) => {
    const { role } = (thunkAPI.getState() as RootState).user;
    const chatRoomDocument = (
      await db.collection(DB_PATH.CHAT_ROOMS).doc(chatRoomID).get()
    ).data();

    if (chatRoomDocument === undefined) {
      throw new Error('チャットルーム取得失敗');
    }
    const destinationUserID =
      role === 'FP'
        ? (chatRoomDocument.userID as string) // 自分がFPなら相手はUser
        : (chatRoomDocument.fpID as string);

    return getPersonInfo(destinationUserID, role === 'FP' ? 'User' : 'FP');
  }
);

/**
 * チャットルームIDからClientUserの情報を取得。
 * @param {string} chatRoomID
 * @return {Person} やり取り相手の情報
 */
export const fetchClientUser = createAsyncThunk(
  'message/fetchClientUser',
  async (chatRoomID: string, thunkAPI) => {
    const chatRoomDocument = (
      await db.collection(DB_PATH.CHAT_ROOMS).doc(chatRoomID).get()
    ).data();

    if (chatRoomDocument === undefined) {
      throw new Error('チャットルーム取得失敗');
    }
    const destinationUserID = chatRoomDocument.userID as string; // 自分がFPなら相手はUser
    const role = 'User';

    return getPersonInfo(destinationUserID, role);
  }
);

/* Slice */
export const slice = createSlice({
  name: 'message',
  initialState: messageInitialEntityState,
  reducers: {
    messageSliceInit: () => messageInitialEntityState,
  },
  extraReducers: (builder) => {
    // sendMessage
    builder.addCase(sendMessage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendMessage.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      messagesAdapter.addOne(state, action.payload);
      state.isLoading = false;
    });
    // fetchMessages
    builder.addCase(fetchMessages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMessages.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchMessages.fulfilled, (state, action) => {
      messagesAdapter.setAll(state, action.payload);
      state.isLoading = false;
    });
    // fetchDestinationUser
    builder.addCase(fetchDestinationUser.fulfilled, (state, action) => {
      state.destinationUser = action.payload;
    });
    // fetchClientUser
    builder.addCase(fetchClientUser.fulfilled, (state, action) => {
      state.destinationUser = action.payload;
    });
  },
});

/* Functions */
/**
 * ユーザーとFPの組み合わせからチャットルームIDを特定する。
 * 初めてのチャットの場合、チャットルームを作成する。
 * @param {string} userID
 * @param {string} fpID
 * @return {Promise<string>} チャットルームID
 */
export async function fetchChatRoomID(
  userID: string,
  fpID: string
): Promise<string> {
  const queryResults = (
    await db
      .collection(DB_PATH.CHAT_ROOMS)
      .where('userID', '==', userID)
      .where('fpID', '==', fpID)
      .get()
  ).docs;

  // 該当するチャットルームがあった場合
  if (queryResults.length !== 0) {
    return queryResults[0].id;
  }

  // チャットルームがなかった場合、新規作成
  return (await db.collection(DB_PATH.CHAT_ROOMS).add({ userID, fpID })).id;
}

export default slice.reducer;
export const { messageSliceInit } = slice.actions;

export const messageSelectors = messagesAdapter.getSelectors<RootState>(
  (state) => state.message
);
export const selectDestinationUser = (state: RootState) =>
  state.message.destinationUser;
export const selectIsLoading = (state: RootState) => state.message.isLoading;

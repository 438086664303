import { Container, Grid, Typography, Button } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import categoryHomeScreenStyles from './SpCategoryHomeScreenStyles';
import { RootState } from '../../../../modules/store';
import { getIsFp } from '../../../../modules/functions/userFunctions';

const SpCategoryHomeScreen = () => {
  // state
  const classes = categoryHomeScreenStyles();
  const history = useHistory();
  const { isLoggedIn } = useSelector((state: RootState) => state.user);

  const isFp = getIsFp();

  // functions
  const onClickSignUpBtn = () => {
    history.push({ pathname: '/signup' });
  };
  const onClickDiagnosisStartBtn = () => {
    history.push({
      pathname: `/diagnosis`,
    });
  };
  const onClickMatchingStartBtn = () => {
    history.push({
      pathname: `/matching/fp-list/`,
    });
  };

  return (
    <div>
      <section className={classes.root}>
        <Container className={classes.container}>
          {/* screen */}
          <Grid className={classes.cardRoot}>
            <Typography className={classes.title}>
              人生100年時代を乗り切る
            </Typography>
            <Typography className={classes.text}>
              {`人生100年時代、
              これからは一人一人が自分の資産を守り、
              管理する必要があると言われています。

              「何かはじめないといけない」と
              ぼんやりと思いながらも、
              何をすればいいかもわからず先延ばしに
              していませんか？

              Money Dash は気軽に相談できる
              お金の専門家に出会えるプラットフォームです。

              `}
            </Typography>

            {isLoggedIn ? (
              <Grid>
                <Grid>
                  <Button
                    className={classes.matchingBtn}
                    onClick={onClickDiagnosisStartBtn}>
                    老後の資金診断を開始する (無料) ▶︎
                  </Button>
                </Grid>
                {isFp ? (
                  ''
                ) : (
                  <Grid>
                    <Button
                      className={classes.matchingBtn}
                      onClick={onClickMatchingStartBtn}>
                      お金の相談相手(FP)を見つける
                    </Button>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid>
                <Button
                  className={classes.signUpBtn}
                  onClick={onClickSignUpBtn}>
                  会員登録
                </Button>
                <Typography className={classes.signUpRoot}>
                  既に会員の方は
                  <Link className={classes.signUplink} to="/signin">
                    こちら
                  </Link>
                </Typography>
              </Grid>
            )}
          </Grid>

          {/* background */}
          <div className={classes.backdrop} />
          <div
            className={clsx(classes.backgroundPosition, classes.background)}
          />
        </Container>
      </section>
    </div>
  );
};

export default SpCategoryHomeScreen;

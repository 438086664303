import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../resources/colors';
import welcomeScreenImage from '../../../resources/image/home/welcomeScreenImage02.png';

const categoryHomeScreenStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    height: 600,
    // Add
    // backgroundColor: colors.mintBlue,
  },
  root2: {
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    // Add
    backgroundColor: colors.white,
  },
  root3: {
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    height: 100,
    // Add
    backgroundColor: colors.paleGray,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(8),
  },
  container2: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
  },
  adImage: {
    height: '450px',
    width: 'auto',
  },
  container3: {
    display: 'flex',
    justifyContent: 'center',
    height: '100px',
    marginTop: theme.spacing(0),
    backgroundColor: colors.paleGray,
  },

  /* background */
  backdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.5,
    zIndex: -1,
  },
  background: {
    backgroundImage: `url(${welcomeScreenImage})`,
    backgroundColor: colors.mintBlue, // 画像が取得できない場合に表示される背景色
    backgroundPosition: 'center',
  },
  backgroundPosition: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
  },

  /* screen */
  cardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  howToUseTitleRoot: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    weight: 800,
  },
  howToUseTextRoot: {
    weight: '700px',
  },
  subTitle: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
  mainTitle: {
    fontSize: '40px',
    fontWeight: 'bold',
  },
  title2: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: colors.facebookColor,
  },
  title3: {
    fontSize: '32px',
    marginTop: theme.spacing(2.5),
    color: colors.twitterColor,
    fontWeight: 'bold',
  },
  title4: {
    fontSize: '32px',
    color: colors.twitterColor,
    fontWeight: 'bold',
    backgroundColor: colors.paleGray,
  },
  description: {
    marginTop: theme.spacing(2.5),
    fontSize: '20px',
    whiteSpace: 'pre-line', // 改行を許可
    lineHeight: '32px', // 行間
  },
  description2: {
    marginTop: theme.spacing(2.5),
    fontSize: '20px',
    whiteSpace: 'pre-line', // 改行を許可
    lineHeight: '32px', // 行間
    // Add
    textAlign: 'left',
  },
  signUpBtn: {
    marginTop: theme.spacing(4),
    width: '180px',
    height: '40px',
    fontSize: '18px',
    color: colors.white,
    backgroundColor: colors.lightBlue,
  },
  signUpRoot: {
    marginTop: theme.spacing(4),
    fontSize: '18px',
  },
  signUplink: {
    color: colors.white,
  },
  diagnosisBtn: {
    marginTop: theme.spacing(4),
    width: '600px',
    height: '60px',
    fontSize: '28px',
    color: colors.white,
    backgroundColor: colors.lightBlue,
  },

  /* 説明（文章） */
  descriptionLongRoot: {
    marginBottom: theme.spacing(12),
  },
  descriptionLong: {
    fontSize: '18px',
    whiteSpace: 'pre-line', // 改行を許可
  },

  /* 説明（画像） */
  howToUseImageRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  howToUseImage: {
    height: '450px',
    width: 'auto',
  },

  /* 説明（動画） */
  howToUseVideoRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(8, '10%', 8, '10%'),
  },

  /* 使い方(画面へのリンク付き) */
  howToUseRoot2: {
    height: '100px',
    backgroundColor: colors.paleBlue,
  },
  howToUseCardRoot: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    boxShadow: 'none',
    alignItems: 'top',
  },
  howToUseCardRoot2: {
    width: '100%',
    height: '100px',
    flexDirection: 'row',
    display: 'flex',
    boxShadow: 'none',
    alignItems: 'center',
    backgroundColor: colors.paleGray,
  },
  howToUseBtn: {
    backgroundColor: colors.lightBlue,
    color: 'white',
    borderColor: 'black',
    fontSize: '20px',
    height: '32px',
    width: '750px',
    marginTop: '30px', // ボトムに配置
  },
}));

export default categoryHomeScreenStyles;

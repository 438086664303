import { Stack, Grid, Typography } from '@mui/material';

import colors from '../../../../resources/colors';

type Props = {
  /** 老後までに貯めるべきお金(丸めた値) */
  totalRequiredSavings: number;
  /** 現金で貯蓄する場合の必要貯金額(万円/月) */
  requiredSavingsPerMonth: number;
  /** 積立運用(年利3%)の場合の必要積立額(万円/月) */
  requiredInvestmentPerMonth: number;
};

const ResultSavingMoneyBox = ({
  totalRequiredSavings,
  requiredSavingsPerMonth,
  requiredInvestmentPerMonth,
}: Props) => (
  <Stack
    spacing={1}
    padding={1}
    sx={{
      backgroundColor: colors.skyBlue,
      borderRadius: 2,
    }}>
    <Typography
      fontSize={12}
      fontWeight={800}
      color={colors.facebookColor}
      align="left">
      このお金を老後までに貯めるには
    </Typography>
    <Grid container>
      <Grid xs={0.75} />
      <Grid item xs={5}>
        <Stack>
          <Typography
            fontSize={10}
            fontWeight={800}
            color={colors.facebookColor}>
            貯金の場合
          </Typography>
          <Grid
            container
            rowSpacing={1}
            alignItems="center"
            sx={{
              width: '100%',
              textAlign: 'center',
              borderBottom: 'solid',
              color: colors.lightBlue,
            }}>
            <Grid item xs={3}>
              <Typography
                fontSize={10}
                fontWeight={800}
                color={colors.facebookColor}>
                毎月
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                fontSize={20}
                fontWeight={800}
                color={colors.facebookColor}>
                {requiredSavingsPerMonth.toFixed(1)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {totalRequiredSavings !== 4500 ? (
                <Typography
                  fontSize={10}
                  fontWeight={800}
                  color={colors.facebookColor}>
                  万円程度
                </Typography>
              ) : (
                <Typography
                  fontSize={10}
                  fontWeight={800}
                  color={colors.facebookColor}>
                  万円以上
                </Typography>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid xs={0.5} />

      <Grid item xs={5}>
        <Stack>
          <Typography
            fontSize={10}
            fontWeight={800}
            color={colors.facebookColor}>
            積立運用(年利3%)の場合
          </Typography>
          <Grid
            container
            rowSpacing={1}
            alignItems="center"
            sx={{
              width: '100%',
              textAlign: 'center',
              borderBottom: 'solid',
              color: colors.lightBlue,
            }}>
            <Grid item xs={3}>
              <Typography
                fontSize={10}
                fontWeight={800}
                color={colors.facebookColor}>
                毎月
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                fontSize={20}
                fontWeight={800}
                color={colors.facebookColor}>
                {requiredInvestmentPerMonth.toFixed(1)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {totalRequiredSavings !== 4500 ? (
                <Typography
                  fontSize={10}
                  fontWeight={800}
                  color={colors.facebookColor}>
                  万円程度
                </Typography>
              ) : (
                <Typography
                  fontSize={10}
                  fontWeight={800}
                  color={colors.facebookColor}>
                  万円以上
                </Typography>
              )}
            </Grid>
          </Grid>
        </Stack>
        <Grid xs={0.75} />
      </Grid>
    </Grid>
  </Stack>
);

export default ResultSavingMoneyBox;

import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../modules/store';
import { addReservedInterviewDate } from '../../../modules/store/slices/chatRoomSlice';
import { Modal } from '../../../newComponents/Modal';
import { Text } from '../../../newComponents/Text';

export const MakeInterviewDoneModal = ({
  data,
  onClose,
  onOk,
}: {
  data?: { chatRoomID: string; name: string };
  onClose: () => void;
  onOk: () => void;
}) => {
  const dispatch: AppDispatch = useDispatch();
  return (
    <Modal
      open={!!data}
      onClose={onClose}
      footerButtons={[
        <Button
          variant="contained"
          color="error"
          onClick={async () => {
            if (data === undefined) return;
            dispatch(addReservedInterviewDate(data.chatRoomID));
            onOk();
          }}>
          はい
        </Button>,
        <Button
          color="inherit"
          onClick={() => {
            onClose();
          }}>
          いいえ
        </Button>,
      ]}>
      <Text variant="body2">
        {data?.name ?? ''}さんとの面談を実施済みにしますか?
      </Text>
      <Text variant="body2" type="error">
        面談数に応じて請求されます。 <br />
        ※この操作は取り消しできません。
      </Text>
    </Modal>
  );
};

import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { iconMap } from './iconMap';

type IconMap = typeof iconMap;

/**
 * iconKey と secondIconKey で指定したアイコンを表示する。
 * @param {string} props.iconKey [iconMap.tsx](https://github.com/GH-KeiKat/fist-plat/blob/78bd4406d183bff14145b10e5396e295d324d5b6/src/components/Icon/iconMap.tsx#L1-L77) に記載されているオブジェクトの第一階層のキーを指定
 * @param {string=} props.secondIconKey オブジェクトがさらに階層が深い場合、その階層のキーを設定
 * @example
 * ```typescript
 * // keyが1階層の場合
 * <Icon iconKey="emotionError" />
 * // keyが2階層の場合、アイコンをグルーピング化し動的に設定したい場合などで使用
 * <Icon
 *   iconKey="sex"
 *   secondIconKey={original.sex === 0 ? 'male' : 'female'}
 * />
 * ```
 */
export const Icon = <T extends keyof IconMap>({
  iconKey,
  secondIconKey,
  sx = {},
  size,
  className,
}: {
  iconKey: T;
  secondIconKey?: keyof IconMap[T];
  sx?: SxProps;
  size?: string;
  className?: string;
}) => {
  const commonProps = {
    sx: {
      ...sx,
      ...(size && {
        '& > svg': {
          width: size,
          height: size,
        },
      }),
    },
  };
  if (secondIconKey)
    return <Box {...commonProps}>{iconMap[iconKey][secondIconKey]}</Box>;
  return <Box {...commonProps}>{iconMap[iconKey]}</Box>;
};

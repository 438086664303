import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  ArrowBackOutlined,
  ChatBubbleOutline,
  PriorityHigh,
} from '@mui/icons-material';
import { format } from 'date-fns';

import { AppDispatch, RootState } from '../modules/store';
import { colors, defaultTheme } from '../resources/theme/defaultTheme';
import { Icon } from '../components/Icon';
import { ClientUser } from '../modules/entities/ClientUser';
import {
  chatRoomSelectors,
  fetchChatRooms,
} from '../modules/store/slices/chatRoomSlice';
import { fetchChatRoomID } from '../modules/store/slices/messageSlice';

export const ClientHeader = ({
  user,
  onMakeInterviewDoneClick,
}: {
  user?: ClientUser;
  onMakeInterviewDoneClick: (modalUserData: {
    chatRoomID: string;
    name: string;
  }) => void;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const { uid } = useSelector((state: RootState) => state.user);
  const chatRooms = useSelector(chatRoomSelectors.selectAll);
  const chatRoom = chatRooms.find(
    (item) => item.destinationUserID === user?.id
  );
  useEffect(() => {
    dispatch(fetchChatRooms());
    // 初期データ取得のため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${colors.grey[300]}`,
        display: 'flex',
        alignItems: 'center',
        p: 2,
        gap: 4,
        minHeight: '86px',
        '& > *': {
          height: '100%',
        },
      }}>
      <Box sx={{ flex: '1', display: 'flex', gap: 2, alignItems: 'center' }}>
        <IconButton size="small" onClick={() => history.goBack()}>
          <ArrowBackOutlined fontSize="small" />
        </IconButton>
        <Icon
          iconKey="sex"
          secondIconKey={(user?.sex ?? '男性') === '男性' ? 'male' : 'female'}
          size="41px"
        />
        <LabelValue label="" value={user?.nickName} />
      </Box>
      <LabelValue
        label="お住まい"
        value={user?.address ? `${user.address}` : '-'}
      />
      <LabelValue label="年齢" value={user?.age ? `${user.age}歳` : '-'} />
      <LabelValue
        label="年収"
        value={user?.income ? `${user.income}万円` : '-'}
      />
      <LabelValue
        label="登録日"
        value={
          user?.registrationDate !== undefined
            ? format(user?.registrationDate, 'yyyy/MM/dd')
            : '-'
        }
      />
      <LabelValue
        label="面談状況"
        value={
          <Button
            sx={{ p: 0 }}
            disabled={!(user?.isDiagnosed ?? false)}
            onClick={() => {
              if (chatRoom !== undefined) {
                onMakeInterviewDoneClick({
                  chatRoomID: chatRoom.id,
                  name: user?.nickName ?? '',
                });
              }
            }}>
            面談済みにする
          </Button>
        }
      />
      <Box>
        <Button
          size="large"
          sx={{ gap: 1 }}
          variant="contained"
          onClick={async () => {
            if (user === undefined) return;
            const chatRoomID = await fetchChatRoomID(user.id, uid);
            history.push({
              pathname: `/matching/chatRooms/FP/${chatRoomID}`,
            });
            window.location.reload(); // 初回遷移時はページNotFoundErrorが返却されるため、再読み込みさせる
          }}>
          <ChatBubbleOutline />
          メッセージ
          {!(chatRoom?.isRead ?? true) && (
            <Box
              sx={{
                position: 'absolute',
                top: '-9px',
                left: '-9px',
                border: `2px solid ${colors.common.white}`,
                backgroundColor: defaultTheme.palette.warning.main,
                width: '20px',
                height: '20px',
                borderRadius: '10px',
                '& > svg': {
                  width: '14px',
                  height: '14px',
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <PriorityHigh />
            </Box>
          )}
        </Button>
      </Box>
    </Box>
  );
};

const LabelValue = ({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
    <Box sx={{ color: colors.grey[600], fontSize: '12px', lineHeight: '170%' }}>
      {label}
    </Box>
    <Box sx={{ fontSize: '18px' }}>{value}</Box>
  </Box>
);

import { Stack, Avatar, Box, Button, Typography, Grid } from '@mui/material';

import iconRobot from '../../../../resources/image/icon_robot.png';
import colors from '../../../../resources/colors';

type Props = {
  /** ロボットアイコンを表示するか */
  isIconShown?: boolean;
  /** ユーザー側のメッセージか */
  isOwnMessage?: boolean;
  /** 一つ前の質問に戻るボタンを表示するか */
  isBackButton?: boolean;
  /** 質問のテキスト */
  text: string;
  onClick: () => void;
};

/**
 * 診断機能(スマホ)の1行分のメッセージボックス(Robotアイコン含む)のコンポーネント
 */
function DiagnosisMessageBox(props: Props) {
  const {
    isIconShown = false,
    isOwnMessage = false,
    isBackButton = false,
    text,
  } = props;
  return (
    <Stack direction="row" spacing={1}>
      {isIconShown ? (
        <Avatar alt="icon" src={iconRobot} variant="rounded" />
      ) : (
        <Avatar
          alt="icon"
          src={iconRobot}
          sx={{ opacity: 0 }}
          variant="rounded"
        />
      )}
      <Box
        padding={1}
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: isOwnMessage ? colors.lightGray : colors.skyBlue,
          borderRadius: 2,
          fontSize: 14,
          textAlign: isOwnMessage ? 'right' : 'left',
        }}>
        {/* ロボのチャット、ユーザーのチャット以外の時(説明文の時)はグレー文字を表示 */}
        {isIconShown || isOwnMessage ? (
          <Typography fontSize={14} whiteSpace="pre-line">
            {text}
          </Typography>
        ) : (
          <Typography
            fontSize={13}
            fontWeight="fontWeightMedium"
            color={colors.gray}
            whiteSpace="pre-line">
            {text}
          </Typography>
        )}
        {isBackButton && (
          <Grid container spacing={1} sx={{ marginTop: 1 }}>
            <Grid item xs />
            <Grid item xs={7}>
              <Button
                variant="text"
                color="inherit"
                sx={{
                  width: '100%',
                  backgroundColor: colors.skyBlue,
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
                onClick={() => {
                  props.onClick();
                }}>
                <Typography fontSize={8}>ひとつ前の質問に戻る</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Stack>
  );
}
export default DiagnosisMessageBox;

import { Backdrop, Box, Fade, Modal as MuiModal } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';
import { colors, defaultTheme } from '../resources/theme/defaultTheme';

export const Modal = ({
  open,
  onClose,
  sx,
  children,
  footerButtons,
}: {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  sx?: SxProps;
  footerButtons?: ReactNode[];
}) => {
  if (!open) return <></>;

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          backgroundColor: colors.backdropColor,
        },
      }}>
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: defaultTheme.palette.common.white,
            border: `1px solid ${defaultTheme.palette.grey[300]}`,
            borderRadius: 1,
            p: 5,
            ...sx,
          }}>
          {children}
          {footerButtons && (
            <Box
              sx={{
                marginTop: 4,
                display: 'flex',
                gap: 2,
                '& > button': {
                  minWidth: '150px',
                },
              }}>
              {footerButtons.map((footerButton) => footerButton)}
            </Box>
          )}
        </Box>
      </Fade>
    </MuiModal>
  );
};

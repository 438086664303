import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import homeImage from '../../../resources/image/screen.png';

import colors from '../../../resources/colors';

export const introductionHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      minHeight: 600,
    },
    container: {
      display: 'flex',
      marginTop: theme.spacing(13),
    },
    backdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: colors.backdropBlack,
      opacity: 0.5,
      zIndex: -1,
    },
    background: {
      backgroundImage: `url(${homeImage})`,
      backgroundColor: colors.mintBlue, // 画像が取得できない場合に表示される背景色
      backgroundPosition: 'center',
    },
    backgroundPosition: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: -2,
    },
    cardRoot: {
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'flex-start',
    },
    uploadRoot: {
      textDecoration: 'underline',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '18px',
    },
    title: {
      whiteSpace: 'pre-line', // 改行を許可
      fontSize: '28px',
      marginBottom: theme.spacing(8),
    },
    description: {
      whiteSpace: 'pre-line', // 改行を許可
      fontSize: '20px',
      lineHeight: '30px', // 行間
      marginBottom: theme.spacing(1),
    },
  })
);

export const introductionHeaderBtnStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      marginBottom: theme.spacing(2),
    },
    simpleBtn: {
      backgroundColor: colors.lightBlue,
      color: colors.white,
      height: '48px',
      width: '296px',
      fontSize: '16px',
      whiteSpace: 'pre-line', // 改行を許可
    },
    downloadBtn: {
      backgroundColor: colors.lightBlue,
      color: colors.white,
      height: '48px',
      width: '296px',
      fontSize: '16px',
      whiteSpace: 'pre-line', // 改行を許可
    },
    matchingBtnRoot: {
      flexDirection: 'column',
      display: 'flex',
    },
    matchingStartBtn: {
      backgroundColor: colors.lightBlue,
      color: colors.white,
      height: '48px',
      width: '296px',
      fontSize: '16px',
    },
    matchingWaitBtn: {
      marginTop: '20px',
      backgroundColor: colors.lightBlue,
      color: colors.white,
      height: '48px',
      width: '296px',
      fontSize: '16px',
    },
    lifePlanningStartBtn: {
      backgroundColor: colors.lightBlue,
      color: colors.white,
      height: '120px',
      width: '200px',
      fontSize: '16px',
    },
    lifePlanningCheckBtn: {
      marginLeft: theme.spacing(6),
      backgroundColor: colors.white,
      color: colors.lightBlue,
      borderColor: colors.lightBlue,
      height: '120px',
      width: '200px',
      fontSize: '16px',
    },
  })
);

export const introductionHeaderShortStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        height: '160px',
        minHeight: 100,
        maxHeight: 1300,
      },
    },
    container: {
      display: 'flex',
      marginTop: theme.spacing(5),
    },
    backdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: colors.backdropBlack,
      opacity: 0.5,
      zIndex: -1,
    },
    background: {
      backgroundImage: `url(${homeImage})`,
      backgroundColor: colors.mintBlue, // 画像が取得できない場合に表示される背景色
    },
    backgroundPosition: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: -2,
    },
    cardRoot: {
      whiteSpace: 'pre-line', // 改行を許可
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'flex-start',
      overflow: 'auto', // TODO: ヘッダー高さが狭く文字が下に溢れるため一旦スクロール対応する
    },
    title: {
      whiteSpace: 'pre-line', // 改行を許可
      fontSize: '28px',
      marginBottom: theme.spacing(1),
    },
    description: {
      whiteSpace: 'pre-line', // 改行を許可
      fontSize: '20px',
      lineHeight: '30px', // 行間
    },
  })
);

export const introductionHeaderVeryShortStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        //        height: '160px',
        height: '10px',
        minHeight: 100,
        maxHeight: 1300,
      },
    },
    container: {
      display: 'flex',
      marginTop: theme.spacing(5),
    },
    backdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: colors.backdropBlack,
      opacity: 0.5,
      zIndex: -1,
    },
    background: {
      backgroundImage: `url(${homeImage})`,
      backgroundColor: colors.mintBlue, // 画像が取得できない場合に表示される背景色
    },
    backgroundPosition: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      zIndex: -2,
    },
    cardRoot: {
      whiteSpace: 'pre-line', // 改行を許可
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'flex-start',
      overflow: 'auto', // TODO: ヘッダー高さが狭く文字が下に溢れるため一旦スクロール対応する
    },
    title: {
      whiteSpace: 'pre-line', // 改行を許可
      fontSize: '28px',
      marginBottom: theme.spacing(1),
    },
    description: {
      whiteSpace: 'pre-line', // 改行を許可
      fontSize: '20px',
      lineHeight: '30px', // 行間
    },
  })
);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase';

import {
  getLearningContentsList as getLearningContentsListFunc,
  getLearningContents as getLearningContentsFunc,
  LearningContents,
} from '../../functions/learningFunctions';

type State = {
  isLoading: boolean;

  // learningContents
  learningContentsList: LearningContents[];
  learningContents: LearningContents;
};

const initialState: State = {
  isLoading: false,

  // learningContents
  learningContentsList: [],
  learningContents: {
    id: '',
    title: '',
    type: 'image',
    description: '',
    others: '',
    publicationDate: firebase.firestore.Timestamp.fromDate(new Date()), // TODO: 初期値を設定
    contentsVideoPath: '',
    contentsImagePathList: [],
  },
};

/**
 * Firestore から学習コンテンツ一覧を取得
 * @return {LearningContents[]} 学習コンテンツの配列
 */
export const getLearningContentsList = createAsyncThunk(
  'getLearningContentsList/learning',
  async (): Promise<LearningContents[]> => {
    try {
      return await getLearningContentsListFunc();
    } catch (error) {
      /* TODO: エラー対応 */
      throw error;
    }
  }
);

/**
 * Firestore から学習コンテンツを取得
 * @param {uid} string
 * @return {LearningContents} 学習コンテンツ
 */
export const getLearningContents = createAsyncThunk(
  'getLearningContents/learning',
  async (uid: string): Promise<LearningContents> => {
    try {
      return await getLearningContentsFunc(uid);
    } catch (error) {
      /* TODO: エラー対応 */
      throw error;
    }
  }
);

export const slice = createSlice({
  name: 'learning',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* getLearningContentsList */
    builder.addCase(
      getLearningContentsList.fulfilled,
      (state, action): State => ({
        ...state,
        learningContentsList: action.payload,
        isLoading: false,
      })
    );
    builder.addCase(
      getLearningContentsList.pending,
      (state): State => ({
        ...state,
        learningContentsList: [],
        isLoading: true,
      })
    );
    builder.addCase(
      getLearningContentsList.rejected,
      (state): State => ({
        ...state,
        learningContentsList: [],
        isLoading: false,
      })
    );

    /* getLearningContents */
    builder.addCase(
      getLearningContents.fulfilled,
      (state, action): State => ({
        ...state,
        learningContents: action.payload,
        isLoading: false,
      })
    );
    builder.addCase(
      getLearningContents.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      getLearningContents.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );
  },
});

export default slice.reducer;

import { Box, GlobalStyles } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';
import AuthCheck from './components/AuthCheck';
import SideNav from './components/SideNav';
import NotFoundErrorScreen from './containers/error/NotFoundErrorScreen';
import NewClientScreen from './containers/matching/newClient/newClientScreen';
import { ClientDetailScreen } from './containers/matching/clientDetail/ClientDetailScreen';
import { MessageHistoryScreen } from './containers/matching/messageHistory/MessageHistoryScreen';
import FirstChatMessageSettingScreen from './containers/setting/firstChatMessageSetting/FirstChatMessageSettingScreen';
import NotificationSetteingScreen from './containers/setting/notificationSetteing/NotificationSetteingScreen';
import CustomersControlScreen from './containers/setting/customersControl/CustomersControlScreen';

import { auth } from './firebase';
import { defaultTheme } from './resources/theme/defaultTheme';

export const newDesignPaths = [
  /\/new-client/gi,
  /\/message-history/gi,
  /\/clients\//gi,
  /\/first-message\//gi,
  /\/notification-setting\//gi,
  /\/customers-control\//gi,
];

export const FPPrivateRoute = ({ component, ...rest }: RouteProps) => {
  const [authState, setAuthState] = useState<
    'beforeAuth' | 'authorized' | 'Unauthorized'
  >('beforeAuth');

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setAuthState(user?.displayName === 'FP' ? 'authorized' : 'Unauthorized');
    });
  }, []);

  if (authState === 'Unauthorized') window.location.href = '/signIn';

  return <Route component={component} {...rest} />;
};

const Routes = () => (
  <Router>
    <GlobalStyles
      styles={{
        'html, body, #root': {
          height: '100%',
          backgroundColor: defaultTheme.palette.common.white,
        },
      }}
    />
    <AuthCheck>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '216px 1fr',
          gridTemplateRows: '1fr',
        }}>
        <SideNav sx={{ height: '100vh', position: 'sticky', top: 0 }} />
        <Switch>
          <FPPrivateRoute path="/new-client" component={NewClientScreen} />
          <FPPrivateRoute
            path="/clients/:clientId"
            component={ClientDetailScreen}
          />
          <FPPrivateRoute
            path="/message-history"
            component={MessageHistoryScreen}
          />
          <FPPrivateRoute
            path="/first-message"
            component={FirstChatMessageSettingScreen}
          />
          <FPPrivateRoute
            path="/notification-setting"
            component={NotificationSetteingScreen}
          />
          <FPPrivateRoute
            path="/customers-control"
            component={CustomersControlScreen}
          />

          <Route component={NotFoundErrorScreen} />
        </Switch>
      </Box>
    </AuthCheck>
  </Router>
);

export default Routes;

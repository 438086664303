import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../../resources/colors';
import welcomeScreenImage from '../../../../resources/image/home/welcomeScreenImage02.png';

const categoryHomeScreenStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    height: 600,
    width: 'auto',
  },
  root2: {
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    // Add
    backgroundColor: colors.white,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
  },
  container2: {
    display: 'flex',
    justifyContent: 'center',
    // marginTop: theme.spacing(10),
    // marginBottom: theme.spacing(5),
  },
  /* background */
  backdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.5,
    zIndex: -1,
  },
  background: {
    backgroundPositionX: 'left',
    backgroundImage: `url(${welcomeScreenImage})`,
    backgroundColor: colors.mintBlue, // 画像が取得できない場合に表示される背景色
    backgroundPosition: 'center',
    width: '100%',
  },
  backgroundPosition: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
  },

  /* screen */
  cardRoot: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  howToUseTitleRoot: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    weight: 800,
  },
  howToUseTextRoot: {
    weight: '350px',
  },
  title: {
    fontSize: '28px',
  },
  title2: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: colors.facebookColor,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  title3: {
    fontSize: '24px',
    marginTop: theme.spacing(2.5),
    color: colors.twitterColor,
    fontWeight: 'bold',
  },
  description: {
    // marginTop: theme.spacing(10),
    fontSize: '20px',
    whiteSpace: 'pre-line', // 改行を許可
    // lineHeight: '32px', // 行間
  },
  description2: {
    marginTop: theme.spacing(0.5),
    fontSize: '15px',
    whiteSpace: 'pre-line', // 改行を許可
    lineHeight: '22px', // 行間
    // Add
    textAlign: 'center',
    color: 'black',
  },
  text: {
    marginTop: theme.spacing(),
    fontSize: '15px',
    whiteSpace: 'pre-line', // 改行を許可
  },
  matchingBtn: {
    marginTop: theme.spacing(4),
    width: '280px',
    height: '56px',
    backgroundColor: colors.white,
    whiteSpace: 'pre-line', // 改行を許可
  },
  btnText: {
    fontSize: '16px',
    whiteSpace: 'pre-line', // 改行を許可
    fontWeight: 600,
  },
  signUpBtn: {
    marginTop: theme.spacing(1),
    width: '160px',
    height: '40px',
    fontSize: '14px',
    color: colors.white,
    backgroundColor: colors.lightBlue,
  },
  signUpRoot: {
    marginTop: theme.spacing(4),
    fontSize: '18px',
  },
  signUplink: {
    color: colors.white,
  },
  fpLogo: {
    height: 32,
    width: 'auto',
  },
  /* 説明（画像） */
  howToUseImage: {
    width: '350px',
    height: 'auto',
  },
  /* 使い方(画面へのリンク付き) */
  howToUseCardRoot: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    boxShadow: 'none',
    alignItems: 'top',
  },
  howToUseBtn: {
    backgroundColor: colors.lightBlue,
    color: 'white',
    borderColor: 'black',
    fontSize: '18px',
    height: '32px',
    width: '350px',
    marginTop: '10px', // ボトムに配置
    marginBottom: '30px', // ボトムに配置
  },
}));

export default categoryHomeScreenStyles;

import { Box, Button } from '@mui/material';
import { format } from 'date-fns';
import { UseTableCellProps } from 'react-table';
import { Cell } from '../../../components/DataGrid';
import { Tag } from '../../../components/Tag';

export type MessageHistoryData = {
  chatRoomID: string;
  userId: string;
  read: boolean;
  lastInterviewDate?: Date;
  sex: number;
  name: string;
  lastComment: string;
  lastCommentDate: Date;
};

export const getColumnComponents = (
  makeInterviewedCallback: (original: MessageHistoryData) => void
) => ({
  read: ({ value }: UseTableCellProps<MessageHistoryData>) =>
    !value ? <Tag>未読あり</Tag> : 'なし',
  lastInterviewDate: ({
    value,
    row: { original },
  }: UseTableCellProps<MessageHistoryData>) =>
    value ? (
      <Box
        sx={{
          lineHeight: '36.5px',
          gap: 1,
          display: 'flex',
          alignItems: 'center',
        }}>
        <Tag>済</Tag>
        {format(new Date(value), 'yyyy/MM')}
      </Box>
    ) : (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          makeInterviewedCallback(original);
        }}>
        面談済みにする
      </Button>
    ),
  name: ({
    value,
    row: { original },
  }: UseTableCellProps<MessageHistoryData>) => <Cell.Row>{value}</Cell.Row>,
  lastComment: ({ value }: UseTableCellProps<MessageHistoryData>) => (
    <Cell.Ellipsis sx={{ width: '600px' }}>{value}</Cell.Ellipsis>
  ),
  lastCommentDate: ({ value }: UseTableCellProps<MessageHistoryData>) =>
    value ? format(new Date(value), 'yyyy/MM/dd hh:mm') : '-',
});

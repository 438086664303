import firebase from 'firebase';

/**
 * DB上の診断結果のデータ型。
 * export禁止。
 */
type DiagnosisDataDocument = {
  // 支出に関わる項目
  marriageStatus: string; // 老後の婚姻状況[ '夫婦' : '単身' ]
  livingStandard60to80: number; // 60歳から80歳の生活にかかるコスト
  livingStandard80to100: number; // 80歳から100歳の生活にかかるコスト

  houseStatus: string; // 老後の住居について[ '持ち家' : '賃貸' ]
  ownedHouseInfo?: {
    purchaseAge: number; // 持ち家購入時の年齢
    price: number; // 持ち家購入時の金額
  };
  houseRent?: number; // 賃貸の家賃
  livingCost: number; // 生活費
  housingCost: number; // 住宅費
  careCost: number; // 介護費
  medicalExpenses: number; // 医療費
  funeralExpenses: number; // 葬儀/死後精算費用
  otherExpenses: number; // その他費用
  basicAnnuity?: number; // 基礎年金
  employeeAnnuity?: number; // 厚生年金
  annuity?: number; // 年金の合計
  partnerEmployeeAnnuity?: number; // 配偶者の厚生年金

  // 収入に関わる項目
  age: number; // 年齢
  address: string; // 住所
  sex?: '男性' | '女性'; // 性別
  lifeSpan?: number; // 想定寿命
  income: number; // 年収
  estimatedIncome: number; // 想定最大年収
  isEmployee: boolean; // 会社員かどうか
  savingsAmount: number; // 貯蓄額
  retiredPay?: number; // 退職金
  incomeAfterRetired?: number; // 老後の定期収入(月単位)
  partnerIncome?: number; // 配偶者の年収

  // 集計項目
  totalExpense: number; // 老後に必要なお金の概算合計(支出)
  totalRequiredSavings: number; // 老後までに貯蓄するべき金額
  requiredSavingsPerMonth?: number; // 毎月の必要な貯金額
  requiredInvestmentPerMonth?: number; // 積立運用の場合の毎月の積立額

  roundingTotalRequiredSavings: number; // 老後までに貯蓄するべき金額(丸めた値)
  roundingRequiredSavingsPerMonth?: number; // 毎月の必要な貯金額(丸めた値)
  roundingRequiredInvestmentPerMonth?: number; // 積立運用の場合の毎月の積立額(丸めた値)

  totalRetirementIncome?: number; // 老後の収入合計

  // 診断自体に関する項目
  anxietyLevel?: number;
  /** 「診断を受けてどう思ったか」
   * 0:診断結果を解説して欲しい
   * 1:将来のお金に不安を感じた
   * 2:よくわからなかった
   * 3:将来のお金に不安は感じていない 9:初期値 */

  diagnosisDate: firebase.firestore.Timestamp;
};

/**
 * Storeで保持する診断結果のデータ型。
 * DBには以下の項目は保存しない。
 */
export type DiagnosisData = {
  uid: string;
  anxietyLevel?: DiagnosisDataDocument['anxietyLevel'];
  // 支出に関わる項目
  marriageStatus: string; // 老後の婚姻状況[ '夫婦' : '単身' ]
  livingStandard60to80: number; // 60歳から80歳の生活にかかるコスト
  livingStandard80to100: number; // 80歳から100歳の生活にかかるコスト

  houseStatus: string; // 老後の住居について[ '持ち家' : '賃貸' ]
  ownedHouseInfo?: {
    purchaseAge: number; // 持ち家購入時の年齢
    price: number; // 持ち家購入時の金額
  };
  houseRent?: number; // 賃貸の家賃
  livingCost: number; // 生活費
  housingCost: number; // 住宅費
  careCost: number; // 介護費
  medicalExpenses: number; // 医療費
  funeralExpenses: number; // 葬儀/死後精算費用
  otherExpenses: number; // その他費用
  basicAnnuity?: number; // 基礎年金
  employeeAnnuity?: number; // 厚生年金
  annuity?: number; // 年金の合計
  partnerEmployeeAnnuity?: number; // 配偶者の厚生年金

  // 収入に関わる項目
  age: number; // 年齢
  address: string; // 住所
  sex: '男性' | '女性' | '未設定'; // 性別
  lifeSpan?: number; // 想定寿命
  income: number; // 年収
  estimatedIncome: number; // 想定最大年収
  isEmployee: boolean; // 会社員かどうか
  savingsAmount: number; // 貯蓄額
  retiredPay?: number; // 退職金
  incomeAfterRetired?: number; // 老後の定期収入(月単位)
  partnerIncome?: number; // 配偶者の年収

  // 集計項目
  totalExpense: number; // 老後に必要なお金の概算合計
  totalRequiredSavings: number; // 老後までに貯蓄するべき金額
  requiredSavingsPerMonth?: number; // 毎月の必要な貯金額
  requiredInvestmentPerMonth?: number; // 積立運用の場合の毎月の積立額

  roundingTotalRequiredSavings: number; // 老後までに貯蓄するべき金額(丸めた値)
  roundingRequiredSavingsPerMonth?: number; // 毎月の必要な貯金額(丸めた値)
  roundingRequiredInvestmentPerMonth?: number; // 積立運用の場合の毎月の積立額(丸めた値)

  totalRetirementIncome?: number; // 老後の収入合計
};

/**
 * DBから取得した診断結果データが正しいか確認する。
 */
function isDiagnosisDataDocument(
  value: unknown
): value is DiagnosisDataDocument {
  const diagnosisData: any = value;

  if (typeof diagnosisData !== 'object') {
    return false;
  }

  if (
    !(
      'marriageStatus' in diagnosisData &&
      (diagnosisData.marriageStatus === '夫婦' ||
        diagnosisData.marriageStatus === '単身')
    )
  ) {
    return false;
  }

  if (!('houseStatus' in diagnosisData)) {
    return false;
  }
  switch (diagnosisData.houseStatus) {
    case '持ち家':
      if (
        !('ownedHouseInfo' in diagnosisData) ||
        typeof diagnosisData.ownedHouseInfo !== 'object'
      ) {
        return false;
      }
      if (
        !('purchaseAge' in diagnosisData.ownedHouseInfo) ||
        typeof diagnosisData.ownedHouseInfo.purchaseAge !== 'number'
      ) {
        return false;
      }
      if (
        !('price' in diagnosisData.ownedHouseInfo) ||
        typeof diagnosisData.ownedHouseInfo.price !== 'number'
      ) {
        return false;
      }
      break;
    case '賃貸':
      if (
        !('houseRent' in diagnosisData) ||
        typeof diagnosisData.houseRent !== 'number'
      ) {
        return false;
      }
      break;
    default:
      return false;
  }

  if (
    !('livingCost' in diagnosisData) ||
    typeof diagnosisData.livingCost !== 'number'
  ) {
    return false;
  }
  if (
    !('housingCost' in diagnosisData) ||
    typeof diagnosisData.housingCost !== 'number'
  ) {
    return false;
  }
  if (
    !('careCost' in diagnosisData) ||
    typeof diagnosisData.careCost !== 'number'
  ) {
    return false;
  }
  if (
    !('medicalExpenses' in diagnosisData) ||
    typeof diagnosisData.medicalExpenses !== 'number'
  ) {
    return false;
  }
  if (
    !('funeralExpenses' in diagnosisData) ||
    typeof diagnosisData.funeralExpenses !== 'number'
  ) {
    return false;
  }
  if (
    !('otherExpenses' in diagnosisData) ||
    typeof diagnosisData.otherExpenses !== 'number'
  ) {
    return false;
  }
  if (
    !('basicAnnuity' in diagnosisData) ||
    typeof diagnosisData.basicAnnuity !== 'number'
  ) {
    return false;
  }
  if (
    !('employeeAnnuity' in diagnosisData) ||
    typeof diagnosisData.employeeAnnuity !== 'number'
  ) {
    return false;
  }
  if (
    !('annuity' in diagnosisData) ||
    typeof diagnosisData.annuity !== 'number'
  ) {
    return false;
  }
  if (!('age' in diagnosisData) || typeof diagnosisData.age !== 'number') {
    return false;
  }
  if (
    !('address' in diagnosisData) ||
    typeof diagnosisData.address !== 'string'
  ) {
    return false;
  }
  if (
    'sex' in diagnosisData &&
    diagnosisData.sex !== '男性' &&
    diagnosisData.sex !== '女性'
  ) {
    return false;
  }
  if (
    !('lifeSpan' in diagnosisData) ||
    typeof diagnosisData.lifeSpan !== 'number'
  ) {
    return false;
  }
  if (
    !('income' in diagnosisData) ||
    typeof diagnosisData.income !== 'number'
  ) {
    return false;
  }
  if (
    !('estimatedIncome' in diagnosisData) ||
    typeof diagnosisData.estimatedIncome !== 'number'
  ) {
    return false;
  }
  if (
    !('isEmployee' in diagnosisData) ||
    typeof diagnosisData.isEmployee !== 'boolean'
  ) {
    return false;
  }
  if (
    !('savingsAmount' in diagnosisData) ||
    typeof diagnosisData.savingsAmount !== 'number'
  ) {
    return false;
  }
  if (
    !('retiredPay' in diagnosisData) ||
    typeof diagnosisData.retiredPay !== 'number'
  ) {
    return false;
  }
  if (
    !('incomeAfterRetired' in diagnosisData) ||
    typeof diagnosisData.incomeAfterRetired !== 'number'
  ) {
    return false;
  }
  if (
    !('partnerIncome' in diagnosisData) ||
    typeof diagnosisData.partnerIncome !== 'number'
  ) {
    return false;
  }
  if (
    !('partnerEmployeeAnnuity' in diagnosisData) ||
    typeof diagnosisData.partnerEmployeeAnnuity !== 'number'
  ) {
    return false;
  }
  if (!(diagnosisData.diagnosisDate instanceof firebase.firestore.Timestamp)) {
    return false;
  }

  return true;
}

/* Firestore Data Converter */
export const diagnosisDataConverter: firebase.firestore.FirestoreDataConverter<DiagnosisData> =
  {
    toFirestore: (data: DiagnosisData): firebase.firestore.DocumentData => {
      // バリデーション
      switch (data.houseStatus) {
        case '持ち家': {
          if (data.ownedHouseInfo === undefined) {
            throw new Error('ownedHouseInfo is undifined.');
          }
          break;
        }
        case '賃貸': {
          if (data.houseRent === undefined) {
            throw new Error('houseRent is undeifined.');
          }
          break;
        }
        default:
          throw new Error('houseStatus is invalid.');
      }
      if (
        data.careCost < 0 ||
        data.estimatedIncome < 0 ||
        data.funeralExpenses < 0 ||
        (data.houseRent ?? 0) < 0 ||
        data.income < 0 ||
        data.livingCost < 0 ||
        data.housingCost < 0 ||
        data.medicalExpenses < 0 ||
        data.otherExpenses < 0 ||
        (data.ownedHouseInfo?.purchaseAge ?? 0) < 0 ||
        (data.ownedHouseInfo?.price ?? 0) < 0
      ) {
        throw new Error('value of diagnosisData is invalid');
      }
      // DB上に保存したいデータのみ抽出。
      return {
        marriageStatus: data.marriageStatus,
        livingStandard60to80: data.livingStandard60to80,
        livingStandard80to100: data.livingStandard80to100,
        houseStatus: data.houseStatus,
        ownedHouseInfo: data.ownedHouseInfo,
        houseRent: data.houseRent,
        livingCost: data.livingCost,
        housingCost: data.housingCost,
        careCost: data.careCost,
        medicalExpenses: data.medicalExpenses,
        funeralExpenses: data.funeralExpenses,
        otherExpenses: data.otherExpenses,
        basicAnnuity: data.basicAnnuity,
        employeeAnnuity: data.employeeAnnuity,
        annuity: data.annuity,
        age: data.age,
        address: data.address,
        sex: ['男性', '女性'].includes(data.sex) ? data.sex : undefined,
        lifeSpan: data.lifeSpan,
        income: data.income,
        estimatedIncome: data.estimatedIncome,
        isEmployee: data.isEmployee,
        savingsAmount: data.savingsAmount,
        retiredPay: data.retiredPay,
        incomeAfterRetired: data.incomeAfterRetired,
        partnerIncome: data.partnerIncome,
        partnerEmployeeAnnuity: data.partnerEmployeeAnnuity,
        totalExpense: data.totalExpense,
        totalRequiredSavings: data.totalRequiredSavings,
        requiredSavingsPerMonth: data.requiredSavingsPerMonth,
        requiredInvestmentPerMonth: data.requiredInvestmentPerMonth,
        roundingTotalRequiredSavings: data.roundingTotalRequiredSavings,
        roundingRequiredSavingsPerMonth: data.roundingRequiredSavingsPerMonth,
        roundingRequiredInvestmentPerMonth:
          data.roundingRequiredInvestmentPerMonth,
        totalRetirementIncome: data.totalRetirementIncome,
        anxietyLevel: data.anxietyLevel,
        diagnosisDate: firebase.firestore.Timestamp.now(),
      };
    },
    fromFirestore: (
      snapshot: firebase.firestore.QueryDocumentSnapshot
    ): DiagnosisData => {
      const diagnosisData = snapshot.data();
      // バリデーション
      const uid = snapshot.ref.parent.parent?.id;
      if (uid === undefined) {
        throw new Error();
      }
      if (!isDiagnosisDataDocument(diagnosisData)) {
        throw new Error(
          `document of diagnoses is invalid. uid: ${uid}, document ID: ${snapshot.id}`
        );
      }

      return {
        ...diagnosisData,
        uid,
        sex: diagnosisData.sex ?? '未設定',
      };
    },
  };

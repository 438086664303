import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../resources/colors';

export const SpMyPageScreenStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.paleWhite,
  },
  container: {
    flexDirection: 'row',
    display: 'flex',
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: theme.spacing(0, 1, 1, 1),
  },
  componentContainer: {
    paddingBottom: theme.spacing(1),
    width: '320px',
  },
  divider: {
    backgroundColor: colors.darkGray,
  },
  link: {
    color: colors.lightBlue,
    fontSize: '14px',
    fontWeight: 400,
    textDecoration: 'none',
    paddingBottom: theme.spacing(1),
  },
  text: {
    textAlign: 'center',
    fontSize: '16px',
    whiteSpace: 'pre-line', // 改行を許可
  },
  subText: {
    textAlign: 'center',
    fontSize: '14px',
    whiteSpace: 'pre-line', // 改行を許可
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },

  primaryCard: {
    backgroundColor: colors.white,
    height: '100px',
    width: '280px',
    margin: theme.spacing(2, 2, 2, 2),
    // boxShadow: '4px 4px 4px #808080',
    whiteSpace: 'pre-line', // 改行を許可
    border: 'solid',
    borderWidth: 'thin',
  },
}));

import _ from 'lodash';
import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../modules/store';
import { auth } from '../firebase';

/**
 * 【使い方】
 *
 * ① PrivateRoute を import
 * ・import PrivateRoute from '../{随時}/../components/PrivateRoute'
 *
 * ② PrivateRoute を配置する
 * ・<PrivateRoute
 *     path="/matching/home"
 *     exact
 *     component={MatchingHomeScreen}
 *   />
 */

// ログイン済か判定し、未済なら新規登録画面へ遷移させる
export const PrivateRouteFunc: React.FC<RouteProps> = (props) => {
  const history = useHistory();
  const { isAnonymous } = useSelector((state: RootState) => state.user);

  const checkIsLoggedIn = async () => {
    // ユーザー情報取得
    auth.onAuthStateChanged(async (user) => {
      if (!user || isAnonymous) {
        // ログイン未済：新規登録画面へ遷移
        history.push({ pathname: '/signUp' });
      }
    });
  };

  useEffect(() => {
    checkIsLoggedIn();
  }, []);

  const rest = _.omit(props, ['component']);
  return <Route {...rest} render={() => <Route {...props} />} />;
};

export default PrivateRouteFunc;

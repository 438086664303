import firebase from 'firebase';
import { db } from '../../firebase';

const LEARNING_PATH = 'learning';

export interface LearningContentsOfFirestore {
  title: string;
  type: 'image' | 'video';
  description: string;
  others: string;
  publicationDate: firebase.firestore.Timestamp;
  contentsVideoPath: string;
  contentsImagePathList: string[];
}

export interface LearningContents extends LearningContentsOfFirestore {
  id: string;
}

/**
 * LearningContents I/O コンバーター
 */
const learningContentsListConverter: firebase.firestore.FirestoreDataConverter<LearningContentsOfFirestore> =
  {
    toFirestore: (
      learningContents: LearningContentsOfFirestore
    ): firebase.firestore.DocumentData => learningContents,
    fromFirestore: (
      snapshot: firebase.firestore.QueryDocumentSnapshot
    ): LearningContentsOfFirestore => {
      const learningContentsData =
        snapshot.data() as LearningContentsOfFirestore;
      return {
        title: learningContentsData.title ?? '',
        type: learningContentsData.type ?? '',
        description: learningContentsData.description ?? '',
        others: learningContentsData.others ?? '',
        publicationDate: learningContentsData.publicationDate, // TODO: 空の場合の処理
        contentsVideoPath: learningContentsData.contentsVideoPath ?? '',
        contentsImagePathList: learningContentsData.contentsImagePathList ?? [],
      };
    },
  };

/**
 * Firestore から学習コンテンツ一覧を取得
 * @return {LearningContents[]} 学習コンテンツの配列
 */
export async function getLearningContentsList(): Promise<LearningContents[]> {
  const querySnapshot = await db
    .collection(LEARNING_PATH)
    .withConverter(learningContentsListConverter)
    .get();
  const learningContentsList = await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const learningContentsData = doc.data();
      return {
        ...learningContentsData,
        id: doc.id,
      };
    })
  );
  return learningContentsList;
}

/**
 * Firestore から学習コンテンツを取得
 * @param {uid} string
 * @return {LearningContents} 学習コンテンツ
 */
export async function getLearningContents(
  uid: string
): Promise<LearningContents> {
  const documentSnapshot = await db
    .collection(LEARNING_PATH)
    .doc(uid)
    .withConverter(learningContentsListConverter)
    .get();
  const learningContents =
    documentSnapshot.data() as LearningContentsOfFirestore;
  return {
    ...learningContents,
    id: uid,
  };
}

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { ComponentProps } from 'react';

export const Select = ({
  items,
  ...props
}: {
  items: { label: string; value: string | number }[];
} & ComponentProps<typeof MuiSelect>) => (
  <FormControl fullWidth>
    {props.label && <InputLabel>{props.label}</InputLabel>}
    <MuiSelect {...props}>
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
);

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Button,
  CircularProgress,
  Backdrop,
  Typography,
  Grid,
  TextField,
} from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';

import useStyles from './ProfileScreenStyles';
import { AppDispatch, RootState } from '../../../modules/store';
import {
  setErrMsg,
  toggleIsErrModalOpen,
  toggleIsSuccessModalOpen,
  updateNickname,
  getNickName,
  getSelectPictureScreenInfo,
  updateIsDisplayed,
  toggleIsDisplayed,
} from '../../../modules/store/slices/userSlice';
import { isTextInvalid } from '../../../components/validation';
import Model from '../../../components/Modal';

const ProfileScreen: React.FC = () => {
  const classes = useStyles();

  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 766px)' });

  const dispatch: AppDispatch = useDispatch();

  const [textField, setTextField] = React.useState({ newNickname: '' });
  const { newNickname } = textField;

  const [isDisplayedModalOpen, setIsDisplayedModalOpen] = React.useState(false);

  const [disabled, setDisabled] = React.useState(true);
  const {
    nickName,
    isLoading,
    errMsg,
    isErrModalOpen,
    isSuccessModalOpen,
    successMsg,
    successTitle,
    profileInfo,
  } = useSelector((state: RootState) => state.user);

  // 登録ボタン活性・非活性処理
  const isDisabled = () => isTextInvalid(newNickname);

  React.useEffect(() => {
    setDisabled(isDisabled());
  }, [newNickname]);

  React.useEffect(() => {
    dispatch(getSelectPictureScreenInfo());
  }, []);

  const onChangeTextField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setTextField({ ...textField, [name]: value });
  };

  const onClickUpdateBtn = () => {
    dispatch(updateNickname({ newNickname }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getNickName());
        dispatch(toggleIsSuccessModalOpen());
      })
      .catch((error) => {
        dispatch(setErrMsg(error.message));
        dispatch(toggleIsErrModalOpen());
      });
    setTextField({ newNickname: '' });
  };

  const onClickCloseErrModalBtn = () => {
    dispatch(toggleIsErrModalOpen());
  };

  const onClickCloseSuccessModalBtn = () => {
    dispatch(toggleIsSuccessModalOpen());
    setTextField({ newNickname: '' });
  };

  const onClickIsDisplayedBtn = () => {
    setIsDisplayedModalOpen(true);
  };

  const onClickCloseIsDisplayedModalBtn = () => {
    dispatch(
      updateIsDisplayed({ isDisplayed: !profileInfo.isDisplayed })
    ).finally(() => {
      dispatch(toggleIsDisplayed());
    });
    setIsDisplayedModalOpen(false);
  };

  return (
    <div>
      {/* ---------- web画面用表示領域 (min-width: 767px) ---------- */}
      {isDesktop && (
        <Grid className={classes.root}>
          <Grid className={classes.cardRoot}>
            <Typography className={classes.title}>プロフィール</Typography>

            {/* TODO: 画像選択できるようにする */}
            <Avatar
              src={profileInfo.imageUrl}
              sx={{
                width: 120,
                height: 120,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 3,
              }}
            />

            <Card className={classes.textCardRoot}>
              <Typography className={classes.pictureTypography}>
                {`メールアドレス : ${profileInfo.mail}`}
              </Typography>
              <Typography className={classes.pictureTypography}>
                {`ニックネーム : ${nickName}`}
              </Typography>
              <TextField
                className={classes.pictureTextField}
                value={newNickname}
                variant="outlined"
                name="newNickname"
                placeholder={nickName}
                onChange={onChangeTextField}
                size="small"
              />
              <Button
                className={classes.UpdateNickNameButton}
                onClick={onClickUpdateBtn}
                disabled={disabled}
                classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
              >
                更新
              </Button>
              {profileInfo.isFp && (
                <Typography className={classes.isDisplayedCheckText}>
                  マッチング画面への自分の表示状況 :
                  {profileInfo.isDisplayed ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className={classes.displayedText}>
                        表示
                      </Typography>
                      <Button
                        className={classes.switchDisplayBtn}
                        onClick={onClickIsDisplayedBtn}>
                        切り替え
                      </Button>
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className={classes.nonDisplayedText}>
                        非表示
                      </Typography>
                      <Button
                        className={classes.switchDisplayBtn}
                        onClick={onClickIsDisplayedBtn}>
                        切り替え
                      </Button>
                    </Box>
                  )}
                </Typography>
              )}
            </Card>

            {/* modal */}
            <Model
              isOpen={isErrModalOpen}
              title=""
              body={errMsg}
              buttonName="もう一度入力する"
              onClickCloseModalBtn={onClickCloseErrModalBtn}
            />
            <Model
              isOpen={isSuccessModalOpen}
              title={successTitle}
              body={successMsg}
              buttonName="閉じる"
              onClickCloseModalBtn={onClickCloseSuccessModalBtn}
            />
            <Model
              isOpen={isDisplayedModalOpen}
              title="表示切り替え"
              body={
                profileInfo.isDisplayed
                  ? `マッチング画面において、\nあなたとやり取りを開始していないユーザーが\nあなたを見つけられなくなります。`
                  : `マッチング画面において、\n新しいユーザーがあなたを見つけられるようになります。`
              }
              buttonName="表示を切り替える"
              onClickCloseModalBtn={onClickCloseIsDisplayedModalBtn}
            />

            {/* インジケーター */}
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress />
            </Backdrop>
          </Grid>
        </Grid>
      )}
      {/* ---------- スマホ画面用表示領域 (max-width: 766px) ---------- */}
      {isMobile && (
        <Grid className={classes.root}>
          <Grid className={classes.spCardRoot}>
            <Typography className={classes.spTitle}>プロフィール</Typography>

            {/* TODO: 画像選択できるようにする */}
            <Avatar
              src={profileInfo.imageUrl}
              sx={{
                width: 120,
                height: 120,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 3,
              }}
            />

            <Card className={classes.spTextCardRoot}>
              <Typography className={classes.pictureTypography}>
                {`メールアドレス :${profileInfo.mail}`}
              </Typography>
              <Typography className={classes.pictureTypography}>
                {`ニックネーム : ${nickName}`}
              </Typography>
              <TextField
                className={classes.pictureTextField}
                value={newNickname}
                variant="outlined"
                name="newNickname"
                placeholder={nickName}
                onChange={onChangeTextField}
                size="small"
              />
              <Button
                className={classes.UpdateNickNameButton}
                onClick={onClickUpdateBtn}
                disabled={disabled}
                classes={{ disabled: classes.disableButton }} // 非活性時のスタイル
              >
                更新
              </Button>
              {profileInfo.isFp && (
                <Grid>
                  <Typography className={classes.isDisplayedCheckText}>
                    マッチング画面への自分の表示状況 :
                  </Typography>

                  {profileInfo.isDisplayed ? (
                    <Grid>
                      <Typography className={classes.displayedText}>
                        表示
                      </Typography>
                      <Button
                        className={classes.spSwitchDisplayBtn}
                        onClick={onClickIsDisplayedBtn}>
                        切り替え
                      </Button>
                    </Grid>
                  ) : (
                    <Grid>
                      <Typography className={classes.nonDisplayedText}>
                        非表示
                      </Typography>
                      <Button
                        className={classes.spSwitchDisplayBtn}
                        onClick={onClickIsDisplayedBtn}>
                        切り替え
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </Card>

            {/* modal */}
            <Model
              isOpen={isErrModalOpen}
              title=""
              body={errMsg}
              buttonName="もう一度入力する"
              onClickCloseModalBtn={onClickCloseErrModalBtn}
            />
            <Model
              isOpen={isSuccessModalOpen}
              title={successTitle}
              body={successMsg}
              buttonName="閉じる"
              onClickCloseModalBtn={onClickCloseSuccessModalBtn}
            />
            <Model
              isOpen={isDisplayedModalOpen}
              title="表示切り替え"
              body={
                profileInfo.isDisplayed
                  ? `マッチング画面において、\nあなたとやり取りを開始していないユーザーが\nあなたを見つけられなくなります。`
                  : `マッチング画面において、\n新しいユーザーがあなたを見つけられるようになります。`
              }
              buttonName="表示を切り替える"
              onClickCloseModalBtn={onClickCloseIsDisplayedModalBtn}
            />

            {/* インジケーター */}
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress />
            </Backdrop>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ProfileScreen;

import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../resources/colors';

const matchingChatScreenStyles = makeStyles((theme) => ({
  root: {
    height: '650px',
    backgroundColor: colors.paleBlue,
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },

  /* header */
  headerRoot: {
    flexDirection: 'row',
    display: 'flex',
    height: '80px',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  headerLeftRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  image: {
    height: '60px',
    width: 'auto',
  },
  username: {
    color: colors.nameColor,
    fontSize: '30px',
    fontWeight: 700,
    marginLeft: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: colors.gray,
    height: '2px',
  },

  /* chat screen */
  chatRoot: {
    backgroundColor: colors.paleGray,
    height: '400px',
    flexDirection: 'column',
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    overflow: 'auto', // コンポーネント内のスクロールを許可する
  },
  chatCardUser: {
    width: '30%',
    marginLeft: '70%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chatCardFpRoot: {
    flexDirection: 'row',
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  chatCardFpImage: {
    height: '56px',
    width: 'auto',
  },
  chatCardFp: {
    width: '30%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  chatInfo: {
    font: '5px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  chatText: {
    wordBreak: 'break-all', // 単語サイズを無視して改行を許可
    whiteSpace: 'pre-line', // 改行を許可
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& a': {
      color: '#1B089D',
      '&:hover': {
        borderBottom: 'solid',
      },
      '&:visited': {
        color: '#61179A',
      },
    },
  },

  /* input chat text & send text */
  textFieldRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
  },
  inputTextField: {
    width: `calc(100% - 100px)`,
    marginRight: '10px',
    backgroundColor: colors.white,
  },
  sendBtn: {
    width: '100px',
    height: '24px',
    fontSize: '18px',
    color: colors.white,
    backgroundColor: colors.lightBlue,
  },
  disableSendBtn: {
    backgroundColor: colors.lightGray,
  },
  paymentBtnRoot: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paymentBtn: {
    fontSize: '24px',
    paddingRight: '64px',
    paddingLeft: '64px',
    color: colors.white,
    backgroundColor: colors.lightBlue,
  },
  reservedInterviewCountTextBtn: {
    height: '40px',
    fontSize: '18px',
    color: colors.mainBlue,
    backgroundColor: colors.white,
    borderColor: colors.mainBlue,
    fontWeight: 'bold',
    padding: '0px 40px 0px 40px',
  },
  reservedInterviewCountText: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 8,
  },
}));

export default matchingChatScreenStyles;

import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';

import colors from '../resources/colors';

const Const = {
  iconSize: 40,
  twitterColor: colors.twitterColor,
};

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
    backgroundColor: colors.darkGray,
  },
  facebookIcon: {
    // size
    width: Const.iconSize,
    height: Const.iconSize,
    // color
    color: theme.palette.primary.main,
  },
  twitterIconButton: {
    marginLeft: theme.spacing(1),
  },
  twitterIconImage: {
    // size
    width: Const.iconSize,
    height: Const.iconSize,
    // color
    color: Const.twitterColor,
  },
  iconImage: {
    // size
    height: Const.iconSize,
    width: 'auto',
  },
  copyLight: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: colors.darkGray,
  },
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <>
      {/* <Grid container spacing={2} className={classes.linkContainer}>
        <Grid item xs>
          <Link href="/preparing-page">about</Link>
        </Grid>
        <Grid item xs>
          <Link href="/terms-of-use">利用規約</Link>
        </Grid>
        <Grid item xs>
          <Link href="/privacy-policy">プライバシーポリシー</Link>
        </Grid>
        <Grid item xs>
          <Link href="/preparing-page">企業情報</Link>
        </Grid>
        <Grid item xs>
          <Link href="/contact">お問い合わせ</Link>
        </Grid>
        <Grid item xs>
          <Link href="/preparing-page">API</Link>
        </Grid>
      </Grid> */}

      <Divider variant="middle" className={classes.divider} />

      {/* <Grid container>
        <Grid item xs />
        <Grid item xs={2}>
          <IconButton size="small">
            <Facebook className={classes.facebookIcon} />
          </IconButton>
          <IconButton size="small" className={classes.twitterIconButton}>
            <Twitter className={classes.twitterIconImage} />
          </IconButton>
        </Grid>
        <Grid item xs />
      </Grid> */}

      <Typography className={classes.copyLight}>
        © 2022 fistplat.com. All rights reserved.
      </Typography>
    </>
  );
}

import React, { useEffect } from 'react';
import {
  Grid,
  Divider,
  CardMedia,
  Typography,
  Button,
  Box,
  Link,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import ReviewsScreenCard from './components/ReviewsScreenCard';
import Rating from '../../../components/Rating';

// others
import { useStyles } from './ReviewsScreenStyle';
import {
  getUserUid,
  getIsFpFromUrl,
} from '../../../modules/functions/userFunctions';
import { AppDispatch, RootState } from '../../../modules/store';
import {
  getFpInfo,
  getUserInfo,
  getFpReviewList,
} from '../../../modules/store/slices/matchingSlice';
import monedoc from '../../../resources/image/monedoc.png';
import { fetchChatRoomID } from '../../../modules/store/slices/messageSlice';

const ReviewsScreen: React.FC = () => {
  // URL に記載された uid を取得する
  const { id } = useParams<{ id: string }>();

  // state
  const classes = useStyles();
  const history = useHistory();
  const isFp = getIsFpFromUrl('user');
  const dispatch: AppDispatch = useDispatch();
  const { fpInfo, userInfo, fpReviewList } = useSelector(
    (state: RootState) => state.matching
  );

  const uid = getUserUid();
  const fpId = isFp ? uid : id;
  const userId = isFp ? id : uid;

  useEffect(() => {
    dispatch(getFpReviewList({ fpId: fpInfo.id }));
  }, []);

  // fetch（画面再レンダリング時のみ実行される）
  React.useEffect(() => {
    // ユーザー or FP の情報を取得
    if (isFp) {
      if (userInfo.id === '') {
        dispatch(getUserInfo({ uid: userId }));
      }
    } else if (fpInfo.id === '') {
      dispatch(getFpInfo({ uid: fpId }));
    }
  }, []);

  // functions
  const onClickBackBtn = () => {
    history.push({
      pathname: `/matching/search/user/${uid}`,
    });
  };

  const onClickChatBtn = async () => {
    const chatRoomID = await fetchChatRoomID(userId, fpId);
    history.push({
      pathname: `/matching/chatRooms/${chatRoomID}`,
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* <IntroductionHeader screenName="matching" mode="short" /> */}
      {/* 左余白 */}
      <Grid container spacing={3}>
        <Grid item xs={1} />
        {/* 情報表示部 */}
        <Grid item xs={10} className={classes.rootContainer}>
          <Grid className={classes.root}>
            {/* header */}
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: '2px',
              }}>
              <Button
                className={classes.backBtn}
                variant="outlined"
                onClick={onClickBackBtn}>
                {isFp ? '一般ユーザー一覧へ戻る' : 'FP一覧へ戻る'}
              </Button>
              <Button
                className={classes.btnRoot}
                variant="outlined"
                onClick={onClickChatBtn}>
                このFPに相談する
              </Button>
            </Box>
            <Divider className={classes.divider} />
            {/* main */}
            <Grid item xs>
              <Grid>
                <Box
                  display="grid"
                  style={{ gridTemplateColumns: '40% 60%' }}
                  className={classes.mainRoot}>
                  {/* Left Side */}
                  {
                    <Grid>
                      {/* Profile */}
                      <Box
                        display="grid"
                        style={{ gridTemplateColumns: '40% 60%' }}>
                        {/* Left Side */}
                        <Grid>
                          <CardMedia
                            component="img"
                            image={fpInfo.imageUrl}
                            className={classes.fpImage}
                          />
                        </Grid>

                        {/* Right Side */}
                        <Grid>
                          <Box className={classes.nickname}>
                            {fpInfo.nickName}
                          </Box>
                          <CardMedia
                            component="img"
                            image={monedoc}
                            className={classes.fpBelongsIcon}
                          />

                          <Box className={classes.itemTitleBox}>
                            {' '}
                            ＦＰ歴：
                            <Box width="10px" />
                            {fpInfo.fpHistory ? (
                              <Typography>{fpInfo.fpHistory}年</Typography>
                            ) : (
                              <Typography>ー</Typography>
                            )}
                          </Box>
                          <Box className={classes.itemTitleBox}>
                            {' '}
                            年齢/性別：
                            <Box width="10px" />
                            {/* ５０ 代 男性 */}
                            {fpInfo.age ? (
                              <Typography>{fpInfo.age}歳</Typography>
                            ) : (
                              <Typography>ー</Typography>
                            )}
                            <Typography>/</Typography>
                            {fpInfo.sex ? (
                              <Typography>{fpInfo.sex}</Typography>
                            ) : (
                              <Typography>ー</Typography>
                            )}
                          </Box>
                        </Grid>
                      </Box>
                      <Box height="5px" />
                      {/* Next box */}
                      <Box className={classes.itemTitleBox}>
                        {' '}
                        総合評価：
                        {fpInfo.ratingMap?.totalRating ? (
                          <Grid>
                            <Rating
                              rating={fpInfo.ratingMap.totalRating}
                              nodata={1}
                            />
                          </Grid>
                        ) : (
                          <Grid>
                            <Rating rating={0} nodata={0} />
                          </Grid>
                        )}
                        {fpInfo.ratingMap?.totalRating ? (
                          <Grid>
                            {fpInfo.ratingMap.totalRating.toFixed(1)}
                            {/* 小数点以下1桁まで表示 */}
                          </Grid>
                        ) : (
                          <Grid>---</Grid>
                        )}
                      </Box>
                    </Grid>
                  }
                  <Grid>
                    <Box className={classes.itemTitleBox}>項目別評価</Box>

                    <Box
                      display="grid"
                      style={{ width: 350, gridTemplateColumns: '50% 50%' }}>
                      {/* Left Side */}
                      <Grid>
                        <Box className={classes.itemTitleBox}>話しやすさ：</Box>
                        <Box className={classes.itemTitleBox}>納得感：</Box>
                        <Box className={classes.itemTitleBox}>
                          わかりやすさ：
                        </Box>
                        <Box className={classes.itemTitleBox}>
                          また相談したいか：
                        </Box>
                      </Grid>

                      {/* Right Side */}
                      <Grid>
                        {/* 話しやすさ */}
                        <Box className={classes.ratingStarBox}>
                          {fpInfo.ratingMap?.affable ? (
                            <Grid>
                              <Rating
                                rating={fpInfo.ratingMap.affable}
                                nodata={1}
                              />
                            </Grid>
                          ) : (
                            <Grid>
                              <Rating rating={0} nodata={0} />
                            </Grid>
                          )}
                          <Box width="10px" />
                          {fpInfo.ratingMap?.affable ? (
                            <Grid>
                              {fpInfo.ratingMap.affable.toFixed(1)}
                              {/* 小数点以下1桁まで表示 */}
                            </Grid>
                          ) : (
                            <Grid>---</Grid>
                          )}
                        </Box>
                        {/* 納得感 */}
                        <Box className={classes.ratingStarBox}>
                          {fpInfo.ratingMap?.satisfactory ? (
                            <Grid>
                              <Rating
                                rating={fpInfo.ratingMap.satisfactory}
                                nodata={1}
                              />
                            </Grid>
                          ) : (
                            <Grid>
                              <Rating rating={0} nodata={0} />
                            </Grid>
                          )}
                          <Box width="10px" />
                          {fpInfo.ratingMap?.satisfactory ? (
                            <Grid>
                              {fpInfo.ratingMap.satisfactory.toFixed(1)}
                              {/* 小数点以下1桁まで表示 */}
                            </Grid>
                          ) : (
                            <Grid>---</Grid>
                          )}
                        </Box>
                        {/* わかりやすさ */}
                        <Box className={classes.ratingStarBox}>
                          {fpInfo.ratingMap?.understandability ? (
                            <Grid>
                              <Rating
                                rating={fpInfo.ratingMap.understandability}
                                nodata={1}
                              />
                            </Grid>
                          ) : (
                            <Grid>
                              <Rating rating={0} nodata={0} />
                            </Grid>
                          )}
                          <Box width="10px" />
                          {fpInfo.ratingMap?.understandability ? (
                            <Grid>
                              {fpInfo.ratingMap.understandability.toFixed(1)}
                              {/* 小数点以下1桁まで表示 */}
                            </Grid>
                          ) : (
                            <Grid>---</Grid>
                          )}
                        </Box>
                        {/* また相談したいか */}
                        <Box className={classes.ratingStarBox}>
                          {fpInfo.ratingMap?.retainable ? (
                            <Grid>
                              <Rating
                                rating={fpInfo.ratingMap.retainable}
                                nodata={1}
                              />
                            </Grid>
                          ) : (
                            <Grid>
                              <Rating rating={0} nodata={0} />
                            </Grid>
                          )}
                          <Box width="10px" />
                          {fpInfo.ratingMap?.retainable ? (
                            <Grid>
                              {fpInfo.ratingMap.retainable.toFixed(1)}
                              {/* 小数点以下1桁まで表示 */}
                            </Grid>
                          ) : (
                            <Grid>---</Grid>
                          )}
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            {/* レビュー内容 */}
            {fpReviewList.length ? (
              <Grid>
                {fpReviewList.map((info) => (
                  <ReviewsScreenCard fpReviewInfo={info} />
                ))}
              </Grid>
            ) : (
              <Grid>
                <Typography className={classes.text}>
                  口コミはまだありません。
                </Typography>
              </Grid>
            )}

            {/* footer */}
            <Grid className={classes.btnRoot}>
              {fpInfo.mtgLink ? (
                <Grid className={classes.mtgLink}>
                  <Typography className={classes.text}>
                    <Link
                      href={fpInfo.mtgLink}
                      underline="hover"
                      target="_blank">
                      ミーティングを設定する
                    </Link>
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Grid className={classes.btnRoot}>
              <Button className={classes.chatBtn} onClick={onClickChatBtn}>
                このFPに相談する
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* 右余白 */}
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default ReviewsScreen;

import React from 'react';
import {
  Grid,
  Divider,
  CardMedia,
  Card,
  Typography,
  Button,
  Box,
  Link,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// components
import Rating from '../../../components/Rating';
import Expertise from '../../../components/Expertise';
import monedoc from '../../../resources/image/monedoc.png';

// others
import individualFpScreenStyle from './IndividualFpScreenStyle';
import { getUserUid } from '../../../modules/functions/userFunctions';
import Video from '../../../components/videoPlayer';
import { AppDispatch, RootState } from '../../../modules/store';
import {
  getFpInfo,
  matchingUsers,
} from '../../../modules/store/slices/matchingSlice';

const IndividualFpScreen: React.FC = () => {
  // state
  const classes = individualFpScreenStyle();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { fpInfo, isLoading } = useSelector(
    (state: RootState) => state.matching
  );

  const { id } = useParams<{ id: string }>();

  const uid = getUserUid();
  const fpId = id;

  // fetch（画面再レンダリング時のみ実行される）
  React.useEffect(() => {
    dispatch(getFpInfo({ uid: fpId }));
  }, []);

  // functions
  const onClickBackBtn = () => {
    history.push({
      pathname: `/matching/search/user/${uid}`,
    });
  };

  const onClickChatBtn = () => {
    dispatch(matchingUsers(fpId))
      .then(unwrapResult)
      .then((chatRoomID) => {
        history.push({
          pathname: `/matching/chatRooms/${chatRoomID}`,
        });
      })
      .catch(() => {
        // TODO: エラー処理
      });
  };

  const onClickReviewsBtn = () => {
    history.push({
      pathname: `/matching/fp-reviews/${uid}`,
    });
  };

  // コンポーネント
  // TODO: 複数の画像表示方法実装後に外に出す
  const FpVideo = () => (
    <Grid className={classes.fpVideo}>
      <Video src={fpInfo.videoUrl} />
    </Grid>
  );

  return (
    <>
      <Grid className={classes.root}>
        {/* <IntroductionHeader screenName="matching" mode="short" /> */}
        {/* 左余白 */}
        <Grid container spacing={3}>
          <Grid item xs={1} />
          {/* 情報表示部 */}
          <Grid item xs={10} className={classes.rootContainer}>
            <Box className={classes.rootBox}>
              <Button
                className={classes.backBtn}
                variant="outlined"
                onClick={onClickBackBtn}>
                FP一覧へ戻る
              </Button>
              <Button
                className={classes.btnRoot}
                variant="outlined"
                onClick={onClickChatBtn}>
                このFPに相談する
              </Button>
            </Box>
            <Divider className={classes.divider} />
            {/* main */}
            <Grid item xs>
              <Grid>
                <Box
                  display="grid"
                  style={{ gridTemplateColumns: '40% 60%' }}
                  className={classes.mainRoot}>
                  {/* 画像/映像 */}
                  {
                    <Grid>
                      {/* Profile */}
                      <Box
                        display="grid"
                        style={{ gridTemplateColumns: '40% 60%' }}>
                        {/* Left Side */}
                        <Grid>
                          <CardMedia
                            component="img"
                            image={fpInfo.imageUrl}
                            className={classes.fpImage}
                          />
                        </Grid>

                        {/* Right Side */}
                        <Grid>
                          <Box className={classes.nickname}>
                            {fpInfo.nickName.length < 30
                              ? fpInfo.nickName
                              : fpInfo.nickName.substr(0, 30).concat('..')}
                          </Box>
                          {fpInfo.belongs === '株式会社ＦＰパートナー' ? (
                            <CardMedia
                              component="img"
                              image={monedoc}
                              className={classes.fpBelongsIcon}
                            />
                          ) : (
                            ''
                          )}
                          <Box className={classes.itemTitleBox}>
                            ＦＰ歴：
                            <Box width="10px" />
                            {fpInfo.fpHistory ? (
                              <Typography>{fpInfo.fpHistory}年</Typography>
                            ) : (
                              <Typography>ー</Typography>
                            )}
                          </Box>
                          <Box className={classes.itemTitleBox}>
                            {' '}
                            年齢：
                            <Box width="10px" />
                            {fpInfo.age ? (
                              <Typography>{fpInfo.age}歳</Typography>
                            ) : (
                              <Typography>ー</Typography>
                            )}
                            {/* <Typography>/</Typography>
                            {fpInfo.sex ? (
                              <Typography>{fpInfo.sex}</Typography>
                            ) : (
                              <Typography>ー</Typography>
                            )} */}
                          </Box>
                        </Grid>
                      </Box>
                      <Box height="5px" />
                      {/* Next box */}
                      <Box className={classes.itemTitleBox}>
                        {' '}
                        総合評価：
                        {fpInfo.ratingMap?.totalRating ? (
                          <Rating
                            rating={fpInfo.ratingMap.totalRating}
                            nodata={1}
                          />
                        ) : (
                          <Rating rating={0} nodata={0} />
                        )}
                        <Box width="10px" />
                        {fpInfo.ratingMap?.totalRating ? (
                          <Grid>{fpInfo.ratingMap.totalRating}</Grid>
                        ) : (
                          <Grid>---</Grid>
                        )}
                      </Box>

                      <Box height="10px" />
                      <Box display="flex">
                        <Box className={classes.strongAreaRoot}>
                          <Box className={classes.itemTitleBox}>
                            得意相談領域
                          </Box>
                          <Box height="7px" />
                          <Box className={classes.strongAreaContainer}>
                            {fpInfo.strongAreaMap?.oldAge ? (
                              <Expertise
                                content="老後"
                                active={fpInfo.strongAreaMap.oldAge}
                              />
                            ) : (
                              <Expertise content="老後" active={false} />
                            )}
                            {fpInfo.strongAreaMap?.housing ? (
                              <Expertise
                                content="住宅"
                                active={fpInfo.strongAreaMap.housing}
                              />
                            ) : (
                              <Expertise content="住宅" active={false} />
                            )}
                            {fpInfo.strongAreaMap?.education ? (
                              <Expertise
                                content="教育"
                                active={fpInfo.strongAreaMap.education}
                              />
                            ) : (
                              <Expertise content="教育" active={false} />
                            )}
                            {fpInfo.strongAreaMap?.assetManagement ? (
                              <Expertise
                                content="運用"
                                active={fpInfo.strongAreaMap.assetManagement}
                              />
                            ) : (
                              <Expertise content="運用" active={false} />
                            )}
                            {fpInfo.strongAreaMap?.insurance ? (
                              <Expertise
                                content="保険"
                                active={fpInfo.strongAreaMap.insurance}
                              />
                            ) : (
                              <Expertise content="保険" active={false} />
                            )}
                          </Box>
                        </Box>
                      </Box>

                      {/* Next box */}
                      <Box height="30px" />
                      <Box className={classes.itemTitleBox}>項目別評価</Box>

                      <Box
                        display="grid"
                        style={{
                          width: 350,
                          gridTemplateColumns: '50% 50%',
                        }}>
                        {/* Left Side */}
                        <Grid>
                          <Box className={classes.itemTitleBox}>
                            話しやすさ：
                          </Box>
                          <Box className={classes.itemTitleBox}>納得感：</Box>
                          <Box className={classes.itemTitleBox}>
                            わかりやすさ：
                          </Box>
                          <Box className={classes.itemTitleBox}>
                            また相談したいか：
                          </Box>
                        </Grid>

                        {/* Right Side */}
                        <Grid>
                          {/* 話しやすさ */}
                          <Box className={classes.ratingStarBox}>
                            {fpInfo.ratingMap?.affable ? (
                              <Grid>
                                <Rating
                                  rating={fpInfo.ratingMap.affable}
                                  nodata={1}
                                />
                              </Grid>
                            ) : (
                              <Grid>
                                <Rating rating={0} nodata={0} />
                              </Grid>
                            )}
                            <Box width="10px" />
                            {fpInfo.ratingMap?.affable ? (
                              <Grid>
                                {fpInfo.ratingMap.affable.toFixed(1)}
                                {/* 小数点以下1桁まで表示 */}
                              </Grid>
                            ) : (
                              <Grid>---</Grid>
                            )}
                          </Box>
                          {/* 納得感 */}
                          <Box className={classes.ratingStarBox}>
                            {fpInfo.ratingMap?.satisfactory ? (
                              <Grid>
                                <Rating
                                  rating={fpInfo.ratingMap.satisfactory}
                                  nodata={1}
                                />
                              </Grid>
                            ) : (
                              <Grid>
                                <Rating rating={0} nodata={0} />
                              </Grid>
                            )}
                            <Box width="10px" />
                            {fpInfo.ratingMap?.satisfactory ? (
                              <Grid>
                                {fpInfo.ratingMap.satisfactory.toFixed(1)}
                                {/* 小数点以下1桁まで表示 */}
                              </Grid>
                            ) : (
                              <Grid>---</Grid>
                            )}
                          </Box>
                          {/* わかりやすさ */}
                          <Box className={classes.ratingStarBox}>
                            {fpInfo.ratingMap?.understandability ? (
                              <Grid>
                                <Rating
                                  rating={fpInfo.ratingMap.understandability}
                                  nodata={1}
                                />
                              </Grid>
                            ) : (
                              <Grid>
                                <Rating rating={0} nodata={0} />
                              </Grid>
                            )}
                            <Box width="10px" />
                            {fpInfo.ratingMap?.understandability ? (
                              <Grid>
                                {fpInfo.ratingMap.understandability.toFixed(1)}
                                {/* 小数点以下1桁まで表示 */}
                              </Grid>
                            ) : (
                              <Grid>---</Grid>
                            )}
                          </Box>
                          {/* また相談したいか */}
                          <Box className={classes.ratingStarBox}>
                            {fpInfo.ratingMap?.retainable ? (
                              <Grid>
                                <Rating
                                  rating={fpInfo.ratingMap.retainable}
                                  nodata={1}
                                />
                              </Grid>
                            ) : (
                              <Grid>
                                <Rating rating={0} nodata={0} />
                              </Grid>
                            )}
                            <Box width="10px" />
                            {fpInfo.ratingMap?.retainable ? (
                              <Grid>
                                {fpInfo.ratingMap.retainable.toFixed(1)}
                                {/* 小数点以下1桁まで表示 */}
                              </Grid>
                            ) : (
                              <Grid>---</Grid>
                            )}
                          </Box>
                        </Grid>
                      </Box>

                      {/* Next box */}
                      <Button
                        className={classes.backBtn}
                        variant="outlined"
                        onClick={onClickReviewsBtn}>
                        クチコミを見る
                      </Button>
                    </Grid>
                  }
                  <Grid>
                    {/* Next box */}
                    <Box className={classes.itemTitleBox}>
                      FP紹介動画 / Introduction movie
                    </Box>
                    <FpVideo />
                    {/* 個人情報 */}
                    <Card className={classes.userCard}>
                      <Grid>
                        <Typography className={classes.text}>
                          {fpInfo.introduction}
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            {/* footer */}
            <Grid className={classes.btnRoot}>
              {fpInfo.mtgLink ? (
                <Grid className={classes.mtgLink}>
                  <Typography className={classes.text}>
                    <Link
                      href={fpInfo.mtgLink}
                      underline="hover"
                      target="_blank">
                      ミーティングを設定する
                    </Link>
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Grid className={classes.btnRoot}>
              <Button className={classes.chatBtn} onClick={onClickChatBtn}>
                このFPに相談する
              </Button>
            </Grid>
          </Grid>

          {/* 右余白 */}
          <Grid item xs={1} />
        </Grid>
      </Grid>

      {/* インジケーター */}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default IndividualFpScreen;

import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Grid,
  Button,
  ButtonBase,
  CardMedia,
  Backdrop,
  CircularProgress,
  Box,
  IconButton,
  Badge,
} from '@material-ui/core';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import { AppDispatch, RootState } from '../../modules/store';
import {
  chatRoomSelectors,
  fetchChatRooms,
  listenDocmentChange,
  setChatRoom,
  setIsReadCount,
  updateChatRoom,
} from '../../modules/store/slices/chatRoomSlice';
import { getIsFp } from '../../modules/functions/userFunctions';
import { getPersonInfo } from '../../modules/functions/matchingFunctions';
import colors from '../../resources/colors';
import companyLogo from '../../resources/image/companyLogo.png';
import UserMenu from './UserMenu';

const Const = {
  toolbarHeight: 80,
};

const SpConst = {
  toolbarHeight: 58,
};

/* Style */
const styles = (theme: Theme) => ({
  appbar: {
    backgroundColor: colors.white,
  },
  toolbar: {
    minHeight: Const.toolbarHeight,
    justifyContent: 'space-between',
  },
  spToolbar: {
    minHeight: SpConst.toolbarHeight,
    justifyContent: 'space-between',
  },
  companyLogo: {
    height: Const.toolbarHeight * 0.85,
    width: 'auto',
  },
  spCompanyLogo: {
    height: SpConst.toolbarHeight / 1.3,
    width: 'auto',
  },
  rightMenuArea: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  userArea: {
    display: 'flex',
    alignItems: 'center',
  },
  chatIconButton: {
    marginRight: theme.spacing(2),
  },
  chatIconImage: {
    color: colors.darkGray,
    height: Const.toolbarHeight / 2,
    width: Const.toolbarHeight / 1.5,
  },
  spChatIconImage: {
    color: colors.darkGray,
    height: SpConst.toolbarHeight / 2,
    width: SpConst.toolbarHeight / 1.5,
  },
  badge: {
    top: Const.toolbarHeight / 8,
  },
  spBadge: {
    top: SpConst.toolbarHeight / 8,
  },
  loginButton: {
    // color
    color: theme.palette.info.main,
    backgroundColor: colors.darkGray,
    '&:hover': {
      color: theme.palette.info.light,
      backgroundColor: colors.darkGray,
    },
    // size
    width: 104,
    height: Const.toolbarHeight / 1.5,
    // margin
    marginRight: theme.spacing(1),
  },
  spLoginButton: {
    // color
    color: theme.palette.info.main,
    backgroundColor: colors.darkGray,
    '&:hover': {
      color: theme.palette.info.light,
      backgroundColor: colors.darkGray,
    },
    // size
    width: 92,
    height: 28,
    // margin
    marginRight: theme.spacing(0.5),
  },
  registerButton: {
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.light,
    },
    // size
    width: 104,
    height: Const.toolbarHeight / 1.5,
    // margin
    marginRight: theme.spacing(1),
  },
  spRegisterButton: {
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.light,
    },
    // size
    width: 84,
    height: 28,
    // margin
    marginRight: theme.spacing(0.5),
  },
  btnText: {
    fontSize: 14,
  },
  spBtnText: {
    fontSize: 11,
  },

  /* header の height 分だけスクリーン全体を下に下げる */
  placeholder: {
    height: Const.toolbarHeight,
    [theme.breakpoints.up('sm')]: {
      height: Const.toolbarHeight,
    },
  },
  spPlaceholder: {
    height: SpConst.toolbarHeight,
    [theme.breakpoints.up('sm')]: {
      height: SpConst.toolbarHeight,
    },
  },

  /* インジケーター */
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
});

/**
 * アプリ全体で使用するAppBar。
 * 画面上部に表示され、ログイン時にはユーザーメニュー、
 * 非ログイン時にはログインボタンと会員登録ボタンが表示される。
 */
function FPAppBar({ classes }: WithStyles<typeof styles>) {
  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 766px)' });

  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const isFp = getIsFp();
  const { isLoggedIn, isLoading, uid, role } = useSelector(
    (state: RootState) => state.user
  );
  const chatRooms = useSelector(chatRoomSelectors.selectAll);
  const { isReadCount } = useSelector((state: RootState) => state.chatRoom);

  // やり取り相手一覧の取得
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchChatRooms());
      const unsubscribe = listenDocmentChange(uid, role, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          // 追加
          if (change.type === 'added') {
            const chatRoom = change.doc.data();
            getPersonInfo(
              chatRoom.destinationUserID,
              role === 'FP' ? 'User' : 'FP'
            ).then((user) => {
              chatRoom.destinationUser = user;
              dispatch(setChatRoom(chatRoom));
            });
          }
          // 更新
          else if (change.type === 'modified') {
            dispatch(updateChatRoom(change.doc.data()));
          }
        });
        return () => unsubscribe();
      });
    }
  }, [isLoggedIn]);

  // 未読件数の更新
  useEffect(() => {
    dispatch(
      setIsReadCount(chatRooms.filter((chatRoom) => !chatRoom.isRead).length)
    );
  }, [chatRooms]);

  // functions
  const onClickHomeBtn = () => {
    // window.location.replace('https://www.money-dash.com');
    history.push({ pathname: '/my-page' });

    window.scrollTo(0, 0);
  };

  // functions
  const onClickFpBackBtn = () => {
    history.push({ pathname: '/new-client/' });
    window.location.reload(); // 初回遷移時はページNotFoundErrorが返却されるため、再読み込みさせる
  };

  const onClickChatButton = () => {
    history.push({ pathname: '/matching/chatRooms' });
  };

  return (
    <div>
      {/* ---------- web画面用表示領域 (min-width: 767px) ---------- */}
      {isDesktop && (
        <div>
          <AppBar position="fixed" className={classes.appbar}>
            <Toolbar className={classes.toolbar}>
              {/* ホームボタン */}
              {isLoggedIn === true && isFp !== true ? (
                <Box>
                  <ButtonBase
                    onClick={onClickHomeBtn}
                    style={{
                      paddingRight: '20px',
                    }}>
                    <CardMedia
                      className={classes.companyLogo}
                      component="img"
                      image={companyLogo}
                      title="companyLogo"
                    />
                  </ButtonBase>
                  <Button
                    style={{
                      width: '120px',
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: colors.darkGray,
                    }}
                    color="inherit"
                    component={Link}
                    to="/diagnosis">
                    1. 診断する
                  </Button>
                  <Button
                    style={{
                      width: '120px',
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: colors.darkGray,
                    }}
                    color="inherit"
                    component={Link}
                    to="/matching/home">
                    2. 相談する
                  </Button>
                </Box>
              ) : (
                <Box>
                  <ButtonBase onClick={onClickHomeBtn}>
                    <CardMedia
                      className={classes.companyLogo}
                      component="img"
                      image={companyLogo}
                      title="companyLogo"
                    />
                  </ButtonBase>
                </Box>
              )}

              {/* FP管理画面へ戻るボタン */}
              {isFp === true ? (
                <Grid>
                  <Button
                    style={{
                      width: '240px',
                      fontSize: 14,
                      fontWeight: 'bold',
                      color: colors.darkGray,
                    }}
                    color="inherit"
                    onClick={onClickFpBackBtn}>
                    Home画面へ戻る
                  </Button>
                </Grid>
              ) : (
                ''
              )}

              {/* アカウントボタン */}
              {isLoggedIn ? (
                <div className={classes.userArea}>
                  {/* チャットボタン */}
                  <IconButton
                    size="small"
                    className={classes.chatIconButton}
                    onClick={onClickChatButton}>
                    <Badge
                      badgeContent={isReadCount}
                      color="error"
                      classes={{ badge: classes.badge }}>
                      <ChatBubbleOutlineIcon
                        className={classes.chatIconImage}
                      />
                    </Badge>
                  </IconButton>
                  {/* ユーザーメニュー */}
                  <UserMenu />
                </div>
              ) : (
                ''
                // <Grid>
                //   <Button
                //     variant="outlined"
                //     className={classes.loginButton}
                //     onClick={onClickSignInBtn}>
                //     <Typography className={classes.btnText}>
                //       ログイン
                //     </Typography>
                //   </Button>
                //   <Button
                //     variant="contained"
                //     color="primary"
                //     className={classes.registerButton}
                //     onClick={onClickSignOutBtn}>
                //     <Typography className={classes.btnText}>登録</Typography>
                //   </Button>
                // </Grid>
              )}
            </Toolbar>
          </AppBar>

          {/* header の height 分だけスクリーン全体を下に下げる */}
          <div className={classes.placeholder} />

          {/* インジケーター */}
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress />
          </Backdrop>
        </div>
      )}
      {/* ---------- スマホ画面用表示領域 (max-width: 766px) ---------- */}
      {isMobile && (
        <div>
          <AppBar position="fixed" className={classes.appbar}>
            <Toolbar className={classes.spToolbar}>
              {/* ホームボタン */}
              <Box>
                <ButtonBase onClick={onClickHomeBtn}>
                  <CardMedia
                    className={classes.spCompanyLogo}
                    component="img"
                    image={companyLogo}
                    title="companyLogo"
                  />
                </ButtonBase>
              </Box>

              {/* アカウントボタン */}
              {isLoggedIn ? (
                <div className={classes.userArea}>
                  {/* チャットボタン */}
                  <IconButton
                    size="small"
                    className={classes.chatIconButton}
                    onClick={onClickChatButton}>
                    <Badge
                      badgeContent={isReadCount}
                      color="error"
                      classes={{ badge: classes.badge }}>
                      <ChatBubbleOutlineIcon
                        className={classes.spChatIconImage}
                      />
                    </Badge>
                  </IconButton>
                  {/* ユーザーメニュー */}
                  <UserMenu />
                </div>
              ) : (
                ''
              )}
            </Toolbar>
          </AppBar>

          {/* header の height 分だけスクリーン全体を下に下げる */}
          <div className={classes.spPlaceholder} />

          {/* インジケーター */}
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress />
          </Backdrop>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(FPAppBar);

import { Grid, Typography } from '@material-ui/core';

const PreparingPageComponent = () => (
  // screen
  <Grid>
    <Typography variant="h3" align="center">
      公開準備中
    </Typography>
    <Typography variant="body1" align="center">
      現在ページの準備を進めています。
      {'\n'}
      今しばらくお待ちください。
    </Typography>
  </Grid>
);

export default PreparingPageComponent;

import { createTheme, colors as muiColors } from '@mui/material';

export const colors = {
  ...muiColors,
  primary: {
    50: '#F9FCFF',
    100: '#DFEBF5',
    200: '#B8D9F6',
    300: '#74A4CA',
    400: '#3D83BA',
    500: '#166FB6',
    600: '#1168AE',
    700: '#0C5FA2',
    800: '#06528F',
    900: '#00345E',
  },
  backdropColor: 'rgba(255, 255, 255, 0.7)',
};

export const defaultTheme = createTheme({
  shape: {
    borderRadius: 4,
  },
  palette: {
    ...colors,
    primary: {
      ...colors.primary,
      main: colors.primary[800],
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Noto Sans JP', 'sans-serif'].join(','),
    h1: {
      fontSize: '32px',
      fontWeight: 'normal',
    },
    h2: {
      fontSize: '25px',
      fontWeight: 'normal',
    },
    subtitle1: {
      fontSize: '18px',
    },
    body1: {
      fontSize: '14px',
    },
    caption: {
      fontSize: '12px',
      color: colors.grey[600],
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: colors.primary[900],
            backgroundColor: colors.primary[100],
            '&:hover': {
              backgroundColor: colors.primary[200],
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          position: 'relative',
          '&.--row-clickable:hover': {
            backgroundColor: colors.common.white,
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2);',
            cursor: 'pointer',
            zIndex: 1,
          },
          '&.--negative': {
            backgroundColor: colors.grey[100],
            '&:hover': {
              backgroundColor: colors.grey[100],
            },
          },
        },
      },
    },
  },
});

import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../resources/colors';

const matchingChatScreenStyles = makeStyles((theme) => ({
  root: {
    height: '600px',
    backgroundColor: colors.paleBlue,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },

  /* header */
  headerRoot: {
    flexDirection: 'row',
    display: 'flex',
    height: '50px',
    alignItems: 'flex-end',
  },
  image: {
    height: '40px',
    width: 'auto',
  },
  username: {
    fontSize: '24px',
    fontWeight: 700,
    color: colors.darkGray,
    marginLeft: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: colors.gray,
    height: '2px',
  },

  /* chat screen */
  chatRoot: {
    backgroundColor: colors.paleGray,
    height: '400px',
    flexDirection: 'column',
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflow: 'auto', // コンポーネント内のスクロールを許可する
  },
  chatCardUser: {
    width: '100%',
    marginLeft: '25%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chatCardFpRoot: {
    flexDirection: 'row',
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  chatCardFpImage: {
    height: '40px',
    width: 'auto',
  },
  chatCardFp: {
    width: '75%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  chatInfo: {
    font: '4px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  chatText: {
    wordBreak: 'break-all', // 単語サイズを無視して改行を許可
    whiteSpace: 'pre-line', // 改行を許可
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& a': {
      color: '#1B089D',
      '&:hover': {
        borderBottom: 'solid',
      },
      '&:visited': {
        color: '#61179A',
      },
    },
  },

  /* input chat text & send text */
  textFieldRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
  },
  inputTextField: {
    width: `calc(100% - 100px)`,
    marginRight: '10px',
    backgroundColor: colors.white,
  },
  sendBtn: {
    width: '100px',
    height: '24px',
    fontSize: '14px',
    color: colors.white,
    backgroundColor: colors.lightBlue,
  },
  disableSendBtn: {
    backgroundColor: colors.lightGray,
  },
  paymentBtnRoot: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paymentBtn: {
    fontSize: '14px',
    paddingRight: '64px',
    paddingLeft: '64px',
    color: colors.white,
    backgroundColor: colors.lightBlue,
  },
}));

export default matchingChatScreenStyles;

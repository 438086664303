import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { AppDispatch } from '../../../modules/store';
import { getUserUid } from '../../../modules/functions/userFunctions';
import {
  registerDiagnosisData,
  selectIsLoading,
} from '../../../modules/store/slices/diagnosisDataSlice';
import { updateAnxietyLevel } from '../../../modules/store/slices/clientUserSlice';
import {
  calculateBasicAnnuity,
  calculateEmployeeAnnuity,
  calculateAnnuity,
  calculateRequiredInvestmentPerMonth,
  calculateRequiredSavingsForOldAge,
  calculateRequiredSavingsPerMonth,
  calculateTotalRetirementIncome,
  calculateRoundingRequiredSavingsForOldAge,
} from '../../../utils/financeFunctions';
import { DiagnosisData } from '../../../modules/entities/DiagnosisData';

const ContentScreen: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const history = useHistory();
  const [IndexsArray, setIndexsArray] = useState<number[]>([0]);
  const [percent, setPercent] = useState(1);
  const [percentSecond, setPercentSecond] = useState(1);

  const [partnerStatus, setPartnerStatus] = useState(9); // 夫婦か単身か	(単身:0, 夫婦:1, 初期値9)
  const [houseStatus, sethouseStatus] = useState(9); // 持ち家か賃貸か	(賃貸:0, 持ち家:1, 初期値9)
  const [lifePlanCost60to80, setLifePlanCost60to80] = useState(0); // 60-80歳時に希望する月のコスト
  const [lifePlanCost80to100, setLifePlanCost80to100] = useState(0); // 80-100歳時に希望する月のコスト
  const [purchaseHouseAge, setPurchaseHouseAge] = useState(35); // 住宅購入年齢
  const [purchaseHousePrice, setPurchaseHousePrice] = useState(3000); // 住宅購入価格
  const [monthlyRent, setMonthlyRent] = useState(10); // 老後の賃貸家賃
  const [housingCost, setHousingCost] = useState(0); // 老後の住宅費用
  const [livingCost, setLivingCost] = useState(0); // 老後の生活費用
  const [medicalCost, setMedicalCost] = useState(400); // 老後の医療費用
  const [careCost, setCareCost] = useState(400); // 老後の介護費用
  const [endingCost, setEndingCost] = useState(200); // 葬儀等死後清算費用
  const [otherCost, setOtherCost] = useState(0); // その他老後費用
  const [age, setAge] = useState(35); // 現在の年齢
  const [sex, setSex] = useState(9); // 性別 (女:0, 男:1, 初期値9)
  const [lifeSpan, setLifeSpan] = useState(100); // 寿命
  const [income, setIncome] = useState(400); // 現在の年収
  const [estimatedMaxIncome, setEstimatedMaxIncome] = useState(600); // 将来の最大年収
  const [isEmployee, setIsEmployee] = useState(9); // 会社員・公務員か否か (not:0, yes:1, 初期値9)
  const [isSaving, setIsSaving] = useState(9); // 現在老後生活費用の貯蓄があるか否か (not:0, yes:1, 初期値9)
  const [savingsAmount, setSavingsAmount] = useState(0); // 老後のための貯蓄
  const [retiredPay, setRetiredPay] = useState(0); // 退職金
  const [retiredIncome, setRetiredIncome] = useState(0); // 退職後の定期収入
  const [basicAnnuity, setBasicAnnuity] = useState(0); // 基礎年金
  const [employeeAnnuity, setEmployeeAnnuity] = useState(0); // 厚生年金
  const [annuity, setAnnuity] = useState(0); // 年金合計

  const [totalRequiredSavings, setTotalRequiredSavings] = useState(0); // 老後に必要なお金
  const [roundingTotalRequiredSavings, setRoundingTotalRequiredSavings] =
    useState(0); // 老後に必要なお金(丸めた値)

  const [anxietyLevel, setAnxietyLevel] = React.useState(9); // 診断実施後の感想(0,1,2,3,9)

  const uid = getUserUid();

  // ページング管理
  const previousPage = () => {
    window.scrollTo(0, 0);
    setIndexsArray((indexs) => [...indexs.slice(0, indexs.length - 1)]);
  };

  // stap1診断 case1 〜 case7, step2診断 case10 〜 case14
  const nextPage = (index: number) => {
    if (index === 4) {
      calculatedLivingCost();
    }
    if (index === 7) {
      calculatedHousingCost();

      calculatedCareCost();
      calculatedMedicalCost();
      calculatedEndingCost();
    }
    if (index === 12) {
      calculatedBasicAnnuity();
      calculatedEmployeeAnnuity();
      calculatedTotalAnnuity();
    }
    if (index === 14) {
      calculatedTotalRequiredSavings();
    }
    if (index === 15) {
      regist();
    }
    window.scrollTo(0, 0);
    setIndexsArray((indexs) => [...indexs, index]);
  };

  // 診断後画面遷移
  const onClickMaching = () => {
    registAnxietyLevel();
    history.push({ pathname: '/matching/home' });
    window.scrollTo(0, 0);
  };

  // 生活費の計算
  // lifePlanの希望に応じた生活費を計算。
  // 寿命が60-80の場合及び80-100の場合で条件分岐。夫婦の場合1.8倍する。
  const calculatedLivingCost = () => {
    let preCost = 0;
    if (lifeSpan > 80) {
      preCost =
        Math.round(
          lifePlanCost60to80 * 20 + lifePlanCost80to100 * (lifeSpan - 80)
        ) * 12;
      setLivingCost(preCost);
    } else {
      preCost = Math.round(lifePlanCost60to80 * (lifeSpan - 60)) * 12;
      setLivingCost(preCost);
    }
    if (partnerStatus === 1) {
      preCost *= 1.8;
      setLivingCost(preCost);
    }
  };

  // 住宅費の計算
  // 賃貸の場合,寿命までの家賃を計算。なお、更新料を2年に1回とする。(24ヶ月におきに1ヶ月分追加)
  // 持ち家場合,ローンを計算。なお、修繕費固定資産税を年10万円とする。
  const calculatedHousingCost = () => {
    if (houseStatus === 0) {
      setHousingCost((monthlyRent * 12 * (lifeSpan - 60) * 25) / 24);
    } else {
      const housingLoan =
        purchaseHouseAge < 26
          ? 0
          : Math.round(
              ((purchaseHousePrice * 1.07) / 35) * (purchaseHouseAge - 25)
            );

      setHousingCost(10 * (lifeSpan - 60) + housingLoan);
    }
  };

  // 介護費の計算
  // 夫婦の場合2倍とする。
  const calculatedCareCost = () => {
    if (partnerStatus === 1) {
      setCareCost(careCost * 2);
    }
  };

  // 医療費の計算
  // 夫婦の場合2倍とする。
  const calculatedMedicalCost = () => {
    if (partnerStatus === 1) {
      setMedicalCost(medicalCost * 2);
    }
  };

  // 葬儀費の計算
  // 夫婦の場合2倍とする。
  const calculatedEndingCost = () => {
    if (partnerStatus === 1) {
      setEndingCost(endingCost * 2);
    }
  };

  // 基礎年金の計算
  // 夫婦の場合2倍とする。
  const calculatedBasicAnnuity = () => {
    setBasicAnnuity(calculateBasicAnnuity(lifeSpan, partnerStatus === 1));
  };

  // 厚生年金の計算
  // 厚生年金試算計算ロジック : 最大年収の80%が平均年収と仮定し、500万円の厚生年金8.4万円/月を用いて比例計算。上限は18万円/月と設定。
  const calculatedEmployeeAnnuity = () => {
    setEmployeeAnnuity(
      calculateEmployeeAnnuity(isEmployee === 1, estimatedMaxIncome, lifeSpan)
    );
  };

  // 年金の総額の計算
  // 厚生年金と基礎年金を合算する。夫婦の場合2倍とする。
  // 厚生年金試算計算ロジック : 最大年収の80%が平均年収と仮定し、500万円の厚生年金8.4万円/月を用いて比例計算。上限は18万円/月と設定。
  const calculatedTotalAnnuity = () => {
    setAnnuity(
      calculateAnnuity(
        isEmployee === 1,
        estimatedMaxIncome,
        lifeSpan,
        partnerStatus === 1
      )
    );
  };

  /**
   * 老後のために必要な貯金額を計算
   */
  const calculatedTotalRequiredSavings = () => {
    setTotalRequiredSavings(
      calculateRequiredSavingsForOldAge(
        lifeSpan,
        annuity,
        0,
        retiredPay,
        retiredIncome,
        livingCost,
        housingCost,
        medicalCost,
        careCost,
        endingCost,
        otherCost,
        savingsAmount
      )
    );
  };

  /**
   * 老後のために必要な貯金額を計算(丸めた値)
   */
  const calculatedRoundingTotalRequiredSavings = () => {
    setRoundingTotalRequiredSavings(
      calculateRoundingRequiredSavingsForOldAge(
        lifeSpan,
        annuity,
        0,
        retiredPay,
        retiredIncome,
        livingCost,
        housingCost,
        medicalCost,
        careCost,
        endingCost,
        otherCost,
        savingsAmount
      )
    );
  };

  // 診断結果のDB登録
  const regist = () => {
    const obj: DiagnosisData = {
      uid,
      marriageStatus: partnerStatus === 1 ? '夫婦' : '単身',
      livingStandard60to80: lifePlanCost60to80,
      livingStandard80to100: lifePlanCost80to100,
      houseStatus: houseStatus === 1 ? '持ち家' : '賃貸',
      ownedHouseInfo:
        houseStatus === 1
          ? { purchaseAge: purchaseHouseAge, price: purchaseHousePrice }
          : undefined,
      houseRent: houseStatus === 0 ? monthlyRent : undefined,
      careCost,
      medicalExpenses: medicalCost,
      funeralExpenses: endingCost,
      otherExpenses: otherCost,
      basicAnnuity,
      employeeAnnuity,
      annuity,
      age,
      address: 'その他',
      // eslint-disable-next-line no-nested-ternary
      sex: sex === 0 ? '女性' : sex === 1 ? '男性' : '未設定',
      lifeSpan,
      income,
      estimatedIncome: estimatedMaxIncome,
      isEmployee: isEmployee === 1,
      savingsAmount,
      retiredPay,
      incomeAfterRetired: retiredIncome,
      livingCost,
      housingCost,
      totalExpense:
        livingCost +
        housingCost +
        medicalCost +
        endingCost +
        otherCost +
        careCost,
      totalRequiredSavings,
      requiredSavingsPerMonth: calculateRequiredSavingsPerMonth(
        totalRequiredSavings,
        savingsAmount,
        age
      ),
      requiredInvestmentPerMonth: calculateRequiredInvestmentPerMonth(
        totalRequiredSavings,
        savingsAmount,
        age
      ),
      roundingTotalRequiredSavings,
      roundingRequiredSavingsPerMonth: calculateRequiredSavingsPerMonth(
        roundingTotalRequiredSavings,
        savingsAmount,
        age
      ),
      roundingRequiredInvestmentPerMonth: calculateRequiredInvestmentPerMonth(
        roundingTotalRequiredSavings,
        savingsAmount,
        age
      ),
      totalRetirementIncome: calculateTotalRetirementIncome(
        isEmployee === 1,
        estimatedMaxIncome,
        lifeSpan,
        partnerStatus === 1,
        retiredPay,
        retiredIncome,
        0,
        savingsAmount
      ),
    };
    dispatch(registerDiagnosisData(obj));
  };

  // 診断実施後の感想のDB登録
  const registAnxietyLevel = () => {
    dispatch(updateAnxietyLevel(anxietyLevel));
  };

  function renderSwitch(index: number) {
    switch (index) {
      case 0:
        return (
          <div className="bg-white shadow px-4 py-8 ">
            <div>
              <h3 className="text-center text-4xl font-bold">老後のお金診断</h3>
            </div>

            <div>
              <h3 className="text-center text-2xl mt-6 font-bold">
                診断時間最短3分！老後のお金について知りましょう
              </h3>
            </div>

            <div className="grid grid-cols-4 ">
              <div className="col-span-2 row-span-2">
                <img src="/image/diagnosis/main1.png" alt="" />
              </div>
              <div className="col-span-2">
                <img src="/image/diagnosis/main2.png" alt="" />
              </div>
              <div className="col-span-2 ">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(1);
                  }}
                  className="rounded-full text-white w-6/12 px-3 py-6 bg-blue-300 font-semibold text-lg ">
                  診断スタート →
                </button>
              </div>
            </div>
          </div>
        );

      // step1診断 case1 〜 case7
      case 1:
        return (
          <div>
            <div className="grid grid-flow-col gap-4 mt-6 px-64 ">
              <div className="flex flex-col gap-2 lg: gap-3">
                <div className="w-full mt-12 px-5 py-8 font-semibold text-xl text-black">
                  老後の生活は「夫婦」と「単身」どちらで考えていますか？
                </div>
                <div className="w-full font-semibold text-lg">
                  <div className="flex flex-row gap-32 px-16">
                    <button
                      type="button"
                      onClick={() => {
                        setPartnerStatus(1);
                      }}
                      className={`${
                        partnerStatus === 1
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } rounded-lg w-full px-3 py-3  border border-blue-300 font-semibold text-lg`}>
                      夫婦
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setPartnerStatus(0);
                      }}
                      className={`${
                        partnerStatus === 0
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } rounded-lg w-full px-3 py-3  border border-blue-300 font-semibold text-lg`}>
                      単身
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full mt-40">
              <div className="flex-none w-1/12 h-14" />
              <div className="flex-none w-2/12 h-14 " />
              <div className="grow h-14 w-6/12" />
              <div className="flex-none w-2/12 h-14">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(2);
                    setPercent(1);
                  }}
                  disabled={partnerStatus === 9}
                  className={`${
                    partnerStatus !== 9 ? 'bg-blue-300 ' : 'bg-gray-300'
                  } rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white`}>
                  次へ 進む→
                </button>
              </div>
              <div className="flex-none w-1/12 h-14 " />
            </div>
          </div>
        );

      case 2:
        return (
          <div>
            <div className="grid grid-flow-col gap-4 mt-2 px-64 ">
              <div className="flex flex-col gap-2 lg: gap-3">
                <div className="w-full mt-4 px-5 font-semibold text-xl text-black">
                  あなたについて教えてください。
                </div>

                <div className="w-full mt-4 px-5 font-semibold text-xl text-black">
                  あなたの性別は？
                </div>

                <div className="w-full font-semibold text-lg">
                  <div className="flex flex-row gap-32 px-16">
                    <button
                      type="button"
                      onClick={() => {
                        setSex(0);
                      }}
                      className={`${
                        sex === 0
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } rounded-lg w-full px-3 py-3  border border-blue-300 font-semibold text-lg`}>
                      男性
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSex(1);
                      }}
                      className={`${
                        sex === 1
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } rounded-lg w-full px-3 py-3  border border-blue-300 font-semibold text-lg`}>
                      女性
                    </button>
                  </div>
                </div>

                <div className="flex mt-5">
                  <div className="flex-initial w-2/12" />
                  <div className="flex-initial w-3/12 ">
                    <div className="font-bold text-lg text-black px-5 py-3">
                      現在の年齢
                    </div>
                  </div>
                  <div className="flex-initial w-1/12" />
                  <div className="flex-initial w-3/12">
                    <input
                      type="number"
                      max={100}
                      min={18}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value: number = Number(event.target.value);
                        setAge(value);
                      }}
                      value={age}
                      className="w-full px-5 py-3 border-2 border-slate-300 bg-gray-100 font-semibold text-lg text-gray-500 rounded-md"
                    />
                  </div>
                  <div className="flex-initial w-2/12 px-5 py-3 font-bold text-lg text-black">
                    歳
                  </div>
                </div>

                <div className="flex mt-5">
                  <div className="flex-initial w-2/12" />
                  <div className="flex-initial w-3/12 ">
                    <div className="font-bold text-lg text-black px-5 py-3">
                      想定する寿命
                    </div>
                  </div>
                  <div className="flex-initial w-1/12" />
                  <div className="flex-initial w-3/12">
                    <input
                      type="number"
                      max={100}
                      min={60}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value: number = Number(event.target.value);
                        setLifeSpan(value);
                      }}
                      value={lifeSpan}
                      className="w-full px-5 py-3 border-2 border-slate-300 bg-gray-100 font-semibold text-lg text-gray-500 rounded-md"
                    />
                  </div>
                  <div className="flex-initial w-2/12 px-5 py-3 font-bold text-lg text-black">
                    歳
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full mt-36">
              <div className="flex-none w-1/12 h-14" />
              <div className="flex-none w-2/12 h-14 ">
                <button
                  type="button"
                  onClick={() => {
                    previousPage();
                  }}
                  className="bg-white border-2 border-black rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-black">
                  ←前に戻る
                </button>
              </div>
              <div className="grow h-14 w-6/12" />
              <div className="flex-none w-2/12 h-14">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(3);
                    setPercent(2);
                  }}
                  disabled={sex === 9 || age === 0 || lifeSpan === 0}
                  className={`${
                    sex !== 9 && age !== 0 && lifeSpan !== 0
                      ? 'bg-blue-300 '
                      : 'bg-gray-300'
                  } rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white`}>
                  次へ 進む→
                </button>
              </div>
              <div className="flex-none w-1/12 h-14" />
            </div>
          </div>
        );

      case 3:
        return (
          <div>
            <div className="grid grid-flow-col gap-4 mt-6 px-64 ">
              <div className="flex flex-col gap-2 lg: gap-3">
                <div className="w-full px-5 pb-4 font-semibold text-2xl text-black">
                  あなたの老後の理想の暮らしはA~Dの中のどれですか？
                </div>
                <div className="w-full font-semibold text-lg" />
              </div>
            </div>

            <ul className="grid gap-x-4 gap-y-8 grid-cols-4">
              <li className="relative">
                <div className="group block w-full border-blue-900 aspect-w-10 aspect-h-14 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                  <img
                    src={
                      partnerStatus === 1
                        ? '/image/diagnosis/aa.png'
                        : '/image/diagnosis/ba.png'
                    }
                    alt=""
                    className="object-fill pointer-events-none "
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group block w-full border-blue-900 aspect-w-10 aspect-h-14 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                  <img
                    src={
                      partnerStatus === 1
                        ? '/image/diagnosis/ab.png'
                        : '/image/diagnosis/bb.png'
                    }
                    alt=""
                    className="object-cover pointer-events-none "
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group block w-full border-blue-900 aspect-w-10 aspect-h-14 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                  <img
                    src={
                      partnerStatus === 1
                        ? '/image/diagnosis/ac.png'
                        : '/image/diagnosis/bc.png'
                    }
                    alt=""
                    className="object-cover pointer-events-none "
                  />
                </div>
              </li>

              <li className="relative">
                <div className="group block w-full border-blue-900 aspect-w-10 aspect-h-14 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                  <img
                    src={
                      partnerStatus === 1
                        ? '/image/diagnosis/ad.png'
                        : '/image/diagnosis/bd.png'
                    }
                    alt=""
                    className="object-cover pointer-events-none "
                  />
                </div>
              </li>
            </ul>

            <div className="grid grid-cols-2 gap-2 mt-2">
              <div className="self-center justify-self-end">
                <div className="w-full ml-4 py-4 font-normal text-lg text-black">
                  60~80歳の時はどの暮らしがいいですか？
                </div>
              </div>
              <div className="self-center ml-4 justify-self-start">
                <label htmlFor="hopeStatusA" className="mx-8">
                  <input
                    id="hopeStatusA"
                    aria-describedby="small-description"
                    checked={lifePlanCost60to80 === 8}
                    onClick={() => setLifePlanCost60to80(8)}
                    name="status"
                    type="radio"
                    className="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  A
                </label>

                <label htmlFor="hopeStatusB" className="mr-4">
                  <input
                    id="hopeStatusB"
                    aria-describedby="small-description"
                    checked={lifePlanCost60to80 === 14}
                    onClick={() => setLifePlanCost60to80(14)}
                    name="status"
                    type="radio"
                    className="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  B
                </label>

                <label htmlFor="hopeStatusC" className="mr-4">
                  <input
                    id="hopeStatusC"
                    aria-describedby="small-description"
                    checked={lifePlanCost60to80 === 20}
                    onClick={() => setLifePlanCost60to80(20)}
                    name="status"
                    type="radio"
                    className="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  C
                </label>
                <label htmlFor="hopeStatusD">
                  <input
                    id="hopeStatusD"
                    aria-describedby="small-description"
                    checked={lifePlanCost60to80 === 30}
                    onClick={() => setLifePlanCost60to80(30)}
                    name="status"
                    type="radio"
                    className="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  D
                </label>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="self-center justify-self-end">
                <div className="w-full ml-4 py-2 font-normal text-lg text-black">
                  80~100歳の時はどの暮らしがいいですか？
                </div>
              </div>
              <div className="self-center ml-4 justify-self-start">
                <label htmlFor="hopeStatusSecondA" className="mx-8">
                  <input
                    id="hopeStatusSecondA"
                    aria-describedby="small-description"
                    checked={lifePlanCost80to100 === 8}
                    onClick={() => setLifePlanCost80to100(8)}
                    name="statusS"
                    type="radio"
                    className="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  A
                </label>

                <label htmlFor="hopeStatusSecondB" className="mr-4">
                  <input
                    id="hopeStatusSecondB"
                    aria-describedby="small-description"
                    checked={lifePlanCost80to100 === 14}
                    onClick={() => setLifePlanCost80to100(14)}
                    name="statusS"
                    type="radio"
                    className="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  B
                </label>

                <label htmlFor="hopeStatusSecondC" className="mr-4">
                  <input
                    id="hopeStatusSecondC"
                    aria-describedby="small-description"
                    checked={lifePlanCost80to100 === 20}
                    onClick={() => setLifePlanCost80to100(20)}
                    name="statusS"
                    type="radio"
                    className="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  C
                </label>

                <label htmlFor="hopeStatusSecondD">
                  <input
                    id="hopeStatusSecondD"
                    aria-describedby="small-description"
                    checked={lifePlanCost80to100 === 30}
                    onClick={() => setLifePlanCost80to100(30)}
                    name="statusS"
                    type="radio"
                    className="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  D
                </label>
              </div>
            </div>

            <div className="flex w-full mt-14">
              <div className="flex-none w-1/12 h-14" />
              <div className="flex-none w-2/12 h-14 ">
                <button
                  type="button"
                  onClick={() => {
                    previousPage();
                    setPercent(1);
                  }}
                  className="bg-white border-2 border-black rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-black">
                  ←前に戻る
                </button>
              </div>
              <div className="grow h-14 w-6/12" />
              <div className="flex-none w-2/12 h-14">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(4);
                    setPercent(3);
                  }}
                  disabled={
                    lifePlanCost60to80 === 0 || lifePlanCost80to100 === 0
                  }
                  className={`${
                    lifePlanCost60to80 !== 0 && lifePlanCost80to100 !== 0
                      ? 'bg-blue-300 '
                      : 'bg-gray-300'
                  } rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white`}>
                  次へ 進む→
                </button>
              </div>
              <div className="flex-none w-1/12 h-14" />
            </div>
          </div>
        );

      case 4:
        return (
          <div>
            <div className="grid grid-flow-col gap-4 mt-6 px-64">
              <div className="flex flex-col gap-2 lg: gap-3">
                <div className="w-full mt-12 px-5 py-8 font-semibold text-xl text-black">
                  老後の生活は「持ち家」と「賃貸」どちらに住んでいたいですか？
                </div>
                <div className="w-full font-semibold text-lg">
                  <div className="flex flex-row gap-32 px-16">
                    <button
                      type="button"
                      onClick={() => {
                        sethouseStatus(1);
                      }}
                      className={`${
                        houseStatus === 1
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } rounded-lg w-full px-3 py-3  border border-blue-300 font-semibold text-lg`}>
                      持ち家
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        sethouseStatus(0);
                      }}
                      className={`${
                        houseStatus === 0
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } rounded-lg w-full px-3 py-3  border border-blue-300 font-semibold text-lg`}>
                      賃貸
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full mt-40">
              <div className="flex-none w-1/12 h-14" />
              <div className="flex-none w-2/12 h-14 ">
                <button
                  type="button"
                  onClick={() => {
                    previousPage();
                    setPercent(2);
                  }}
                  className="bg-white border-2 border-black rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-black">
                  ←前に戻る
                </button>
              </div>
              <div className="grow h-14 w-6/12" />
              <div className="flex-none w-2/12 h-14">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(houseStatus === 1 ? 5 : 6);
                    setPercent(4);
                  }}
                  disabled={houseStatus === 9}
                  className={`${
                    houseStatus !== 9 ? 'bg-blue-300 ' : 'bg-gray-300'
                  } rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white`}>
                  次へ 進む→
                </button>
              </div>
              <div className="flex-none w-1/12 h-14" />
            </div>
          </div>
        );

      case 5:
        return (
          <div>
            <div className="grid grid-flow-col gap-4 mt-2 px-64 ">
              <div className="flex flex-col gap-2 lg: gap-3">
                <div className="w-full mt-4 px-5 font-semibold text-xl text-black">
                  持ち家購入の年齢と金額はどのくらいですか？
                </div>
                <div className="flex mt-5">
                  <div className="flex-initial w-2/12" />
                  <div className="flex-initial w-3/12 ">
                    <div className="font-bold text-lg text-black px-5 py-3">
                      年齢
                    </div>
                  </div>
                  <div className="flex-initial w-1/12" />
                  <div className="flex-initial w-3/12">
                    <input
                      type="number"
                      max={100}
                      min={18}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value: number = Number(event.target.value);
                        setPurchaseHouseAge(value);
                      }}
                      value={purchaseHouseAge}
                      className="w-full px-5 py-3 border-2 border-slate-300 bg-gray-100 font-semibold text-lg text-gray-500 rounded-md"
                    />
                  </div>
                  <div className="flex-initial w-2/12 px-5 py-3 font-bold text-lg text-black">
                    歳
                  </div>
                </div>

                <div className="flex mt-5">
                  <div className="flex-initial w-2/12" />
                  <div className="flex-initial w-3/12 ">
                    <div className="font-bold text-lg text-black px-5 py-3">
                      金額
                    </div>
                  </div>
                  <div className="flex-initial w-1/12" />
                  <div className="flex-initial w-3/12">
                    <input
                      type="number"
                      max={999999}
                      min={0}
                      step={100}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value: number = Number(event.target.value);
                        setPurchaseHousePrice(value);
                      }}
                      value={purchaseHousePrice}
                      className="w-full px-5 py-3 border-2 border-slate-300 bg-gray-100 font-semibold text-lg text-gray-500 rounded-md"
                    />
                  </div>
                  <div className="flex-initial w-2/12 px-5 py-3 font-bold text-lg text-black">
                    万円
                  </div>
                </div>

                <div className="mt-2  ml-12  block self-center  rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                  <img
                    src="/image/diagnosis/info2.png"
                    alt=""
                    className="object-cover pointer-events-none "
                  />
                </div>
              </div>
            </div>

            <div className="flex w-full">
              <div className="flex-none w-1/12 h-14" />
              <div className="flex-none w-2/12 h-14 ">
                <button
                  type="button"
                  onClick={() => {
                    previousPage();
                    setPercent(4);
                  }}
                  className="bg-white border-2 border-black rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-black">
                  ←前に戻る
                </button>
              </div>
              <div className="grow h-14 w-6/12" />
              <div className="flex-none w-2/12 h-14">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(7);
                  }}
                  disabled={purchaseHousePrice === 0}
                  className={`${
                    purchaseHousePrice !== 0 ? 'bg-blue-300 ' : 'bg-gray-300'
                  } rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white`}>
                  次へ 進む→
                </button>
              </div>
              <div className="flex-none w-1/12 h-14" />
            </div>
          </div>
        );

      case 6:
        return (
          <div>
            <div className="grid grid-flow-col gap-4 mt-2 px-64 ">
              <div className="flex flex-col gap-2 lg: gap-3">
                <div className="w-full mt-4 px-5 font-semibold text-xl text-black">
                  老後に住みたい賃貸の毎月の家賃はどのくらいですか？
                </div>
                <div className="w-full px-5 font-semibold text-md text-gray-400">
                  ※「まだ考えていない」という方は現在の家賃を入力してください
                </div>

                <div className="flex mt-5">
                  <div className="flex-initial w-2/12" />
                  <div className="flex-initial w-3/12 ">
                    <div className="font-bold text-lg text-black px-5 py-3">
                      毎月の家賃額
                    </div>
                  </div>
                  <div className="flex-initial w-1/12" />
                  <div className="flex-initial w-3/12">
                    <input
                      type="number"
                      max={99999}
                      min={0}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value: number = Number(event.target.value);
                        setMonthlyRent(value);
                      }}
                      value={monthlyRent}
                      className="w-full px-5 py-3 border-2 border-slate-300 bg-gray-100 font-semibold text-lg text-gray-500 rounded-md"
                    />
                  </div>
                  <div className="flex-initial w-2/12 px-5 py-3 font-bold text-lg text-black">
                    万円
                  </div>
                </div>
                <div className="mt-2 h-3/6 ml-12  block h-full w-8/12 self-center rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-400 overflow-hidden">
                  <img
                    src="/image/diagnosis/info1.png"
                    alt=""
                    className="object-cover pointer-events-none "
                  />
                </div>
              </div>
            </div>

            <div className="flex w-full">
              <div className="flex-none w-1/12 h-14" />
              <div className="flex-none w-2/12 h-14 ">
                <button
                  type="button"
                  onClick={() => {
                    previousPage();
                    setPercent(4);
                  }}
                  className="bg-white border-2 border-black rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-black">
                  ←前に戻る
                </button>
              </div>
              <div className="grow h-14 w-6/12" />
              <div className="flex-none w-2/12 h-14">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(7);
                  }}
                  disabled={monthlyRent === 0}
                  className={`${
                    monthlyRent !== 0 ? 'bg-blue-300 ' : 'bg-gray-300'
                  } rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white`}>
                  次へ 進む→
                </button>
              </div>
              <div className="flex-none w-1/12 h-14" />
            </div>
          </div>
        );

      case 7:
        return (
          <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="bg-blue-200 mx-6 pb-4">
                  <h3 className="pt-4 text-2xl font-bold leading-6 text-gray-900 ">
                    結果発表
                  </h3>
                  <p className="text-lg font-medium text-black mt-4">
                    あなたが老後に必要なお金は
                  </p>
                  <div className="mt-4">
                    <span className="text-4xl font-bold text-blue-900">
                      {livingCost +
                        housingCost +
                        medicalCost +
                        endingCost +
                        otherCost +
                        careCost}
                    </span>
                    <span className="ml-2 text-lg text-black">万円</span>
                  </div>
                  <p className="text-right text-lg mr-14 ">です！</p>
                </div>
                <h3 className="text-lg mt-8 font-bold text-left leading-6 text-gray-900 mx-8 text-blue-900">
                  Point!
                </h3>
                <p className="mt-1 ml-4 pt-6 text-lg font-bold text-black">
                  生活費・住宅費以外は数値が変更可能
                </p>
                <p className="mt-1 mx-10 pt-6 text-md font-medium text-gray-700 text-left">
                  毎月の積立・貯蓄をより正確に把握するために
                  <br />
                  変更点の記入をおすすめします！
                </p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <h3 className="text-lg font-bold text-left leading-6 text-gray-900">
                  詳細の内訳
                </h3>
                <ul className="divide-y-2 divide-gray-100">
                  <li>
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="truncate text-xl font-bold">
                                生活費
                              </span>
                            </p>
                          </div>
                        </div>
                        <span className="text-3xl text-blue-400 font-bold mr-6">
                          {livingCost}
                        </span>
                        <span className="text-lg font-bold">万円</span>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="truncate text-xl font-bold">
                                住宅費
                              </span>
                            </p>
                          </div>
                        </div>
                        <span className="text-3xl text-blue-400 font-bold mr-6">
                          {housingCost}
                        </span>
                        <span className="text-lg font-bold">万円</span>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="truncate text-xl font-bold">
                                介護費
                              </span>
                            </p>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              <p className="mt-2 flex items-center text-lg text-gray-500">
                                {/* ※平均40万円 */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <span className="text-3xl text-blue-400 font-bold mr-6">
                          <input
                            type="number"
                            max={99999}
                            min={0}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value: number = Number(event.target.value);
                              setCareCost(value);
                            }}
                            value={careCost}
                            className="w-full px-5 border-2 border-slate-300 bg-gray-100 font-semibold text-3xl  mr-6 text-right text-gray-500 rounded-md"
                          />
                        </span>
                        <span className="text-lg font-bold">万円</span>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="truncate text-xl font-bold">
                                医療費
                              </span>
                            </p>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              <p className="mt-2 flex items-center text-lg text-gray-500">
                                {/* ※平均40万円 */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <span className="text-3xl text-blue-400 font-bold mr-6">
                          <input
                            type="number"
                            max={99999}
                            min={0}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value: number = Number(event.target.value);
                              setMedicalCost(value);
                            }}
                            value={medicalCost}
                            className="w-full px-5 border-2 border-slate-300 bg-gray-100 font-semibold text-3xl  mr-6 text-right text-gray-500 rounded-md"
                          />
                        </span>
                        <span className="text-lg font-bold">万円</span>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="truncate text-xl font-bold">
                                葬儀/死後精算費用
                              </span>
                            </p>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              <p className="mt-2 flex items-center text-lg text-gray-500">
                                {/* ※平均40万円 */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <span className="text-3xl text-blue-400 font-bold mr-6">
                          <input
                            type="number"
                            max={99999}
                            min={0}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value: number = Number(event.target.value);
                              setEndingCost(value);
                            }}
                            value={endingCost}
                            className="w-full px-5 border-2 border-slate-300 bg-gray-100 font-semibold text-3xl  mr-6 text-right text-gray-500 rounded-md"
                          />
                        </span>
                        <span className="text-lg font-bold">万円</span>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="truncate text-xl font-bold">
                                その他費用
                              </span>
                            </p>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              <p className="mt-2 flex items-center text-lg text-gray-500">
                                {/* ※平均40万円 */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <span className="text-3xl text-blue-400 font-bold mr-6">
                          <input
                            type="number"
                            max={99999}
                            min={0}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value: number = Number(event.target.value);
                              setOtherCost(value);
                            }}
                            value={otherCost}
                            className="w-full px-5 border-2 border-slate-300 bg-gray-100 font-semibold text-3xl  mr-6 text-right text-gray-500 rounded-md"
                          />
                        </span>
                        <span className="text-lg font-bold">万円</span>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="truncate text-xl font-bold">
                                概算合計
                              </span>
                            </p>
                          </div>
                        </div>
                        <span className="text-3xl text-blue-400 font-bold mr-6">
                          {livingCost +
                            housingCost +
                            medicalCost +
                            endingCost +
                            otherCost +
                            careCost}
                        </span>
                        <span className="text-lg font-bold">万円</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex w-full mt-6">
              <div className="flex-none w-1/12 h-14" />
              <div className="flex-none w-2/12 h-14 ">
                <button
                  type="button"
                  onClick={() => {
                    previousPage();
                    setCareCost(400);
                    setMedicalCost(400);
                    setEndingCost(200);
                  }}
                  className="bg-white border-2 border-black rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-black">
                  ←前に戻る
                </button>
              </div>
              <div className="grow h-14 w-6/12" />
              <div className="flex-none w-2/12 h-14">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(10);
                  }}
                  className="bg-blue-300  rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white">
                  次へ 進む→
                </button>
              </div>
              <div className="flex-none w-1/12 h-14" />
            </div>
          </div>
        );

      // step2診断 case10 〜 case14
      case 10:
        return (
          <div>
            <div className="grid grid-flow-col gap-4 mt-2 px-64 ">
              <div className="flex flex-col gap-2 lg: gap-3">
                <div className="w-full mt-4 px-5 font-semibold text-xl text-black">
                  あなたの収入はどのくらいですか？
                </div>

                <div className="flex mt-5">
                  <div className="flex-initial w-2/12" />
                  <div className="flex-initial w-3/12 ">
                    <div className="font-bold text-lg text-black px-5 py-3">
                      現在の年収
                    </div>
                  </div>
                  <div className="flex-initial w-1/12" />
                  <div className="flex-initial w-3/12">
                    <input
                      type="number"
                      max={999999}
                      min={0}
                      step={10}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value: number = Number(event.target.value);
                        setIncome(value);
                      }}
                      value={income}
                      className="w-full px-5 py-3 border-2 border-slate-300 bg-gray-100 font-semibold text-lg text-gray-500 rounded-md"
                    />
                  </div>
                  <div className="flex-initial w-2/12 px-5 py-3 font-bold text-lg text-black">
                    万円
                  </div>
                </div>

                <div className="flex mt-5">
                  <div className="flex-initial w-2/12" />
                  <div className="flex-initial w-3/12 ">
                    <div className="font-bold text-lg text-black px-5 py-3">
                      想定最大年収
                    </div>
                  </div>
                  <div className="flex-initial w-1/12" />
                  <div className="flex-initial w-3/12">
                    <input
                      type="number"
                      max={99999}
                      min={0}
                      step={10}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value: number = Number(event.target.value);
                        setEstimatedMaxIncome(value);
                      }}
                      value={estimatedMaxIncome}
                      className="w-full px-5 py-3 border-2 border-slate-300 bg-gray-100 font-semibold text-lg text-gray-500 rounded-md"
                    />
                  </div>
                  <div className="flex-initial w-2/12 px-5 py-3 font-bold text-lg text-black">
                    万円
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full mt-36">
              <div className="flex-none w-1/12 h-14" />
              <div className="flex-none w-2/12 h-14 ">
                <button
                  type="button"
                  onClick={() => {
                    previousPage();
                  }}
                  className="bg-white border-2 border-black rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-black">
                  ←前に戻る
                </button>
              </div>
              <div className="grow h-14 w-6/12" />
              <div className="flex-none w-2/12 h-14">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(11);
                    setPercentSecond(1);
                  }}
                  disabled={estimatedMaxIncome === 0 || income === 0}
                  className={`${
                    estimatedMaxIncome !== 0 && income !== 0
                      ? 'bg-blue-300 '
                      : 'bg-gray-300'
                  } rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white`}>
                  次へ 進む→
                </button>
              </div>
              <div className="flex-none w-1/12 h-14" />
            </div>
          </div>
        );

      case 11:
        return (
          <div>
            <div className="grid grid-flow-col gap-4 mt-6 px-64">
              <div className="flex flex-col gap-2 lg: gap-3">
                <div className="w-full mt-12 px-5 py-8 font-semibold text-xl text-black">
                  現在あなたは会社員または公務員ですか？
                </div>
                <div className="w-full font-semibold text-lg">
                  <div className="flex flex-row gap-32 px-16">
                    <button
                      type="button"
                      onClick={() => {
                        setIsEmployee(1);
                      }}
                      className={`${
                        isEmployee === 1
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } rounded-lg w-full px-3 py-3  border border-blue-300 font-semibold text-lg`}>
                      はい
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsEmployee(0);
                      }}
                      className={`${
                        isEmployee === 0
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } rounded-lg w-full px-3 py-3  border border-blue-300 font-semibold text-lg`}>
                      いいえ
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full mt-40">
              <div className="flex-none w-1/12 h-14" />
              <div className="flex-none w-2/12 h-14 ">
                <button
                  type="button"
                  onClick={() => {
                    previousPage();
                    setPercentSecond(1);
                  }}
                  className="bg-white border-2 border-black rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-black">
                  ←前に戻る
                </button>
              </div>
              <div className="grow h-14 w-6/12" />
              <div className="flex-none w-2/12 h-14">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(12);
                    setPercentSecond(2);
                  }}
                  disabled={isEmployee === 9}
                  className={`${
                    isEmployee !== 9 ? 'bg-blue-300 ' : 'bg-gray-300'
                  } rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white`}>
                  次へ 進む→
                </button>
              </div>
              <div className="flex-none w-1/12 h-14" />
            </div>
          </div>
        );

      case 12:
        return (
          <div>
            <div className="grid grid-flow-col gap-4 mt-6 px-64">
              <div className="flex flex-col gap-2 lg: gap-3">
                <div className="w-full  px-5 py-8 font-semibold text-xl text-black">
                  老後資金用の貯蓄はしていますか？
                </div>
                <div className="w-full font-semibold text-lg">
                  <div className="flex flex-row gap-32 px-16">
                    <button
                      type="button"
                      onClick={() => {
                        setIsSaving(1);
                      }}
                      className={`${
                        isSaving === 1
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } rounded-lg w-full px-3 py-3  border border-blue-300 font-semibold text-lg`}>
                      はい
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsSaving(0);
                      }}
                      className={`${
                        isSaving === 0
                          ? 'bg-blue-300 text-white'
                          : 'bg-white text-black'
                      } rounded-lg w-full px-3 py-3  border border-blue-300 font-semibold text-lg`}>
                      いいえ
                    </button>
                  </div>
                </div>
                {isSaving === 1 && (
                  <div>
                    <div className="w-full mt-4 px-5 font-semibold text-xl text-black">
                      現在の貯蓄額はどのくらいですか？
                    </div>
                    <div className="flex mt-5">
                      <div className="flex-initial w-2/12" />
                      <div className="flex-initial w-3/12 ">
                        <div className="font-bold text-lg text-black px-5 py-3">
                          貯蓄額
                        </div>
                      </div>
                      <div className="flex-initial w-1/12" />
                      <div className="flex-initial w-3/12">
                        <input
                          type="number"
                          max={999999}
                          min={0}
                          step={10}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const value: number = Number(event.target.value);
                            setSavingsAmount(value);
                          }}
                          value={savingsAmount}
                          className="w-full px-5 py-3 border-2 border-slate-300 bg-gray-100 font-semibold text-lg text-gray-500 rounded-md"
                        />
                      </div>
                      <div className="flex-initial w-2/12 px-5 py-3 font-bold text-lg text-black">
                        万円
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex w-full mt-24">
              <div className="flex-none w-1/12 h-14" />
              <div className="flex-none w-2/12 h-14 ">
                <button
                  type="button"
                  onClick={() => {
                    previousPage();
                    setPercentSecond(2);
                  }}
                  className="bg-white border-2 border-black rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-black">
                  ←前に戻る
                </button>
              </div>
              <div className="grow h-14 w-6/12" />
              <div className="flex-none w-2/12 h-14">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(13);
                    setPercentSecond(3);
                  }}
                  disabled={isSaving === 9}
                  className={`${
                    isSaving !== 9 ? 'bg-blue-300 ' : 'bg-gray-300'
                  } rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white`}>
                  次へ 進む→
                </button>
              </div>
              <div className="flex-none w-1/12 h-14" />
            </div>
          </div>
        );

      case 13:
        return (
          <div>
            <div className="grid grid-flow-col gap-4 mt-2 px-64 ">
              <div className="flex flex-col gap-2 lg: gap-3">
                <div className="w-full mt-4 px-5 font-semibold text-xl text-black">
                  退職金はいくらもらう想定ですか？
                </div>
                <div className="flex mt-5">
                  <div className="flex-initial w-7/12" />
                  <div className="flex-initial w-3/12">
                    <input
                      type="number"
                      max={999999}
                      min={0}
                      step={100}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value: number = Number(event.target.value);
                        setRetiredPay(value);
                      }}
                      value={retiredPay}
                      className="w-full px-5 py-3 border-2 border-slate-300 bg-gray-100 font-semibold text-lg text-gray-500 rounded-md"
                    />
                  </div>
                  <div className="flex-initial w-2/12 px-5 py-3 font-bold text-lg text-black">
                    万円
                  </div>
                </div>

                <div className="w-full mt-4 px-5 font-semibold text-xl text-black">
                  自身の年金以外の定期収入があればその月額を入力してください。(配偶者の厚生年金も入力)
                </div>
                <div className="flex mt-5">
                  <div className="flex-initial w-7/12" />
                  <div className="flex-initial w-3/12">
                    <input
                      type="number"
                      max={99999}
                      min={0}
                      step={10}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const value: number = Number(event.target.value);
                        setRetiredIncome(value);
                      }}
                      value={retiredIncome}
                      className="w-full px-5 py-3 border-2 border-slate-300 bg-gray-100 font-semibold text-lg text-gray-500 rounded-md"
                    />
                  </div>
                  <div className="flex-initial w-2/12 px-5 py-3 font-bold text-lg text-black">
                    万円
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full mt-36">
              <div className="flex-none w-1/12 h-14" />
              <div className="flex-none w-2/12 h-14 ">
                <button
                  type="button"
                  onClick={() => {
                    previousPage();
                    setPercentSecond(3);
                  }}
                  className="bg-white border-2 border-black rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-black">
                  ←前に戻る
                </button>
              </div>
              <div className="grow h-14 w-6/12" />
              <div className="flex-none w-2/12 h-14">
                <button
                  type="button"
                  onClick={() => {
                    nextPage(14);
                  }}
                  disabled={estimatedMaxIncome === 0 || income === 0}
                  className={`${
                    estimatedMaxIncome !== 0 && income !== 0
                      ? 'bg-blue-300 '
                      : 'bg-gray-300'
                  } rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white`}>
                  次へ 進む→
                </button>
              </div>
              <div className="flex-none w-1/12 h-14" />
            </div>
          </div>
        );

      case 14:
        return (
          <div>
            <div className="grid grid-flow-col gap-4 mt-2 px-64 ">
              <div className="flex flex-col gap-2 lg: gap-3">
                <div className="grow h-14 w-6/12" />
                <div className="w-full mt-4 px-18 font-semibold text-xl text-black">
                  診断お疲れ様でした！！
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 lg: gap-3">
              <div className="grow h-14 w-4/12" />
              <div>
                <button
                  type="button"
                  onClick={() => {
                    nextPage(15);
                  }}
                  className="bg-blue-300 rounded-3xl shadow-lg w-full px-5 py-3 font-semibold text-lg text-white">
                  結果を確認する
                </button>
              </div>
              <div className="flex-none w-1/12 h-14" />
            </div>
          </div>
        );

      case 15:
        return (
          <div className="bg-white shadow px-4 py-5 ">
            <div>
              <h3 className="text-center text-2xl font-bold">結果発表</h3>
              <div className="mx-16 mt-6 pb-6 rounded-lg">
                <div className="px-4 py-5 bg-white shadow rounded-lg border-4 overflow-hidden sm:p-6">
                  <div className="mt-4">
                    <span className="text-center h-6 text-xl mr-4">
                      {' '}
                      あなたが老後までに貯蓄するべきお金は{' '}
                    </span>
                    <span className="text-5xl font-bold text-blue-900">
                      {totalRequiredSavings}
                    </span>
                    <span className="ml-2 text-lg text-black">万円</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-12">
                <span className="text-3xl px-5 font-semibold text-xl text-black">
                  {' '}
                  このお金を60歳までに貯めるには...
                </span>
              </div>
              <div>
                <dl className="mt-12 grid grid-cols-2 gap-16 px-32 ">
                  <div className="px-4 py-5 bg-white shadow rounded-lg border-4 overflow-hidden sm:p-6">
                    <dt className="text-1.8xl font-medium text-gray-700 truncate">
                      資産運用せずに貯金した場合の毎月の貯金額
                    </dt>
                    <span className="mt-4">
                      <span className="mt-1 text-xl text-gray-900">毎月</span>
                      <span className="text-5xl text-center font-extrabold text-blue-800 tracking-tight mx-4">
                        {Math.round(
                          ((totalRequiredSavings - savingsAmount) * 10) /
                            (60 - age) /
                            12
                        ) / 10}
                      </span>
                      <span className="text-lg font-extrabold text-black tracking-tight">
                        万円
                      </span>
                    </span>
                  </div>

                  <div className="px-4 py-5 bg-white shadow rounded-lg border-4 overflow-hidden ">
                    <dt className="text-1.8xl font-medium text-gray-700 truncate">
                      資産運用(年利3%)した場合の毎月の積立金額
                    </dt>
                    <span className="mt-4">
                      <span className="mt-1 text-xl text-gray-900">毎月</span>
                      <span className="text-5xl text-center font-extrabold text-blue-800 tracking-tight mx-4">
                        {Math.round(
                          10 *
                            ((totalRequiredSavings * 1.03 ** (60 - age) +
                              savingsAmount) /
                              (60 - age) /
                              12 /
                              2.72018462574 ** (0.0165 * (60 - age)))
                        ) / 10}
                      </span>
                      <span className="text-lg font-extrabold text-black tracking-tight">
                        万円
                      </span>
                    </span>
                  </div>
                </dl>
              </div>
            </div>
            <img
              src="/image/diagnosis/final.png"
              alt=""
              className="object-cover pointer-events-none "
            />
            <div className="mt-12">
              {/* 診断結果に対する感想 */}

              <div className="w-full mt-4 px-5 font-semibold text-xl text-black">
                診断結果に対して感じたことを選択してください。
              </div>

              <div className="w-full font-semibold text-lg">
                <div className="flex flex-row gap-4 px-4">
                  <button
                    type="button"
                    onClick={() => {
                      setAnxietyLevel(0);
                    }}
                    className={`${
                      anxietyLevel === 0
                        ? 'bg-blue-300 text-white'
                        : 'bg-white text-black'
                    } rounded-lg w-full px-1 py-2  border border-blue-300 text-1xl font-medium text-gray-700 `}>
                    診断結果を解説して欲しい
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setAnxietyLevel(1);
                    }}
                    className={`${
                      anxietyLevel === 1
                        ? 'bg-blue-300 text-white'
                        : 'bg-white text-black'
                    } rounded-lg w-full px-1 py-2  border border-blue-300 text-1xl font-medium text-gray-700`}>
                    将来のお金に不安を感じた
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setAnxietyLevel(2);
                    }}
                    className={`${
                      anxietyLevel === 2
                        ? 'bg-blue-300 text-white'
                        : 'bg-white text-black'
                    } rounded-lg w-full px-1 py-2  border border-blue-300 text-1xl font-medium text-gray-700 `}>
                    よく分からなかった
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setAnxietyLevel(3);
                    }}
                    className={`${
                      anxietyLevel === 3
                        ? 'bg-blue-300 text-white'
                        : 'bg-white text-black'
                    } rounded-lg w-full px-1 py-2  border border-blue-300 text-1xl font-medium text-gray-700`}>
                    将来のお金に不安は感じなかった
                  </button>
                </div>
              </div>
              <br />
              <span className="mt-4 text-center font-semibold text-lg text-gray-500">
                FPさんにはその他のことも気軽に相談できます！
              </span>

              <div className="max-w-2xl mx-auto text-center px-32">
                <button
                  type="button"
                  onClick={() => {
                    onClickMaching();
                  }}
                  className="mt-8 w-full h-16 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-full text-white bg-blue-400">
                  FPに相談するのはこちらから
                </button>
              </div>
            </div>
            <span className="mt-4 text-center text-lg text-gray-500">
              <br />
              <br />
              注１）出力される診断結果ははあくまでも目安です。正確性・完全性を保証するものではありません。
            </span>
            <span className="mt-4 text-center text-lg text-gray-500">
              <br />
              注２）本診断については、特定のサービス、金融商品等の勧誘を目的とするものではありません。
            </span>
            <span className="mt-4 text-center text-lg text-gray-500">
              <br />
              注３）本診断及び掲載された情報の利用により生じるいかなる責務（直接・間接的問わず）も負うものではありません。
            </span>
          </div>
        );
      default:
        return 'foo';
    }
  }
  return (
    <section className="container p-6 mx-auto">
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      {IndexsArray[IndexsArray.length - 1] !== 9 &&
        IndexsArray[IndexsArray.length - 1] < 9 &&
        IndexsArray[IndexsArray.length - 1] !== 0 && (
          <div className="pt-4 px-4">
            <span className="flex items-center truncate space-x-3">
              <span className="font-black truncate text-lg text-blue-900 leading-6">
                Step1
                <span className="truncate font-normal text-black ml-4">
                  老後のお金診断
                </span>
              </span>
            </span>

            <div className="mt-3" aria-hidden="true">
              <div className="bg-gray-200 rounded-full overflow-hidden">
                <div
                  className="h-4 bg-blue-800 rounded-full"
                  style={{ width: `${percent * 20}%` }}
                />
              </div>
              <h4 className="text-right text-lg">{percent}/5</h4>
            </div>
          </div>
        )}

      {IndexsArray[IndexsArray.length - 1] !== 15 &&
        IndexsArray[IndexsArray.length - 1] > 9 && (
          <div className="pt-4 px-4">
            <span className="flex items-center truncate space-x-3">
              <span className="font-black truncate text-lg text-blue-900 leading-6">
                Step2
                <span className="truncate font-normal text-black ml-4">
                  毎月の積立・貯蓄を把握
                </span>
              </span>
            </span>

            <div className="mt-3" aria-hidden="true">
              <div className="bg-gray-200 rounded-full overflow-hidden">
                <div
                  className="h-4 bg-blue-800 rounded-full"
                  style={{ width: `${percentSecond * 25}%` }}
                />
              </div>
              <h4 className="text-right text-lg">{percentSecond}/4</h4>
            </div>
          </div>
        )}

      {renderSwitch(IndexsArray[IndexsArray.length - 1])}
    </section>
  );
};

export default ContentScreen;

/* eslint-disable no-alert */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import firebase from 'firebase';
import { functions } from '../../../firebase';

import { ClientUser } from '../../entities/ClientUser';
import {
  Fp,
  FpReview,
  getFpList as getFpListFunc,
  getUserList as getUserListFunc,
  getFpReviewList as getFpReviewListFunc,
  getFpInfo as getFpInfoFunc,
  getUserInfo as getUserInfoFunc,
  updateFpInfo as updateFpInfoFunc,
} from '../../functions/matchingFunctions';
import { uploadPlanningResultFile as uploadPlanningResultFileFunc } from '../../functions/storageFunctions';

type State = {
  isLoading: boolean;

  // Lists
  fpList: Fp[];
  userList: ClientUser[];
  fpReviewList: FpReview[];

  // info
  fpInfo: Fp;
  userInfo: ClientUser;
  fpReviewInfo: FpReview;
};

const initialState: State = {
  isLoading: false,

  // Lists
  fpList: [],
  userList: [],
  fpReviewList: [],

  // info
  fpInfo: {
    role: 'FP',
    nickName: '',
    belongs: '',
    age: 0,
    fpHistory: 0,
    assetsType: '',
    operationType: '',
    specialtyArray: [],
    strongAreaMap: undefined,
    deliverable: '',
    firstTimeFree: '',
    fee: '',
    financialProductsArray: [],
    introduction: '',
    others: '',
    ratingMap: undefined,
    mtgLink: '',
    id: '',
    autoReplyMessage: '',

    isDisplayed: true,
    receiveNotificationFlag: false,

    imageUrl: undefined,
    videoUrl: undefined,
  },
  userInfo: {
    role: 'User',
    nickName: '',
    age: 0,
    job: '',
    income: 0,
    isDiagnosed: false,
    request: '',
    id: '',
    imageUrl: undefined,
    matchedFPCount: 0,
  },
  fpReviewInfo: {
    userId: '',
    reviewerAge: 0,
    reviewerSex: '',
    message: '',
    ratingMap: undefined,
    createdDate: firebase.firestore.Timestamp.fromDate(new Date()), // TODO: 初期値を設定
  },
};

/**
 * Firestore からFP一覧を取得
 * @return {Promise<Fp[]>} FPの配列
 */
export const getFpList = createAsyncThunk(
  'getFpList/matching',
  async (): Promise<Fp[]> => {
    try {
      return await getFpListFunc();
    } catch (error) {
      /* TODO: エラー対応 */
      window.alert(JSON.stringify(error));
      throw error;
    }
  }
);

/**
 * Firestore から全User一覧を取得
 * @return {Promise<ClientUser[]>} userの配列
 */
export const getUserList = createAsyncThunk(
  'getUserList/matching',
  async (): Promise<ClientUser[]> => {
    try {
      return await getUserListFunc();
    } catch (error) {
      /* TODO: エラー対応 */
      window.alert(JSON.stringify(error));
      throw error;
    }
  }
);

/**
 * Firestore からFPのレビュー一覧を取得
 * @param {fpId} string
 * @return {Promise<FpReview[]>} FPの配列
 */
export const getFpReviewList = createAsyncThunk(
  'getFpReviewList/matching',
  async (args: { fpId: string }): Promise<FpReview[]> => {
    const { fpId } = args;
    try {
      return await getFpReviewListFunc(fpId);
    } catch (error) {
      /* TODO: エラー対応 */
      window.alert(JSON.stringify(error));
      throw error;
    }
  }
);

/**
 * Firestore からFP Info を取得
 * @param {uid} string
 * @return {Fp}
 */
export const getFpInfo = createAsyncThunk(
  'getFpInfo/matching',
  async (args: { uid: string }): Promise<Fp> => {
    try {
      return await getFpInfoFunc(args.uid);
    } catch (error) {
      throw error;
    }
  }
);

/**
 * Firestore から User Info を取得
 * @param {uid} string
 * @return {ClientUser}
 */
export const getUserInfo = createAsyncThunk(
  'getUserInfo/matching',
  async (args: { uid: string }): Promise<ClientUser> => {
    try {
      return await getUserInfoFunc(args.uid);
    } catch (error) {
      throw error;
    }
  }
);

/**
 * Cloud Storageに診断結果ファイルをアップロード
 * @param {userId} string
 * @param {file} File
 */
export const uploadPlanningResultFile = createAsyncThunk<
  void,
  {
    userId: string;
    file: File;
  }
>('matching/upload-file', async ({ userId, file }): Promise<void> => {
  await uploadPlanningResultFileFunc(userId, file);
});

/**
 * FPにてクライアントユーザーとのチャットルームを作成する。
 * 既にチャットルームがあった場合は該当するチャットルームのIDを返却する。
 * @param userID - 相手のuid
 * @return {Promise<string>} チャットルームID
 */
export const matching = createAsyncThunk(
  'matching/matchingUsers',
  async (userID: string): Promise<string> => {
    const chatRoomID = (await functions.httpsCallable('matching')({ userID }))
      .data;
    if (typeof chatRoomID !== 'string') {
      throw new Error();
    }
    return chatRoomID;
  }
);

/**
 * クライアントユーザーとFPをマッチングさせる。(= チャットルームを作成し、自動返信を送信する。)
 * マッチング時に作成された
 * 既にマッチング済みであった場合は該当するチャットルームのIDを返却する。
 * @param fpID - 相手FPのuid
 * @return {Promise<string>} チャットルームID
 */
export const matchingUsers = createAsyncThunk(
  'matching/matchingUsers',
  async (fpID: string): Promise<string> => {
    const chatRoomID = (
      await functions.httpsCallable('matchingUsers')({ fpID })
    ).data;
    if (typeof chatRoomID !== 'string') {
      throw new Error();
    }
    return chatRoomID;
  }
);

/**
 * Firestore上のFPのデータを更新する。
 * @param {string} fpID - FPのuid
 * @param {Partial<Fp>} fpInfo - FP情報(の一部)のオブジェクト
 */
export const updateFpInfo = createAsyncThunk(
  'matching/updateFpInfo',
  async (args: { fpID: string; fpInfo: Partial<Fp> }): Promise<void> => {
    updateFpInfoFunc(args.fpID, args.fpInfo);
  }
);

export const slice = createSlice({
  name: 'matching',
  initialState,
  reducers: {
    /* FP */
    setFpInfo: (
      state: State,
      action: PayloadAction<{ fpInfo: Fp }>
    ): State => ({
      ...state,
      fpInfo: action.payload.fpInfo,
    }),

    /* User */
    setUserInfo: (
      state: State,
      action: PayloadAction<{ userInfo: ClientUser }>
    ): State => ({
      ...state,
      userInfo: action.payload.userInfo,
    }),
  },
  extraReducers: (builder) => {
    /* getFpList */
    builder.addCase(
      getFpList.fulfilled,
      (state, action): State => ({
        ...state,
        fpList: action.payload.filter((fpInfo) => fpInfo.isDisplayed === true),
        isLoading: false,
      })
    );
    builder.addCase(
      getFpList.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      getFpList.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );

    /* getUserList */
    builder.addCase(
      getUserList.fulfilled,
      (state, action): State => ({
        ...state,
        userList: action.payload,
        isLoading: false,
      })
    );
    builder.addCase(
      getUserList.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      getUserList.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );

    /* getFpReviewList */
    builder.addCase(
      getFpReviewList.fulfilled,
      (state, action): State => ({
        ...state,
        fpReviewList: action.payload,
        isLoading: false,
      })
    );
    builder.addCase(
      getFpReviewList.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      getFpReviewList.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );

    /* uploadPlanningResultFile */
    builder.addCase(
      uploadPlanningResultFile.fulfilled,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );
    builder.addCase(
      uploadPlanningResultFile.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      uploadPlanningResultFile.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );

    // /* sendMailAndChatToAllFp */
    // builder.addCase(
    //   sendMailAndChatToAllFp.fulfilled,
    //   (state): State => ({
    //     ...state,
    //     isLoading: false,
    //   })
    // );
    // builder.addCase(
    //   sendMailAndChatToAllFp.pending,
    //   (state): State => ({
    //     ...state,
    //     isLoading: true,
    //   })
    // );
    // builder.addCase(
    //   sendMailAndChatToAllFp.rejected,
    //   (state): State => ({
    //     ...state,
    //     isLoading: false,
    //   })
    // );

    /* getFpInfo */
    builder.addCase(
      getFpInfo.fulfilled,
      (state, action): State => ({
        ...state,
        fpInfo: action.payload,
        isLoading: false,
      })
    );
    builder.addCase(
      getFpInfo.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      getFpInfo.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );

    /* getUserInfo */
    builder.addCase(
      getUserInfo.fulfilled,
      (state, action): State => ({
        ...state,
        userInfo: action.payload,
        isLoading: false,
      })
    );
    builder.addCase(
      getUserInfo.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      getUserInfo.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );

    /* matchingUsers */
    builder.addCase(
      matchingUsers.fulfilled,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );
    builder.addCase(
      matchingUsers.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      matchingUsers.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );

    /* updateFpInfo */
    builder.addCase(
      updateFpInfo.fulfilled,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );
    builder.addCase(
      updateFpInfo.pending,
      (state): State => ({
        ...state,
        isLoading: true,
      })
    );
    builder.addCase(
      updateFpInfo.rejected,
      (state): State => ({
        ...state,
        isLoading: false,
      })
    );
  },
});

export default slice.reducer;
export const {
  /* FP */
  setFpInfo,

  /* User */
  setUserInfo,
} = slice.actions;

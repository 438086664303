import firebase from 'firebase';
import { db } from '../../firebase';
import { DB_PATH } from '../../resources/constants';

import { Person } from '../entities/Person';
import { clientUserConverter, ClientUser } from '../entities/ClientUser';

import { getImageUrlFromStorage } from './storageFunctions';

const FP_PATH = 'fps';
const REVIEW_PATH = 'reviews';
const FP_IMAGE_PATH = 'fp-images';
const USER_IMAGE_PATH = 'user-images';
const FP_VIDEO_PATH = 'fp-videos';

export const DEFAULT_AUTO_REPLY_MESSAGE = `※このメールはシステムからの自動返信です。

チャット開始ありがとうございます。
FPからの連絡をお待ちください。`;

export interface FpOfFirestore {
  nickName: string;
  belongs: string;
  age: number;
  sex?: '男性' | '女性';
  fpHistory: number;
  assetsType: string;
  operationType: string;
  specialtyArray: string[];

  strongAreaMap?: {
    oldAge: boolean;
    housing: boolean;
    education: boolean;
    assetManagement: boolean;
    insurance: boolean;
  };

  deliverable: string;
  firstTimeFree: string;
  fee: string;
  financialProductsArray: string[];
  introduction: string;
  others: string;

  ratingMap?: {
    totalRating: number;
    affable: number;
    satisfactory: number;
    understandability: number;
    retainable: number;
  };

  mtgLink: string;

  isDisplayed: boolean;
  receiveNotificationFlag: boolean;

  autoReplyMessage: string;
}

export interface Fp extends FpOfFirestore, Person {
  videoUrl: string | undefined;
}

export type TalkOfFirestore = {
  isFp: boolean;
  date: firebase.firestore.Timestamp;
  body: string;
  serialNumber: number;
};

export interface FpReview {
  userId: string;
  reviewerAge: number;
  reviewerSex: string;
  message: string;

  ratingMap?: {
    totalRating: number;
    affable: number;
    satisfactory: number;
    understandability: number;
    retainable: number;
  };
  createdDate: firebase.firestore.Timestamp;
}

/**
 * FpListコレクション I/O コンバーター
 */
const fpListConverter: firebase.firestore.FirestoreDataConverter<FpOfFirestore> =
  {
    toFirestore: (fp: FpOfFirestore): firebase.firestore.DocumentData => fp,
    fromFirestore: (
      snapshot: firebase.firestore.QueryDocumentSnapshot
    ): FpOfFirestore => {
      const fpData = snapshot.data() as FpOfFirestore;
      return {
        nickName: fpData.nickName ?? '',
        belongs: fpData.belongs ?? '',
        age: fpData.age ?? 0,
        sex: fpData.sex,
        fpHistory: fpData.fpHistory ?? 0,
        assetsType: fpData.assetsType ?? '',
        operationType: fpData.operationType ?? '',
        specialtyArray: fpData.specialtyArray ?? [],
        strongAreaMap: fpData.strongAreaMap ?? {
          oldAge: false,
          housing: false,
          education: false,
          assetManagement: false,
          insurance: false,
        },
        deliverable: fpData.deliverable ?? '',
        firstTimeFree: fpData.firstTimeFree ?? '',
        fee: fpData.fee ?? '',
        financialProductsArray: fpData.financialProductsArray ?? [],
        introduction: fpData.introduction ?? '',
        others: fpData.others ?? '',
        ratingMap: fpData.ratingMap ?? {
          totalRating: 0,
          affable: 0,
          satisfactory: 0,
          understandability: 0,
          retainable: 0,
        },
        mtgLink: fpData.mtgLink ?? '',
        isDisplayed: fpData.isDisplayed ?? true,
        receiveNotificationFlag: fpData.receiveNotificationFlag ?? false,

        autoReplyMessage: fpData.autoReplyMessage ?? DEFAULT_AUTO_REPLY_MESSAGE,
      };
    },
  };

/**
 * FpReviewListコレクション I/O コンバーター
 */
const fpReviewListConverter: firebase.firestore.FirestoreDataConverter<FpReview> =
  {
    toFirestore: (fpReview: FpReview): firebase.firestore.DocumentData =>
      fpReview,
    fromFirestore: (
      snapshot: firebase.firestore.QueryDocumentSnapshot
    ): FpReview => {
      const fpData = snapshot.data() as FpReview;
      return {
        userId: fpData.userId ?? '',
        reviewerAge: fpData.reviewerAge ?? 0,
        reviewerSex: fpData.reviewerSex ?? '',
        message: fpData.message ?? '',
        ratingMap: fpData.ratingMap ?? {
          totalRating: 0,
          affable: 0,
          satisfactory: 0,
          understandability: 0,
          retainable: 0,
        },
        createdDate: fpData.createdDate,
      };
    },
  };

/**
 * Firestore からFP一覧を取得
 * @return {Fp[]} FPの配列
 */
export async function getFpList(): Promise<Fp[]> {
  const fpQuerySnapshot = await db
    .collection(FP_PATH)
    .withConverter(fpListConverter)
    .get();
  const fpList = await Promise.all(
    fpQuerySnapshot.docs.map(async (doc) => {
      const fpData = doc.data();
      return {
        ...fpData,
        id: doc.id,
        role: 'FP' as Person['role'],
        imageUrl: await getImageUrlFromStorage(
          `${FP_IMAGE_PATH}/${doc.id}.png`
        ),
        videoUrl: await getImageUrlFromStorage(
          `${FP_VIDEO_PATH}/${doc.id}.mp4`
        ),
      };
    })
  );
  return fpList;
}

/**
 * Firestore からFP Info を取得
 * @param {uid} string
 * @return {Fp}
 */
export async function getFpInfo(uid: string): Promise<Fp> {
  const documentSnapshot = await db
    .collection(FP_PATH)
    .doc(uid)
    .withConverter(fpListConverter)
    .get();
  const fpInfo = documentSnapshot.data() as Fp;
  return {
    ...fpInfo,
    id: uid,
    role: 'FP',
    imageUrl: await getImageUrlFromStorage(`${FP_IMAGE_PATH}/${uid}.png`),
    videoUrl: await getImageUrlFromStorage(`${FP_VIDEO_PATH}/${uid}.mp4`),
  };
}

/**
 * FirestoreのFPドキュメントを更新する。
 * @param {string} fpID - FPのuid
 * @param {Fp} fpInfo - 更新後のFPの情報。この内容でFirestoreを更新する。
 */
export async function updateFpInfo(fpID: string, fpInfo: Partial<Fp>) {
  await db
    .collection(FP_PATH)
    .doc(fpID)
    .withConverter(fpListConverter)
    .set(fpInfo, { merge: true });
}

/**
 * Firestore から全User一覧を取得
 * @return {ClientUser[]} Userの配列
 */
export async function getUserList(): Promise<ClientUser[]> {
  const userIdQuerySnapshot = await db
    .collection(DB_PATH.USERS)
    .withConverter(clientUserConverter)
    .get();

  return userIdQuerySnapshot.docs.map((doc) => doc.data());
}

/**
 * Firestore から User Info を取得
 * @param {uid} string
 * @return {ClientUser}
 */
export async function getUserInfo(uid: string): Promise<ClientUser> {
  const documentSnapshot = await db
    .collection(DB_PATH.USERS)
    .doc(uid)
    .withConverter(clientUserConverter)
    .get();
  const userInfo = documentSnapshot.data() as ClientUser;
  return {
    ...userInfo,
    imageUrl: await getImageUrlFromStorage(`${USER_IMAGE_PATH}/${uid}.png`),
  };
}

/**
 * Firestore から User Info or FP Info を取得。
 * ともにPersonとして扱う。
 * @param {uid} string
 * @param {role} string
 * @return {ClientUser}
 */
export async function getPersonInfo(
  uid: string,
  role: Person['role']
): Promise<Person> {
  return role === 'FP' ? getFpInfo(uid) : getUserInfo(uid);
}

// /** 全 FP 宛にメールの送信とメッセージの送信
//  * @param {userId} string
//  * @return {void} なし
//  */
// export async function sendMailAndChatToAllFp(userId: string): Promise<void> {
//   // nickname の取得
//   await getNickName().then(async (nickname) => {
//     // FP一覧 の取得
//     await getFpList().then((fpList) => {
//       fpList.forEach(async (fp) => {
//         const body = `※このメールはシステムからの自動送信です。\n\n\n\n${nickname}さんはFistPlatで専門家の提案を待っています。\n\nPCログインはこちら：\nhttps://fistplat.web.app/signin`;

//         // チャットの送信
//         await sendChatMsg(fp.id, userId, body, false);

//         // id からメールアドレスを取得
//         functions
//           .httpsCallable('getEmailAddress')(fp.id)
//           .then((result: { data: string }) => {
//             const recipientMailAddress = result.data;

//             // メッセージを送信する
//             try {
//               functions.httpsCallable('sendMail')({
//                 receiverEmailAddress: recipientMailAddress,
//                 title: '【FistPlat】メッセージ受信のお知らせ',
//                 body,
//               });
//             } catch (error) {
//               throw error;
//             }
//           });
//       });
//     });
//   });
// }

/**
 * Firestore からレビュー一覧を取得
 * @param {fpId} string
 * @return {FpReview[]} FPの配列
 */
export async function getFpReviewList(fpId: string): Promise<FpReview[]> {
  const fpQuerySnapshot = await db
    .collection(FP_PATH)
    .doc(fpId)
    .collection(REVIEW_PATH)
    .withConverter(fpReviewListConverter)
    .get();
  const fpReviewList = await Promise.all(
    fpQuerySnapshot.docs.map(async (doc) => {
      const fpReviewInfo = doc.data() as FpReview;
      return {
        ...fpReviewInfo,
      };
    })
  );
  return fpReviewList;
}

/**
 * Firestore から該当のFPのReview情報を取得
 * @param {fpId} string
 * @param {reviewId} string
 * @return {Fp}
 */
export async function getFpReviewInfo(
  fpId: string,
  reviewId: string
): Promise<FpReview> {
  const documentSnapshot = await db
    .collection(FP_PATH)
    .doc(fpId)
    .collection(REVIEW_PATH)
    .doc(reviewId)
    .withConverter(fpReviewListConverter)
    .get();
  const fpReviewInfo = documentSnapshot.data() as FpReview;
  return {
    ...fpReviewInfo,
  };
}
